import React, { useEffect, useState } from "react";
import "./CandidateRegistration.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ReusableButton from "../../../../src/CommonComponents/Button/Button";
import ReusableInputfield from "../../../CommonComponents/TextField/TextField";
import It from "../../../../src/Assests/IT.png";
import CandidateTrained from "../../../../src/Assests/trained.png";
import CrackItLogo from "../../../../src/Assests/CrackItLogo.png";
import Crack from "../../../../src/Assests/Crack.png";
import { makeStyles } from "@material-ui/core";
import { MyForm } from "../../../CommonComponents/MyForm/MyForm";
import { API } from "../../../Networking/API";
import Notification from "../../../CommonComponents/Notification/Notification";
import { Navigate, useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import AbsyzLogo from "../../../Assests/absyz_logo.png";
import AbsyzNewLogo from "../../../Assests/absyz.svg";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Modal,
  Typography,
} from "@mui/material";

const CandidateRegistrationTrained = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [done, setDone] = useState(false);
  const handleClose = () => {
    setOpen(false);
    navigate("/candidate-login");
  };
  const [successMsg, setSuccessMsg] = useState("");
  const [closeTab, setCloseTab] = useState(false);
  const location = useLocation();
  const [driveID] = useState(Number(location.state));
  const [privacyStatus, setPrivacyStatus] = useState(true);
  const [privacyPopUp, setPrivacyPopup] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phNo: "",
    collegeYear: "",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: { xs: "75vh", md: "auto" },
    overflowX: { xs: "scroll", md: "inherit" },
  };

  const handlePrivacyClick = () => {
    setPrivacyPopup(true);
  };

  const handleClosePrivacyPopup = () => {
    setPrivacyPopup(false);
  };

  const d = new Date();
  let years = d.getFullYear();

  const validate = (inputValues = values) => {
    let code = { ...errors };

    if ("firstName" in inputValues)
      code.firstName = inputValues.firstName ? "" : "First name is required";

    if ("lastName" in inputValues)
      code.lastName = inputValues.lastName ? "" : "Last name is required";

    const emailValidation =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if ("email" in inputValues) {
      code.email = inputValues.email
        ? emailValidation.test(inputValues.email)
          ? ""
          : "Email is not valid"
        : "Email is required";
    }

    if ("phNo" in inputValues)
      code.phNo = inputValues.phNo
        ? inputValues.phNo.length !== 10
          ? "Invalid Phone number"
          : ""
        : "Phone number is required";

    if ("collegeYear" in inputValues) {
      code.collegeYear = inputValues.collegeYear ? "" : "Year is required";
      if (inputValues.collegeYear > years) {
        code.collegeYear =
          inputValues.collegeYear.length !== 4
            ? "Please provide correct year"
            : "";
      }
    }

    setErrors({
      ...code,
    });

    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    validateOnEdit,
  } = MyForm(initialValues, true, validate);

  const handleSubmit = () => {
    if (validate()) {
      setDone(true);

      API.submitTrainedRegistration(
        driveID,
        values.firstName,
        values.lastName,
        values.email,
        values.phNo,
        values.collegeYear
      ).then(({ response }) => {
        if (response.status === true) {
          // setNotify({
          //   isOpen: true,
          //   message: response.message,
          //   type: "success",
          // });
          setDone(false);
          setSuccessMsg(response.message);
          setOpen(true);
          resetForm();
        } else {
          setDone(false);
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
        }
      });
    }
  };

  const handleCheckboxTick = (event) => {
    setPrivacyStatus(!event.target.checked);
  };

  return (
    <div>
      <Box className="candidate-Landing-Page-Outer-trained">
        {done ? (
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#00728E"}
              height={100}
              width={100}
            />
          </Box>
        ) : (
          <>
            {/* //for_web */}
            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              className="for_Web"
            >
              <Grid
                container
                spacing={2}
                style={{
                  width: "70%",
                  margin: "50px auto 0px auto",
                  background: "#fff",
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    padding: "0px",
                  }}
                >
                  <img
                    src={CandidateTrained}
                    alt="CandidateTrained"
                    width="100%"
                    height="100%"
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginTop: "20px",
                    }}
                  >
                    {/* <img
                    src={CrackItLogo}
                    alt="logo"
                    width="80"
                    height="80"
                  ></img>
                  <span style={{ marginTop: "6px" }} className="recruiter_text">
                    Recruitment 2023
                    <img src={Crack} alt="logo" width="80" height="17"></img>{" "}
                  &nbsp;
                  <img src={It} alt="logo" width="20" height="20"></img>
                  </span> */}
                    {/* <Box sx={{ marginTop: "20px" }}> */}
                    <img
                      src={AbsyzNewLogo}
                      alt="logo"
                      // width="80"
                      // height="80"
                    ></img>
                    {/* </Box> */}
                  </div>

                  <div className="content_text">
                    Trained Candidates Registration Form
                  </div>

                  <div className="form__div">
                    <Grid container spacing={2} style={{}}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          First Name
                        </div>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="firstName"
                          placeholder="Enter first name"
                          required
                          value={values.firstName}
                          inputProps={{
                            autoComplete: "off",
                          }}
                          className="trained_inputfields"
                          onChange={handleInputChange}
                          error={errors.firstName}
                          helperText={errors.firstName}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Last Name
                        </div>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="lastName"
                          className="trained_inputfields"
                          placeholder="Enter last name"
                          required
                          value={values.lastName}
                          inputProps={{
                            autoComplete: "off",
                          }}
                          onChange={handleInputChange}
                          error={errors.lastName}
                          helperText={errors.lastName}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Email
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="email"
                          id="email"
                          placeholder="Enter email"
                          required
                          className="trained_inputfields"
                          name="email"
                          value={values.email}
                          onChange={handleInputChange}
                          error={errors.email}
                          helperText={errors.email}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        marginTop: "0",
                      }}
                    >
                      <Grid
                        item
                        xs={5.6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Phone Number
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          id="phNo"
                          required
                          name="phNo"
                          className="trained_inputfields"
                          placeholder="Enter phone number"
                          value={values.phNo}
                          onChange={handleInputChange}
                          error={errors.phNo}
                          helperText={errors.phNo}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={5.6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          College Year
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="number"
                          id="collegeYear"
                          className="trained_inputfields"
                          required
                          placeholder="Enter college year"
                          name="collegeYear"
                          value={values.collegeYear}
                          onChange={handleInputChange}
                          error={errors.collegeYear}
                          helperText={errors.collegeYear}
                        />
                      </Grid>
                    </Grid>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Checkbox
                        label="checkbox"
                        className="privacy_checkbox"
                        onChange={handleCheckboxTick}
                      />
                      <span className="privacy_text">
                        I accept the{" "}
                        <span onClick={handlePrivacyClick}>Privacy Notice</span>
                      </span>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginTop: "15px" }}>
                        <ReusableButton
                          text={"Get Started"}
                          endIcon={<ArrowRightAltIcon />}
                          variant="contained"
                          onClick={handleSubmit}
                          disabled={privacyStatus}
                          style={{
                            background: "#00728E",
                            borderRadius: "4px",
                            fontFamily: "Helvetica",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "24px",
                            color: "#FFFFFF",
                            textTransform: "capitalize !important",
                            "&:hover": { backgroundColor: "#00728E" },
                            padding: "6px 38px",
                          }}
                        />
                      </div>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Box>

            {/* //for_mobile */}
            <Box
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
              className="for_Mobile"
            >
              <Grid
                container
                spacing={2}
                style={{
                  width: "70%",
                  margin: "50px auto 0px auto",
                  background: "#fff",
                }}
              >
                {/* <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    padding: "0px",
                  }}
                >
                  <img
                    src={CandidateTrained}
                    alt="CandidateTrained"
                    width="100%"
                    height="100%"
                  />
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginTop: "20px",
                    }}
                  >
                    {/* <img
                    src={CrackItLogo}
                    alt="logo"
                    width="80"
                    height="80"
                  ></img>
                  <span style={{ marginTop: "6px" }} className="recruiter_text">
                    Recruitment 2023
                    <img src={Crack} alt="logo" width="80" height="17"></img>{" "}
                  &nbsp;
                  <img src={It} alt="logo" width="20" height="20"></img>
                  </span> */}
                    {/* <Box sx={{ marginTop: "20px" }}> */}
                    <img
                      src={AbsyzNewLogo}
                      alt="logo"
                      // width="80"
                      // height="80"
                    ></img>
                    {/* </Box> */}
                  </div>

                  <div className="content_text">
                    Trained Candidates Registration Form
                  </div>

                  <div className="form__div">
                    <Grid container spacing={2} style={{}}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          First Name
                        </div>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="firstName"
                          placeholder="Enter first name"
                          required
                          value={values.firstName}
                          inputProps={{
                            autoComplete: "off",
                          }}
                          className="trained_inputfields"
                          onChange={handleInputChange}
                          error={errors.firstName}
                          helperText={errors.firstName}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Last Name
                        </div>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="lastName"
                          className="trained_inputfields"
                          placeholder="Enter last name"
                          required
                          value={values.lastName}
                          inputProps={{
                            autoComplete: "off",
                          }}
                          onChange={handleInputChange}
                          error={errors.lastName}
                          helperText={errors.lastName}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Email
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="email"
                          id="email"
                          placeholder="Enter email"
                          required
                          className="trained_inputfields"
                          name="email"
                          value={values.email}
                          onChange={handleInputChange}
                          error={errors.email}
                          helperText={errors.email}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        marginTop: "12px",
                      }}
                    >
                      <Grid
                        item
                        xs={5.6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Phone Number
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          id="phNo"
                          required
                          name="phNo"
                          className="trained_inputfields"
                          placeholder="Enter phone number"
                          value={values.phNo}
                          onChange={handleInputChange}
                          error={errors.phNo}
                          helperText={errors.phNo}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={5.6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          College Year
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="number"
                          id="collegeYear"
                          className="trained_inputfields"
                          required
                          placeholder="Enter college year"
                          name="collegeYear"
                          value={values.collegeYear}
                          onChange={handleInputChange}
                          error={errors.collegeYear}
                          helperText={errors.collegeYear}
                        />
                      </Grid>
                    </Grid>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Checkbox
                        label="checkbox"
                        className="privacy_checkbox"
                        onChange={handleCheckboxTick}
                      />
                      <span className="privacy_text">
                        I accept the{" "}
                        <span onClick={handlePrivacyClick}>Privacy Notice</span>
                      </span>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginTop: "15px" }}>
                        <ReusableButton
                          text={"Get Started"}
                          endIcon={<ArrowRightAltIcon />}
                          variant="contained"
                          onClick={handleSubmit}
                          disabled={privacyStatus}
                          style={{
                            background: "#00728E",
                            borderRadius: "4px",
                            fontFamily: "Helvetica",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "24px",
                            color: "#FFFFFF",
                            textTransform: "capitalize !important",
                            "&:hover": { backgroundColor: "#00728E" },
                            padding: "6px 38px",
                          }}
                        />
                      </div>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>

      <Dialog
        divider
        open={open}
        fullWidth="true"
        maxWidth="sm"
        onClose={handleClose}
        BackdropProps={{
          background: "#00000022",
          boxShadow: "0px 0px 0px 0px !important",
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent
          style={{ boxShadow: "none" }}
          BackdropProps={{
            style: { boxShadow: "none", transition: "none", width: "100%" },
          }}
        >
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ width: "100%", padding: "25px 25px 0px 25px" }}
          >
            <Box
              className="edit__modal"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginRight: "15px",
              }}
            >
              <span className="success_msg">
                {successMsg} !{/* Candidate Registered Successfully! */}
              </span>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            width: "100%",
            padding: "25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ReusableButton
            size="medium"
            variant="outlined"
            className="qstnUpldPageBttn"
            text="OK"
            onClick={handleClose}
            style={{
              TextAlign: "center",
              color: "#fff",
              backgroundColor: "#00728E",
              "&:hover": { backgroundColor: "#00728E" },
            }}
          />
        </DialogActions>
      </Dialog>

      {/* //privacy pop up */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={privacyPopUp}
        onClose={handleClosePrivacyPopup}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={privacyPopUp}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              className="privacy_header-text"
            >
              Privacy Notice
            </Typography>
            <Box id="transition-modal-description" sx={{ mt: 2 }}>
              <span className="privacy_modal-text">
                As part of any recruitment process, ABSYZ collects and processes
                personal data relating to job applicants. Our organization is
                committed to being transparent about how it collects and uses
                that data and to meeting its data protection obligations.
              </span>

              <Box className="sub_content">
                <span className="privacy_modal-text">
                  The data we are collecting includes:
                </span>
                <ul>
                  <li className="privacy_modal-text-li">
                    Your name, contact details including email address and phone
                    number
                  </li>
                  <li className="privacy_modal-text-li">
                    Qualification, skills
                  </li>
                  <li className="privacy_modal-text-li">
                    College, branch name, CGPA
                  </li>
                  <li className="privacy_modal-text-li">
                    Any other details shared during registration
                  </li>
                </ul>
              </Box>

              <Box className="sub_content-secondary">
                <span className="privacy_modal-text secondary-text">
                  ABSYZ may collect this information in a variety of ways. For
                  example, data might be contained in resumes, application
                  forms, photos collected through interviews or other forms of
                  assessment.
                </span>

                <span className="privacy_modal-text secondary-text second-data">
                  This information will be stored in the HR management system
                  and other IT systems. Your privacy is important to us and we
                  provide utmost care to protect your data. Your information may
                  be shared internally, with the other entities of our group and
                  third parties for the purposes of the recruitment process.
                  This includes members of the Human Resources (HR) and
                  recruitment team, interviewers involved in the recruitment
                  process, clients, Background Check (BGC) vendors if access to
                  the data is necessary for the performance of their roles.
                </span>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default CandidateRegistrationTrained;
