import React, { useEffect, useState } from "react";
import "./QuizCompleted.css";
import Box from "@mui/material/Box";
import ReusableButton from "../../../CommonComponents/Button/Button";
import Paper from "@mui/material/Paper";
import GreetingPic from "../../../Assests/Group 283.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { API } from "../../../Networking/API";
import Notification from "../../../CommonComponents/Notification/Notification";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";
import { useLocation } from "react-router-dom";

export const QuizCompleted = () => {
  const navigate = useNavigate();
  const [candidateId, setCandidateId] = useState(
    localStorage.getItem("candidateId")
  );
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [apitudeStatus, setAptitudeStatus] = useState(false);
  const [reasoningStatus, setReasoningStatus] = useState(false);
  const [verbalStatus, setVerbalStatus] = useState(false);
  const [technicalStatus, setTechnicalStatus] = useState(false);
  const [pendingQuiz, setPendingQuiz] = useState();
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    getQuizCategory();
  });

  const getQuizCategory = () => {
    API.getQuizCategoryByCandidate(candidateId).then((response) => {
      if (response.data.status) {
        const data = response.data.data;

        const logoutStatus = data.find((item) => {
          if (!item.tracking?.is_question_submitted) {
            return item;
          }
        });

        const isSubmitted = data.map((items, index) => {
          if (!logoutStatus) {
            setLogout(true);
            setPendingQuiz("logout");
          } else {
            if (items.id !== location.state) {
              if (items.tracking !== null) {
                if (items.tracking.is_question_submitted) {
                  setAptitudeStatus(true);
                } else {
                  setPendingQuiz("next category");
                }
              } else {
                setPendingQuiz(`next category`);
              }
            }
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "error",
        });
      }
    });
  };

  const handleContinueButton = () => {
    if (logout === true) {
      Cookies.remove("jwtToken");
      Cookies.remove("role");
      localStorage.removeItem("candidateID");
      localStorage.removeItem("driveID");
      localStorage.removeItem("experience");
      navigate("/candidate-login");
    } else {
      navigate("/quiz-section");
      setPendingQuiz("");
    }
  };

  return (
    <>
      <Box
        className="greetings-main-container"
        sx={{
          padding: { xs: "20px", sm: "0px" },
        }}
      >
        <Paper
          elevation={1}
          sx={{
            margin: "auto",
            padding: "10px 20px 50px 40px",
            borderRadius: "0px",
          }}
        >
          {loading === true ? (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: { xs: "48vh", sm: "69vh" },
                width: { xs: "35vh", sm: "63vh" },
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#00728E"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <Box className="paper-content">
              <div style={{ width: "100%", textAlign: "end" }}>
                {/* <IconButton size="large">
                  <CloseIcon fontSize="large" className="close-icon" />
                </IconButton> */}
              </div>
              <img
                src={GreetingPic}
                alt="greeting_pic"
                width=""
                height=""
                style={{ margin: "auto" }}
              />
              <div style={{ display: "grid", paddingRight: "20px" }}>
                <span className="tnq-text">Thank you!</span>
                <span className="completed-text">
                  You have successfully completed the quiz!
                </span>
                <hr className="greeting-line"></hr>

                <span className="continue-text">
                  Click continue for {pendingQuiz}
                </span>

                <div className="continue-button">
                  <ReusableButton
                    endIcon={<ArrowRightAltIcon />}
                    onClick={handleContinueButton}
                    text="Continue"
                    style={{
                      color: "#fff",
                      background: "#00728E",
                      fontWeight: "500",
                      border: "none",
                      boxShadow: "none",
                      textTranform: "capitalize !important",
                      padding: "8px 30px",
                      borderRadius: "8px",
                      "&:hover": {
                        background: "#00728E",
                        border: "none",
                        boxShadow: "none",
                        cursor: "pointer",
                      },
                    }}
                  />
                </div>
              </div>
            </Box>
          )}
        </Paper>
        <Notification notify={notify} setNotify={setNotify} />
      </Box>
    </>
  );
};
