/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "./Quiz.css";
import Box from "@mui/material/Box";
import ReusableButton from "../../../CommonComponents/Button/Button";
import Grid from "@mui/material/Grid";
import ResuableDialogBox from "../../../CommonComponents/DialogBox/DialogBox";
import WestIcon from "@mui/icons-material/West";
import { Link, useLocation } from "react-router-dom";
import { API } from "../../../Networking/API";
import QuizHeader from "./QuizHeader";
import Notification from "../../../CommonComponents/Notification/Notification";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText } from "@material-ui/core";
import ReactLoading from "react-loading";
import moment from "moment";
import QuizQuestions from "./QuizQuestions";

export function Quiz() {
  const location = useLocation();

  const [selectedSection, setSelectedSection] = useState(
    location.state.selectedSectionName
  );
  const [selectedSectionBgImage, setSelectedSectionBgImage] = useState(
    location.state.selectedSectionBgbefore
  );
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allQuestionData, setAllQuestionData] = useState([]);
  const [qstnState, setQstnState] = useState(0);
  const [backBtnOpen, setBackBtnOpen] = React.useState(false);
  const [qstnIdFromUser, setQstnIdFromUser] = useState(
    location.state.questionID
  );
  const [clicked, setClicked] = useState(null);
  const [innerText, setInnerText] = useState("");
  const [status, setStatus] = useState(false);
  const [trackCrntQstnId, setTrackCrntQstnId] = useState("");
  const [trackCrntQstnIndex, setTrackCrntQstnIndex] = useState("");
  const [candidateId, setCandidateId] = useState(
    localStorage.getItem("candidateId")
  );
  const [quizCategoryId, setQuizCategoryId] = useState(
    location.state.QuizCategoryId
  );
  const [timer, setTimer] = useState([]);
  const [crntQstnId, setCrntQstnId] = useState("");
  const [sectionType, setSectionType] = useState(location.state.sectionType);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [sectionStatus, setSectionStatus] = useState(
    location.state.sectionClicked
  );
  const [timerOver, setTimerOver] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [done, setDone] = useState(false);
  const [fromTime, setFromTime] = useState();
  const [toTime, setToTime] = useState();
  const [attended, setAttended] = useState(location.state.attendedDetails);
  const [msqOption, setMsqOption] = useState([]);
  const [qstnType, setQstnType] = useState();
  const [countErrMsg, setCountErrMsg] = useState(false);
  const [unansweredCount, setUnansweredCount] = useState("");
  const [questionId, setQuestionId] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);

  const lengthOfQuestion = allQuestionData.length;

  let trackingstatus = localStorage.getItem("trackingStatus" + quizCategoryId);

  useEffect(() => {
    if (quizCategoryId !== null) {
      getQuizQuestions();
    }
  }, []);

  useEffect(() => {
    let hiddenTime;
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        hiddenTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        setFromTime(hiddenTime);
      }
      if (document.visibilityState === "visible") {
        const visibleTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        setToTime(visibleTime);

        backgroundTracking(hiddenTime, visibleTime);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const backgroundTracking = (fromTime, visibleTime) => {
    API.createBackgroundTracking(candidateId, fromTime, visibleTime).then(
      ({ response }) => {}
    );
  };

  useEffect(() => {
    if (allQuestionData.length > 0) {
      if (timerOver) {
        setShowModal(true);
      }
    }
  }, [timerOver]);

  useEffect(() => {
    if (allQuestionData.length > 0) {
      creactCandidateTracking(allQuestionData);
    }
  }, [allQuestionData]);

  const getQuizQuestions = () => {
    var updatedData = {};
    API.getAllQuizQuestionsCandidate(quizCategoryId, candidateId).then(
      (response) => {
        if (response.data.status) {
          const data = response.data.data;
          updatedData = data.map((items, index) => ({
            id: index + 1,
            QuestionAnswerId:
              items.quiz?.question_type === "MSQ"
                ? JSON.parse(items.question_answer_id)
                : parseInt(items.question_answer_id),
            QuestionId: items.question_id,
            Question: items.quiz?.question,
            QuestionType: items.quiz?.question_type,
            QuestionOptions: items.quiz?.answers,
            QuestionAnswerCount: items.quiz?.required_answer_count,
          }));

          setAllQuestionData(updatedData);

          const QuestionZerothIndex = updatedData[0].QuestionId;

          setCrntQstnId(updatedData[0].QuestionId);
          const crntOptionValue =
            updatedData[0].QuestionType === "MSQ"
              ? updatedData[0].QuestionAnswerId
              : [];
          setClicked(updatedData[0].QuestionAnswerId);

          const filterData = attended.find((items) => {
            return items.id === quizCategoryId;
          });
        } else {
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "error",
          });
        }
      }
    );
  };

  const creactCandidateTracking = (allQuestionData) => {
    if (trackingstatus === "false") {
      const status = localStorage.setItem(
        "trackingStatus" + quizCategoryId,
        true
      );
      const timeTook = 0;
      API.createTrack(
        candidateId,
        quizCategoryId,
        allQuestionData[0].QuestionId,
        timeTook
      ).then(({ response }) => {
        if (response.status) {
          trackingstatus = true;
          setSectionStatus(false);
          setLoading(false);
        }
        setSectionStatus(false);
      });
    } else if (trackingstatus === "true") {
      API.GetCreateTrack(candidateId, quizCategoryId).then((response) => {
        if (response.data.status) {
          const data = response.data.data[0];

          setSectionStatus(false);
          const qstnid = data.current_question_id;
          setTrackCrntQstnId(qstnid);

          var indexOfCurrentQstn = allQuestionData.findIndex(
            (i) => i.QuestionId === qstnid
          );
          setTrackCrntQstnIndex(indexOfCurrentQstn);
          setQstnState(indexOfCurrentQstn);

          setLoading(false);
        }
        setSectionStatus(false);
      });
    }
  };

  const handleNext = (currentQstnID) => {
    setCrntQstnId(currentQstnID);
    // here we're getting balance time.//
    const minutes = timer[0];
    const seconds = timer[1];
    const timeBalance = Number(`${minutes}.${seconds}`);
    const remainingTime = `${minutes}:${seconds}`;

    //here we're getting duration of selected quiz category//
    const filterData = sectionType.find((items) => {
      return items.id === quizCategoryId;
    });

    var endTime = moment(`${filterData.duration}:00`, "mm:ss");
    var startTime = moment(`${remainingTime}`, "mm:ss");

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in minutes
    var minute = parseInt(duration.asMinutes()) % 60;
    var second = parseInt(duration.asSeconds()) % 60;

    const timeConsumed = Number(`${minute}.${second}`);

    var indexOfCurrentQstnId = allQuestionData.findIndex(
      (i) => i.QuestionId === currentQstnID
    );

    let nextQstnId = allQuestionData[indexOfCurrentQstnId + 1].QuestionId;

    let currentAnswer = allQuestionData[indexOfCurrentQstnId].QuestionAnswerId;
    const requiredAnsCount =
      allQuestionData[indexOfCurrentQstnId].QuestionAnswerCount;

    const questionType = allQuestionData[indexOfCurrentQstnId].QuestionType;
    const optionvalue = questionType === "MSQ" ? msqOption : parseInt(clicked);

    const previousValue = currentAnswer ? currentAnswer : "";

    const answersLength = status ? optionvalue : previousValue;

    setQstnState(qstnState + 1);
    if (status === true) {
      setDone(true);
      API.nextPrevFunc(
        candidateId,
        quizCategoryId,
        timeConsumed,
        currentQstnID,
        nextQstnId,
        optionvalue,
        questionType
      ).then(({ response }) => {
        if (response.status) {
          setDone(false);
          setClicked("");
          setMsqOption([]);
          setStatus(false);
          setCountErrMsg(false);
        } else {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
          setClicked("");
          setMsqOption([]);
          setDone(false);
          setCountErrMsg(false);
        }
      });
    } else if (status === false) {
      setDone(true);
      API.nextPrevFunc(
        candidateId,
        quizCategoryId,
        timeConsumed,
        currentQstnID,
        nextQstnId,
        previousValue,
        questionType
      ).then(({ response }) => {
        if (response.status) {
          setClicked("");
          setMsqOption([]);
          setDone(false);
          setStatus(false);
          setCountErrMsg(false);
        } else {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
          setClicked("");
          setCountErrMsg(false);
          setMsqOption([]);
          setDone(false);
        }
      });
    }
  };

  const handlePrevious = (currentQstnID) => {
    setCrntQstnId(currentQstnID);
    setDone(true);

    // here we're getting balance time.
    const minutes = timer[0];
    const seconds = timer[1];
    const timeBalance = Number(`${minutes}.${seconds}`);
    const remainingTime = `${minutes}:${seconds}`;

    //here we're getting duration of selected quiz category
    const filterData = sectionType.find((items) => {
      return items.id === quizCategoryId;
    });

    var endTime = moment(`${filterData.duration}:00`, "mm:ss");
    var startTime = moment(`${remainingTime}`, "mm:ss");

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in minutes
    var minute = parseInt(duration.asMinutes()) % 60;
    var second = parseInt(duration.asSeconds()) % 60;

    const timeConsumed = Number(`${minute}.${second}`);

    // here we're getting previous Qstn id by using index of current qstn id
    var indexOfCurrentQstnId = allQuestionData.findIndex(
      (i) => i.QuestionId === currentQstnID
    );

    var previousQstnId = allQuestionData[indexOfCurrentQstnId - 1].QuestionId;

    let currentAnswerId =
      allQuestionData[indexOfCurrentQstnId].QuestionAnswerId;

    const questionType = allQuestionData[indexOfCurrentQstnId].QuestionType;

    const optionvalue = questionType === "MSQ" ? msqOption : parseInt(clicked);
    const previousValue = currentAnswerId ? currentAnswerId : "";

    const requiredAnsCount =
      allQuestionData[indexOfCurrentQstnId].QuestionAnswerCount;

    const answersLength = status ? optionvalue : previousValue;

    setQstnState(qstnState - 1);
    if (status === true) {
      API.nextPrevFunc(
        candidateId,
        quizCategoryId,
        timeConsumed,
        currentQstnID,
        previousQstnId,
        optionvalue,
        questionType
      ).then(({ response }) => {
        if (response.status) {
          setDone(false);
          // setClicked("");
          setStatus(false);
        } else {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
          setClicked("");
          setMsqOption([]);
          setDone(false);
        }
      });
    } else if (status === false) {
      API.nextPrevFunc(
        candidateId,
        quizCategoryId,
        timeConsumed,
        currentQstnID,
        previousQstnId,
        previousValue,
        questionType
      ).then(({ response }) => {
        if (response.status) {
          // setClicked("");
          setStatus(false);
          setDone(false);
        } else {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
          setClicked("");
          setMsqOption([]);
          setDone(false);
        }
      });
    }
  };

  const getCandidateSummary = () => {
    API.candidateSummary(candidateId, quizCategoryId).then(({ response }) => {
      if (response.status) {
        if (response.data.unansweredCount !== 0) {
          setUnansweredCount(
            `Unanswered questions - ${response.data.unansweredCount}`
          );
          setQuestionId(response.data.skippedFirstQuestionId);
        } else {
          setUnansweredCount("");
          setQuestionId(0);
        }
      } else {
        setUnansweredCount("");
        setQuestionId(0);
      }
      setOpen(true);
    });
  };

  const handleSubmit = (currentQstnID) => {
    setCrntQstnId(currentQstnID);
    const minutes = timer[0];
    const seconds = timer[1];
    const timeBalance = Number(`${minutes}.${seconds}`);
    const remainingTime = `${minutes}:${seconds}`;

    //here we're getting duration of selected quiz category//
    const filterData = sectionType.find((items) => {
      return items.id === quizCategoryId;
    });

    var endTime = moment(`${filterData.duration}:00`, "mm:ss");
    var startTime = moment(`${remainingTime}`, "mm:ss");

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in minutes
    var minute = parseInt(duration.asMinutes()) % 60;
    var second = parseInt(duration.asSeconds()) % 60;

    const timeConsumed = Number(`${minute}.${second}`);

    var indexOfCurrentQstnId = allQuestionData.findIndex(
      (i) => i.QuestionId === currentQstnID
    );

    let nextQstnId = allQuestionData[indexOfCurrentQstnId].QuestionId;

    let currentAnswer = allQuestionData[indexOfCurrentQstnId].QuestionAnswerId;
    const requiredAnsCount =
      allQuestionData[indexOfCurrentQstnId].QuestionAnswerCount;

    const questionType = allQuestionData[indexOfCurrentQstnId].QuestionType;
    const optionvalue = questionType === "MSQ" ? msqOption : parseInt(clicked);

    const previousValue = currentAnswer ? currentAnswer : "";

    const answersLength = status ? optionvalue : previousValue;

    setQstnState(qstnState);
    if (status === true) {
      setButtonLoading(true);
      API.nextPrevFunc(
        candidateId,
        quizCategoryId,
        timeConsumed,
        currentQstnID,
        nextQstnId,
        optionvalue,
        questionType
      ).then(({ response }) => {
        if (response.status) {
          setButtonLoading(false);
          setClicked("");
          setMsqOption([]);
          setStatus(false);
          setCountErrMsg(false);
        } else {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
          setClicked("");
          setMsqOption([]);
          setButtonLoading(false);
          setCountErrMsg(false);
        }
        getCandidateSummary();
      });
    } else if (status === false) {
      setButtonLoading(true);
      API.nextPrevFunc(
        candidateId,
        quizCategoryId,
        timeConsumed,
        currentQstnID,
        nextQstnId,
        previousValue,
        questionType
      ).then(({ response }) => {
        if (response.status) {
          setClicked("");
          setMsqOption([]);
          setButtonLoading(false);
          setStatus(false);
          setCountErrMsg(false);
        } else {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
          setClicked("");
          setCountErrMsg(false);
          setMsqOption([]);
          setButtonLoading(false);
        }
        getCandidateSummary();
      });
    }
  };

  const submitButton = () => {
    setDone(true);

    // here we're getting balance time.
    const minutes = timer[0];
    const seconds = timer[1];
    const remainingTime = `${minutes}:${seconds}`;

    //here we're getting duration of selected quiz category
    const filterData = sectionType.find((items) => {
      return items.id === quizCategoryId;
    });

    var endTime = moment(`${filterData.duration}:00`, "mm:ss");
    var startTime = moment(`${remainingTime}`, "mm:ss");

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in minutes
    var minute = parseInt(duration.asMinutes()) % 60;
    var second = parseInt(duration.asSeconds()) % 60;

    const timeConsumed = Number(`${minute}.${second}`);

    var indexOfCurrentQstnId = allQuestionData.findIndex(
      (i) => i.QuestionId === crntQstnId
    );

    let currentAnswersId =
      allQuestionData[indexOfCurrentQstnId].QuestionAnswerId;

    const questionType = allQuestionData[indexOfCurrentQstnId].QuestionType;
    const optionvalue = questionType === "MSQ" ? msqOption : parseInt(clicked);
    const previousValue = currentAnswersId ? currentAnswersId : "";

    if (status === true) {
      API.submitQuiz(
        candidateId,
        quizCategoryId,
        timeConsumed,
        crntQstnId,
        optionvalue,
        questionType
      ).then(({ response }) => {
        if (response.status) {
          // setClicked("");
          setStatus(false);
          setDone(false);
        } else {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
          setClicked("");
          setMsqOption([]);
          setDone(false);
        }
      });
    } else if (status === false) {
      API.submitQuiz(
        candidateId,
        quizCategoryId,
        timeConsumed,
        crntQstnId,
        previousValue,
        questionType
      ).then(({ response }) => {
        if (response.status) {
          // setClicked("");
          setStatus(false);
          setDone(false);
        } else {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
          setClicked("");
          setMsqOption([]);
          setDone(false);
        }
      });
    }
  };

  const handleClose = () => setOpen(false);

  const handleBackBtnOpen = () => {
    setBackBtnOpen(true);
  };

  const backFuncApi = () => {
    // here we're getting balance time.
    const minutes = timer[0];
    const seconds = timer[1];
    const timeBalance = Number(`${minutes}.${seconds}`);
    const remainingTime = `${minutes}:${seconds}`;

    const filterData = sectionType.find((items) => {
      return items.id === quizCategoryId;
    });

    var endTime = moment(`${filterData.duration}:00`, "mm:ss");
    var startTime = moment(`${remainingTime}`, "mm:ss");

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in minutes
    var minute = parseInt(duration.asMinutes()) % 60;
    var second = parseInt(duration.asSeconds()) % 60;

    const timeConsumed = Number(`${minute}.${second}`);

    API.backFunc(candidateId, quizCategoryId, timeConsumed).then(
      ({ response }) => {}
    );
  };

  const handleBackBtnClose = () => setBackBtnOpen(false);

  const handleAnswerClick = (id, name, oldValue, index, type) => {
    setStatus(true);
    setClicked(id);
    setInnerText(name);
    setQstnType(type);
    if (id != oldValue) {
      var CurrentQuestion = allQuestionData[index];

      CurrentQuestion.QuestionAnswerId = id;

      var QnsList = allQuestionData;
      QnsList[index] = CurrentQuestion;
      // setAllQuestionData(QnsList);
      setAllQuestionData((allQuestionData) => {
        const modifiedValue = QnsList;

        return modifiedValue;
      });
    } else {
      var CurrentQuestions = allQuestionData[index];
      CurrentQuestions.question_answer_id = null;
      var QnsLists = allQuestionData;
      QnsLists[index] = CurrentQuestions;
      setAllQuestionData((allQuestionData) => {
        const modifiedValue = QnsLists;

        return modifiedValue;
      });
    }
  };

  const handleOptionClick = (id, type, index) => {
    setStatus(true);

    setQstnType(type);

    var CurrentQuestion = allQuestionData[index];

    let qestionArray = [];
    if (
      CurrentQuestion.QuestionAnswerId !== null &&
      CurrentQuestion.QuestionAnswerId.includes(id)
    ) {
      qestionArray = CurrentQuestion.QuestionAnswerId.filter((v) => v !== id);
    } else {
      if (CurrentQuestion.QuestionAnswerId === null) {
        qestionArray = [id];
      } else {
        qestionArray = [...CurrentQuestion.QuestionAnswerId, id];
      }
    }
    CurrentQuestion.QuestionAnswerId = qestionArray;

    setMsqOption(qestionArray);
    var QnsList = allQuestionData;
    QnsList[index] = CurrentQuestion;
    // setAllQuestionData(QnsList);
    setAllQuestionData((allQuestionData) => {
      const modifiedValue = QnsList;
      return modifiedValue;
    });
  };

  const handleGobackFunc = () => {
    let index;
    if (questionId !== 0) {
      index = allQuestionData.findIndex(
        (each) => each.QuestionId === questionId
      );
    } else {
      index = allQuestionData.length - 1;
    }

    setQstnState(index);
  };

  return (
    <QuizQuestions
      key={allQuestionData}
      loading={loading}
      handleBackBtnOpen={handleBackBtnOpen}
      done={done}
      allQuestionData={allQuestionData}
      qstnState={qstnState}
      lengthOfQuestion={lengthOfQuestion}
      countErrMsg={countErrMsg}
      handleOptionClick={handleOptionClick}
      handleAnswerClick={handleAnswerClick}
      clicked={clicked}
      handlePrevious={handlePrevious}
      handleSubmit={handleSubmit}
      open={open}
      handleClose={handleClose}
      submitButton={submitButton}
      handleNext={handleNext}
      setNotify={setNotify}
      notify={notify}
      backBtnOpen={backBtnOpen}
      handleBackBtnClose={handleBackBtnClose}
      backFuncApi={backFuncApi}
      showModal={showModal}
      msqOption={msqOption}
      quizCategoryId={quizCategoryId}
      setTimer={setTimer}
      setTimerOver={setTimerOver}
      selectedSection={selectedSection}
      selectedSectionBgImage={selectedSectionBgImage}
      handleGobackFunc={handleGobackFunc}
      unansweredCount={unansweredCount}
      buttonLoading={buttonLoading}
    />
  );
}
