import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardHeader } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";

export const Cards = ({
  className,
  content,
  style,
  title,
  height,
  image,
  alt,
  action,
  width,
}) => {
  return (
    <>
      <Card className={`${className}`} style={style}>
        <CardHeader title={title} style={{ padding: 0 }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardMedia
            style={{ margin: 0, padding: 0 }}
            component="img"
            height={height}
            width={width}
            image={image}
            alt={alt}
          />
        </div>

        <CardContent
          style={{
            padding: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {content}
        </CardContent>
        <CardActions style={{ padding: 0 }}>{action}</CardActions>
      </Card>
    </>
  );
};
