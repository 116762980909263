// import { BaseURL, APIRequests } from "./NetworkConstants";
// import * as Constants from "../Utils/Validations";
import Cookies from "js-cookie";
import axios from "axios";
import { CompressOutlined } from "@mui/icons-material";

const RequestMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

const getJWTToken = async () => {
  const jwtToken = Cookies.get("jwtToken");

  return jwtToken;
};

const get = async (url) => {
  return callGetApi(RequestMethod.GET, url);
};

const post = async (url, params, header) => {
  return callPostApi(RequestMethod.POST, url, params, header);
};

const put = async (url, params, header) => {
  return callPutApi(RequestMethod.PUT, url, params, header);
};

const Delete = async (url) => {
  return callDeleteApi(RequestMethod.DELETE, url);
};

const callGetApi = async (requestMethod, url) => {
  let jwtToken = await getJWTToken();

  // let loginheaders = {
  //   "Content-Type": "application/json",
  //   // Connection: "keep-alive",
  //   Accept: "application/json",
  //   // Authorization: `Bearer ${decodeURIComponent(accessToken)}`,
  // };
  const options = {
    method: requestMethod,
    headers: {
      // "authorization": "Bearer" + jwtToken,
      authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  };

  return axios(`${url}`, options)
    .then((response) => {
      const statusCode = response.status;

      return new Promise((resolve, reject) => {
        resolve({ response, statusCode });
        reject(new Error("Invalid Response!"));
      });
    })
    .catch((error) => {});
};

let countPost = 0;
const callPostApi = async (requestMethod, url, params, header) => {
  // let requestOptions = null;

  let jwtToken = await getJWTToken();

  // const Header = { "Content-Type": "application/json" };
  let requestOptions;
  if (header === false) {
    requestOptions = {
      method: requestMethod,
      body: params,
      headers: {
        authorization: `Bearer ${jwtToken}`,
      },
    };
  } else {
    requestOptions = {
      method: requestMethod,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${jwtToken}`,
      },
      body: params,
    };
  }

  // if (header) {
  //   requestOptions = {what
  //     method: requestMethod,
  //     body: params,
  //     mode: "cors",
  //     headers: { "aa-token": jwtToken, "Content-Type": Header["Content-Type"] },
  //   };

  // } else {
  //   requestOptions = {
  //     method: requestMethod,
  //     body: params,
  //     mode: "cors",
  //     headers: { "aa-token": jwtToken },
  //   };

  // }

  return fetch(`${url}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;

      return new Promise((resolve, reject) => {
        response
          .json()
          .then((response) => {
            resolve({ response, statusCode });
          })
          .catch((error) => {
            reject("Invalid Response!");
          });
      });
    })
    .catch((error) => {
      // if(countPost === 0){
      //   alert("Server TimeOut Please Try Again Later")
      //   countPost++;
      // }
      
      // return networkError;
    });
};

let countDelete = 0;
const callDeleteApi = async (requestMethod, url) => {
  let jwtToken = await getJWTToken();

  const DeleteOptions = {
    method: requestMethod,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${jwtToken}`,
    },
  };

  return fetch(`${url}`, DeleteOptions).then((response) => {
    const statusCode = response.status;

    return new Promise((resolve, reject) => {
      //     return new Promise((resolve, reject) => {

      // });
      response
        .json()
        .then((result) => {
          // alert('responsebase',response)
          resolve({ result, statusCode });
        })
        .catch((error) => {
          reject("Invalid Response!");
          // if(countDelete === 0){
          //   alert("Server TimeOut Please Try Again Later")
          //   countDelete++;
          // }
        });
    });
  });
};

let countPut = 0;
const callPutApi = async (requestMethod, url, params, header) => {
  let jwtToken = await getJWTToken();
  // const Header = { "Content-Type": "application/json" };
  let requestOptions;
  if (header === false) {
    requestOptions = {
      method: requestMethod,
      body: params,
      headers: {
        authorization: `Bearer ${jwtToken}`,
      },
    };
  } else {
    requestOptions = {
      method: requestMethod,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${jwtToken}`,
      },
      body: params,
    };
  }
  // }

  return fetch(`${url}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;

      return new Promise((resolve, reject) => {
        response
          .json()
          .then((response) => {
            //alert('response',response)

            resolve({ response, statusCode });
          })
          .catch((error) => {
            reject("Invalid Response!");
          });
      });
    })
    .catch((error) => {
    
      // if(countPut === 0){
      //   alert("Server TimeOut Please Try Again Later")
      //   countPut++;
      // }
      // return networkError;
    });
};

const axiosInstance = async (url, method) => {
  let jwtToken = await getJWTToken();
  const api = axios.create({
    baseURL: `${url}`,
    headers: {
      "Content-type": "application/json",
      authorization: `Bearer ${jwtToken}`,
    },
  });

  api.interceptors.response.use(
    (req) => {
      // req.headers.authorization = `Bearer ${jwtToken}`;

      return req;
    },
    async (error) => {
      if (error.response.status === 404) {
        return error.response;
      }
    }
  );

  const response = await api.get();

  return response;
};

const exportedObject = {
  get,
  post,
  put,
  Delete,
  axiosInstance,
};

export default exportedObject;
