import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ReusableButton from "../Button/Button";
// import { Icons } from "../../Utilis/icons";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  height: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ResuableDialogBox(props) {
  const {
    open,
    handleClose,
    modalText,
    btnText1,
    btnText2,
    path,
    submitApiCall,
    state,
    handleGoback,
    unansweredCount,
    
  } = props;

  const handleCloseModal = () => {
    handleClose();
    handleGoback();
  };

  const handleYesButton = () => {
    submitApiCall();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                // padding: "0px",
                // marginTop: "-14px",
                // paddingBottom:'2%',
                cursor: "pointer",
              }}
            >
              <CloseIcon onClick={handleCloseModal} />
            </Box>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontFamily: "Helvetica",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "24px",
                lineHeight: "26px",
                paddingTop: "10px",
                // paddingBottom: "12%",
                paddingLeft: "2%",
                justifyContent: "center",
              }}
            >
              {modalText}
              <br />
              <span style={{ fontSize: "18px", marginTop: "10px" }}>
                {unansweredCount}
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "10%",
              }}
            >
              <Box>
                <ReusableButton
                  text={btnText1}
                  variant="outlined"
                  onClick={handleCloseModal}
                  style={{
                    border: "1px solid #00728E",
                    borderRadius: "4px",
                    fontFamily: "Helvetica",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#00728E",
                    textTransform: "capitalize !important",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                />
              </Box>

              {btnText2 ? (
                <Box>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={path}
                    state={state}
                  >
                    <ReusableButton
                      text={btnText2}
                      variant="contained"
                      onClick={handleYesButton}
                      style={{
                        // border: "1px solid #00728E",
                        background: "#00728E",
                        borderRadius: "4px",
                        fontFamily: "Helvetica",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                        textTransform: "capitalize !important",
                        "&:hover": { backgroundColor: "#00728E" },
                      }}
                    />
                  </Link>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
