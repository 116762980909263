import React from "react";
import { useState, useEffect } from "react";
import { Cards } from "../../../CommonComponents/Cards/Cards";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import "./RecruiterLandingPage.css";
import Recruiters from "../../../Assests/Recruiters.png";
import Recruiting_Drive from "../../../Assests/Rectangle32.png";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled, alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ProfilePic from "../../../Assests/Group 766.png";
import { API } from "../../../Networking/API";
import Candidates_Hired from "../../../Assests/Candidates Hired.png";
import Candidates_List from "../../../Assests/Rectangl.png";
import Logout from "../../../CommonComponents/Logout/Logout";
import Ongoing from "../../../Assests/ongoing.png";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export function RecruiterLandingPage() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const image = localStorage.getItem("image");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    navigate("/");
    Cookies.remove("jwtToken");
  };

  const handleCloseMenuBar = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="Admin-section-wrapper">
        <div
          style={{
              // display: "flex",
              // justifyContent: "end",
            margin: "0px 22px",
            marginBottom: "20px",
          }}
        >
          <div style={{marginRight:"7%"}}>
         <Logout />
         </div>
        </div>

        <div style={{ margin: "0px 120px" }}>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <Link href="/recruiter-recruitment-Drive" underline="none">
                <Cards
                  image={Recruiting_Drive}
                  height="150"
                  width="80"
                  style={{ borderRadius: "0px" }}
                  content={
                    <div className="recruitecard-content-text">
                      <span className="section-name-texts">
                        Recruiting Drives
                      </span>
                    </div>
                  }
                />
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Link href="/recruiter-candidate-list" underline="none">
                <Cards
                  //   className="quiz-section-cards"
                  image={Candidates_List}
                  height="150"
                  width="80"
                  style={{ borderRadius: "0px" }}
                  content={
                    <div className="recruitecard-content-text">
                      <span className="section-name-texts">
                        Candidates List
                      </span>
                    </div>
                  }
                />
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Link href="/recruiter-ongoing-drive" underline="none">
                <Cards
                  //   className="quiz-section-cards"
                  image={Ongoing}
                  height="150"
                  width="80"
                  style={{ borderRadius: "0px" }}
                  content={
                    <div className="recruitecard-content-text">
                      <span className="section-name-texts">
                        Ongoing Drive Track
                      </span>
                    </div>
                  }
                />
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
