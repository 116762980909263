import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ReusableButton from "../../../CommonComponents/Button/Button";
import ReusableInputfield from "../../../CommonComponents/TextField/TextField";
import Grid from "@mui/material/Grid";
import ResuableDropdown from "../../../CommonComponents/DropDown/DropDown";
import { MyForm } from "../../../CommonComponents/MyForm/MyForm";
import { API } from "../../../Networking/API";
import { useEffect } from "react";
import "./RecruiterCandidateCreatePage.css";
import Notification from "../../../CommonComponents/Notification/Notification";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const EmployeeType = [
  { id: "FULLTIME", full_name: "Fulltime" },
  { id: "INTERNSHIP", full_name: "Internship" },
];

export default function RecruiterCandidatesEditPage(props) {
  const {
    open,
    handleClose,
    editData,
    setloderstate,
    coursesData,
    getAllCandidateData,
    branchData,
    courseArray,
    DriveNameValue,
    driveName,
    getCandidatesDataByDriveID,
    driveType,
  } = props;
  // const [DriveNameValue, setDriveNameValue] = useState();
  const [branchArray, setbranchArray] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const candidateData = editData;
  const candidateId = editData.id;
  const candidateDriveId = candidateData.drives.id;

  const initialValues = {
    firstname: candidateData.first_name,
    lastname: candidateData.last_name,
    UniversityCollegeName: candidateData.college_name,
    Email: candidateData.email,
    PhoneNumber: candidateData.phone_no,
    coursetype: candidateData.department,
    branch: candidateData.branch,
    year: candidateData.college_year,
    cgpa: candidateData.cgpa,
  };

  const currentYear = new Date().getFullYear();
  const validYears = [currentYear, currentYear + 1, currentYear + 2];

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("firstname" in inputValues)
      code.firstname = inputValues.firstname ? "" : "First Name is required";
    if ("lastname" in inputValues)
      code.lastname = inputValues.lastname ? "" : "Last Name is required";
    if (props.driveType === "FRESHER") {
      if ("UniversityCollegeName" in inputValues)
        code.UniversityCollegeName = inputValues.UniversityCollegeName
          ? ""
          : "University Name is required";
    } else {
      code.UniversityCollegeName = "";
    }
    const emailValidation =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if ("Email" in inputValues)
      code.Email = inputValues.Email
        ? emailValidation.test(inputValues.Email)
          ? ""
          : "Email is not valid"
        : "Email is required";
    if ("PhoneNumber" in inputValues)
      code.PhoneNumber =
        inputValues.PhoneNumber.length === 10 ? "" : "Phone Number is required";

    if (props.driveType === "FRESHER") {
      if ("branch" in inputValues)
        code.branch = inputValues.branch ? "" : "branch is required";
      if ("coursetype" in inputValues)
        code.coursetype = inputValues.coursetype
          ? ""
          : "Course type is required";
    } else {
      code.branch = "";
      code.coursetype = "";
    }

    // if ("year" in inputValues) {
    //   code.year = inputValues.year ? "" : "Year is required";
    //   if (inputValues.year > years) {
    //     code.year = "Please provide correct year";
    //   }
    // }

    if ("year" in inputValues) {
      const year = parseInt(inputValues.year);
      code.year = inputValues.year ? "" : "Year is required";
      if (!validYears.includes(year)) {
        code.year = `Please provide a valid year`;
      }
    }

    if (props.driveType === "FRESHER") {
      if ("cgpa" in inputValues) {
        code.cgpa = inputValues.cgpa ? "" : "CGPA is required";
        if (inputValues.cgpa > 10 || inputValues.cgpa < 0) {
          code.cgpa = "Please provide correct value";
        }
      }
    }

    if (props.driveType === "FRESHER") {
      if ("location" in inputValues)
        code.location = inputValues.location ? "" : "Location is required";
    } else {
      code.location = "";
    }

    setErrors({
      ...code,
    });
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    validateOnEdit,
  } = MyForm(initialValues, true, validate);

  const handleSaveChanges = (e) => {
    if (validate()) {
      API.editCandidateData(
        values.firstname,
        values.lastname,
        values.Email,
        values.PhoneNumber,
        values.UniversityCollegeName,
        candidateDriveId,
        values.coursetype,
        values.branch,
        values.year,
        values.cgpa,
        candidateId
      ).then(({ response }) => {
        if (response.status === true) {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "success",
          });
          const timer = setTimeout(() => {
            handleClose();
            getAllCandidateData();
            resetForm();
            setloderstate(false);
            getCandidatesDataByDriveID(DriveNameValue);
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
        }
      });
    }
  };

  const handleCloseModal = () => {
    setloderstate(false);
    handleClose();
    resetForm();
  };

  const handleInputChangePhno = (e) => {
    const { name, value } = e.target;
    const Phvalue = value.replace(/\D/g, "");

    setValues({
      ...values,
      [name]: Phvalue,
    });
    // if (validateOnEdit) {
    validate({ [name]: Phvalue });
    // }
  };
  const handleBranchDropDown = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, branch: "" });
  };

  const handleCourseDropDown = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, coursetype: "" });

    if (e.target.value !== "") {
      const courseId = e.target.value;
      getBranchBId(courseId);
    }
  };

  const getBranchBId = (courseId) => {
    API.getBranchData(courseId).then(({ response }) => {
      if (response.status === 200) {
        const data = response.data.data.rows;
        const updatedData = data.map((items, index) => ({
          id: items.id,
          full_name: items.branch_name,
        }));
        setbranchArray(updatedData);
      }
    });
  };

  const handleEmployementDropDown = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, employeeType: "" });
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box>
              <Box
                style={{
                  display: "flex",
                  paddingBottom: "20px",
                }}
              >
                <Box
                  style={{
                    width: "30vw",
                    fontSize: "22px",
                    fontWeight: "700",
                    color: "#424242",
                  }}
                >
                  <spam>{driveName} / Edit Candidate</spam>
                </Box>
              </Box>

              <Grid spacing={2} container>
                <Grid item xs={4} style={{}}>
                  <Box className="recruiterCandidate-AdminFelidTextBox">
                    <label style={{ paddingBottom: "5px" }} htmlFor="">
                      First Name
                    </label>
                    <ReusableInputfield
                      className="Input_Field_Text_Box"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="firstname"
                      // label="Drive Name"
                      placeholder="Enter Name"
                      value={values.firstname}
                      error={errors.firstname ? true : null}
                      helperText={errors.firstname}
                      onChange={handleInputChange}
                    />
                  </Box>
                </Grid>

                <Grid item xs={4} style={{}}>
                  <Box className="recruiterCandidate-AdminFelidTextBox">
                    <label style={{ paddingBottom: "5px" }} htmlFor="">
                      Last Name
                    </label>
                    <ReusableInputfield
                      className="Input_Field_Text_Box"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="lastname"
                      // label="Drive Name"
                      placeholder="Enter Name"
                      value={values.lastname}
                      error={errors.lastname ? true : null}
                      helperText={errors.lastname}
                      onChange={handleInputChange}
                    />
                  </Box>
                </Grid>

                <Grid item xs={4} style={{}}>
                  <Box className="recruiterCandidate-AdminFelidTextBox">
                    <label style={{ paddingBottom: "5px" }} htmlFor="">
                      Email Address
                    </label>
                    <ReusableInputfield
                      className="Input_Field_Text_Box"
                      variant="outlined"
                      size="small"
                      type="Email"
                      name="Email"
                      placeholder="Enter Email Address"
                      value={values.Email}
                      error={errors.Email ? true : false}
                      helperText={errors.Email}
                      onChange={handleInputChange}
                    />
                  </Box>
                </Grid>

                <Grid item xs={4} style={{}}>
                  <Box className="recruiter-AdminFelidTextBoxs">
                    <label style={{ paddingBottom: "5px" }} htmlFor="">
                      Phone Number
                    </label>
                    <ReusableInputfield
                      className="Input_Field_Text_Box"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="PhoneNumber"
                      placeholder="0000000000"
                      value={values.PhoneNumber}
                      error={errors.PhoneNumber ? true : false}
                      helperText={errors.PhoneNumber}
                      inputProps={{
                        maxLength: 10,
                      }}
                      onChange={handleInputChangePhno}
                    />
                  </Box>
                </Grid>

                {props.driveType === "FRESHER" ? (
                  <Grid item xs={4} style={{}}>
                    <Box className="recruiter-AdminFelidTextBoxs">
                      <label style={{ paddingBottom: "5px" }} htmlFor="">
                        University/College Name
                      </label>
                      <ReusableInputfield
                        className="Input_Field_Text_Box"
                        // className="input"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="UniversityCollegeName"
                        placeholder="Enter College Name"
                        value={values.UniversityCollegeName}
                        error={errors.UniversityCollegeName ? true : false}
                        helperText={errors.UniversityCollegeName}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}

                {props.driveType === "FRESHER" ? (
                  <Grid item xs={4} style={{}}>
                    <Box className="recruiterCandidate-AdminFelidTextBox">
                      <label style={{ paddingBottom: "5px" }} htmlFor="">
                        Course Type
                      </label>
                      <ReusableInputfield
                        className="Input_Field_Text_Box"
                        // className="input"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="coursetype"
                        placeholder="Enter Course Type"
                        value={values.coursetype}
                        error={errors.coursetype ? true : false}
                        helperText={errors.coursetype}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}

                {props.driveType === "FRESHER" ? (
                  <Grid item xs={4} style={{}}>
                    <Box className="recruiterCandidate-AdminFelidTextBox">
                      <label style={{ paddingBottom: "5px" }} htmlFor="">
                        Branch
                      </label>
                      <ReusableInputfield
                        className="Input_Field_Text_Box"
                        // className="input"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="branch"
                        placeholder="Enter Branch Name"
                        value={values.branch}
                        error={errors.branch ? true : false}
                        helperText={errors.branch}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}

                <Grid item xs={4} style={{}}>
                  <Box className="recruiterCandidate-AdminFelidTextBox">
                    <label style={{ paddingBottom: "5px" }} htmlFor="">
                      Year
                    </label>
                    <ReusableInputfield
                      className="Input_Field_Text_Box"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="year"
                      placeholder="select Year"
                      value={values.year}
                      error={errors.year ? true : false}
                      helperText={errors.year}
                      onChange={handleInputChangePhno}
                    />
                  </Box>
                </Grid>

                {props.driveType === "FRESHER" ? (
                  <Grid item xs={4} style={{}}>
                    <Box className="recruiterCandidate-AdminFelidTextBox">
                      <label style={{ paddingBottom: "5px" }} htmlFor="">
                        CGPA
                      </label>
                      <ReusableInputfield
                        className="Input_Field_Text_Box"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="cgpa"
                        placeholder="Enter Value"
                        value={values.cgpa}
                        error={errors.cgpa ? true : null}
                        helperText={errors.cgpa}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}

                {/* {props.driveType === "FRESHER" ? (
                  <Grid item xs={4} style={{}}>
                    <Box className="recruiterCandidate-AdminFelidTextBox">
                      <label style={{ paddingBottom: "5px" }} htmlFor="">
                        Location
                      </label>
                      <ReusableInputfield
                        className="Input_Field_Text_Box"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="location"
                        placeholder="Select location"
                        value={values.location}
                        error={errors.location ? true : false}
                        helperText={errors.location}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Grid>
                ) : (
                  ""
                )} */}
              </Grid>

              <Box>
                <Box style={{ marginTop: "30px" }}>
                  <ReusableButton
                    style={{
                      float: "right",
                      backgroundColor: "#00728E",
                      "&:hover": { backgroundColor: "#00728E" },
                      textTransform: "capitalize",
                    }}
                    text="Save Changes"
                    onClick={handleSaveChanges}
                  />
                </Box>
                <Box>
                  <ReusableButton
                    style={{
                      float: "right",
                      Color: "#00728E",
                      marginRight: "20px",
                      marginBottom: "20px",
                      textTransform: "capitalize",
                    }}
                    text="cancel"
                    variant="outlined"
                    onClick={handleCloseModal}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
