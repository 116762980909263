import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import ReusableButton from "../../../CommonComponents/Button/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { API } from "../../../Networking/API";
import "./RecruiterUploadCandidatesList.css";
import ResuableDropdown from "../../../CommonComponents/DropDown/DropDown";
import RecruiterCandidatesCreatePage from "./RecruiterCandidatesCreatePage";
//  import "./RecruiterCandidateCreatePage.css";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import Notification from "../../../CommonComponents/Notification/Notification";
import RecruiterCandidatesEditPage from "./RecruiterCandidateEditPage";
import Grid from "@mui/material/Grid";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Logout from "../../../CommonComponents/Logout/Logout";
import ReactLoading from "react-loading";
// import Cookies from "js-cookie";
import { DataTable } from "../../../CommonComponents/DataTable/DataTable";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {
  CompressOutlined,
  NoEncryption,
  UpdateDisabled,
} from "@mui/icons-material";
import { makeStyles } from "@material-ui/core";

const errorText = "Only .csv format allowed.";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00728E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRadius: "20%",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function RecruiterUploadCandidatesList() {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [DriveNameValue, setDriveNameValue] = useState("");
  const [CandidateCompetency, setCandidateCompetency] = useState("");

  const [fileMerge, setFileMerge] = useState("");
  const [spanText, setSpan] = useState("file.ext");
  const [DriveArray, setDriveArray] = useState([]);
  const [courseArray, setCourseArray] = useState([]);
  const [courseId, setCourseId] = useState();
  const [branchArray, setbranchArray] = useState([]);
  const [branchNameData, setBranchNameData] = useState([]);
  const [candidatesArray, setcandidatesArray] = useState([]);
  const [logout, setlogout] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropValue, setDropValue] = useState(false);
  const [candidateId, setCandidateId] = useState();
  const [editData, setEditData] = useState("");
  const [NoofRows, setNoofrows] = useState(5);
  const [makeDisable, setMakeDisable] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [driveName, setDriveName] = useState();
  const [driveType, setDriveType] = useState("FRESHER");
  const [notYetHired, setIsNotYetHired] = useState(false);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const aRef = useRef(null);
  const useStyles = makeStyles({
    dropdownStyle: {
      width: "fit-content !important",
      maxHeight: "200px !important",
      height: "fit-content !important",
      top: "214px !important",
    },
  });

  const datagridSx = {
    "& .MuiDataGrid-autoHeight": {
      marginRight: "12px !important",
      marginLeft: "12px !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "rgb(0, 114, 142) !important",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      color: "#ffffff !important",
      fontSize: "0.9rem !important",
    },
  };

  const [loaderstate, setloderstate] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [currentDriveData, setCurrentDriveData] = useState({});
  const [driveStatus, setDriveStatus] = useState({});
  const [disableStatus, setDisableStatus] = useState();
  const [branchData, setBranchData] = useState();
  const [coursesData, setCourseData] = useState();
  const [done, setDone] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showBranch, setBranch] = useState(true);
  const [showFile, setShowFile] = useState(true);
  const [showBtn, setShowBtn] = useState(true);
  const navigate = useNavigate();
  const [showCode, setShowCode] = useState(false);
  // const DriveError = [{ id: 1, full_name: "No Drives" }];
  const [addCandidatesNoDrive, setAddCandidatesNoDrive] = useState(false);
  const [showResendCode, setShowResendCode] = useState(false);

  const ref = useRef();

  const openDrop = Boolean(anchorEl);

  const SheetJSFT = ["csv"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");

  const onhandleChange = (files /*:File*/) => {
    setFileMerge(files.target.files[0]);
    setSpan(files.target.files[0].name);
    setShowBtn(false);
    // setShowValues(true);
  };

  const handleSubmit = () => {
    setOpen(true);
  };

  useEffect(() => {
    // handleDriveData();
    // const courseId = 1;
    if (courseId !== undefined) {
      getAllBranchData(courseId);
    }
  }, [courseId]);

  const handleClose = () => setOpen(false);

  const modalClose = () => setModalOpen(false);

  useEffect(() => {
    getAllDriveData();
    // getAllDriveData();
  }, []);

  let pageNumber = 1;

  const handlePageClick = async (e, pageNumber) => {
    pageNumber = e.selected;
    // getIdValue(pageNumber);
    if (DriveNameValue !== undefined) {
      getCandidatesDataByDriveID(DriveNameValue, pageNumber + 1);
    }
  };

  const getAllDriveData = async () => {
    setLoading(true);
    API.getDriveData().then((response) => {
      if (response.data.status) {
        const data = response.data.data.rows;
        setAddCandidatesNoDrive(false);
        if (data.length > 0) {
          const updatedData = data.map((items, index) => ({
            id: items.id,
            full_name: items.drive_name,
          }));
          setDriveNameValue(data[0].id);
          setDriveArray(updatedData);
          setDriveType(data[0].candidate_experience_level);
          setDriveName(data[0].drive_name);
          setCurrentDriveData(data[0]);
          setDriveStatus(data[0].drive_running_status);
          if (
            (data[0].drive_running_status === "onGoing" ||
              data[0].drive_running_status === "pending") &&
            data[0].status !== 2
          ) {
            setMakeDisable(false);
          }
          if (updatedData.length > 0) {
            getCandidatesDataByDriveID(
              data[0].id,
              pageNumber,
              data[0].candidate_experience_level,
              data[0].institute_name
            );
          }
          setLoading(false);
        }
      } else {
        setLoading(false);

        setAddCandidatesNoDrive(true);
        // setDriveArray(DriveError);
      }
    });
  };

  const handleClick = (event, params) => {
    if (
      params.row.status === "Registered" ||
      params.row.status === "Unregistered"
    ) {
      setIsNotYetHired(true);
    }
    setCandidateId(params.id);
    setAnchorEl(event.currentTarget);
    setDisableStatus(params.status);
    onecandidatedata(params.id);
    setShowResendCode(params.row.DriveStatus);
  };

  const handleDriveData = async (e) => {
    setDriveNameValue(e.target.value);
    setFileMerge();
    setBranchNameData();
    setCourseId();
    setBranchNameData();
    setMakeDisable(true);
    if (currentDriveData.length > 0) {
      setCurrentDriveData({});
    }
    let InstituteName;
    setLoading(true);
    await API.getOneDriveData(e.target.value).then(({ response }) => {
      if (response.data.status) {
        setLoading(false);
        const receivedData = response.data.data;
        setCurrentDriveData(receivedData);
        InstituteName = receivedData.institute_name;
        setDriveStatus(receivedData.drive_running_status);
        setShowBtn(true);
        setShowFile(true);
        setBranch(true);
        const receivedDataStatus = receivedData.drive_running_status;
        // alert(receivedData.drive_name);
        setDriveName(receivedData.drive_name);
        setDriveType((CandidateExperience) => {
          const ModifiedCandidateExperience =
            receivedData.candidate_experience_level;
          return ModifiedCandidateExperience;
        });
        if (
          (receivedDataStatus === "onGoing" ||
            receivedDataStatus === "pending") &&
          receivedData.status !== 2
        ) {
          setMakeDisable(false);
        }
        getCandidatesDataByDriveID(
          e.target.value,
          pageNumber,
          receivedData.candidate_experience_level,
          InstituteName
        );
      } else {
        setLoading(false);

        setNotify({
          isOpen: true,
          message: response.data.data.message,
          type: "error",
        });
        setMakeDisable(false);
        setShowBtn(true);
        setShowFile(true);
        setBranch(true);
      }
    });
  };

  const getAllCourseData = () => {
    API.getCoursesData().then(({ response }) => {
      if (response.status === 200) {
        const data = response.data.data.rows;
        const updatedData = data.map((items, index) => ({
          id: items.id,
          full_name: items.course_name,
        }));
        setCourseArray(updatedData);
      }
    });
  };

  const handleCourseData = (e) => {
    setCourseId(e.target.value);
  };

  const getAllBranchData = (courseId) => {
    try {
      API.getBranchData(courseId).then(({ response }) => {
        if (response.data.status) {
          setBranch(false);
          const data = response.data.data.rows;
          const updatedData = data.map((items, index) => ({
            id: items.id,
            full_name: items.branch_name,
          }));
          setbranchArray(updatedData);
        }
      });
    } catch (e) {}
  };

  const getCandidatesDataByDriveID = async (
    DriveNameValue,
    pageNumber,
    CompentcyLevel,
    InstituteName
  ) => {
    // setLoading(true);
    API.getCandidatesDataByDrive(DriveNameValue, pageNumber)
      .then((response) => {
        if (response.data.status) {
          let data = response.data.data.rows;
          const institute = currentDriveData.institute_name;
          if (CompentcyLevel && CompentcyLevel === "TRAINED") {
            const updatedData = data.map((items, index) => ({
              sno: (pageNumber - 1) * 5 + (index + 1),
              id: items.id,
              CandidateName: `${items.first_name} ${items.last_name}`,
              DriveName:
                items.drives.drive_name[0].toUpperCase() +
                items.drives.drive_name.toLowerCase().substring(1),
              Institute: items.drives.institute_name,
              DriveStatus: items.drives.is_drive_initialized,
              CandidateEmail: items.email,
              PhoneNumber: items.phone_no,
              status:
                items.candidate_recruitment_status[0].toUpperCase() +
                items.candidate_recruitment_status.toLowerCase().substring(1),
            }));
            setcandidatesArray(updatedData);
          } else if (CompentcyLevel && CompentcyLevel === "FRESHER") {
            const updatedValues = data.map((items, index) => ({
              sno: (pageNumber - 1) * 5 + (index + 1),
              id: items.id,
              CandidateName: `${items.first_name} ${items.last_name}`,
              DriveName:
                items.drives.drive_name[0].toUpperCase() +
                items.drives.drive_name.toLowerCase().substring(1),
              DriveStatus: items.drives.is_drive_initialized,
              UniversityCollege: items.college_name,
              CandidateEmail: items.email,
              PhoneNumber: items.phone_no,
              status:
                items.candidate_recruitment_status[0].toUpperCase() +
                items.candidate_recruitment_status.toLowerCase().substring(1),
            }));
            setcandidatesArray(updatedValues);
          } else if (driveType === "TRAINED") {
            const updatedData = data.map((items, index) => ({
              sno: (pageNumber - 1) * 5 + (index + 1),
              id: items.id,
              CandidateName: `${items.first_name} ${items.last_name}`,
              DriveName:
                items.drives.drive_name[0].toUpperCase() +
                items.drives.drive_name.toLowerCase().substring(1),
              Institute: items.drives.institute_name,
              CandidateEmail: items.email,
              PhoneNumber: items.phone_no,
              DriveStatus: items.drives.is_drive_initialized,
              status:
                items.candidate_recruitment_status[0].toUpperCase() +
                items.candidate_recruitment_status.toLowerCase().substring(1),
            }));
            setcandidatesArray(updatedData);
          } else {
            const updatedValues = data.map((items, index) => ({
              sno: (pageNumber - 1) * 5 + (index + 1),
              id: items.id,
              CandidateName: `${items.first_name} ${items.last_name}`,
              DriveName:
                items.drives.drive_name[0].toUpperCase() +
                items.drives.drive_name.toLowerCase().substring(1),
              UniversityCollege: items.college_name,
              CandidateEmail: items.email,
              DriveStatus: items.drives.is_drive_initialized,
              PhoneNumber: items.phone_no,
              status:
                items.candidate_recruitment_status[0].toUpperCase() +
                items.candidate_recruitment_status.toLowerCase().substring(1),
            }));
            setcandidatesArray(updatedValues);
          }
          setPageCount(response.data.data.count);
          // setLoading(false);
        } else {
          // setNotify({
          //   isOpen: true,
          //   message: response.data.message,
          //   type: "error",
          // });
          setcandidatesArray([]);
          // setLoading(false);
        }
      })
      .catch((error) => {
        // setLoading(false);
      });
  };

  const getAllCandidateData = (pageNumber) => {
    try {
      API.getCandidatesData(pageNumber)
        .then(({ response }) => {
          if (response.data.status) {
            setDone(true);
            const data = response.data.data.rows;
            const updatedData = data.map((items, index) => ({
              sno: (pageNumber - 1) * 5 + (index + 1),
              id: items.id,
              CandidateName: `${items.first_name} ${items.last_name}`,
              DriveName:
                items.drives.drive_name[0].toUpperCase() +
                items.drives.drive_name.toLowerCase().substring(1),
              UniversityCollege: items.college_name,
              CandidateEmail: items.email,
              PhoneNumber: items.phone_no,
              status:
                items.candidate_recruitment_status[0].toUpperCase() +
                items.candidate_recruitment_status.toLowerCase().substring(1),
            }));
            setPageCount(response.data.data.count);
            setcandidatesArray(updatedData);
            setLoading(false);
          }
        })
        .catch((error) => {
          setDone(true);
        });
    } catch (e) {
      setDone(true);
    }
  };

  const uploadExcelFile = () => {
    setFileMerge();
    setSpan("file.ext");
    setShowBtn(true);
    aRef.current.value = null;

    API.bulkUploadCandidatesData(fileMerge, DriveNameValue).then(
      ({ response }) => {
        // if (response.status) {

        //   setNotify({
        //     isOpen: true,
        //     message: response
        //       ? response.message
        //       : "Error occured please retry upload",
        //     type: "success",
        //   });
        //   setLoading(false);
        // } else {
        //   setNotify({
        //     isOpen: true,
        //     message: response
        //       ? response.message
        //       : "Error occured please retry upload",
        //     type: "error",
        //   });
        //   setLoading(false);
        // }
        if (response.status) {
          setLoading(false);
          getCandidatesDataByDriveID(DriveNameValue, pageNumber);
          const data = response.data.data.rows;
          const updatedData = data.map((items, index) => ({
            file: items.fileMerge,
            drive_id: items.DriveNameValue,
            course_id: items.courseId,
            branch_id: items.branchNameData,
          }));
          setcandidatesArray(updatedData);
          setNotify({
            isOpen: true,
            message: "success",
            type: "success",
          });
        } else {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
        }
        setLoading(false);
      }
    );
  };

  const disableEnableDrive = (dropValue, candidateId, disableStatus) => {
    const value = disableStatus === 0 ? true : false;
    const message = value
      ? "Candidate Enabled Successfully"
      : "Candidate Disable Successfully";
    API.getDisableEnableDrive(value, candidateId).then(({ response }) => {
      if (response.status) {
        setNotify({
          isOpen: true,
          message: message,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: message,
          type: "error",
        });
      }
    });
  };

  const handleCloseDrop = () => {
    setAnchorEl(null);
    setIsNotYetHired(false);
  };

  const handleDisable = () => {
    setDropValue(!dropValue);
    setAnchorEl(null);
    disableEnableDrive(dropValue, candidateId, disableStatus);
  };

  const handleDeleteData = () => {
    setAnchorEl(null);
    deleteCandidate(candidateId);
  };

  const onecandidatedata = (id) => {
    API.onecandidate(id).then(({ response }) => {
      setDriveType(response.data.data.drives.candidate_experience_level);
    });
  };

  const handleResendCode = () => {
    setAnchorEl(null);
    setLoading(true);
    API.resendTestLinkToCandidate(candidateId).then(({ response }) => {
      if (response.status === true) {
        setLoading(false);
        setNotify({
          isOpen: true,
          message: response.message,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "error",
        });
        setLoading(false);
      }
    });
  };

  const deleteCandidate = (candidateId) => {
    API.deleteCandidateData(candidateId).then(({ result }) => {
      if (result.status === true) {
        setNotify({
          isOpen: true,
          message: `${result.message}`,
          type: "success",
        });

        const timer = setTimeout(() => {
          getCandidatesDataByDriveID(DriveNameValue, pageNumber);
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        setNotify({
          isOpen: true,
          message: result.message,
          type: "error",
        });
      }
    });
  };

  const handleEditData = () => {
    setAnchorEl(null);
    setModalOpen(true);

    editCandidateData(candidateId);
  };

  const editCandidateData = (candidateId) => {
    API.editOneCandidate(candidateId).then(({ response }) => {
      if (response.data.status === true) {
        setEditData(response.data.data);
        setloderstate(true);
        setCourseData(response.data.data.courses);
        setBranchData(response.data.data.branches);
      }
    });
  };

  const rowColumns = [
    {
      field: "sno",
      headerName: "S.No",
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      cellClassName: "textCapital_letter",
      flex: 1,
      disable: true,
    },
    {
      field: "id",
      headerName: "id",
      align: "center",
      headerClassName: "super-app-theme--header",
      cellClassName: "textCapital_letter",
      flex: 1,
      hide: true,
    },

    {
      field: "CandidateName",
      headerName: "Candidate Name",
      cellClassName: "textCapital_letter",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => (
        <>
          <span
            style={{
              cursor: "pointer",
              color: "#00728E",
              textTransform: "capitalize",
            }}
          >
            {params.row.CandidateName}
          </span>
        </>
      ),
      flex: 1,
    },

    {
      field: "DriveName",
      headerName: "Drive Name",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "left",
      ClassName: "textCapital_letter",
      flex: 1,
    },

    {
      field: driveType === "FRESHER" ? "UniversityCollege" : "Institute",
      headerName: driveType === "FRESHER" ? "UniversityCollege" : "Institute",
      headerClassName: "super-app-theme--header",
      cellClassName: "textCapital_letter:first-letter",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        if (driveType === "TRAINED") {
          return (
            <span
              className="textCapital_letter"
              style={{ justifyContent: "center", textTransform: "capitalize" }}
            >
              {params.row.Institute ? params.row.Institute : "NA"}
            </span>
          );
        } else if (driveType === "FRESHER") {
          return (
            <span
              // className="textCapital_letter"
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                textTransform: "capitalize",
              }}
            >
              {params.row.UniversityCollege
                ? params.row.UniversityCollege
                : "NA"}
            </span>
          );
        }
      },
    },
    {
      field: "CandidateEmail",
      headerName: "Candidate Email",
      cellClassName: "textCapital_letter",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "left",
      flex: 1,
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      ClassName: "textCapital_letter",
      align: "left",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      flex: 1,
      disable: true,
    },

    {
      field: "status",
      headerName: "Status",
      ClassName: "textCapital_letter",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerName: "Action",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              id="positioned-demo-button"
              aria-controls={openDrop ? "positioned-demo-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openDrop ? "true" : undefined}
              variant="outlined"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#00728E",
              }}
              color="neutral"
              onClick={(event) => handleClick(event, params)}
            >
              <MoreVert />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleBranchData = (e) => {
    setBranchNameData(e.target.value);
    setShowFile(false);
  };

  const handleHomeClick = () => {
    navigate("/recruiter-home");
  };

  return (
    <>
      <Box className="UploadCandidate-main-container">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
            marginBottom: "8px",
            textTransform: "capitalize",
          }}
        >
          <div
            className="RecruiterCndidates-about-breadcrumbs"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  "&:hover": { textDecoration: "none", color: "#fff" },
                }}
                color="inherit"
                href="/recruiter-home"
              >
                <WestIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Home
              </Link>
              <Link
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  "&:hover": { textDecoration: "none", color: "#fff" },
                }}
                color="inherit"
              >
                Candidates List
              </Link>
            </Breadcrumbs>
          </div>

          <div style={{ marginRight: "9%" }}>
            <Logout />
          </div>
        </div>

        <Box className="UploadCandidate-container">
          <>
            {!done ? (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80vh",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#00728E"}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <Box className="UploadRecruiters_Select_section">
                <Box className="UploadCandidate-Header">
                  <span>Candidates Lists</span>

                  {/* {!makeDisable ? (
                    <>
                      {addCandidatesNoDrive === false ? (
                        <ReusableButton
                          style={{
                            float: "right",
                            backgroundColor: "#00728E",
                            textTransform: "capitalize",
                            "&:hover": { backgroundColor: "#00728E" },
                          }}
                          endIcon={<ArrowRightAltIcon />}
                          text="Add Candidate"
                          onClick={handleSubmit}
                          // setMakeDisable(false);
                          // disabled={showBtn}
                        />
                      ) : (
                        <ReusableButton
                          style={{
                            float: "right",
                            backgroundColor: "#00728E",
                            display: "none",
                            textTransform: "capitalize",
                            "&:hover": { backgroundColor: "#00728E" },
                          }}
                          endIcon={<ArrowRightAltIcon />}
                          text="Add Candidate"
                          onClick={handleSubmit}
                          // setMakeDisable(false);
                          // disabled={showBtn}
                        />
                      )}
                    </>
                  ) : null} */}

                </Box>
                <Grid>
                  <Box
                    style={{
                      display: "flex",
                      paddingTop: "20px",
                      width: "100%",
                    }}
                  >
                    <Grid item xs={3}>
                      <div>
                        <div style={{ marginBottom: "9px" }}>
                          <label style={{ paddingBottom: "0px" }}>
                            Select Drive
                          </label>
                        </div>
                        {DriveArray.length > 0 ? (
                          <>
                            <ResuableDropdown
                              className="dropDown_Boder_ColorChanges"
                              MenuProps={useStyles}
                              style={{
                                height: "40px",
                                width: "180px",
                                marginRight: "10px",
                                // overflow:"hidden",
                                color: DriveNameValue === "none" ? "grey" : "",
                              }}
                              name="Select Drive"
                              // label="Select Source"
                              options={DriveArray}
                              value={DriveNameValue}
                              onChange={handleDriveData}
                              helperText="asasdsa"
                              size="small"
                              spanText="Select Drive"
                            />
                          </>
                        ) : (
                          <>
                            <div className="label-select-class">
                              <FormControl
                                sx={{ m: 1, minWidth: 180 }}
                                disabled
                              >
                                <InputLabel
                                  id="demo-simple-select-disabled-label"
                                  className="select-class-drive-candlist"
                                >
                                  <em>No Drives</em>
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-disabled-label"
                                  id="demo-simple-select-disabled"
                                  label="Age"
                                ></Select>
                              </FormControl>
                            </div>
                          </>
                        )}
                      </div>
                    </Grid>

                    {/* {!makeDisable ? (
                      <>
                        <Grid item xs={4}>
                          <div style={{ width: "21vw" }} disabled={makeDisable}>
                            <div>
                              <label>Upload Candidates List</label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                // marginTop:"2%",
                                alignItems: "baseline",
                                border: "0.5px solid #00728E",
                                borderRight: "0",
                                borderRadius: "0.3rem",
                                width: "91%",
                                position: "static",
                                marginTop: "1.8%",
                              }}
                            >
                              <input
                                id="file-chosen"
                                className="browserFiles"
                                accept=".csv"
                                style={{
                                  borderTopStyle: "hidden",
                                  borderBottomStyle: "hidden",
                                  borderLeftStyle: "hidden",
                                  borderRightStyle: "hidden",
                                  width: "64%",
                                  opacity: "0.5",
                                }}
                                value={spanText}
                              />

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "baseline",
                                  width: "51%",
                                }}
                              >
                                <label
                                  for="actual-btn"
                                  className="upload-label"
                                >
                                  Browse File
                                </label>
                                <input
                                  type="file"
                                  id="actual-btn"
                                  hidden
                                  accept={SheetJSFT}
                                  onChange={onhandleChange}
                                  // disabled={showFile}
                                  ref={aRef}
                                  className="browserFiles"
                                  readonly
                                />
                              </div>
                            </div>
                            <span style={{ opacity: "0.85", fontSize: 12 }}>
                              {" "}
                              {errorText}{" "}
                            </span>
                          </div>
                        </Grid>
                        <Grid item xs={2} style={{ width: "100%" }}>
                          <Box
                            style={{
                              "&:hover": { backgroundColor: "transparent" },
                            }}
                          >
                            <ReusableButton
                              style={{
                                float: "right",
                                backgroundColor: "#00728E",
                                textTransform: "capitalize",
                                "&:hover": { backgroundColor: "#00728E" },
                                marginTop: "40px",
                              }}
                              endIcon={<ArrowRightAltIcon />}
                              text="Upload"
                              // onClick={handleSubmit}
                              onClick={() => uploadExcelFile()}
                              disabled={showBtn}
                            />
                          </Box>
                        </Grid>
                      </>
                    ) : null} */}
                  </Box>
                </Grid>
              </Box>
            )}
          </>
          {loading ? (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "60vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#00728E"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <>
              {done && (
                <>
                  {candidatesArray.length > 0 ? (
                    <Box>
                      {/* <TabPanel value={value} index={0}> */}
                      <Box
                        className="uploadCandidate-table"
                        style={{
                          height: "100%",
                          width: "100%",
                          marginTop: "16px",
                          padding: "0px 34px 0px 34px",
                        }}
                      >
                        <DataTable
                          rows={candidatesArray}
                          columns={rowColumns}
                          rowHeight={40}
                          autoHeight
                          pageSize={5}
                          hideFooter={true}
                          hideFooterPagination={true}
                          hideFooterSelectedRowCount={true}
                          sx={datagridSx}
                        />
                        <Box
                          style={{
                            display: "flex",
                            // justifyContent: "flex-end",
                            justifyContent: "center",
                            marginTop: "25px",
                            marginRight: "20px",
                            position: "absolute",
                            bottom: "0px",
                            width: "72%",
                          }}
                        >
                          <ReactPaginate
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={1}
                            pageCount={pageCount / NoofRows}
                            previousLabel="<"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        </Box>
                      </Box>
                      {/* </TabPanel> */}
                    </Box>
                  ) : (
                    <div style={{ margin: "20px 35px 0px 35px" }}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell className="sno-content">
                              S.No
                            </StyledTableCell>
                            <StyledTableCell>Candidate Name</StyledTableCell>
                            <StyledTableCell>Drive Name</StyledTableCell>
                            <StyledTableCell>
                              University/College
                            </StyledTableCell>
                            {/* <StyledTableCell>Location</StyledTableCell> */}
                            <StyledTableCell>Candiadate Email</StyledTableCell>
                            <StyledTableCell>Phone Number</StyledTableCell>
                            <StyledTableCell className="content">
                              Status
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                      </Table>

                      <div
                        style={{
                          width: "100%",
                          height: "30vh",
                          textAlign: "center",
                          marginTop: "100px",
                        }}
                      >
                        <p>Candidates not found</p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </Box>
        <RecruiterCandidatesCreatePage
          open={open}
          handleClose={handleClose}
          branchArray={branchArray}
          courseArray={courseArray}
          DriveNameValue={DriveNameValue}
          DriveName={driveName}
          driveType={driveType}
          getAllCandidateData={getAllCandidateData}
          getAllBranchData={getAllBranchData}
          getCandidatesDataByDriveID={getCandidatesDataByDriveID}
          pageNumber={pageNumber}
        ></RecruiterCandidatesCreatePage>

        {loaderstate ? (
          <RecruiterCandidatesEditPage
            open={modalOpen}
            handleClose={modalClose}
            branchData={branchData}
            coursesData={coursesData}
            courseArray={courseArray}
            getAllCandidateData={getAllCandidateData}
            setloderstate={setloderstate}
            editData={editData}
            getCandidatesDataByDriveID={getCandidatesDataByDriveID}
            DriveNameValue={DriveNameValue}
            driveType={driveType}
            pageNumber={pageNumber}
            driveName={driveName}
          ></RecruiterCandidatesEditPage>
        ) : null}
      </Box>

      <StyledEngineProvider injectFirst>
        <CssVarsProvider>
          <Menu
            id="positioned-demo-menu"
            anchorEl={anchorEl}
            open={openDrop}
            onClose={handleCloseDrop}
            aria-labelledby="positioned-demo-button"
            placement="bottom-end"
          >
            <MenuItem onClick={handleEditData}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteData}>Delete</MenuItem>
            {showResendCode ? (
              <MenuItem onClick={handleResendCode}>Resend Code</MenuItem>
            ) : null}
          </Menu>
        </CssVarsProvider>
      </StyledEngineProvider>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
