import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import ReusableButton from "../../../CommonComponents/Button/Button";
import Grid from "@mui/material/Grid";
import "./AdminCompletedCandidatesRecruited.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import Cookies from "js-cookie";
import { Breadcrumbs, Button, Link } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import ProfilePic from "../../../Assests/Group 766.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import { API } from "../../../Networking/API";
import FlagIcon from "@mui/icons-material/Flag";
import { DataTable } from "../../../CommonComponents/DataTable/DataTable";
import Geocode from "react-geocode";
import Logout from "../../../CommonComponents/Logout/Logout";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export function AdminCompletedCandidatesRecruited(props) {
  const location = useLocation();

  const [value, setValue] = React.useState(0);
  const [driveid, setDriveid] = useState(location.state.driveData);
  const [candidates, setCandidates] = useState(location.state.candidate);
  const [
    candidateConsolidationRecruitedData,
    setCandidateConsolidationRecruitedData,
  ] = useState("");
  const [candidateConsolidationData, setCandidateConsolidationData] =
    useState("");
  const [dataCount, setDataCount] = useState(0);
  const [dataCountH, setDataCountH] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const image = localStorage.getItem("image");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const recruitersData = driveid.recruiters;
  const time = moment(driveid.drive_time, ["HH:mm"]).format("h:mm A");

  useEffect(() => {
    getConsolidatedHiredDataByDrive(driveid.id);
    getConsolidatedDataByDrive(driveid.id);
  }, []);


  const getLocation = (locationdata) => {
    Geocode.setApiKey("AIzaSyDwOeav_33UjrA28kwyXPnBa6eEKx_Hm5c");
    try {
      const location = [];
      Geocode.fromLatLng(
        locationdata.split(",")[0],
        locationdata.split(",")[1]
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          let city;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          location.push(city);
        },

        (error) => {
          return "N/A";
        }
      );
      return location;
    } catch (error) {
      return "N/A";
    }
  };


  const getConsolidatedHiredDataByDrive = (driveId) => {
    API.getConsolidatedRecruitedCandidateList(driveId).then(({ response }) => {
      if (response.data.status) {
        let GetOverallPercentage = (OverAllResults) => {
          try {
            if (OverAllResults) {
              let FinalPercentage = 0;
              for (var IndividualResults of OverAllResults) {
                FinalPercentage =
                  FinalPercentage +
                  parseInt(IndividualResults.calculated_percentage);
              }
              return Math.ceil(FinalPercentage);
            } else return 0;
          } catch {
            return 0;
          }
        };
        const data = response.data.data;
        setDataCountH(data.length);
        const consolidatedData = data.map((items, index) => {
          let firstName =
            items.first_name[0]?.toUpperCase() + items.first_name?.substring(1);
          let lastName =
            items.last_name[0]?.toUpperCase() + items.last_name?.substring(1);
          return {
            id: index + 1,
            Candidates: `${firstName} ${lastName}`,
            Recruiter: items?.recruiter?.full_name ?? "NA",
            ScoresArchived: `${GetOverallPercentage(items?.results)}`,
            Location: items?.location ? getLocation(items.location) : "NA",
          };
        });
        setCandidateConsolidationRecruitedData(consolidatedData);
      } else {
        setDataCountH(0);
        setNotify({
          isOpen: true,
          message: response.data.data.message,
          type: "error",
        });
      }
    });
  };
  const getConsolidatedDataByDrive = (driveId) => {
    API.getConsolidatedCandidateList(driveId).then((response) => {
      if (response.data.status) {
        const data = response.data.data;
        setDataCount(data.length);
        const consolidatedData = data.map((items, index) => {
          let Aptitude = "";
          let Technical = "";
          let Verbal = "";
          let Reasoning = "";
          if (items.results.length > 0) {
            const data = items.results.find((i) => {
              return i.category?.quiz_category_code === "APTITUDE";
            });
            const data1 = items.results.find((i) => {
              return i.category?.quiz_category_code === "TECHNICAL";
            });
            const data2 = items.results.find((i) => {
              return i.category?.quiz_category_code === "REASONING";
            });
            const data3 = items.results.find((i) => {
              return i.category?.quiz_category_code === "VERBAL";
            });

           

            const num =
              data === undefined ? 0 : Math.ceil(data.calculated_percentage);
            const num1 =
              data1 === undefined ? 0 : Math.ceil(data1.calculated_percentage);
            const num2 =
              data2 === undefined ? 0 : Math.ceil(data2.calculated_percentage);
            const num3 =
              data3 === undefined
                ? 0
                : Math.ceil(Math.ceil(data3.calculated_percentage));
            Aptitude =
              data?.calculated_percentage &&
              Number(data?.calculated_percentage) !== 0
                ? `${num}`
                : "0";

            Technical =
              data1?.calculated_percentage &&
              Number(data1?.calculated_percentage) !== 0
                ? `${num1}`
                : "0";

            Verbal =
              data3?.calculated_percentage &&
              Number(data3?.calculated_percentage) !== 0
                ? `${num3}`
                : "0";
            Reasoning =
              data2?.calculated_percentage &&
              Number(data2?.calculated_percentage) !== 0
                ? `${num2}`
                : "0";
          }

          let firstName =
            items.first_name[0]?.toUpperCase() + items.first_name?.substring(1);
          let lastName =
            items.last_name[0]?.toUpperCase() + items.last_name?.substring(1);
          return {
            id: index + 1,
            Candidates: `${firstName} ${lastName}`,
            Aptitude: Aptitude ? Aptitude : "0",
            Technical: Technical ? Technical : "0",
            Verbal: Verbal ? Verbal : "0",
            Reasoning: Reasoning ? Reasoning : "0",
            DriveTime: time,
            DriveDate: driveid.drive_date,
            backgroundTracking: items.background_activity_total_time,
          };
        });
        setCandidateConsolidationData(consolidatedData);
      } else {
        setDataCount(0);
        setNotify({
          isOpen: true,
          message: response.data.data.message,
          type: "error",
        });
      }
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      align: "center",
      headerClassName: "completed-task-table",
      cellClassName: "completed-task-table-id-row",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "backgroundTracking",
      headerName: "",
      headerClassName: "completed-task-table",
      cellClassName: "completed-task-table-id-row",
      width: 30,
      renderCell: (params) => {
        return (
          <>
            {params.row.backgroundTracking > 2 ? (
              <FlagIcon style={{ color: "#BB0000" }} />
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "Candidates",
      headerName: "Candidates",
      headerClassName: "completed-task-table",
      cellClassName: "completed-task-table-id-row",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },

    {
      field: "Aptitude",
      headerName: "Aptitude",
      headerClassName: "completed-task-table",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "Technical",
      headerClassName: "completed-task-table",
      headerName: "Technical",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "Verbal",
      headerClassName: "completed-task-table",
      headerName: "Verbal",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "Reasoning",
      headerClassName: "completed-task-table",
      headerName: "Reasoning",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "DriveDate",
      headerClassName: "completed-task-table",
      headerName: "Drive Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "DriveTime",
      headerClassName: "completed-task-table",
      headerName: "Drive Time",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
  ];

  const candidatesRecruitedColumns = [
    {
      field: "id",
      headerName: "S.No",
      align: "left",
      headerClassName: "completed-task-table",
      cellClassName: "completed-task-table-id-row",
      // width: 60,
      flex: 1,
    },
    {
      field: "Candidates",
      headerName: "Candidates",
      headerClassName: "completed-task-table",
      cellClassName: "completed-task-table-id-row",
      align: "left",
      // width: 231,
      flex: 1,
    },
    {
      field: "Recruiter",
      headerName: "Recruiter",
      headerClassName: "completed-task-table",
      align: "left",
      // width: 130,
      flex: 1,
    },
    {
      field: "ScoresArchived",
      headerClassName: "completed-task-table",
      headerName: "Scores Archived",
      align: "left",
      flex: 1,
    },
    {
      field: "Location",
      headerClassName: "completed-task-table",
      headerName: "Location",
      align: "left",
      flex: 1,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const handleCancelButton = () => {
    navigate("/admin-recruiting-drive");
    setDriveid();
    setCandidates();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuBar = () => {
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    navigate("/");
    Cookies.remove("jwtToken");
  };

  const handleHomeClick = () => {
    navigate("/admin-recruiting-drive");
    setDriveid();
    setCandidates();
  };

  return (
    <Box className="Candidate-container-div">
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <ReusableButton
          text="Back"
          startIcon={<WestIcon />}
          onClick={handleHomeClick}
          style={{
            textTransform: "capitalize",
            // marginLeft: "10%",
            color: "#fff",
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            background: "none",
            border: "none",
            padding: "0px",
            boxShadow: "none",
            outline: "none",
            "&:focus": {
              background: "none",
              border: "none",
              padding: "0px",
              boxShadow: "none",
              outline: "none",
            },
            "&:hover": {
              background: "none",
              border: "none",
              padding: "0px",
              boxShadow: "none",
              outline: "none",
            },
          }}
        ></ReusableButton>

        <div style={{ width: "10%" }}>
          <Logout />
        </div>
      </div>
      <Box className="Candidates-container box_height Candidates-tabs">
        <Box
          style={{
            paddingTop: "30px",
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="Candidates-Header">{driveid.drive_name}</span>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <span className="completed-dot"></span>
            <span className="completed_Word_styles">
              {driveid.drive_running_status}
            </span>
          </div>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Drive Name
                </label>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                  }}
                >
                  {driveid.drive_name}
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  University/institute
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {driveid.college_name === null
                    ? driveid.institute_name
                    : driveid.college_name}
                </span>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Location
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {driveid.location}
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Drive Date
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {driveid.drive_date}
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Drive Time
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {time}
                </span>
              </Box>
            </Grid>

            {recruitersData.map((items, index) => (
              <Grid item xs={3}>
                <Box
                  className=""
                  style={{ display: "grid", marginTop: "14px" }}
                >
                  <label
                    style={{ paddingBottom: "5px", fontSize: "14px" }}
                    htmlFor=""
                  >
                    Recruiter {index + 1}
                  </label>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      textTransform: "capitalize",
                    }}
                  >
                    {items.full_name}
                  </span>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          style={{ display: "flex", marginTop: "25px" }}
          className="completed-tabs"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            // className="Candidates-tabs"
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              label="Candidates Recruited"
              style={{ textTransform: "capitalize" }}
              {...a11yProps(0)}
            />
            <Tab
              label="Candidates Drive Results"
              style={{ textTransform: "capitalize" }}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Box sx={{ height: 400, width: "100%" }} className="candiatetable">
            <DataTable
              className="completed-table-one"
              rows={candidateConsolidationRecruitedData}
              columns={candidatesRecruitedColumns}
              autoHeight
              rowHeight={50}
              // pageSize={10}
              // rowsPerPageOptions={[5]}
              // checkboxSelection
              // disableSelectionOnClick
              hideFooter={true}
              hideFooterPagination={true}
            />
            <div className="overall-data-found">
              <span className="total-data-text">
                Total Number of Candidates
              </span>
              <span className="total-data-text" style={{ paddingLeft: "15px" }}>
                {candidateConsolidationRecruitedData.length}
              </span>
            </div>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Box sx={{ height: 400, width: "100%" }} className="candiatetable">
            <DataTable
              rows={candidateConsolidationData}
              columns={columns}
              autoHeight
              rowHeight={50}
              className="completed-table-two"
              // pageSize={10}
              // rowsPerPageOptions={[5]}
              // checkboxSelection
              // disableSelectionOnClick
              // experimentalFeatures={{ newEditingApi: true }}
              // hideFooter={true}
              hideFooter={true}
              hideFooterPagination={true}
            />
            <div className="overall-data-found">
              <span className="total-data-text">Total Number of Candidates</span>
              <span className="total-data-text" style={{ paddingLeft: "15px" }}>
                {candidateConsolidationData.length}
              </span>
            </div>
          </Box>
        </TabPanel>

        <ReusableButton
          style={{
            float: "right",
            Color: "#00728E",
            marginRight: "50px",
            textTransform: "capitalize",
            marginTop: "100px",
            padding: "4px 25px",
            color: "#fff",
            background: "#00728E",
            borderRadius: "4px",
            "&:hover": {
              background: "#00728E",
              borderRadius: "4px",
              Color: "#00728E",
            },
          }}
          size="medium"
          text="cancel"
          variant="outlined"
          onClick={handleCancelButton}
        />
      </Box>
    </Box>
  );
}
