import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import ReusableButton from "../../../CommonComponents/Button/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { API } from "../../../Networking/API";
import ResuableDropdown from "../../../CommonComponents/DropDown/DropDown";
import Notification from "../../../CommonComponents/Notification/Notification";
import RecruiterCandidatesEditPage from "../../Recruiter/RecruiterUploadCandidatesList/RecruiterCandidateEditPage";
import Grid from "@mui/material/Grid";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Logout from "../../../CommonComponents/Logout/Logout";
import ReactLoading from "react-loading";
import { DataTable } from "../../../CommonComponents/DataTable/DataTable";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { TextField } from "@material-ui/core";
import { BorderLeft } from "@mui/icons-material";
import "./AdminUploadQuestions.css";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { maxWidth, textAlign } from "@mui/system";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/joy/IconButton";
import LinkM from "@mui/material/Link";

let note = "Only .csv format allowed";
// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#00728E",
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//     borderRadius: "20%",
//   },
// }));

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }
// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function Row(props) {
  let {
    Quizno,
    QuizId,
    QuizQuestions,
    QuizCategory,
    QuizTechonology,
    QuizDepartment,
    QuizAnswer,
    QuestionIndex,
  } = props;
  const [open, setOpen] = React.useState(false);
  let OptionsIndex = ["A", "B", "C", "D", "E", "F"];
  let CheckwithOptionIdentifier = (Answer, index) => {
    if (index == 1) {
    }
    let OptionIndexCheck = [
      `a)`,
      "b)",
      "c)",
      "d)",
      "a).",
      "b).",
      "c).",
      "d).",
      "A)",
      "B)",
      "C)",
      "D)",
      "E)",
      "A).",
      "B).",
      "C).",
      "D).",
      "E).",
    ];

    switch (OptionIndexCheck.includes(Answer.split(" ")[0])) {
      case true:
        switch (Answer.split(" ")[0].includes(".")) {
          case true:
            return Answer.slice(3);
          case false:
            return Answer.slice(2);
        }
      case false:
        return Answer;
    }
  };

  return (
    <>
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell style={{ border: "none" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {!open ? (
            <>
              <TableCell align="center" style={{ border: "none" }}>
                {Quizno}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  textOverflow: "ellipsis",
                  border: "none",
                  maxWidth: "250px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {QuizQuestions}
              </TableCell>
              <TableCell align="center" style={{ border: "none" }}>
                {QuizCategory}
              </TableCell>
              <TableCell
                align="center"
                style={{ border: "none", textTransform: "capitalize" }}
              >
                {QuizTechonology}
              </TableCell>
              <TableCell
                align="center"
                style={{ border: "none", textTransform: "capitalize" }}
              >
                {QuizDepartment}
              </TableCell>
            </>
          ) : null}
        </TableRow>

        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{ margin: 1 }}
                style={{ marginBottom: "20px", marginTop: "-43px" }}
                className="AccordianInnerContent"
              >
                <span style={{ marginLeft: "30px" }}>
                  <b> {QuizQuestions}</b>
                </span>
                <Box className="OptionsDiv">
                  {QuizAnswer?.map((Answers, index) => {
                    return Answers.is_correct ? (
                      <div className="CorrectOption">
                        <b>
                          ({OptionsIndex[index]}).{" "}
                          {CheckwithOptionIdentifier(
                            Answers.question_answer,
                            index
                          )}
                        </b>
                      </div>
                    ) : (
                      <div className="WrongOption">
                        ({OptionsIndex[index]}).{" "}
                        {CheckwithOptionIdentifier(
                          Answers.question_answer,
                          index
                        )}
                      </div>
                    );
                  })}
                </Box>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    </>
  );
}

export function AdminUploadQuiz() {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [categoryNameValue, setCategoryNameValue] = useState("");

  const [fileMerge, setFileMerge] = useState("");
  const [spanText, setSpan] = useState("file.ext");
  const [categoryArray, setCategoryArray] = useState([]);
  const [courseArray, setCourseArray] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subCategoryArray, setSubCategoryArray] = useState([]);
  const [branchNameData, setBranchNameData] = useState([]);
  const [candidatesArray, setcandidatesArray] = useState([]);

  const [logout, setlogout] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropValue, setDropValue] = useState(false);
  const [candidateId, setCandidateId] = useState();
  const [editData, setEditData] = useState("");
  const [makeDisable, setMakeDisable] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [driveName, setDriveName] = useState();
  const [loaderstate, setloderstate] = useState(false);
  const aRef = useRef(null);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [disableStatus, setDisableStatus] = useState();
  const [branchData, setBranchData] = useState();
  const [coursesData, setCourseData] = useState();
  const [done, setDone] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [showBranch, setBranch] = useState(true);
  const [showFile, setShowFile] = useState(true);
  const [showBtn, setShowBtn] = useState(true);
  const [errorCatId, setShowErrorCatId] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();

  const openDrop = Boolean(anchorEl);

  const SheetJSFT = ["csv"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");

  const onhandleChange = (files /*:File*/) => {
    setFileMerge(files.target.files[0]);
    setSpan(files.target.files[0].name);
    setShowBtn(false);
  };

  const handleSubmit = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (categoryNameValue !== undefined) {
      getAllSubCategoriesData(categoryNameValue);
    }
  }, [categoryNameValue]);

  const handleClose = () => setOpen(false);

  const modalClose = () => setModalOpen(false);
  let pageNumber = 1;

  useEffect(() => {
    getAllQuizCategories();
  }, []);

  useEffect(() => {
    getQuizAllQuestionsData(pageNumber);
  }, [pageNumber]);

  const handlePageClick = async (e, pageNumber) => {
    pageNumber = e.selected;
    if (categoryNameValue.length === 0) {
      getQuizAllQuestionsData(e.selected + 1);
    } else {
      getQuestionsDataBycategoryBased(categoryNameValue, e.selected + 1);
    }
  };

  const getAllQuizCategories = async () => {
    API.getAllQuizCategories().then((response) => {
      if (response.data.status) {
        const data = response.data.data;
        const updatedData = data.map((items, index) => ({
          id: items.id,
          full_name: items.quiz_category_name,
        }));
        setCategoryArray(updatedData);
      }
    });
  };

  const handleCategoryData = async (e) => {
    setFileMerge();
    aRef.current.value = null;
    setCategoryNameValue(e.target.value);
    setLoading(true);
    setShowBtn(true);
    setShowErrorCatId("");
    getQuestionsDataBycategoryBased(e.target.value, pageNumber);
  };

  const handleDepartmentData = (e) => {
    setSubCategoryId(e.target.value);
    setShowErrorCatId(false);
  };

  const getAllSubCategoriesData = (categoryNameValue) => {
    if (categoryNameValue === 2) {
      try {
        API.getallquestionCategoriesByCategory(categoryNameValue).then(
          (response) => {
            if (response) {
              setBranch(false);

              const data = response.data.data;
              const updatedData = data.map((items, index) => ({
                id: items.id,
                full_name: items.question_category_name,
              }));

              setSubCategoryArray(updatedData);
            } else {
              setSubCategoryArray([]);
            }
          }
        );
      } catch (e) {}
    }
  };

  const getQuizAllQuestionsData = (pageNumber) => {
    try {
      API.getQuizAllQuestions(pageNumber)
        .then(({ response }) => {
          if (response.data.status) {
            setDone(true);
            const data = response.data.data.rows;
            const updatedData = data.map((items, index) => ({
              sno: (pageNumber - 1) * 5 + (index + 1),
              id: items.id,
              Questions: items.question,
              Category: items.mainCategory.quiz_category_name,
              Department:
                items.category === null
                  ? "-"
                  : items.category.question_category_name === null
                  ? "-"
                  : items.category.question_category_name.toLowerCase(),
              Technology:
                items.subcategory === null
                  ? "-"
                  : items.subcategory.question_subcategory_name === null
                  ? "-"
                  : items.subcategory.question_subcategory_name.toLowerCase(),
              Answers: items.answers,
            }));

            setPageCount(response.data.data.count);
            setcandidatesArray(updatedData);
            setLoading(false);
          }
        })
        .catch(() => {
          setDone(true);
        });
    } catch (e) {
      setDone(true);
    }
  };

  const getQuestionsDataBycategoryBased = async (
    categoryNameValue,
    pageNumber
  ) => {
    API.getQuestionsDataBycategory(categoryNameValue, pageNumber).then(
      (response) => {
        if (response.data.status) {
          const data = response.data.data.rows;
          if (data[0].category && data[0].subcategory) {
            const updatedData = data.map((items, index) => ({
              sno: (pageNumber - 1) * 5 + (index + 1),
              id: items.id,
              Questions: items.question,
              Category: items.mainCategory.quiz_category_name,
              Department:
                items.category === null
                  ? "-"
                  : items.category.question_category_name === null
                  ? "-"
                  : items.category.question_category_name.toLowerCase(),
              Technology:
                items.subcategory === null
                  ? "-"
                  : items.subcategory.question_subcategory_name === null
                  ? "-"
                  : items.subcategory.question_subcategory_name.toLowerCase(),
              Answers: items?.answers ? items?.answers : [],
            }));

            setcandidatesArray(updatedData);
          } else {
            const updatedGeneralData = data.map((items, index) => ({
              sno: (pageNumber - 1) * 5 + (index + 1),
              id: items.id,
              Questions: items.question,
              Category: items.mainCategory.quiz_category_name,
              Department: "-",
              Technology: "-",
              Answers: items?.answers ? items?.answers : [],
            }));
            setcandidatesArray(updatedGeneralData);
          }
          setPageCount(response.data.data.count);
          setLoading(false);
        } else {
          setcandidatesArray([]);
          setLoading(false);
        }
      }
    );
  };

  const uploadExcelFile = () => {
    if (
      (categoryNameValue === 2 && subCategoryId !== "") ||
      categoryNameValue !== 2
    ) {
      setFileMerge();
      aRef.current.value = null;
      setSpan("file.ext");
      setShowBtn(true);
      let catValue = parseInt(categoryNameValue);
      let subCatValue = Number(subCategoryId);
      if (categoryNameValue === 2 && subCategoryId !== "") {
        API.bulkUploadQuizData(fileMerge, catValue, subCatValue).then(
          ({ response }) => {
            if (response.status) {
              setNotify({
                isOpen: true,
                message: response.message,
                type: "success",
              });
              setCategoryNameValue("");
              setSubCategoryId("");
              setShowBtn(true);

              setLoading(true);
              const timer = setTimeout(() => {}, 1000);
              getQuestionsDataBycategoryBased(categoryNameValue, pageNumber);

              return () => clearTimeout(timer);
            } else {
              setNotify({
                isOpen: true,
                message: response.message,
                type: "error",
              });
            }
            setLoading(false);
          }
        );
      }
      if (categoryNameValue !== 2) {
        API.bulkUploadQuizDataNonTech(fileMerge, catValue).then(
          ({ response }) => {
            if (response.status) {
              setNotify({
                isOpen: true,
                message: response.message,
                type: "success",
              });
              setCategoryNameValue("");
              setSubCategoryId("");
              setShowBtn(true);

              setLoading(true);
              const timer = setTimeout(() => {}, 1000);
              getQuestionsDataBycategoryBased(categoryNameValue, pageNumber);

              return () => clearTimeout(timer);
            } else {
              setNotify({
                isOpen: true,
                message: response.message,
                type: "error",
              });
            }
            setLoading(false);
          }
        );
      }
    } else {
      setShowErrorCatId(true);
    }
  };

  const disableEnableDrive = (dropValue, candidateId, disableStatus) => {
    const value = disableStatus === 0 ? true : false;
    const message = value
      ? "Candidate Enabled Successfully"
      : "Candidate Disable Successfully";
    API.getDisableEnableDrive(value, candidateId).then(({ response }) => {
      if (response.status) {
        getQuizAllQuestionsData(pageNumber);
        setNotify({
          isOpen: true,
          message: message,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong",
          type: "error",
        });
      }
    });
  };

  const rowColumns = [
    {
      field: "sno",
      headerName: "S.No",
      align: "center",
      headerAlign: "center",
      flex: 1,
      disable: true,
    },
    {
      field: "id",
      headerName: "id",
      align: "center",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },

    {
      field: "Questions",
      headerName: "Questions",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },

    {
      field: "Category",
      headerName: "Category",
      headerAlign: "center",
      cellClassName: "pending-table1",
      align: "center",
      flex: 1,
    },

    {
      field: "Department",
      headerName: "Department",
      headerAlign: "center",
      cellClassName: "pending-table1",
      align: "center",
      flex: 1,
    },

    {
      field: "Technology",
      headerName: "Technology",
      headerAlign: "center",
      cellClassName: "pending-table1",
      align: "center",
      flex: 1,
    },
  ];

  return (
    <>
      <Box className="QuizCandidate-main-container">
        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: 0,
          }}
        >
          <div
            className="Adminupload-about-breadcrumbs"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <LinkM
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  "&:hover": { textDecoration: "none", color: "#fff" },
                }}
                color="inherit"
                href="/admin-home"
              >
                <WestIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Home
              </LinkM>
              <LinkM
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  "&:hover": { textDecoration: "none", color: "#fff" },
                }}
                color="inherit"
              >
                Upload Quiz
              </LinkM>
            </Breadcrumbs>
          </div>
          <div style={{ width: "10%" }}>
            <Logout />
          </div>
        </div>
        <Box className="QuizCandidate-container">
          <>
            {!done ? (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80vh",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#00728E"}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <Box className="QuizRecruiters_Select_section" >
                <Box className="QuizCandidate-Header">
                  <span>Upload Quiz</span>
                </Box>
                <Grid>
                  <Box
                    style={{
                      display: "flex",
                      paddingTop: "20px",
                      width: "100%",
                    }}
                  >
                    <Grid item xs={3}>
                      <div>
                        <div style={{ marginBottom: "10px" }}>
                          <label style={{ paddingBottom: "10px" }}>
                            Select Category
                          </label>
                        </div>
                        <ResuableDropdown
                          className="dropDown_Boder_ColorChanges"
                          style={{
                            height: "40px",
                            width: "180px",
                            marginRight: "10px",
                            color: categoryNameValue === "none" ? "grey" : "",
                          }}
                          name="Select Category"
                          // label="Select Source"
                          options={categoryArray}
                          value={categoryNameValue}
                          onChange={handleCategoryData}
                          helperText="asasdsa"
                          size="small"
                          spanText="Select Category"
                        />
                      </div>
                    </Grid>
                    {/* {!makeDisable ? ( */}
                    {categoryNameValue === 2 ? (
                      <>
                        <Grid item xs={3}>
                          <div>
                            <div style={{ marginBottom: "20px" }}>
                              <label>Department</label>
                            </div>
                            <ResuableDropdown
                              style={{
                                height: "40px",
                                width: "180px",
                                marginRight: "10px",
                              }}
                              name="Select Department"
                              // label="Select Source"
                              options={
                                subCategoryArray ? subCategoryArray : null
                              }
                              value={subCategoryId}
                              onChange={handleDepartmentData}
                              helperText="asasdsa"
                              size="small"
                              disabled={makeDisable}
                              spanText="Select Department"
                            />
                            {errorCatId && (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                Please Select Department
                              </span>
                            )}
                          </div>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}

                    <Grid item xs={4}>
                      <div style={{ width: "30vw" }} disabled={makeDisable}>
                        <div>
                          <label>Upload Questions</label>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            // marginTop:"2%",
                            alignItems: "baseline",
                            border: "0.5px solid #00728E",
                            borderRight: "0",
                            borderRadius: "0.3rem",
                            width: "60%",
                            position: "static",
                            marginTop: "4%",
                          }}
                        >
                          <input
                            id="file-chosen"
                            className="quizbrowserFiles"
                            style={{
                              borderTopStyle: "hidden",
                              borderBottomStyle: "hidden",
                              borderLeftStyle: "hidden",
                              borderRightStyle: "hidden",
                              width: "60%",
                              opacity: "0.5",
                            }}
                            value={spanText}
                          />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "baseline",
                              width: "56%",
                            }}
                          >
                            <label
                              for="actual-btn"
                              className="Quizupload-label"
                            >
                              Browse File
                            </label>
                            <input
                              type="file"
                              id="actual-btn"
                              hidden
                              accept={SheetJSFT}
                              onChange={onhandleChange}
                              // disabled={showFile}
                              ref={aRef}
                              className="quizbrowserFiles"
                              readonly
                            />
                          </div>
                        </div>
                        <span style={{ opacity: "0.7", fontSize: 12 }}>
                          {note}
                        </span>
                      </div>
                    </Grid>

                    <Box
                      style={{
                        width: "75%",
                        "&:hover": { backgroundColor: "transparent" },
                      }}
                    >
                      <ReusableButton
                        style={{
                          float: "right",
                          backgroundColor: "#00728E",
                          textTransform: "capitalize",
                          "&:hover": { backgroundColor: "#00728E" },
                          marginTop: "40px",
                        }}
                        endIcon={<ArrowRightAltIcon />}
                        text="Upload"
                        // onClick={handleSubmit}
                        onClick={() => uploadExcelFile()}
                        disabled={showBtn}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Box>
            )}
          </>
          {loading ? (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#00728E"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <>
              {done && (
                <>
                  {/* {candidatesArray.length >0 ? ( */}
            
                    {/* <TabPanel value={value} index={0}> */}
                    <Box
                      className="QuizCandidate-table line-break"
                      style={{ height: "100%", width: "100%", padding: "30px" }}
                    >
                      <TableContainer
                        component={Paper}
                        className="QuizCandidateshadow"
                      >
                        <Table aria-label="collapsible table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{
                                  color: "white",
                                  backgroundColor: "#00728e",
                                  borderTopLeftRadius: "4px",
                                }}
                              />
                              {/* <TableCell
                      style={{ color: "white", backgroundColor: "#00728e" }}
                    /> */}
                              <TableCell
                                align="center"
                                style={{
                                  color: "white",
                                  backgroundColor: "#00728e",
                                }}
                              >
                                S.No
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  color: "white",
                                  backgroundColor: "#00728e",
                                }}
                              >
                                Questions
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  color: "white",
                                  backgroundColor: "#00728e",
                                }}
                              >
                                Category
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  color: "white",
                                  backgroundColor: "#00728e",
                                }}
                              >
                                Technology
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  color: "white",
                                  backgroundColor: "#00728e",
                                  borderTopRightRadius: "4px",
                                }}
                              >
                                Department
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {candidatesArray.length > 0
                              ? candidatesArray.map((Quizdata, index) => {
                                  return (
                                    <Row
                                      Quizno={Quizdata?.sno}
                                      QuizId={Quizdata?.id}
                                      QuizQuestions={Quizdata?.Questions}
                                      QuizCategory={Quizdata?.Category}
                                      QuizTechonology={Quizdata?.Technology}
                                      QuizDepartment={Quizdata?.Department}
                                      QuizAnswer={Quizdata?.Answers}
                                      QuestionIndex={index}
                                    ></Row>
                                  );
                                })
                              : null}
                            {/* {driveDatas.length > 0
                    ? driveDatas.map((data, index) => {
                     
                        return (
                          <Row
                            driveData={data}
                            index={index}
                            time={time}
                            dataDrive={dataDrive}
                          />
                        );
                      })
                    : // <div style={{ height: "50vh",display:"flex",justifyContent:"center",alignItems:"center" }}>
                      //   <span className="error-text">{errorMsg}</span>
                      // </div>
                      null} */}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {candidatesArray.length > 0 ? (
                        <Box
                          style={{
                            display: "flex",
                            // justifyContent: "flex-end",
                            justifyContent: "center",
                            marginTop: "30px",
                            marginRight: "20px",
                          }}
                        >
                          <ReactPaginate
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={1}
                            pageCount={pageCount / 5}
                            previousLabel="<"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        </Box>
                      ) : (
                        <Box className="QuizEmptyContainer">
                          No Question Uploaded
                        </Box>
                      )}
                    </Box>
                    {/* </TabPanel> */}
               
                  {/* ): (
                    <p>No data found</p>
                  )} */}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
