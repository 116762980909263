import { Box } from "@mui/system";
import React, { useEffect } from "react";
import ResuableDialogBox from "../../../CommonComponents/DialogBox/DialogBox";
import ReusableButton from "../../../CommonComponents/Button/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import WestIcon from "@mui/icons-material/West";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { API } from "../../../Networking/API";
import CloseIcon from "@mui/icons-material/Close";

const CandidateInstructions = () => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [backBtnOpen, setBackBtnOpen] = React.useState(false);
  const [candidateId, setCandidateId] = React.useState(
    localStorage.getItem("candidateId")
  );
  // const{innerWidth} = window;
  // const [windowSize, setWindowSize] = React.useState(getWindowSize());

  const [activeSessionDetails, setActiveSessionDetails] = React.useState([]);

  // useEffect(() => {
  //   function handleWindowResize() {
  //     setWindowSize(getWindowSize());
  //   }

  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);

  // function getWindowSize() {
  //   const {innerWidth, innerHeight} = window;
  //   return {innerWidth, innerHeight};
  // }

  useEffect(() => {
    getActiveTrackingSession();
  }, []);

  const getActiveTrackingSession = () => {
    API.getActiveSession(candidateId).then((response) => {
      const data = response.data.data;

      const updatedData = data.map((items, index) => ({
        id: items.quiz_category_id,
        QuestionAnswerId: items.current_answer_id,
        QuestionId: items.current_question_id,
        QuizCatId: items.quiz_category_id,
        duration: items.total_time_allowed_in_minutes,
      }));

      setActiveSessionDetails(updatedData);
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const handleBackBtnOpen = () => {
    setBackBtnOpen(true);
    Cookies.remove("jwtToken");
    Cookies.remove("role");
    localStorage.removeItem("candidateID");
    localStorage.removeItem("driveId");
    localStorage.removeItem("experience");
    navigate("/candidate-login");
  };
  const handleBackBtnClose = () => setBackBtnOpen(false);

  const handleConfirm = () => {
    if (activeSessionDetails.length !== 0) {
      navigate("/quiz", {
        state: {
          QuizCategoryId: activeSessionDetails[0].QuizCatId,
          sectionType: activeSessionDetails,
          sectionClicked: "",
          attendedDetails: activeSessionDetails,
          questionID: activeSessionDetails[0].QuestionId,
          fromUserInstruction: true,
        },
      });
    } else {
      navigate("/quiz-section");
    }
  };

  return (
    <>
      <Box
        className="Landing-Page"
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: { xs: "84vw", sm: "81vw" },
            marginBottom: "0px",
            marginTop: "-10px",
          }}
        >
          <ReusableButton
            startIcon={<WestIcon />}
            text="Back"
            variant="outlined"
            onClick={handleOpen}
            style={{
              fontFamily: "Helvetica",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "18px",
              color: "#424242",
              border: "none",
              textTranform: "capitalize",
              padding: "10px !important",
              "&:hover": {
                backgroundColor: "transparent",
                border: "none",
              },
              cursor: "pointer",
            }}
          />
        </Box>
        <Box
          className="child-container"
          sx={{
            overflow: { xs: "scroll", sm: "scroll", md: "hidden" },
            padding: { xs: "25px", sm: "35px" },
          }}
        >
          <Box
            sx={{
              marginBottom: "20px",
              fontSize: { xs: "1px", sm: "20px" },
            }}
          >
            <h2
              style={{
                fontFamily: "Helvetica",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "23px",
                /* identical to box height */
                color: "#424242",
              }}
            >
              Quiz Instructions :
            </h2>
          </Box>
          <ul
            style={{
              fontFamily: "Helvetica",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "15px",
              lineHeight: "20px",
              marginLeft: "0px",
              color: "#424242",
            }}
          >
            <li style={{ marginBottom: "13px" }}>
              Each question in the quiz is of multiple-choice, multiple-select
              or "True or False" format. Read each question carefully and click
              the button next to your response to select the option.
            </li>
            <li style={{ marginBottom: "13px" }}>
              After responding to a question, click on the "Next" button at the
              bottom right to go to the next question. After responding to the
              last question, click on the "Submit" button at the bottom to
              submit the quiz.
            </li>
            <li style={{ marginBottom: "13px" }}>
              If the response to a question needs to be changed, one can go back
              and select the desired option to the respective question.
              Selecting an option saves the response automatically.
            </li>
            <li style={{ marginBottom: "13px" }}>
              The total score for the quiz is based on response to all the
              questions. If a question is incorrectly answered or skipped, it
              will result in zero points for that question.
            </li>
            <li style={{ marginBottom: "13px" }}>
              One can start with any section and need to finish that section to
              start another one. Each section has a specific time duration. Once
              time is up, that section will be auto submitted.
            </li>
          </ul>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "end",
              paddingBottom: "25px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "end",
                marginTop: "1.5%",
              }}
            >
              {/* <Box sx={{ marginRight: "20px" }}>
              <ReusableButton
                text="Cancel"
                variant="outlined"
                onClick={handleOpen}
                style={{
                  border: "1px solid #00728E",
                  borderRadius: "4px",
                  fontFamily: "Helvetica",
                  fontStyle: "normal",
                  fontWeight: 400,
                  width: "140px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#00728E",
                  textTransform: "capitalize !important",
                  "&:hover": { backgroundColor: "transparent" },
                }}
              />
            </Box> */}
              <Box>
                <ReusableButton
                  text="Confirm"
                  variant="contained"
                  onClick={handleConfirm}
                  endIcon={<ArrowRightAltIcon />}
                  style={{
                    background: "#00728E",
                    borderRadius: "4px",
                    fontFamily: "Helvetica",
                    fontStyle: "normal",
                    width: "140px",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginBottom: "18px",
                    color: "#FFFFFF",
                    textTransform: "capitalize !important",
                    "&:hover": { backgroundColor: "#00728E" },
                  }}
                />
                {/* </Link> */}
              </Box>
            </Box>
          </Box>
        </Box>

        <ResuableDialogBox
          open={open}
          handleClose={handleClose}
          submitApiCall={handleBackBtnOpen}
          modalText="Are you sure you want to logout?"
          btnText1="No"
          btnText2="Yes"
          path="/candidate-login"
        />

        <ResuableDialogBox
          open={backBtnOpen}
          handleClose={handleBackBtnClose}
          modalText="Are you sure you want to go back
        to the previous screen?"
          btnText1="No"
          btnText2="Yes"
          path="/candidate-login"
        />
      </Box>

      <Box
        className="Landing-Page"
        sx={{ display: { xs: "flex", sm: "none" }, height: "auto" }}
      >
        <div className="child-container-mob">
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0px",
              marginTop: "-10px",
            }}
          >
            <CloseIcon onClick={handleOpen} />
          </Box>
          <h2
            style={{
              fontFamily: "Helvetica",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "23px",
              marginBottom: "16px",
              color: "#424242",
              marginTop: "10px",
            }}
          >
            Quiz Instructions :
          </h2>
          <p
            style={{
              fontFamily: "Helvetica",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              /* or 125% */
              color: "#424242",
            }}
          >
            The quizzes consist of questions carefully designed to help you
            self-assess your comprehension of the information presented on the
            topics covered in the module. No data will be collected on the
            website regarding your responses or how many times you take the
            quiz.
          </p>
          <p
            style={{
              fontFamily: "Helvetica",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              /* or 125% */
              color: "#424242",
            }}
          >
            Each question in the quiz is of multiple-choice, multiple-select or
            "true or false." format. Read each question carefully, and click on
            the button next to your response to select the option.
          </p>
          <p
            style={{
              fontFamily: "Helvetica",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              /* or 125% */
              color: "#424242",
            }}
          >
            After responding to a question, click on the "Next" button at the
            bottom to go to the next question. After responding to the last
            question, click on "Submit" button on the bottom to submit the quiz.
          </p>
          <p
            style={{
              fontFamily: "Helvetica",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              /* or 125% */
              color: "#424242",
            }}
          >
            If you think you selected an incorrect response for a question, you
            can try again until you get the correct response. Once the time is
            out, quiz will be automatically submitted.
          </p>
          <p
            style={{
              fontFamily: "Helvetica",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              /* or 125% */
              color: "#424242",
            }}
          >
            The total score for the quiz is based on your responses to all
            questions. If you respond incorrectly to a question or skip any
            question, score will be marked as zero for that question.
          </p>
          <p
            style={{
              fontFamily: "Helvetica",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              /* or 125% */
              color: "#424242",
            }}
          >
            You can switch between the categories any number of times before
            submitting the quiz for that particular category.
          </p>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "end",
              paddingBottom: "25px",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                // marginTop: "1.5%",
              }}
            >
              {/* <Box sx={{ width:"100%", marginBottom: "10px",display: "flex",
              justifyContent: "center", }}>
              <ReusableButton
                text="Cancel"
                variant="outlined"
                onClick={handleOpen}
                style={{
                  width:"50%",
                  border: "1px solid #00728E",
                  borderRadius: "4px",
                  fontFamily: "Helvetica",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#00728E",
                  textTransform: "capitalize !important",
                  "&:hover": { backgroundColor: "transparent" },
                }}
              />
            </Box> */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "15px",
                }}
              >
                <ReusableButton
                  text="Confirm"
                  variant="contained"
                  onClick={handleConfirm}
                  endIcon={<ArrowRightAltIcon />}
                  style={{
                    width: "50%",
                    background: "#00728E",
                    borderRadius: "4px",
                    fontFamily: "Helvetica",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                    textTransform: "capitalize !important",
                    "&:hover": { backgroundColor: "#00728E" },
                  }}
                />
                {/* </Link> */}
              </Box>
            </Box>
          </Box>
        </div>

        <ResuableDialogBox
          open={open}
          handleClose={handleClose}
          submitApiCall={handleBackBtnOpen}
          modalText="Are you sure you want to logout?"
          btnText1="No"
          btnText2="Yes"
          path="/candidate-login"
        />

        <ResuableDialogBox
          open={backBtnOpen}
          handleClose={handleBackBtnClose}
          modalText="Are you sure you want to go back
        to the previous screen?"
          btnText1="No"
          btnText2="Yes"
          path="/candidate-login"
        />
      </Box>
    </>
  );
};

export default CandidateInstructions;
