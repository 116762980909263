import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ReusableButton from "../../../CommonComponents/Button/Button";
import Grid from "@mui/material/Grid";
import "./RecruiterCandidateHiredAddCandidates.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Textarea from "@mui/joy/Textarea";
import moment from "moment";
import { API } from "../../../Networking/API";

export function RecruiterCandidateHiredAddCandidates(props) {
  const location = useLocation();

  const [value, setValue] = React.useState(0);
  const [candidate, setCandidate] = useState("");
  const RecruitmentAddDriveDetails = location.state.data;

  useEffect(() => {
    GetRecruitementAddDriveDetails(RecruitmentAddDriveDetails);
  }, []);

  const GetRecruitementAddDriveDetails = (id) => {
    API.editOneCandidate(id).then(({ response }) => {
      if (response.status === 200) {
        setCandidate(response.data.data);
      }
    });
  };

  const navigate = useNavigate();

  const handleAddButton = () => {
    navigate("/recruiter-candidates-hired");
  };
  const handleCancelButton = () => {
    navigate("/recruiter-candidates-hired");
  };

  return (
    <Box className="Candidate-container-div">
      <Box className="Candidates-container box_height Candidates-tabs">
        <Box
          style={{
            paddingTop: "30px",
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="Candidates-Header">Add Candidate</span>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  First Name
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {/* {driveid.drive_name} */}
                  Sriman
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Last Name
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  Ramaraju
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Email Address
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  sriman.ramaraju@gmail.com
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Phone Number
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {/* {driveid.location} */}
                  98765432210
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  University/College
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  Indian Institutes of Technology
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Department
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  Information Technology
                </span>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Location
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  Hyderabad
                </span>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Average Score
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>80%</span>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: "3.5rem" }}>
          <span className="add-text-overview">Overview</span>

          <Grid container spacing={2} sx={{ marginTop: "1.2rem" }}>
            <Grid item xs={12}>
              <Box>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Question Number 1
                </label>
                <Textarea
                  size="lg"
                  label="Size"
                  placeholder="Add your comments"
                  maxRows={4}
                  sx={{
                    height: "18vh",
                    borderRadius: "4px",
                    border: "1.5px solid #00728E",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "14px" }}>
              <Box>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Question Number 2
                </label>
                <Textarea
                  size="lg"
                  label="Size"
                  placeholder="Add your comments"
                  maxRows={4}
                  sx={{
                    height: "18vh",
                    borderRadius: "4px",
                    border: "1.5px solid #00728E",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "14px" }}>
              <Box>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Question Number 3
                </label>
                <Textarea
                  size="lg"
                  label="Size"
                  placeholder="Add your comments"
                  maxRows={4}
                  sx={{
                    height: "18vh",
                    borderRadius: "4px",
                    border: "1.5px solid #00728E",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "14px" }}>
              <Box>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Question Number 4
                </label>
                <Textarea
                  size="lg"
                  label="Size"
                  placeholder="Add your comments"
                  maxRows={4}
                  sx={{
                    height: "18vh",
                    borderRadius: "4px",
                    border: "1.5px solid #00728E",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <ReusableButton
            style={{
              float: "right",
              Color: "#00728E",
              marginRight: "20px",
              textTransform: "capitalize",
              marginTop: "100px",
              padding: "4px 25px",
              color: "#00728E",
              border: "1px solid #00728E",
              background: "#fff",
              borderRadius: "4px",
              "&:hover": {
                background: "#fff",
                borderRadius: "4px",
                Color: "#fff",
              },
            }}
            size="medium"
            text="Cancel"
            variant="outlined"
            onClick={handleCancelButton}
          />
          <ReusableButton
            style={{
              float: "right",
              Color: "#00728E",
              textTransform: "capitalize",
              marginTop: "100px",
              padding: "4px 40px",
              color: "#fff",
              background: "#00728E",
              borderRadius: "4px",
              "&:hover": {
                background: "#00728E",
                borderRadius: "4px",
                Color: "#00728E",
              },
            }}
            size="medium"
            text="Add"
            variant="outlined"
            onClick={handleAddButton}
          />
        </Box>
      </Box>
    </Box>
  );
}
