import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ReusableButton from "../../../CommonComponents/Button/Button";
import ReusableInputfield from "../../../CommonComponents/TextField/TextField";
import Grid from "@mui/material/Grid";
import "./AdminRecuitmentDriveDialogueBox.css";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { MyForm } from "../../../CommonComponents/MyForm/MyForm";
import Notification from "../../../CommonComponents/Notification/Notification";
import ResuableDropdown from "../../../CommonComponents/DropDown/DropDown";
import { API } from "../../../Networking/API";
import DatePicker from "../../../CommonComponents/DatePicker/DatePicker";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { HolidayVillageSharp } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import ReactLoading from "react-loading";
import "./AdminRecuitmentDriveEditPage.css";
import { OutlinedInput } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  height: 570,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const competency = [
  { id: "EASY", full_name: "easy" },
  { id: "MEDIUM", full_name: "medium" },
  { id: "HARD", full_name: "Hard" },
];

const CandidateOptions = [
  { id: "FRESHER", full_name: "Fresher" },
  { id: "TRAINED", full_name: "Trained" },
  // { id: "EXPERIENCED", full_name: "Experienced" },
];

const categorieOptions = [
  { id: 1, full_name: "Digital" },
  { id: 2, full_name: "Salesforce" },
];

const digitalOptions = [
  { id: 1, full_name: "General" },
  { id: 2, full_name: "React Js" },
  { id: 3, full_name: "Node Js" },
  { id: 4, full_name: "React Native" },
  { id: 5, full_name: "AR&VR" },
];

const salesforceOptions = [
  { id: 1, full_name: "Lwc" },
  { id: 2, full_name: "Apex" },
  { id: 3, full_name: "Aura" },
];

const names = [
  { id: "1", full_name: "Aptitude" },
  { id: "2", full_name: "Technical" },
  { id: "3", full_name: "Verbal" },
  { id: "4", full_name: "Reasoning" },
  // { id: "5", full_name:"GK"}
];

const DriveOptions = [
  { id: "CAMPUS", full_name: "Campus" },
  { id: "INHOUSE", full_name: "In House" },
];

const DriveOptions2 = [
  { id: "INHOUSE", full_name: "In House" },
  { id: "INSTITUTION", full_name: "Institution" },
];
export default function ResuableAdminEditDialogBox(props) {
  const {
    open,
    handleClose,
    setRecruiterAlone,
    getRecruitmentCompletedDrive,
    getRecruitmentPendingDrive,
    recruiterId,
    selectedRecruiterData,
    recruitedAlone,
    comStatus,
    pageNumber,
    penStatus,
    recruiterFinalData,
    categoryData,
    totalRecruitersData,
  } = props;

  const [fields, setFields] = useState(recruitedAlone);
  const [recruiterData, setRecruiterData] = useState([]);
  const [makeDisable, setMakeDisable] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [candidateData, setCandidateData] = useState();
  const [categoriesData, setCategoriesData] = useState(
    selectedRecruiterData.quizCategory
  );
  const useStyles = makeStyles({
    dropdownStyle: {
      width: "200px !important",
      maxHeight: "200px !important",
      height: "fit-content !important",
    },
  });

  const theme = useTheme();

  const date1 = new Date();
  const mindate = moment(date1).format("L");

  const [driveId, setDriveId] = useState(selectedRecruiterData.id);

  var recruiterdataCount = recruitedAlone.length;
  var is_Hold = selectedRecruiterData.is_hold;

  var holdText;
  if (is_Hold === 1) {
    holdText = "Unhold Drive";
  } else if (is_Hold === 0) {
    holdText = "Hold Drive";
  }

  const [done, setDone] = useState(false);
  const [count, setCount] = useState(recruiterdataCount);
  const [addMoreCss, setAddmoreCss] = useState(recruiterdataCount);
  const [competency_level, setCompetencyLevelData] = useState([]);
  const [recruitersCount, setRecruiterCount] = useState(recruiterFinalData);
  const [categoryCount, setCategoryCount] = useState(categoryData);
  const [drive1, setDrive1] = useState(selectedRecruiterData.drive_type);
  const [platform1, setPlatform1] = useState(
    selectedRecruiterData.questionCategory.id
  );
  const [technology1, setTechnology1] = useState(
    selectedRecruiterData.questionSubCategory[0].id
  );

  const [errorRecruiterCount, setErrorRecruiterCount] = useState("");
  const [errorCatagories, setErrorCategories] = useState("");

  // var date = selectedRecruiterData.drive_date.split("-").reverse().join("-");

  const initialValues = {
    firstName: selectedRecruiterData.drive_name,
    universityName: selectedRecruiterData.college_name,
    // universityType: selectedRecruiterData.college_type,
    location: selectedRecruiterData.location,
    driveDate: selectedRecruiterData.drive_date,
    driveTime: selectedRecruiterData.drive_time,
    candidate: selectedRecruiterData.candidate_experience_level,
    recruiter: selectedRecruiterData.recruiters,
    competency_level_id: selectedRecruiterData.competencyLevel[0].id,
    drive: drive1,
    // categories: selectedRecruiterData.quizCategory,
    platform: platform1,
    technology: technology1,
    institution: selectedRecruiterData.institute_name,
    // RecruiterName: firstIndexData,
  };
  const handleCloseModal = () => {
    handleClose();
    setErrorCategories("");
    setErrorRecruiterCount("");
    resetForm();
    setCount(0);
    setRecruiterAlone([]);
    setAddmoreCss(1);
  };

  useEffect(() => {
    getAlldataRecruiter();
    getCompetencyLevels();
  }, []);

  const getCompetencyLevels = async () => {
    await API.getCompetencyLevel().then(({ response }) => {
      if (response) {
        const responsefromcompetency = response.data.data;
        const newData = responsefromcompetency.map((item) => ({
          id: item.id,
          full_name: item.competency_level_name.toLowerCase(),
        }));
        setCompetencyLevelData(newData);
      }
    });
  };

  const handleEditButton = () => {
    setMakeDisable(false);
    setClicked(true);
  };

  const getAlldataRecruiter = () => {
    API.getRecruiters().then(({ response }) => {
      const Value = response.data.data.rows;
      const updatedData = Value.map((items, index) => {
        return {
          id: items.id,
          full_name: items.full_name,
        };
      });
      setRecruiterData(updatedData);
    });
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("firstName" in inputValues)
      code.firstName = inputValues.firstName ? "" : "First Name is required";
    if (inputValues.candidate === "FRESHER") {
      if ("universityName" in inputValues)
        code.universityName = inputValues.universityName
          ? ""
          : "University Name is required";
    } else {
      code.universityName = "";
    }
    if ("location" in inputValues)
      code.location = inputValues.location ? "" : "Drive Location is required";
    if ("driveTime" in inputValues)
      code.driveTime = inputValues.driveTime ? "" : "Drive Time is required";

    if ("driveDate" in inputValues)
      code.driveDate = inputValues.driveDate ? "" : "Drive Date is required";

    if ("categories" in inputValues)
      code.categories = inputValues.categories
        ? ""
        : "Employement Type is required";

    if ("candidate" in inputValues)
      code.candidate = inputValues.candidate
        ? ""
        : "candidate Type is required";

    if ("drive" in inputValues)
      code.drive = inputValues.drive ? "" : "Drive Type is required";

    if ("competencyLevel" in inputValues)
      code.competencyLevel = inputValues.competencyLevel
        ? ""
        : "Competency Level is required";
    if (inputValues.candidate !== "FRESHER") {
      if ("platform" in inputValues)
        code.platform = inputValues.platform ? "" : "platform is required";
    } else {
      code.platform = "";
    }
    if (inputValues.candidate !== "FRESHER") {
      if ("technology" in inputValues)
        code.technology = inputValues.technology
          ? ""
          : "technology is required";
    } else {
      code.technology = "";
    }
    if (inputValues.candidate !== "FRESHER") {
      if ("institution" in inputValues)
        code.institution = inputValues.institution
          ? ""
          : "institution name is required";
    } else {
      code.institution = "";
    }

    setErrors({
      ...code,
    });
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  function handleChange(i, event) {
    const values = [...fields];
    values[i].value = event.target.value;
    setFields(values);
  }

  function handleAdd() {
    setCount(count + 1);
    // if(count>6){
    const values = [...fields];
    values.push({ value: null });
    setFields(values);
    setAddmoreCss(addMoreCss + 1);

    // }
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
    setCount(count - 1);
    setAddmoreCss(addMoreCss - 1);
  }

  // let recruiterIdData = recruitersCount.map((data, index) => {
  //   return data.id;
  // });

  // let categoriesIdData = categoryCount.map((data, index) => {
  //   return data.id;
  // });

  const handleSaveChangesDrive = () => {
    let CategoryId = HandleMultiSelectValues(categoryCount, "Category");
    if (validate()) {
      if (errorRecruiterCount === "") {
        if (errorCatagories === "") {
          setDone(true);
          var finalArray = [];
          finalArray = fields.map(function (obj) {
            return obj.value;
          });
          var set = new Set(finalArray);
          const array = Array.from(set);

          API.updateDriveData(
            values.firstName,
            values.universityName,
            values.location,
            values.institution,
            values.driveDate,
            values.driveTime,
            values.competency_level_id,
            values.candidate,
            values.drive,
            CategoryId,
            values.platform,
            values.technology,
            recruitersCount,
            // array,
            recruiterId
          ).then(({ response }) => {
            if (response.status === true) {
              setNotify({
                isOpen: true,
                message: `${response.message}`,
                type: "success",
              });
              getRecruitmentCompletedDrive(comStatus, pageNumber);
              getRecruitmentPendingDrive(penStatus, pageNumber);
              const timer = setTimeout(() => {
                handleClose();
                resetForm();
                setCount(0);
                setAddmoreCss(1);
                setDone(false);
              }, 1000);
              return () => clearTimeout(timer);
            } else {
              // setErrorRecruiterCount("Recruiter Name is required");
              setNotify({
                isOpen: true,
                message: `${response.message}`,
                type: "error",
              });
              setDone(false);
            }
          });
        }
      }
    }
  };

  function HandleMultiSelectValues(SelectValue, SelectedFieldName) {
    if (SelectedFieldName === "Category") {
      let FinalResult = names
        .filter((Category) => SelectValue.includes(Category.full_name))
        .map((FiltredCategory) => {
          return FiltredCategory.id;
        });
      return FinalResult;
    } else {
      let FinalResult = SelectValue.map((RecruiterId) => {
        return RecruiterId.id;
      });
      return FinalResult;
    }
  }

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    validateOnEdit,
  } = MyForm(initialValues, true, validate);

  const handleRecruiterValue = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    setErrors({ ...errors, RecruiterName: "" });

    if (validateOnEdit) {
      validate({ [name]: value });
    }
  };

  var isHolddd = selectedRecruiterData.is_hold;
  let valueSend;
  let forText = selectedRecruiterData.is_hold;

  const handleHoldDrive = () => {
    if (isHolddd === 0) {
      valueSend = true;

      isHolddd = 1;
      forText = 1;
      // setIsHoldValue(isHold);
    } else if (isHolddd === 1) {
      valueSend = false;

      isHolddd = 0;
      forText = 0;
      // setIsHoldValue(isHold);
    }

    API.makeIsHold(driveId, valueSend).then(({ response }) => {
      if (response.status === true) {
        setNotify({
          isOpen: true,
          message: `${response.message}`,
          type: "success",
        });

        const timer = setTimeout(() => {
          handleClose();
          getRecruitmentPendingDrive("pending", pageNumber);
          resetForm();
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        setNotify({
          isOpen: true,
          message: `${response.message}`,
          type: "error",
        });
        const timer = setTimeout(() => {
          handleClose();
          getRecruitmentPendingDrive("pending", pageNumber);
          resetForm();
        }, 1000);
        return () => clearTimeout(timer);
      }
    });
  };

  const handleFromDate = (e) => {
    const midata = moment(e.target.value).format("L");

    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: midata,
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }

    setErrors({ ...errors, driveDate: "" });
  };

  const handlecandidate = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, employeeType: "" });
    // setRecruiterCount([])
    if (e.target.value === "TRAINED") {
      setValues({
        ...values,
        platform: "",
        drive: "",
        technology: "",
        institution: "",
        [name]: value,
      });
    }
  };

  const handlePlatform = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setPlatform1(e.target.value);
    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, platform: "" });
  };

  const handletechnology = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, technology: "" });
  };

  const handlerecruiters = (event) => {
    const {
      target: { value },
    } = event;
    let FinalRecruiterId = recruiterData?.map((data, index) => {
      if (event.target.value.includes(data.full_name)) {
        return data.id;
      }
    });
    let FiltredRecruiters = FinalRecruiterId.filter(
      (RecruiterId) => RecruiterId !== undefined
    );

    // if(event.target.value)
    if (event.target.value.length === 0) {
      setErrorRecruiterCount("Recruiter Name is required");
    } else {
      setErrorRecruiterCount("");
    }
    setRecruiterCount(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleCategorie = (event) => {
    const {
      target: { value },
    } = event;
    if (event.target.value.length === 0) {
      setErrorCategories("Categories Type is required");
    } else {
      setErrorCategories("");
    }

    setCategoryCount(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

 
    if (value.includes("Technical") === false) {
      setValues({ ...values, candidate: "FRESHER" });
    } else {
      setValues({ ...values, candidate: "TRAINED" });
    }
  };

  const handleCompetencyLevel = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, employeeType: "" });
  };

  const handledrive = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, drive: "" });
  };

  return (
    <div className="AdminEdit">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            {done ? (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "70vh",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#00728E"}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <Box>
                <Box style={{ display: "flex", paddingBottom: "20px" }}>
                  <Box
                    style={{
                      width: "45vw",
                      fontSize: "22px",
                      fontWeight: "700",
                      color: "#424242",
                    }}
                  >
                    <span>{selectedRecruiterData.drive_name}</span>
                  </Box>
                  <Box style={{ width: "100vw" }}>
                    <ReusableButton
                      style={{
                        float: "right",
                        backgroundColor: "#00728E",
                        textTransform: "capitalize",
                        "&:hover": { backgroundColor: "#00728E" },
                      }}
                      text="Edit"
                      onClick={handleEditButton}
                      startIcon={<EditOutlinedIcon />}
                    />
                  </Box>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={4} style={{ height: "100px" }}>
                    <Box className="AdminRecruiter-AdminFelidTextBox">
                      <label style={{ paddingBottom: "5px" }} htmlFor="">
                        Drive Name
                      </label>
                      <ReusableInputfield
                        variant="outlined"
                        size="small"
                        type="text"
                        name="firstName"
                        disabled={makeDisable}
                        placeholder="Create Drive Name"
                        value={values.firstName}
                        error={errors.firstName ? true : false}
                        helperText={errors.firstName}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={4} style={{ height: "100px" }}>
                    <Box className="AdminRecruiter-AdminFelidTextBox">
                      <label style={{ paddingBottom: "9px" }} htmlFor="">
                        Candidates Type
                      </label>
                      <ResuableDropdown
                        size="small"
                        type="text"
                        // placeholder="Select Candidates"
                        spanText="Select Candidates"
                        // style={{ paddingRight: "35px" }}
                        name="candidate"
                        error={errors.candidate ? true : false}
                        value={values.candidate}
                        helperText={errors.candidate}
                        options={CandidateOptions}
                        disabled={makeDisable}
                        MenuProps={useStyles}
                        onChange={handlecandidate}
                      />
                    </Box>
                  </Grid>

                  {values.candidate !== "FRESHER" && values.candidate !== "" ? (
                    <>
                      <Grid item xs={4} style={{ height: "100px" }}>
                        <Box className="AdminRecruiter-AdminFelidTextBox">
                          <label style={{ paddingBottom: "5px" }} htmlFor="">
                            Platform
                          </label>
                          <ResuableDropdown
                            size="small"
                            type="text"
                            // placeholder="Select platform"
                            style={{ paddingRight: "35px" }}
                            name="platform"
                            spanText="Select Platform "
                            error={errors.platform ? true : false}
                            value={values.platform}
                            helperText={errors.platform}
                            options={categorieOptions}
                            disabled={makeDisable}
                            MenuProps={useStyles}
                            onChange={handlePlatform}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={4} style={{ height: "100px" }}>
                        <Box className="AdminRecruiter-AdminFelidTextBox">
                          <label style={{ paddingBottom: "5px" }} htmlFor="">
                            Technology
                          </label>
                          <ResuableDropdown
                            size="small"
                            type="text"
                            // placeholder="Select Recruiter"
                            style={{ paddingRight: "35px" }}
                            name="technology"
                            spanText="Select Recruiter"
                            error={errors.technology ? true : false}
                            value={values.technology}
                            MenuProps={useStyles}
                            helperText={errors.technology}
                            options={
                              values.platform === 1
                                ? digitalOptions
                                : salesforceOptions
                            }
                            disabled={makeDisable}
                            onChange={handletechnology}
                          />
                        </Box>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}

                  {values.candidate !== "FRESHER" ? (
                    ""
                  ) : (
                    <Grid item xs={4} style={{ height: "100px" }}>
                      <Box className="AdminRecruiter-AdminFelidTextBox">
                        <label style={{ paddingBottom: "5px" }} htmlFor="">
                          University/College
                        </label>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="universityName"
                          placeholder="University/College Name"
                          value={values.universityName}
                          error={errors.universityName ? true : false}
                          disabled={makeDisable}
                          helperText={errors.universityName}
                          onChange={handleInputChange}
                        />
                      </Box>
                    </Grid>
                  )}

                  <Grid item xs={4} style={{ height: "100px" }}>
                    <Box className="AdminRecruiter-AdminFelidTextBox">
                      <label style={{ paddingBottom: "9px" }} htmlFor="">
                        Drive Type
                      </label>
                      {/* {
                          values.candidate=== "TRAINED" || values.candidate==="EXPERIENCED" ? */}
                      <ResuableDropdown
                        size="small"
                        type="text"
                        // placeholder="Select Drive Type  "
                        spanText="Select Drive Type"
                        style={{ paddingRight: "35px" }}
                        name="drive"
                        error={errors.drive ? true : false}
                        value={values.drive}
                        MenuProps={useStyles}
                        helperText={errors.drive}
                        options={
                          values.candidate === "FRESHER"
                            ? DriveOptions
                            : DriveOptions2
                        }
                        disabled={makeDisable}
                        // disabled={disable}
                        onChange={handledrive}
                      />
                    </Box>
                  </Grid>

                  {values.candidate !== "FRESHER" && values.candidate !== "" ? (
                    <Grid item xs={4} style={{}}>
                      <Box className="AdminRecruiter-AdminFelidTextBox">
                        <label style={{ paddingBottom: "5px" }} htmlFor="">
                          Institution name
                        </label>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="institution"
                          placeholder="institution Name"
                          value={values.institution}
                          error={errors.institution ? true : false}
                          helperText={errors.institution}
                          disabled={makeDisable}
                          onChange={handleInputChange}
                        />
                      </Box>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid item xs={4}>
                    <Box className="AdminRecruiter-AdminFelidTextBox">
                      <label style={{ paddingBottom: "5px" }} htmlFor="">
                        Drive Location
                      </label>
                      <ReusableInputfield
                        variant="outlined"
                        size="small"
                        type="text"
                        name="location"
                        placeholder="Enter Location Name"
                        value={values.location}
                        error={errors.location ? true : false}
                        helperText={errors.location}
                        disabled={makeDisable}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className="Adminmultiselect"
                    // style={{ height: "100px" }}
                  >
                    <Box className="">
                      <label style={{ paddingBottom: "1%" }} htmlFor="">
                        Categories
                      </label>
                      <div>
                        <FormControl style={{ width: "200px" }}>
                          <Select
                            // labelId="demo-multiple-name-label"
                            // id="demo-multiple-name"
                            multiple
                            displayEmpty
                            value={categoryCount}
                            onChange={handleCategorie}
                            MenuProps={MenuProps}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return (
                                  <em
                                    style={{
                                      opacity: "0.7",
                                      color: "#a2a2a2",
                                      fontWeight: "100",
                                      fontFamily: "sans-serif",
                                    }}
                                  >
                                    Select Categories
                                  </em>
                                );
                              }

                              return selected.join(", ");
                            }}
                            disabled={makeDisable}
                          >
                            <MenuItem
                              value=""
                              sx={{ opacity: "0.5" }}
                              selected
                              hidden
                              disabled="disabled"
                            >
                              <span style={{ opacity: "0.5" }}>
                                select categories
                              </span>
                            </MenuItem>
                            {names.map((name) => (
                              <MenuItem
                                key={name.id}
                                value={name.full_name}
                                style={getStyles(name, categoryCount, theme)}
                              >
                                {name.full_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Box>
                    {categoryCount.length === 0 ? (
                      <span
                        style={{
                          fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                          fontWeight: "400",
                          fontSize: "0.75rem",
                          lineHeight: "1.66",
                          letterSpacing: "0.03333em",
                          textAlign: "left",
                          marginTop: "4px",
                          marginRight: "14px",
                          marginBottom: "0",
                          color: "#d32f2f",
                        }}
                      >
                        {errorCatagories}
                      </span>
                    ) : null}
                  </Grid>

                  <Grid item xs={4} style={{ height: "100px" }}>
                    <Box className="AdminRecruiter-AdminFelidTextBox">
                      <label style={{ paddingBottom: "10px" }} htmlFor="">
                        Drive Date
                      </label>
                      <DatePicker
                        id="date"
                        type="date"
                        placeholder="Select Drive Date"
                        name="driveDate"
                        // sx={{ width: 220 }}
                        // format={'YYYY-MM-DD'}
                        value={values.driveDate}
                        error={errors.driveDate ? true : false}
                        helperText={errors.driveDate}
                        disabled={makeDisable}
                        minDate={mindate}
                        onChange={handleFromDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={4} style={{ height: "100px" }}>
                    <Box className="AdminRecruiter-AdminFelidTextBox">
                      <label style={{ paddingBottom: "5px" }} htmlFor="">
                        Drive Time
                      </label>
                      <ReusableInputfield
                        // sx={{ width: "100%" }}
                        id="time"
                        type="time"
                        defaultValue="07:30"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="driveTime"
                        value={values.driveTime}
                        error={errors.driveTime ? true : false}
                        helperText={errors.driveTime}
                        onChange={handleInputChange}
                        disabled={makeDisable}
                        placeholder="Select Drive Time"
                        size="small"
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={4} style={{ height: "100px" }}>
                    <Box className="AdminRecruiter-AdminFelidTextBox">
                      <label style={{ paddingBottom: "9px" }} htmlFor="">
                        Competency Level
                      </label>
                      <ResuableDropdown
                        size="small"
                        type="text"
                        // placeholder="Select Recruiter"
                        spanText="select Competency Level"
                        style={{ paddingRight: "35px" }}
                        name="competency_level_id"
                        error={errors.competencyLevel ? true : false}
                        value={values.competency_level_id}
                        helperText={errors.competencyLevel}
                        MenuProps={useStyles}
                        options={competency_level}
                        disabled={makeDisable}
                        // disabled={disable}
                        onChange={handleCompetencyLevel}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className="multiselect"
                    style={{ height: "100px" }}
                  >
                    <Box className="AdminRecruiter-AdminFelidTextBox">
                      <label style={{ paddingBottom: "9px" }} htmlFor="">
                        Recruiters
                      </label>
                      {recruiterData.length > 0 ? (
                        <>
                          <FormControl style={{ width: "200px" }}>
                            <Select
                              // labelId="demo-multiple-name-label"
                              // id="demo-multiple-name"
                              multiple
                              displayEmpty
                              value={recruitersCount}
                              onChange={handlerecruiters}
                              style={{ textTransform: "capitalize" }}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return (
                                    <em
                                      style={{
                                        opacity: "0.7",
                                        color: "#a2a2a2",
                                        fontWeight: "100",
                                        fontFamily: "sans-serif",
                                      }}
                                    >
                                      Select Recruiters
                                    </em>
                                  );
                                }
                                let finaldata = recruiterData
                                  .filter((data) =>
                                    recruitersCount.includes(data.id)
                                  )
                                  .map((data) => {
                                    return data.full_name;
                                  });

                                return finaldata.join(", ");
                              }}
                              MenuProps={MenuProps}
                              disabled={makeDisable}
                            >
                              <MenuItem
                                value=""
                                sx={{ opacity: "0.5" }}
                                selected
                                hidden
                                disabled="disabled"
                              >
                                <span style={{ opacity: "0.5" }}>
                                  select Recruiters
                                </span>
                              </MenuItem>
                              {recruiterData.map((name) => (
                                <MenuItem
                                  key={name.id}
                                  value={name.id}
                                  style={getStyles(
                                    name,
                                    recruitersCount,
                                    theme
                                  )}
                                >
                                  <span style={{ textTransform: "capitalize" }}>
                                    {name.full_name}
                                  </span>
                                </MenuItem>
                              ))}
                            </Select>
                            {recruitersCount.length === 0 && (
                              <span
                                style={{
                                  fontFamily:
                                    "Roboto,Helvetica,Arial,sans-serif",
                                  fontWeight: "400",
                                  fontSize: "0.75rem",
                                  lineHeight: "1.66",
                                  letterSpacing: "0.03333em",
                                  textAlign: "left",
                                  marginTop: "4px",
                                  marginRight: "14px",
                                  marginBottom: "0",
                                  color: "#d32f2f",
                                }}
                              >
                                {errorRecruiterCount}
                              </span>
                            )}
                          </FormControl>
                        </>
                      ) : (
                        <>
                          <div className="label-select-class">
                            <FormControl sx={{ m: 1, minWidth: 200 }} disabled>
                              <InputLabel
                                id="demo-simple-select-disabled-label"
                                className="select-class-drive-candlist-edit"
                              >
                                <em>No Recruiters</em>
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-disabled-label"
                                id="demo-simple-select-disabled"
                                label="Age"
                              ></Select>
                            </FormControl>
                          </div>
                        </>
                      )}
                    </Box>
                  </Grid>
                  {/* ) : ("")
} */}

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      flexDirection: "column",
                      // marginTop: "12px",
                    }}
                  >
                    <Box>
                      {clicked === false ? (
                        <>
                          <Box>
                            <ReusableButton
                              style={{
                                float: "right",
                                backgroundColor: "#00728E",
                                textTransform: "capitalize",
                                "&:hover": { backgroundColor: "#00728E" },
                              }}
                              // disabled={makeDisable}
                              // text="Hold Drive"
                              text={holdText}
                              onClick={handleHoldDrive}
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box>
                            <ReusableButton
                              style={{
                                float: "right",
                                backgroundColor: "#00728E",
                                textTransform: "capitalize",
                                "&:hover": { backgroundColor: "#00728E" },
                              }}
                              // disabled={makeDisable}
                              text="Save Drive"
                              onClick={handleSaveChangesDrive}
                            />
                          </Box>
                        </>
                      )}

                      <Box>
                        <ReusableButton
                          style={{
                            float: "right",
                            Color: "#00728E",
                            marginRight: "20px",
                            textTransform: "capitalize",
                          }}
                          text="cancel"
                          variant="outlined"
                          onClick={handleCloseModal}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
