import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import "./RecruitmentDriveDetails.css";
import ReusableButton from "../../../CommonComponents/Button/Button";
import { API } from "../../../Networking/API";
import { MyForm } from "../../../CommonComponents/MyForm/MyForm";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import Cookies from "js-cookie";
import ReusableInputfield from "../../../CommonComponents/TextField/TextField";
import Grid from "@mui/material/Grid";
import ResuableDropdown from "../../../CommonComponents/DropDown/DropDown";
import { useLocation } from "react-router-dom";
import Notification from "../../../CommonComponents/Notification/Notification";
import ReactLoading from "react-loading";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const competency = [
  { id: "EASY", full_name: "Easy" },
  { id: "MEDIUM", full_name: "Medium" },
  { id: "HARD", full_name: "Hard" },
];

export function RecruitmentDriveDetails() {
  const location = useLocation();
  const [driveDetails, setDriveDetails] = useState("");
  const [recruiterDetails, setRecruiterDetails] = useState("");
  const [fields, setFields] = useState(null);
  const [department, setDepartment] = useState([]);
  const [driveId, setDriveId] = useState(location.state.data);
  const [done, setDone] = useState(undefined);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const initialValues = {
    competency: "",
    department: "",
  };

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("competency" in inputValues)
      code.competency = inputValues.competency
        ? ""
        : "competency level is required";
    if ("department" in inputValues)
      code.department = inputValues.department ? "" : "department is required";
    setErrors({
      ...code,
    });

    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    validateOnEdit,
  } = MyForm(initialValues, true, validate);


  const RecruitmentDriveDetails = location.state.data;
  useEffect(() => {
    GetRecruitementDriveDetails(RecruitmentDriveDetails);
    GetRecruitmentDepartmentData();
  }, []);

  const GetRecruitementDriveDetails = (id) => {
    API.getOneDriveData(id)
      .then(({ response }) => {
        if (response.status === 200) {
          setDone(true);
          const receivedData = response.data.data;
          const recruiters = response.data.data.recruiters;
          setDriveDetails(receivedData);
          setRecruiterDetails(recruiters);
          setFields(response.data.data.recruiters);
        }
      })
      .catch(() => {
        setDone(true);
      });
  };

  const GetRecruitmentDepartmentData = () => {
    API.getCoursesData().then(({ response }) => {
      if (response.status === 200) {
     

        const departmentdata = response.data.data;
        const updatedData = departmentdata.map((items, index) => ({
          id: items.id,
          full_name: items.course_name,
        }));
        setDepartment(updatedData);
      }
    });
  };

  const navigate = useNavigate();

  const handleCreateLink = () => {
    if (validate()) {
      API.sendLinkCandidate(driveId, values.department, values.competency).then(
        ({ response }) => {
          if (response.status) {
            setNotify({
              isOpen: true,
              message: "Test invitation link was sent successfully",
              type: "success",
            });

            const timer = setTimeout(() => {
              navigate("/recruiter-recruitment-Drive");
            }, 2000);
            return () => clearTimeout(timer);
          } else {
            setNotify({
              isOpen: true,
              message: response.message,
              type: "error",
            });

            const timer = setTimeout(() => {
              navigate("/recruiter-recruitment-Drive");
            }, 2000);
            return () => clearTimeout(timer);
          }
        }
      );
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHomeClick = () => {
    navigate("/recruiter-recruitment-Drive");
  };

  const handleCloseMenu = () => {
    navigate("/");
    Cookies.remove("jwtToken");
  };

  const handleCloseMenuBar = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box className="recruiterDriveDetails-main-container">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ReusableButton
            text="Home/Recruitment Drive/Recruitment Drive Details"
            startIcon={<WestIcon />}
            onClick={handleHomeClick}
            style={{
              textTransform: "capitalize",
              marginLeft: "10%",
              marginBottom: "15px",
              color: "#fff",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              background: "none",
              border: "none",
              padding: "0px",
              boxShadow: "none",
              outline: "none",
              "&:focus": {
                background: "none",
                border: "none",
                padding: "0px",
                boxShadow: "none",
                outline: "none",
              },
              "&:hover": {
                background: "none",
                border: "none",
                padding: "0px",
                boxShadow: "none",
                outline: "none",
              },
            }}
          ></ReusableButton>

          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginRight: "111px",
              marginBottom: "12px",
            }}
          >
            <Button
              id="demo-customized-button"
              aria-controls={openMenu ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              startIcon={<AccountCircleIcon />}
              endIcon={<KeyboardArrowDownIcon />}
              className="account-menu-button"
            >
              Sriman Raju
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenuBar}
            >
              <MenuItem onClick={handleCloseMenu} disableRipple>
                <LogoutIcon />
                Log Out
              </MenuItem>
            </StyledMenu>
          </div>
        </div>
        <Box className="RecruiterDetails-container">
          <Box className="RecruiterDetails-Header">
            <span>Recruitment Drive Details</span>
          </Box>
          {!done ? (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#00728E"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Box className="recruiterCandidateDetailsFeild">
                    <label style={{ paddingBottom: "5px" }} htmlFor="">
                      Drive Name
                    </label>
                    <ReusableInputfield
                      className="Input_Field_Text_Box"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="firstname"
                      placeholder="Create Drive Name"
                      value={driveDetails.drive_name}
                      disabled
                    />
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box className="recruiterCandidateDetailsFeild">
                    <label style={{ paddingBottom: "5px" }} htmlFor="">
                      University/Collage Name
                    </label>
                    <ReusableInputfield
                      className="Input_Field_Text_Box"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="University/Collage Name"
                      placeholder="University/Collage Name"
                      value={driveDetails.college_name}
                      disabled
                    />
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box className="recruiterCandidateDetailsFeild">
                    <label style={{ paddingBottom: "5px" }} htmlFor="">
                      University/Collage Type
                    </label>
                    <ReusableInputfield
                      className="Input_Field_Text_Box"
                      variant="outlined"
                      size="small"
                      type="text"
                      name=" University/Collage Type"
                      placeholder=" University/Collage Type"
                      value={driveDetails.college_type}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box className="recruiterCandidateDetailsFeild">
                    <label style={{ paddingBottom: "5px" }} htmlFor="">
                      Location
                    </label>
                    <ReusableInputfield
                      className="Input_Field_Text_Box"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="Location"
                      placeholder="Location"
                      value={driveDetails.location}
                      disabled
                    />
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box className="recruiterCandidateDetailsFeild">
                    <label style={{ paddingBottom: "5px" }} htmlFor="">
                      Drive Date
                    </label>
                    <ReusableInputfield
                      className="Input_Field_Text_Box"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="Drive Date"
                      placeholder="Drive Date"
                      value={driveDetails.drive_date}
                      disabled
                    />
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box className="recruiterCandidateDetailsFeild">
                    <label style={{ paddingBottom: "5px" }} htmlFor="">
                      Drive Time
                    </label>
                    <ReusableInputfield
                      className="Input_Field_Text_Box"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="Drive Time"
                      placeholder="Drive Time"
                      value={driveDetails.drive_time}
                      disabled
                    />
                  </Box>
                </Grid>

                {fields?.map((field, idx) => {
                  return (
                    <Grid
                      item
                      xs={3}
                      style={{ marginTop: "4px", paddingTop: "0px" }}
                      className="recruiterCandidateDetailsFeild"
                    >
                      <Grid>
                        <div
                          key={`${field}-${idx + 1}`}
                          // className="add-more-dropdown"
                          style={{ paddingTop: "28px", paddingLeft: "35px" }}
                        >
                          <label htmlFor="">Recruiter {idx + 1}</label>
                          <Box>
                            <ReusableInputfield
                              className="Input_Field_Text_Box"
                              variant="outlined"
                              size="small"
                              type="text"
                              name="Recruiter"
                              placeholder="Recruiter"
                              value={field.full_name}
                              disabled
                            />
                          </Box>
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>

              <Box className="RecruiterDetails-Header-1">
              </Box>

              <Box className="recruiterCandidateDetailsFeild">
                <label style={{ paddingBottom: "5px" }} htmlFor="">
                  Competency Level
                </label>
                <ResuableDropdown
                  size="small"
                  type="text"
                  placeholder="Select Recruiter"
                  style={{ width: "225px", paddingRight: "35px" }}
                  name="competency"
                  value={values.competency}
                  error={errors.competency}
                  helperText={errors.competency}
                  options={competency}
                  onChange={handleInputChange}
                />
              </Box>

              <Box className="recruiterCandidateDetailsFeild">
                <label style={{ paddingBottom: "5px" }} htmlFor="">
                  Select Department
                </label>
                <ResuableDropdown
                  size="small"
                  type="text"
                  placeholder="Select Recruiter"
                  style={{ width: "225px", paddingRight: "35px" }}
                  name="department"
                  value={values.department}
                  error={errors.department}
                  helperText={errors.department}
                  options={department}
                  onChange={handleInputChange}
                />
              </Box>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  flexDirection: "column",
                  marginTop: "12px",
                }}
              >
                <Box style={{ marginBottom: "10px" }}>
                  <Box style={{ paddingRight: "35px" }}>
                    <ReusableButton
                      style={{
                        float: "right",
                        backgroundColor: "#00728E",
                        textTransform: "capitalize",
                        "&:hover": { backgroundColor: "#00728E" },
                      }}
                      text="Send Link"
                      onClick={handleCreateLink}
                    />
                  </Box>
                  <Box style={{ paddingRight: "35px" }}>
                    <ReusableButton
                      style={{
                        float: "right",
                        Color: "#00728E",
                        marginRight: "20px",
                        textTransform: "capitalize",
                      }}
                      text="cancel"
                      variant="outlined"
                      onClick={handleHomeClick}
                    />
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </Box>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
