import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import ReusableButton from "../../../CommonComponents/Button/Button";
import Grid from "@mui/material/Grid";
import "./RecruiterDriveCandidatesResult.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
// import Menu from "@mui/joy/Menu";
// import MenuItem from "@mui/joy/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import ReactLoading from "react-loading";
import IconButton from "@mui/joy/IconButton";
import { API } from "../../../Networking/API";
import Notification from "../../../CommonComponents/Notification/Notification";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Button } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import ProfilePic from "../../../Assests/Group 766.png";
import Cookies from "js-cookie";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Logout from "../../../CommonComponents/Logout/Logout";
import dotCricle from "../../../Assests/dot-circle.png";
import geocoding from "reverse-geocode";
import FlagIcon from "@mui/icons-material/Flag";
import { async } from "q";
import Geocode from "react-geocode";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
// import geocoder from 'local-reverse-geocoder';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ITEM_HEIGHT = 48;

const datagridSx = {
  "& .MuiDataGrid-autoHeight": {
    marginRight: "12px !important",
    marginLeft: "12px !important",
    // width: "100% !important"
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#e2e1e1 !important",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    //  color:"#ffffff !important",
    fontSize: "0.9rem !important",
  },
};

export function RecruiterDriveCandidatesResult(props) {
  const location = useLocation();

  const [value, setValue] = React.useState(0);
  const [driveid, setDriveid] = useState(location.state.driveData);
  const runningState = driveid.drive_running_status;
  const [makeInitiate, setMakeInitiate] = useState(
    driveid.is_drive_initialized
  );
  const [initiateToUpload, setInitiateToUpload] = useState(true);
  const [candidates, setCandidates] = useState(location.state.candidate);
  const [makeDisable, setMakeDisable] = useState(true);
  const [done, setDone] = useState(location.state.isDone);
  const [dataCount, setDataCount] = useState(0);
  const [dataCountH, setDataCountH] = useState(0);
  const [spanText, setSpan] = useState("file.ext");
  const [fileMerge, setFileMerge] = useState("");
  const [showBtn, setShowBtn] = useState(true);
  const [candidateId, setCandidatesId] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [
    candidateConsolidationRecruitedData,
    setCandidateConsolidationRecruitedData,
  ] = useState("");
  const [candidateConsolidationData, setCandidateConsolidationData] = useState(
    []
  );
  const openMenu = Boolean(anchorEl);
  const image = localStorage.getItem("image");
  const [locationStorage, setLocationStorage] = useState();
  const [locationID, setLocationID] = useState();
  const [Candidatelocation, setCandidatelocation] = useState();
  const [longitude, setLongitude] = useState();
  const [totalScore, setTotalScore] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [disableUplaod, setDisableUpload] = useState(true);
  const aRef = useRef(null);

  const SheetJSFT = ["xlsx", "csv"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");
  const [address, setAddress] = useState("");
  //   const recruitersData = driveid.recruiters;
  //   const time = moment(driveid.drive_time, ["HH:mm"]).format("h:mm A");

  useEffect(() => {
    getConsolidatedHiredDataByDrive(driveid.id);
    getConsolidatedDataByDrive(driveid.id);
    getDriveDetailsById(driveid.id);
  }, []);

  useEffect(() => {
    getCsvData(driveid.id);
  }, []);

  const getCsvData = (id) => {
    API.getCsvDataToDownload(id).then(({ response }) => {
      const result = response;
      if (result !== undefined) {
        if (result.data.status === true) {
          const Data = result.data.data.map((reports) => {
            return {
              "Test Start Time": reports.test_start_time,
              "Test End Time": reports.test_end_time,
              "Student Full Name": reports.student_full_name,
              "Student Roll Number": reports.student_roll_number,
              "Student Email-ID": reports.student_email_id,
              "Student CGPA": reports.percentage,
              Dept: reports.dept,
              Aptitude: reports.aptitude,
              Reasoning: reports.reasoning,
              Technical: reports.technical,
              Verbal: reports.verbal,
              "Total Score": reports.total_score,
              "Total Wrong Answer": reports.total_wrong_answer_count,
              "Candidate Drive Status": reports.drive_status,
            };
          });
          setCsvData(Data);
        } else {
          setCsvData([]);
        }
      } else {
      }
    });
  };

  const getDriveDetailsById = (id) => {
    API.getOneDriveData(id).then(({ response }) => {
      if (response.data.status === true) {
        setDriveid(response.data.data);
        setMakeInitiate(response.data.data.is_drive_initialized);
      }
    });
  };
  const csvReport = {
    data: csvData,
    filename: "Reports",
  };

  // useEffect(() => {
  //   const fetchAddress = async () => {
  //     // const geocoder = new google.maps.Geocoder();
  //     // const data = await geocoding.getLocations([40.714224, -73.961452]);
  //     // setAddress(data[0].formattedAddress);

  //     //   var config = {
  //     //     'latitude': 40.00403611111111,
  //     //     'longitude': 116.48485555555555
  //     // };

  //   };

  // }, [Candidatelocation]);

  const getLocation = (locationdata) => {
    Geocode.setApiKey("AIzaSyDwOeav_33UjrA28kwyXPnBa6eEKx_Hm5c");
    try {
      const location = [];
      Geocode.fromLatLng(
        locationdata.split(",")[0],
        locationdata.split(",")[1]
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          let city;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          location.push(city);
        },

        (error) => {
          return "N/A";
        }
      );
      return location;
    } catch (error) {
      return "N/A";
    }
  };

  // useEffect(() => {
  //     const fetchAddress = async () => {
  //       const data = await reverseGeocode.getLocations([latitude, longitude]);
  //       setAddress(data[0].formattedAddress);
  //     };

  //     fetchAddress();
  //   }, []);

  // return <div>{address}</div>;

  const getConsolidatedHiredDataByDrive = (driveId) => {
    let FinalResult = [];

    let Apicall = API.getConsolidatedCandidateList(driveId).then((response) => {
      let CheckPercentage = (CandidateResult) => {
        if (CandidateResult.length > 0) {
          // let TotalPercentage = []
          let SumOfper = 0;
          for (var SingleCheckPercentage of CandidateResult) {
            SumOfper =
              SumOfper + parseInt(SingleCheckPercentage.calculated_percentage);
          }

          return SumOfper;
        } else {
          return 0;
        }
      };

      if (response.data.status) {
        let calculatedPercentage = response.data.data.map((candidatedata) => {
          return {
            id: candidatedata?.id,
            firstname: candidatedata?.first_name,
            TotalPercentage: CheckPercentage(candidatedata.results),
          };
        });

        // setTotalScore(calculatedPercentage)
        FinalResult.push(calculatedPercentage);
        return calculatedPercentage;
      }
    });
    let GetFinalPer = async (id) => {
      let Finalres = await Apicall;
      if (Finalres.length > 0) {
        for (let SingleFinalres of Finalres) {
          if (SingleFinalres.id === id) {
            return SingleFinalres.TotalPercentage;
          }
        }
      }
    };
    API.getHiredCandidateList(driveId).then(async (response) => {
      if (response.data.status) {
        const data = response.data.data;
        setDataCountH(data.length);

        const consolidatedData = await Promise.all(
          data.map(async (items, index) => {
            return {
              id: index + 1,
              Candidates: `${items.first_name} ${items.last_name}`,
              Recruiter: items?.recruiter?.full_name ?? "NA",
              ScoresArchived: `${
                (await GetFinalPer(items.id))
                  ? await GetFinalPer(items.id)
                  : "0"
              } %`,
              Location: getLocation(items?.location) ?? "N/A",
            };
          })
        );
        setCandidateConsolidationRecruitedData(consolidatedData);

        let splitedata = consolidatedData.map((locationData) => {
          return {
            latitude: parseFloat(locationData.Location.split(",")[0]),
            longitude: parseFloat(locationData.Location.split(",")[1]),
          };
        });
        setCandidatelocation(splitedata);
      }
    });
  };

  const getConsolidatedDataByDrive = (driveId) => {
    setDone(false);
    try {
      API.getConsolidatedCandidateList(driveId).then((response) => {
        if (response.data.status) {
          const data = response.data.data;

          setDataCount(data.length);

          const consolidatedData = data.map((items, index) => {
            let Aptitude = "";
            let Technical = "";
            let Verbal = "";
            let Reasoning = "";
            if (items?.results.length > 0) {
              const data = items.results.find((i) => {
                return i.category?.quiz_category_code === "APTITUDE";
              });

              const data1 = items.results.find((i) => {
                return i.category?.quiz_category_code === "TECHNICAL";
              });
              const data2 = items.results.find((i) => {
                return i.category?.quiz_category_code === "REASONING";
              });
              const data3 = items.results.find((i) => {
                return i.category?.quiz_category_code === "VERBAL";
              });
              const num =
                data === undefined ? 0 : Math.ceil(data.calculated_percentage);
              const num1 =
                data1 === undefined
                  ? 0
                  : Math.ceil(data1.calculated_percentage);
              const num2 =
                data2 === undefined
                  ? 0
                  : Math.ceil(data2.calculated_percentage);
              const num3 =
                data3 === undefined
                  ? 0
                  : Math.ceil(data3.calculated_percentage);
              Aptitude =
                data?.calculated_percentage &&
                Number(data?.calculated_percentage) !== 0
                  ? `${num}`
                  : "0";

              Technical =
                data1?.calculated_percentage &&
                Number(data1?.calculated_percentage) !== 0
                  ? `${num1}`
                  : "0";

              Verbal =
                data3?.calculated_percentage &&
                Number(data3?.calculated_percentage) !== 0
                  ? `${num3}`
                  : "0";

              Reasoning =
                data2?.calculated_percentage &&
                Number(data2?.calculated_percentage) !== 0
                  ? `${num2}`
                  : "0";
              return {
                id: index + 1,
                candidateId: items.id,
                Candidates: `${items.first_name} ${items.last_name}`,
                Aptitude,
                Technical,
                Verbal,
                Reasoning,
                DriveTime: time,
                DriveDate: driveid.drive_date,
                backgroundTracking: items.background_activity_total_time,
              };
            } else {
              return {
                id: index + 1,
                candidateId: items.id,
                Candidates: `${items.first_name} ${items.last_name}`,
                Aptitude: "0",
                Technical: "0",
                Verbal: "0",
                Reasoning: "0",
                DriveTime: time,
                DriveDate: driveid.drive_date,
                backgroundTracking: items.background_activity_total_time,
              };
            }
          });
          let FinalScore = 0;
          const FinalResult = data.map((items, index) => {
            let score = 0;
            items.results.map((data1, index) => {
              score = score += data1.calculated_percentage;
            });
          });
          setDone(true);
          setCandidateConsolidationData(consolidatedData);
        } else {
          setDone(true);
          setDataCount(0);
        }
      });
    } catch (error) {
      setDone(true);
      setDataCount(0);
    }
  };

  const openDrop = Boolean(anchorEl);
  // var allCandidates = driveid.candidates;
  var Drive_id = location.state.DriveID;
  var recruitersData = driveid.recruiters;

  const time = moment(driveid.drive_time, ["HH:mm"]).format("h:mm A");

  const [anchorEll, setAnchorEll] = React.useState(null);
  const open = Boolean(anchorEll);

  const handleClickThreeDots = (event, id, status) => {
    setCandidatesId(id);
    setAnchorEll(event.currentTarget);
  };
  const handleCloseDrop = () => {
    setAnchorEll(null);
  };

  const handleInitiateQuizButton = () => {
    API.initiateQuiz(Drive_id).then(({ response }) => {
      if (response.status === true) {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "success",
        });
        setMakeInitiate(true);
        setInitiateToUpload(false);
      } else {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "error",
        });
      }
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      align: "left",
      headerClassName: "completed-task-table",
      cellClassName: "completed-task-table-id-row",
      // width: 60,
      flex: 1,
    },
    {
      field: "backgroundTracking",
      headerName: "",
      headerClassName: "completed-task-table",
      cellClassName: "completed-task-table-id-row",
      width: 30,
      renderCell: (params) => {
        return (
          <>
            {params.row.backgroundTracking > 2 ? (
              <FlagIcon style={{ color: "#BB0000" }} />
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "Candidates",
      headerName: "Candidates",
      headerClassName: "completed-task-table",
      cellClassName: "completed-task-table-id-row",
      align: "left",
      flex: 1.2,
    },
    {
      field: "Aptitude",
      headerName: "Aptitude",
      headerClassName: "completed-task-table",
      align: "left",
      flex: 1,
    },
    {
      field: "Technical",
      headerClassName: "completed-task-table",
      headerName: "Techincal",
      align: "left",
      flex: 1,
    },
    {
      field: "Verbal",
      headerClassName: "completed-task-table",
      headerName: "Verbal",
      align: "left",
      flex: 1,
    },
    {
      field: "Reasoning",
      headerClassName: "completed-task-table",
      headerName: "Reasoning",
      align: "left",
      flex: 1,
    },
    {
      field: "DriveDate",
      headerClassName: "completed-task-table",
      headerName: "Drive Date",
      align: "left",
      flex: 1,
    },
    {
      field: "DriveTime",
      headerClassName: "completed-task-table",
      headerName: "Drive Time",
      align: "left",
      flex: 1,
    },

    {
      // field: "action",
      // headerName: "Action",

      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              style={{
                display: runningState === "completed" ? "none" : "flex",
              }}
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(event) =>
                handleClickThreeDots(
                  event,
                  params.row.candidateId,
                  params.row.status
                )
              }
            >
              <MoreVertIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleClick = (event, id, status) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    navigate("/");
    Cookies.remove("jwtToken");
  };

  const handleCloseMenuBar = () => {
    setAnchorEl(null);
  };

  const candidatesRecruitedColumns = [
    {
      field: "id",
      headerName: "S.No",
      align: "left",
      headerClassName: "completed-task-table",
      cellClassName: "completed-task-table-id-row",
      // width: 60,
      flex: 1,
    },
    {
      field: "Candidates",
      headerName: "Candidates",
      headerClassName: "completed-task-table",
      cellClassName: "completed-task-table-id-row",
      align: "left",
      // width: 231,
      flex: 1,
    },
    {
      field: "Recruiter",
      headerName: "Recruiter",
      headerClassName: "completed-task-table",
      align: "left",
      // width: 130,
      flex: 1,
    },
    {
      field: "ScoresArchived",
      headerClassName: "completed-task-table",
      headerName: "Scores Achieved",
      align: "left",
      flex: 1,
    },
    {
      field: "Location",
      headerClassName: "completed-task-table",
      headerName: "Location",
      align: "left",
      flex: 1,
    },
  ];

  const handleResendCode = () => {
    API.resendTestLinkToCandidate(candidateId).then(({ response }) => {
      if (response.status === true) {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "success",
        });
        setAnchorEll(null);
        //     // const timer = setTimeout(() => {
        //     //   handleClose();
        //     //   getAllCandidateData();
        //     //   resetForm();
        //     //   getCandidatesDataByDriveID(DriveNameValue);
        //     // },1000)
        //     // return () => clearTimeout(timer)
      } else {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "error",
        });
        setAnchorEll(null);
      }
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onhandleChange = (files /*:File*/) => {
    setDisableUpload(false);
    setFileMerge(files.target.files[0]);
    setSpan(files.target.files[0].name);
    setShowBtn(false);
    // setShowValues(true);
  };

  const uploadExcelFile = () => {
    const id = driveid.id;
    setFileMerge();
    setSpan("file.ext");
    setShowBtn(true);
    aRef.current.value = null;
    API.uploadRecruitedCandidatesList(fileMerge, id).then(({ response }) => {
      if (response.status === true) {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "success",
        });
        setShowBtn(true);
        setDisableUpload(true);
        getConsolidatedHiredDataByDrive(id);
      } else {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "error",
        });
        setShowBtn(true);
        setDisableUpload(true);
      }
    });
  };

  const navigate = useNavigate();

  const handleCancelButton = () => {
    navigate("/recruiter-recruitment-Drive");
    setDriveid();
    setMakeInitiate(false);
    // setCandidates();
    // allCandidates="";
  };

  const handleHomeClick = () => {
    navigate("/recruiter-recruitment-Drive");
    setDriveid();
  };

  return (
    <Box className="Candidate-container-div">
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <ReusableButton
          text="Back"
          startIcon={<WestIcon />}
          onClick={handleHomeClick}
          style={{
            textTransform: "capitalize",
            // marginLeft: "10%",
            color: "#fff",
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            background: "none",
            border: "none",
            padding: "0px",
            boxShadow: "none",
            outline: "none",
            "&:focus": {
              background: "none",
              border: "none",
              padding: "0px",
              boxShadow: "none",
              outline: "none",
            },
            "&:hover": {
              background: "none",
              border: "none",
              padding: "0px",
              boxShadow: "none",
              outline: "none",
            },
          }}
        ></ReusableButton>
        <div style={{ marginRight: "-2%" }}>
          <Logout />
        </div>
      </div>
      <Box className="Candidates-container box_height Candidates-tabs">
        <Box
          style={{
            paddingTop: "30px",
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="Candidates-Header">{driveid.drive_name}</span>
          <span className="completed-HeaderStyle">
            <img
              src={dotCricle}
              alt="logout logo"
              style={{ marginRight: "5px" }}
            />
            {runningState}
          </span>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <span className="completed-dot"></span>
            <span className="completed_Word_styles">
              {driveid.drive_running_status}
            </span>
          </div> */}
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{
                    paddingBottom: "5px",
                    fontSize: "14px",
                    textTransform: "capitalize",
                  }}
                  htmlFor=""
                >
                  Drive Name
                </label>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                  }}
                >
                  {driveid.drive_name}
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Candidate Type
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {driveid.candidate_experience_level}
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Drive Type
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {driveid.drive_type}
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  College/ Institution
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {driveid.college_name === null
                    ? driveid.institute_name
                    : driveid.college_name}
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Drive Location
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {driveid.location}
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Categories
                </label>
                <div style={{ display: "" }}>
                  {driveid.quizCategory.length === 1 ? (
                    <>
                      {driveid.quizCategory.map((items, index) => (
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "700",
                            display: "flex",
                          }}
                        >
                          {items.quiz_category_name}
                        </span>
                      ))}
                    </>
                  ) : (
                    <>
                      {driveid.quizCategory.map((items, index) => (
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "700",
                            display: "flex",
                          }}
                        >
                          {items.quiz_category_name},
                        </span>
                      ))}
                    </>
                  )}
                </div>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Competency level
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {driveid.competencyLevel[0].competency_level_name}
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Drive Date
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {driveid.drive_date}
                </span>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="" style={{ display: "grid", marginTop: "14px" }}>
                <label
                  style={{ paddingBottom: "5px", fontSize: "14px" }}
                  htmlFor=""
                >
                  Drive Time
                </label>
                <span style={{ fontSize: "14px", fontWeight: "700" }}>
                  {time}
                </span>
              </Box>
            </Grid>

            {recruitersData.map((items, index) => (
              <Grid item xs={3}>
                <Box
                  className=""
                  style={{ display: "grid", marginTop: "14px" }}
                >
                  <label
                    style={{ paddingBottom: "5px", fontSize: "14px" }}
                    htmlFor=""
                  >
                    Recruiter {index + 1}
                  </label>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      textTransform: "capitalize",
                    }}
                  >
                    {items.full_name}
                  </span>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          style={{ display: "flex", marginTop: "25px" }}
          className="completed-tabs"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            // className="Candidates-tabs"
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              label="Candidates Drive Results"
              style={{ textTransform: "capitalize" }}
              {...a11yProps(0)}
            />
            <Tab
              label="Candidates Recruited"
              style={{ textTransform: "capitalize" }}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <div>
            <span
              style={{
                position: "relative",
                bottom: "38px",
              }}
            >
              {runningState === "completed" || (
                <ReusableButton
                  className={makeInitiate ? "itIsDisabled" : ""}
                  style={{
                    float: "right",
                    textTransform: "capitalize",
                    color: "#fff",
                    background: "#00728E",
                    display:
                      runningState === "pending"
                        ? "none"
                        : driveid.is_drive_initialized
                        ? "none"
                        : "flex",
                    justifyContent: "flex-end",
                    borderRadius: "4px",
                    "&:hover": {
                      background: "#00728E",
                      justifyContent: "flex-end",
                      borderRadius: "4px",
                      color: "#fff",
                    },
                  }}
                  size="medium"
                  text="initiate Quiz"
                  variant="outlined"
                  disabled={driveid.is_drive_initialized}
                  onClick={handleInitiateQuizButton}
                />
              )}

              {csvData.length > 0 ? (
                <Box sx={{ marginLeft: "12px" }}>
                  <CSVLink
                    // on
                    style={{
                      display: "flex",
                      alignItems: "center",
                      float: "right",
                      color: "#fff",
                      marginRight: "50px",
                      textTransform: "capitalize",
                      padding: "4.8px 25px",
                      background: "#00728E",
                      borderRadius: "4px",
                      textDecoration: "none",
                      fontSize: "0.875rem",
                      height: "100%",
                      "&:hover": {
                        background: "#00728E",
                        borderRadius: "4px",
                        color: "#00728E",
                      },
                    }}
                    {...csvReport}
                  >
                    Export CSV
                  </CSVLink>
                </Box>
              ) : null}
            </span>

            <Box sx={{ height: 400, width: "100%" }} className="candiatetable">
              {!done ? (
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50vh",
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#00728E"}
                    height={100}
                    width={100}
                  />
                </Box>
              ) : (
                <DataGrid
                  autoHeight
                  rows={candidateConsolidationData}
                  sx={datagridSx}
                  columns={columns}
                  className="completed-table-two"
                  hideFooter={true}
                  width={100}
                  hideFooterPagination={true}
                  style={{ marginBottom: "20px", width: "100%" }}
                />
              )}
              <div className="overall-data-found">
                <span className="total-data-text">
                  Total Number of Candidates
                </span>
                <span
                  className="total-data-text"
                  style={{ paddingLeft: "15px" }}
                >
                  {dataCount}
                </span>
              </div>
            </Box>
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4} style={{ paddingTop: "15px" }}>
                <div
                  style={{ width: "100%", marginTop: "14px" }}
                  disabled={makeDisable}
                >
                  <div
                  // style={{ marginBottom: "16px" }}
                  >
                    <label>Upload Recruited Candidates List</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      // marginTop:"2%",
                      alignItems: "baseline",
                      border: "0.5px solid #00728E",
                      borderRight: "0",
                      borderRadius: "0.3rem",
                      width: "85%",
                      position: "static",
                      marginTop: "3%",
                      maxHeight: "7.3vh",
                    }}
                  >
                    <input
                      id="file-chosen"
                      className="browserFiles"
                      style={{
                        borderTopStyle: "hidden",
                        borderBottomStyle: "hidden",
                        borderLeftStyle: "hidden",
                        borderRightStyle: "hidden",
                        width: "64%",
                      }}
                      value={spanText}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "baseline",
                        width: "51%",
                        maxHeight: "7.3vh",
                      }}
                    >
                      <label
                        style={{ maxHeight: "7.3vh", fontSize: "14px" }}
                        for="actual-btn"
                        className="upload-label"
                      >
                        Browse File
                      </label>
                      <input
                        type="file"
                        id="actual-btn"
                        hidden
                        accept={SheetJSFT}
                        onChange={onhandleChange}
                        ref={aRef}
                        // disabled={showFile}
                        className="browserFiles"
                        readonly
                      />
                    </div>
                  </div>
                  {/* -------------------- */}
                </div>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginTop: "4.5%",
                }}
              >
                <div>
                  <ReusableButton
                    style={{
                      float: "right",
                      backgroundColor: "#00728E",
                      textTransform: "capitalize",
                      "&:hover": { backgroundColor: "#00728E" },
                    }}
                    endIcon={<ArrowRightAltIcon />}
                    text="Upload"
                    onClick={() => uploadExcelFile()}
                    disabled={
                      driveid.is_drive_initialized === false
                        ? false
                        : disableUplaod
                    }
                  />
                </div>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              ></Grid>
            </Grid>

            <Box sx={{ height: 400, width: "100%" }} className="candiatetable">
              <DataGrid
                className="completed-table-one"
                autoHeight
                rows={candidateConsolidationRecruitedData}
                columns={candidatesRecruitedColumns}
                hideFooter={true}
                hideFooterPagination={true}
                sx={datagridSx}
                style={{
                  margin: "20px 0px 20px 0px",
                  width: "100% !important",
                }}
              />
              <div className="overall-data-found">
                <span className="total-data-text">
                  Total Number of Candidates
                </span>
                <span
                  className="total-data-text"
                  style={{ paddingLeft: "15px" }}
                >
                  {dataCountH}
                </span>
              </div>
            </Box>
          </div>
        </TabPanel>

        <ReusableButton
          style={{
            float: "right",
            Color: "#00728E",
            marginRight: "50px",
            textTransform: "capitalize",
            marginTop: "100px",
            padding: "4px 25px",
            color: "#fff",
            background: "#00728E",
            borderRadius: "4px",
            "&:hover": {
              background: "#00728E",
              borderRadius: "4px",
              Color: "#00728E",
            },
          }}
          size="medium"
          text="cancel"
          variant="outlined"
          onClick={handleCancelButton}
        />
      </Box>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEll}
        open={open}
        onClose={handleCloseDrop}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {makeInitiate && (
          <MenuItem onClick={handleResendCode}>Resend Code</MenuItem>
        )}
      </Menu>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}
