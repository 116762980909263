import * as React from "react";
import { useState } from "react";
// import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ReusableButton from "../../../CommonComponents/Button/Button";
import ReusableInputfield from "../../../CommonComponents/TextField/TextField";
import Grid from "@mui/material/Grid";
// import "./AdminRecuitmentDriveDialogueBox.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { MyForm } from "../../../CommonComponents/MyForm/MyForm";
import "./AdminRecruiterEditPage.css";
import ResuableDropdown from "../../../CommonComponents/DropDown/DropDown";
// import { Pages } from "@mui/icons-material";
import ReactLoading from "react-loading";
import { API } from "../../../Networking/API";
import Notification from "../../../CommonComponents/Notification/Notification";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 803,
  height: 420,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export function AdminRecruiterEditPage(props) {
  const {
    open,
    handleClose,
    page,
    designation,
    designationName,
    id,
    RecruiterDrive,
    pageNumber,
  } = props;

  const initialValues = {
    firstname: page.full_name,
    RecruitersDesignation: designationName,
    Experience: page.year_of_experience,
    Email: page.email,
    PhoneNumber: page.phone_no,
  };

  // const [disable, setDisable] = useState(true);
  const [done, setDone] = useState(false);
  const handleCloseModal = () => {
    handleClose();
    resetForm();
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("firstname" in inputValues)
      code.firstname = inputValues.firstname
        ? ""
        : "Recruiter Name is required";
    if ("RecruitersDesignation" in inputValues)
      code.RecruitersDesignation = inputValues.RecruitersDesignation
        ? ""
        : "Recruiter Designation is required";
    if ("Experience" in inputValues)
      code.Experience = inputValues.Experience ? "" : "Experience is required";
    const emailValidation =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if ("Email" in inputValues)
      code.Email = inputValues.Email
        ? emailValidation.test(inputValues.Email)
          ? ""
          : "Email is not valid"
        : "Email is required";
    if ("PhoneNumber" in inputValues)
      code.PhoneNumber = inputValues.PhoneNumber
        ? inputValues.PhoneNumber.length < 10
          ? "Invalid Phone number"
          : ""
        : "Phone Number is required";
    setErrors({
      ...code,
    });

    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    validateOnEdit,
  } = MyForm(initialValues, true, validate);

  const handleInputChangeDropdown = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, RecruitersDesignation: "" });
  };

  const handleInputChangePhno = (e) => {
    const { name, value } = e.target;
    const Phvalue = value.replace(/\D/g, "");

    setValues({
      ...values,
      [name]: Phvalue,
    });

    // if (validateOnEdit) {
    validate({ [name]: Phvalue });
    // }
  };

  const handleSaveRecruiter = () => {
   
    if (validate()) {
      setDone(true);
      API.adminUpdateRecruiter(values, id).then(({ response }) => {
        if (response.status === true) {
          setNotify({
            isOpen: true,
            message: "recruiter details edited successfully",
            type: "success",
          });
          const timer = setTimeout(() => {
            setDone(false);
            // setDisable(true);
            RecruiterDrive(pageNumber);
            handleCloseModal();
          }, 2000);
          return () => clearTimeout(timer);
        } else if (response.status === false) {
          setNotify({
            isOpen: true,
            message: response.detail.errors[0].message,
            type: "error",
          });
          setDone(false);
        }
      });
    }
  };

  return (
    <>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleCloseModal}
          closeAfterTransition
        >
          <Fade in={open} className="Edit-popup-Box-Styles">
            <Box sx={style}>
              {done ? (
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "55vh",
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#00728E"}
                    height={100}
                    width={100}
                  />
                </Box>
              ) : (
                <Box>
                  <Box style={{ display: "flex", paddingBottom: "20px" }}>
                    <Box
                      style={{
                        width: "30vw",
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#424242",
                      }}
                    >
                      <span>Edit Recruiter</span>
                    </Box>
                  </Box>
                  <Grid container spacing={2} sx={{display:"flex",justifyContent:"center",alignItems:"end"}}>
                    <Grid item xs={4} sx={{ height: "130px"}}>
                      <Box className="recruiterEdit-AdminFelidTextBox">
                        <label style={{ paddingBottom: "5px" }} htmlFor="">
                          Recruiters Name
                        </label>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="firstname"
                          //label="Drive Name"
                          placeholder="Enter Recruiter Name"
                          value={values.firstname}
                          error={errors.firstname ? true : null}
                          helperText={errors.firstname}
                          // disabled={disable}
                          onChange={handleInputChange}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4} sx={{ height: "130px"}}>
                      <Box className="recruiterEdit-AdminFelidTextBox">
                        <label style={{ paddingBottom: "10px" }} htmlFor="">
                          Recruiters Designation
                        </label>
                        <ResuableDropdown
                          size="small"
                          type="text"
                          // placeholder="Select Recruiter"
                          spanTexe="Select Recruiter Designation"
                          style={{ width: "232px" }}
                          name="RecruitersDesignation"
                          error={errors.RecruitersDesignation ? true : false}
                          value={values.RecruitersDesignation}
                          helperText={errors.RecruitersDesignation}
                          options={designation}
                          // disabled={disable}
                          onChange={handleInputChangeDropdown}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4} sx={{ height: "130px"}}>
                      <Box className="recruiterEdit-AdminFelidTextBox">
                        <label style={{ paddingBottom: "5px" }} htmlFor="">
                          Experience
                        </label>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="Experience"
                          inputProps={{
                            maxLength: 2,
                          }}
                          placeholder="In Years"
                          value={values.Experience}
                          // disabled={disable}
                          error={errors.Experience ? true : false}
                          helperText={errors.Experience}
                          onChange={handleInputChangePhno}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sx={{ height: "130px"}}>
                      <Box className="recruiterEdit-AdminFelidTextBox">
                        <label style={{ paddingBottom: "5px" }} htmlFor="">
                          Email Address
                        </label>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="email"
                          name="Email"
                          placeholder="Email Address"
                          value={values.Email}
                          disabled={true}
                          error={errors.Email ? true : false}
                          helperText={errors.Email}
                          onChange={handleInputChange}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4} sx={{ height: "130px"}}>
                      <Box className="recruiterEdit-AdminFelidTextBox">
                        <label style={{ paddingBottom: "5px" }} htmlFor="">
                          Phone Number
                        </label>
                        <ReusableInputfield
                          // className="input"
                          variant="outlined"
                          size="small"
                          type="text"
                          name="PhoneNumber"
                          placeholder="Enter Phone Number"
                          value={values.PhoneNumber}
                          // disabled={disable}
                          error={errors.PhoneNumber ? true : false}
                          helperText={errors.PhoneNumber}
                          inputProps={{
                            maxLength: 10,
                          }}
                          onChange={handleInputChangePhno}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Box>
                    <Box>
                      <ReusableButton
                        style={{
                          float: "right",
                          backgroundColor: "#00728E",
                          "&:hover": { backgroundColor: "#00728E" },
                        }}
                        text="Save"
                        // disabled={disable}
                        onClick={handleSaveRecruiter}
                      />
                    </Box>
                    <Box>
                      <ReusableButton
                        style={{
                          float: "right",
                          Color: "#00728E",
                          marginRight: "20px",
                        }}
                        text="cancel"
                        variant="outlined"
                        onClick={handleCloseModal}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Fade>
        </Modal>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
