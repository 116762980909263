import React from "react";
import { Cards } from "../../../CommonComponents/Cards/Cards";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import "./AdminLandingPage.css";
import Recruiters from "../../../Assests/Rectangle.png";
import Recruiting_Drive from "../../../Assests/Rectangle32.png";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled, alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ProfilePic from "../../../Assests/Group 766.png";
import { API } from "../../../Networking/API";
import { useEffect } from "react";
import UploadQuestions from "../../../Assests/Rectangle11.png";
import Logout from "../../../CommonComponents/Logout/Logout";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const AdminLandingPage = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const image = localStorage.getItem("image");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    navigate("/");
    Cookies.remove("jwtToken");
  };

  const handleCloseMenuBar = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="Admin-section-wrapper">

      <div style={{marginRight:"15.5%"}}>
         <Logout />
         </div>

        <div style={{ margin: "20px 200px 20px 200px" }}>
          <Grid container spacing={5}>
            <Grid item xs={6} className="BoxStyles" style={{display:"flex",justifyContent:"flex-end"}}>
              <div>
                <Link href="/admin-recruiting-drive" underline="none">
                  <Cards
                    image={Recruiting_Drive}
                    height="150"
                    width="80"
                    style={{ borderRadius: "0px" }}
                    content={
                      <div className="card-content-text">
                        <span className="section-name-texts">Drives</span>
                      </div>
                    }
                  />
                </Link>
              </div>
            </Grid>

            <Grid item xs={6} style={{display:"flex",justifyContent:"flex-start"}}>
              <Link href="/admin-recruiters-list" underline="none">
                <Cards
                  image={Recruiters}
                  height="150"
                  width="80"
                  style={{ borderRadius: "0px" }}
                  content={
                    <div className="card-content-text">
                      <span className="section-name-texts">Recruiters</span>
                    </div>
                  }
                />
              </Link>
            </Grid>

            <Grid item xs={6} style={{display:"flex",justifyContent:"flex-end"}}>
              <Link href="/admin-Upload-Quiz" underline="none">
                <Cards
                  image={UploadQuestions}
                  height="150"
                  width="80"
                  style={{ borderRadius: "0px" }}
                  content={
                    <div className="card-content-text">
                      <span className="section-name-texts">
                        Upload Questions
                      </span>
                    </div>
                  }
                />
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AdminLandingPage;
