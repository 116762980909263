import React, { useEffect, useState } from "react";
import "./CandidateRegistration.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ReusableButton from "../../../../src/CommonComponents/Button/Button";
import ReusableInputfield from "../../../CommonComponents/TextField/TextField";
import It from "../../../../src/Assests/IT.png";
import CandidateFreshers from "../../../../src/Assests/fresher.png";
import CrackItLogo from "../../../../src/Assests/CrackItLogo.png";
import Crack from "../../../../src/Assests/Crack.png";
import ResuableDropdown from "../../../CommonComponents/DropDown/DropDown";
import { makeStyles } from "@material-ui/core";
import { MyForm } from "../../../CommonComponents/MyForm/MyForm";
import { API } from "../../../Networking/API";
import Checkbox from "@mui/material/Checkbox";
import Notification from "../../../CommonComponents/Notification/Notification";
import ResuableDialogBox from "../../../CommonComponents/DialogBox/DialogBox";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import { Modal, Typography, Fade } from "@mui/material";
import { pink } from "@mui/material/colors";
import AbsyzNewLogo from "../../../Assests/absyz.svg";
import AbsyzLogo from "../../../Assests/absyz_logo.png";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const CandidateRegistrationFresher = () => {
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [open, setOpen] = useState(false);
  const [done, setDone] = useState(false);
  const [isCourseClicked, setIsCourseClicked] = useState(true);
  const [closeTab, setCloseTab] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const location = useLocation();
  const [driveID, setDriveID] = useState(Number(location.state.state));
  const navigate = useNavigate();
  const [privacyStatus, setPrivacyStatus] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [privacyPopUp, setPrivacyPopup] = useState(false);

  const handleClose = () => {
    setOpen(false);
    navigate("/candidate-login");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: { xs: "75vh", md: "auto" },
    overflowX: { xs: "scroll", md: "inherit" },
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phNo: "",
    collegeName: location.state.collegeName,
    course: "",
    branch: "",
    collegeYear: "",
    cgpa: "",
    rollNo: "",
  };

  const useStyles = makeStyles({
    dropdownStyle: {
      width: "200px !important",
      maxHeight: "200px !important",
      height: "fit-content !important",
    },
  });

  const courseOptions = [
    { id: "1", full_name: "UG" },
    { id: "2", full_name: "PG" },
  ];

  const branchUGOptions = [
    { id: "1", full_name: "CSE" },
    { id: "2", full_name: "IT" },
    { id: "3", full_name: "ECE" },
  ];

  const branchPGOptions = [
    { id: "1", full_name: "M.Tech IT" },
    { id: "2", full_name: "MCA" },
    { id: "3", full_name: "M.Techn CSE" },
  ];

  const branchOptions = [{}];

  // MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root
  // MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root

  const currentYear = new Date().getFullYear();
  const validYears = [currentYear, currentYear + 1, currentYear + 2];

  const validate = (inputValues = values) => {
    let code = { ...errors };

    if ("firstName" in inputValues)
      code.firstName = inputValues.firstName ? "" : "First name is required";

    if ("lastName" in inputValues)
      code.lastName = inputValues.lastName ? "" : "Last name is required";

    const emailValidation =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if ("email" in inputValues) {
      code.email = inputValues.email
        ? emailValidation.test(inputValues.email)
          ? ""
          : "Email is not valid"
        : "Email is required";
    }

    if ("phNo" in inputValues)
      code.phNo = inputValues.phNo
        ? inputValues.phNo.length < 10
          ? "Invalid Phone number"
          : ""
        : "Phone number is required";

    if ("collegeName" in inputValues)
      code.collegeName = inputValues.collegeName
        ? ""
        : "College name is required";

    if ("course" in inputValues)
      code.course = inputValues.course ? "" : "Course is required";

    if ("branch" in inputValues)
      code.branch = inputValues.branch ? "" : "Branch is required";

    if ("collegeYear" in inputValues) {
      const collegeYear = parseInt(inputValues.collegeYear);
      code.collegeYear = inputValues.collegeYear ? "" : "Year is required";
      if (!validYears.includes(collegeYear)) {
        code.collegeYear = `Please provide a valid year`;
      }
    }

    if ("cgpa" in inputValues) {
      code.cgpa = inputValues.cgpa ? "" : "CGPA is required";
      if (inputValues.cgpa > 10 || inputValues.cgpa < 0) {
        code.cgpa = "Please provide correct value";
      }
    }

    if ("rollNo" in inputValues) {
      code.rollNo = inputValues.rollNo ? "" : "Roll No is required";
    }

    setErrors({
      ...code,
    });

    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    validateOnEdit,
  } = MyForm(initialValues, true, validate);

  const handleSubmit = () => {
    if (validate()) {
      setDone(true);
      API.submitFresherRegistration(
        driveID,
        values.firstName,
        values.lastName,
        values.email,
        values.phNo,
        values.rollNo,
        values.cgpa,
        values.collegeName,
        selectedCourse,
        selectedBranch,
        values.collegeYear
      ).then(({ response }) => {
        if (response.status === true) {
          // setNotify({
          //   isOpen: true,
          //   message: response.message,
          //   type: "success",
          // });
          setDone(false);
          setSuccessMsg(response.message);
          setOpen(true);
          resetForm();
        } else {
          setDone(false);
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
        }
      });
    }
  };

  const handleInputCourse = (e) => {
    setIsCourseClicked(false);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    const holder = courseOptions.find((items) => {
      if (items.id === e.target.value) {
        setSelectedCourse(items.full_name);
      }
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, course: "" });
  };

  const handleInputBranch = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    if (selectedCourse === "PG") {
      const holder = branchPGOptions.find((items) => {
        if (items.id === e.target.value) {
          setSelectedBranch(items.full_name);
        }
      });
    } else {
      const holder = branchUGOptions.find((items) => {
        if (items.id === e.target.value) {
          setSelectedBranch(items.full_name);
        }
      });
    }

    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, branch: "" });
  };

  const handleCheckboxTick = (event) => {
    setPrivacyStatus(!event.target.checked);
  };

  const handlePrivacyClick = () => {
    setPrivacyPopup(true);
  };

  const handleClosePrivacyPopup = () => {
    setPrivacyPopup(false);
  };

  return (
    <div>
      <Box className="candidate-Landing-Page-Outer-fresher">
        {done ? (
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#00728E"}
              height={100}
              width={100}
            />
          </Box>
        ) : (
          <>
            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              className="for_Web"
            >
              <Grid
                container
                spacing={2}
                style={{
                  width: "80%",
                  margin: "50px auto 30px auto",
                  background: "#fff",
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    padding: "0px",
                  }}
                >
                  <img
                    src={CandidateFreshers}
                    alt="CandidateFreshers"
                    width="100%"
                    height="100%"
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginTop: "20px",
                    }}
                  >
                    {/* <img
                    src={CrackItLogo}
                    alt="logo"
                    width="80"
                    height="80"
                  ></img>
                  <span style={{ marginTop: "6px" }} className="recruiter_text">
                    Recruitment 2023
                    <img src={Crack} alt="logo" width="80" height="17"></img>{" "}
                    &nbsp;
                    <img src={It} alt="logo" width="20" height="20"></img>
                  </span> */}
                    {/* <Box sx={{ marginTop: "20px" }}> */}
                    <img
                      src={AbsyzNewLogo}
                      alt="logo"
                      // width="80"
                      // height="80"
                    ></img>
                    {/* </Box> */}
                  </div>

                  <div className="content_text">
                    Fresher Candidates Registration Form
                  </div>

                  <div className="form__div">
                    <Grid container spacing={2} style={{}}>
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          paddingRight: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          First Name
                        </div>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="firstName"
                          className="fresher__inputs"
                          placeholder="Enter first name"
                          required
                          value={values.firstName}
                          inputProps={{
                            autoComplete: "off",
                          }}
                          onChange={handleInputChange}
                          error={errors.firstName}
                          helperText={errors.firstName}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Last Name
                        </div>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="lastName"
                          placeholder="Enter last name"
                          className="fresher__inputs"
                          required
                          value={values.lastName}
                          inputProps={{
                            autoComplete: "off",
                          }}
                          onChange={handleInputChange}
                          error={errors.lastName}
                          helperText={errors.lastName}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          paddingRight: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          College Email
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="email"
                          id="email"
                          placeholder="Enter college email"
                          className="fresher__inputs"
                          required
                          name="email"
                          value={values.email}
                          onChange={handleInputChange}
                          error={errors.email}
                          helperText={errors.email}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Phone Number
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          id="phNo"
                          className="fresher__inputs"
                          required
                          name="phNo"
                          placeholder="Enter phone number"
                          value={values.phNo}
                          onChange={handleInputChange}
                          error={errors.phNo}
                          helperText={errors.phNo}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          paddingRight: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          College/University Name
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          className="fresher__inputs"
                          id="collegeName"
                          placeholder="Enter college name"
                          required
                          name="collegeName"
                          value={values.collegeName}
                          onChange={handleInputChange}
                          error={errors.collegeName}
                          helperText={errors.collegeName}
                          disabled
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Course
                        </div>

                        <ResuableDropdown
                          size="small"
                          type="text"
                          className="fresher__inputs"
                          placeholder="Select your course"
                          style={{ paddingRight: "35px" }}
                          name="course"
                          spanText="Select your course"
                          value={values.course}
                          onChange={handleInputCourse}
                          error={errors.course}
                          helperText={errors.course}
                          options={courseOptions}
                          // disabled={disable}
                          MenuProps={useStyles}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          paddingRight: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Branch
                        </div>

                        <ResuableDropdown
                          size="small"
                          type="text"
                          className="fresher__inputs"
                          placeholder="Select your branch"
                          style={{ paddingRight: "35px" }}
                          name="branch"
                          spanText="Select your branch"
                          value={values.branch}
                          onChange={handleInputBranch}
                          error={errors.branch}
                          helperText={errors.branch}
                          options={
                            selectedCourse === "PG"
                              ? branchPGOptions
                              : selectedCourse === "UG"
                              ? branchUGOptions
                              : branchOptions
                          }
                          disabled={isCourseClicked}
                          MenuProps={useStyles}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Graduating Year
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="number"
                          id="collegeYear"
                          required
                          className="fresher__inputs"
                          placeholder="Enter graduating year"
                          name="collegeYear"
                          value={values.collegeYear}
                          onChange={handleInputChange}
                          error={errors.collegeYear}
                          helperText={errors.collegeYear}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          paddingRight: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          CGPA
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="number"
                          id="cgpa"
                          className="fresher__inputs"
                          placeholder="Enter cgpa"
                          required
                          name="cgpa"
                          value={values.cgpa}
                          onChange={handleInputChange}
                          error={errors.cgpa}
                          helperText={errors.cgpa}
                        />
                      </Grid>

                      {/* <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: "0px",
                      paddingLeft: "0px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      className="adminLoginHeadingThree"
                      style={{
                        marginTop: "14px",
                        marginBottom: "4px",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      College Email Address
                    </div>

                    <ReusableInputfield
                      variant="outlined"
                      size="small"
                      type="email"
                      id="collegeEmail"
                      placeholder="Enter college email"
                      required
                      name="collegeEmail"
                      value={values.collegeEmail}
                      onChange={handleInputChange}
                      error={errors.collegeEmail}
                      helperText={errors.collegeEmail}
                    />
                  </Grid> */}

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Roll Number
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          id="rollNo"
                          className="fresher__inputs"
                          required
                          placeholder="Enter roll number"
                          name="rollNo"
                          value={values.rollNo}
                          onChange={handleInputChange}
                          error={errors.rollNo}
                          helperText={errors.rollNo}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      label="checkbox"
                      className="privacy_checkbox"
                      onChange={handleCheckboxTick}
                    />
                    <span className="privacy_text">
                      I accept the{" "}
                      <span onClick={handlePrivacyClick}>Privacy Notice</span>
                    </span>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "18px",
                      marginBottom: "30px",
                    }}
                  >
                    <div>
                      <ReusableButton
                        text={"Get Started"}
                        endIcon={<ArrowRightAltIcon />}
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={privacyStatus}
                        style={{
                          background: "#00728E",
                          borderRadius: "4px",
                          fontFamily: "Helvetica",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          textTransform: "capitalize !important",
                          "&:hover": { backgroundColor: "#00728E" },
                          padding: "6px 38px",
                        }}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
              className="for_Mobile"
            >
              <Grid
                container
                spacing={2}
                style={{
                  width: "80%",
                  margin: "50px auto 30px auto",
                  background: "#fff",
                }}
              >
                {/* <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    padding: "0px",
                  }}
                >
                  <img
                    src={CandidateFreshers}
                    alt="CandidateFreshers"
                    width="100%"
                    height="100%"
                  />
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginTop: "20px",
                    }}
                  >
                    {/* <Box sx={{ marginTop: "20px" }}> */}
                    <img
                      src={AbsyzNewLogo}
                      alt="logo"
                      // width="80"
                      // height="80"
                    ></img>
                    {/* </Box> */}
                  </div>

                  <div className="content_text">
                    Fresher Candidates Registration Form
                  </div>

                  <div className="form__div">
                    <Grid container spacing={2} style={{}}>
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          paddingRight: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          First Name
                        </div>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="firstName"
                          className="fresher__inputs"
                          placeholder="Enter first name"
                          required
                          value={values.firstName}
                          inputProps={{
                            autoComplete: "off",
                          }}
                          onChange={handleInputChange}
                          error={errors.firstName}
                          helperText={errors.firstName}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Last Name
                        </div>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="lastName"
                          placeholder="Enter last name"
                          className="fresher__inputs"
                          required
                          value={values.lastName}
                          inputProps={{
                            autoComplete: "off",
                          }}
                          onChange={handleInputChange}
                          error={errors.lastName}
                          helperText={errors.lastName}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          paddingRight: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          College Email
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="email"
                          id="email"
                          placeholder="Enter college email"
                          className="fresher__inputs"
                          required
                          name="email"
                          value={values.email}
                          onChange={handleInputChange}
                          error={errors.email}
                          helperText={errors.email}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Phone Number
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          id="phNo"
                          className="fresher__inputs"
                          required
                          name="phNo"
                          placeholder="Enter phone number"
                          value={values.phNo}
                          onChange={handleInputChange}
                          error={errors.phNo}
                          helperText={errors.phNo}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          paddingRight: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          College/University Name
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          className="fresher__inputs"
                          id="collegeName"
                          placeholder="Enter college name"
                          required
                          name="collegeName"
                          value={values.collegeName}
                          onChange={handleInputChange}
                          error={errors.collegeName}
                          helperText={errors.collegeName}
                          disabled
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Course
                        </div>

                        <ResuableDropdown
                          size="small"
                          type="text"
                          className="fresher__inputs"
                          placeholder="Select your course"
                          style={{ paddingRight: "35px" }}
                          name="course"
                          spanText="Select your course"
                          value={values.course}
                          onChange={handleInputCourse}
                          error={errors.course}
                          helperText={errors.course}
                          options={courseOptions}
                          // disabled={disable}
                          MenuProps={useStyles}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          paddingRight: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Branch
                        </div>

                        <ResuableDropdown
                          size="small"
                          type="text"
                          className="fresher__inputs"
                          placeholder="Select your branch"
                          style={{ paddingRight: "35px" }}
                          name="branch"
                          spanText="Select your branch"
                          value={values.branch}
                          onChange={handleInputBranch}
                          error={errors.branch}
                          helperText={errors.branch}
                          options={
                            selectedCourse === "PG"
                              ? branchPGOptions
                              : selectedCourse === "UG"
                              ? branchUGOptions
                              : branchOptions
                          }
                          disabled={isCourseClicked}
                          MenuProps={useStyles}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Graduating Year
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="number"
                          id="collegeYear"
                          required
                          className="fresher__inputs"
                          placeholder="Enter graduating year"
                          name="collegeYear"
                          value={values.collegeYear}
                          onChange={handleInputChange}
                          error={errors.collegeYear}
                          helperText={errors.collegeYear}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          paddingRight: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          CGPA
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="number"
                          id="cgpa"
                          className="fresher__inputs"
                          placeholder="Enter cgpa"
                          required
                          name="cgpa"
                          value={values.cgpa}
                          onChange={handleInputChange}
                          error={errors.cgpa}
                          helperText={errors.cgpa}
                        />
                      </Grid>

                      {/* <Grid
        item
        xs={6}
        style={{
          paddingTop: "0px",
          paddingLeft: "0px",
          marginBottom: "5px",
        }}
      >
        <div
          className="adminLoginHeadingThree"
          style={{
            marginTop: "14px",
            marginBottom: "4px",
            display: "flex",
            justifyContent: "start",
          }}
        >
          College Email Address
        </div>

        <ReusableInputfield
          variant="outlined"
          size="small"
          type="email"
          id="collegeEmail"
          placeholder="Enter college email"
          required
          name="collegeEmail"
          value={values.collegeEmail}
          onChange={handleInputChange}
          error={errors.collegeEmail}
          helperText={errors.collegeEmail}
        />
      </Grid> */}

                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          className="adminLoginHeadingThree"
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Roll Number
                        </div>

                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          id="rollNo"
                          className="fresher__inputs"
                          required
                          placeholder="Enter roll number"
                          name="rollNo"
                          value={values.rollNo}
                          onChange={handleInputChange}
                          error={errors.rollNo}
                          helperText={errors.rollNo}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      label="checkbox"
                      className="privacy_checkbox"
                      onChange={handleCheckboxTick}
                    />
                    <span className="privacy_text">
                      I accept the{" "}
                      <span onClick={handlePrivacyClick}>Privacy Notice</span>
                    </span>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "18px",
                      marginBottom: "30px",
                    }}
                  >
                    <div>
                      <ReusableButton
                        text={"Get Started"}
                        endIcon={<ArrowRightAltIcon />}
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={privacyStatus}
                        style={{
                          background: "#00728E",
                          borderRadius: "4px",
                          fontFamily: "Helvetica",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          textTransform: "capitalize !important",
                          "&:hover": { backgroundColor: "#00728E" },
                          padding: "6px 38px",
                        }}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>

      <Dialog
        divider
        open={open}
        fullWidth="true"
        maxWidth="sm"
        onClose={handleClose}
        BackdropProps={{
          background: "#00000022",
          boxShadow: "0px 0px 0px 0px !important",
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent
          style={{ boxShadow: "none" }}
          BackdropProps={{
            style: { boxShadow: "none", transition: "none", width: "100%" },
          }}
        >
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ width: "100%", padding: "25px 25px 0px 25px" }}
          >
            <Box
              className="edit__modal"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginRight: "15px",
              }}
            >
              <span className="success_msg">
                {successMsg} !{/* Candidate Registered Successfully! */}
              </span>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            width: "100%",
            padding: "25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ReusableButton
            size="medium"
            variant="outlined"
            className="qstnUpldPageBttn"
            text="OK"
            onClick={handleClose}
            style={{
              TextAlign: "center",
              color: "#fff",
              backgroundColor: "#00728E",
              "&:hover": { backgroundColor: "#00728E" },
            }}
          />
        </DialogActions>
      </Dialog>

      {/* //privacy_dialogBox */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={privacyPopUp}
        onClose={handleClosePrivacyPopup}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={privacyPopUp}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              className="privacy_header-text"
            >
              Privacy Notice
            </Typography>
            <Box id="transition-modal-description" sx={{ mt: 2 }}>
              <span className="privacy_modal-text">
                As part of any recruitment process, ABSYZ collects and processes
                personal data relating to job applicants. Our organization is
                committed to being transparent about how it collects and uses
                that data and to meeting its data protection obligations.
              </span>

              <Box className="sub_content">
                <span className="privacy_modal-text">
                  The data we are collecting includes:
                </span>
                <ul>
                  <li className="privacy_modal-text-li">
                    Your name, contact details including email address and phone
                    number
                  </li>
                  <li className="privacy_modal-text-li">
                    Qualification, skills
                  </li>
                  <li className="privacy_modal-text-li">
                    College, branch name, CGPA
                  </li>
                  <li className="privacy_modal-text-li">
                    Any other details shared during registration
                  </li>
                </ul>
              </Box>

              <Box className="sub_content-secondary">
                <span className="privacy_modal-text secondary-text">
                  ABSYZ may collect this information in a variety of ways. For
                  example, data might be contained in resumes, application
                  forms, photos collected through interviews or other forms of
                  assessment.
                </span>

                <span className="privacy_modal-text secondary-text second-data">
                  This information will be stored in the HR management system
                  and other IT systems. Your privacy is important to us and we
                  provide utmost care to protect your data. Your information may
                  be shared internally, with the other entities of our group and
                  third parties for the purposes of the recruitment process.
                  This includes members of the Human Resources (HR) and
                  recruitment team, interviewers involved in the recruitment
                  process, clients, Background Check (BGC) vendors if access to
                  the data is necessary for the performance of their roles.
                </span>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default CandidateRegistrationFresher;
