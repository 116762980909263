import * as React from "react";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import "./RecruiterRecruiterDrivePage.css";
import ReusableButton from "../../../CommonComponents/Button/Button";
import { API } from "../../../Networking/API";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import Cookies from "js-cookie";
import ProfilePic from "../../../Assests/Group 766.png";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Logout from "../../../CommonComponents/Logout/Logout";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    textTransform: "capitalize",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiDataGrid-row": {
      textTransform: "capitalize",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const datagridSx = {
  "& .MuiDataGrid-autoHeight": {
    marginRight: "12px !important",
    marginLeft: "12px !important",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "rgb(0, 114, 142) !important",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    color: "#ffffff !important",
    fontSize: "0.9rem !important",
  },
};

export function RecruiterRecruiterDrivePage() {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [dialogue, setDialogue] = useState(false);
  const [pendingtableData, setPendingTableData] = useState("");
  const [completedtableData, setCompletedTableData] = useState("");
  const [penStatus, setPenStatus] = useState("pending");
  const [comStatus, setComStatus] = useState("completed");
  const [recruiterId, setRecruiterId] = useState("");
  const [recruitedAlone, setRecruiterAlone] = useState([]);
  const [pageNumberPending, setpageNumberPending] = useState(1);
  // let pageNumberCompleted=1;
  const [pageNumberCompleted, setpageNumberCompleted] = useState(1);
  const [selectedRecruiterData, setSelectedRecruiterData] = useState([]);
  // const [firstIndexData, setFirstIndexData] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const image = localStorage.getItem("image");
  const [done, setDone] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const navigate = useNavigate();

  const handleSubmit = () => {
    setOpen(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    getRecruitmentPendingDrive(penStatus, pageNumberPending);
  }, []);

  const getRecruitmentPendingDrive = async (penStatus, pageNumber) => {
    if (pendingtableData.length < 0) {
      setDone(true);
    }
    API.getPendingDriveData(penStatus, pageNumber)
      .then((response) => {
        if (response.data.status === true) {
          const data = response.data.data.rows;
          const updatedData = data.map((items, index) => {
            const time = moment(items.drive_time, ["HH:mm"]).format("hh:mm A");
            return {
              sno: (pageNumber - 1) * 5 + (index + 1),
              id: items.id,
              DriveName: items.drive_name,
              UniversityCollage:
                items.college_name === null
                  ? items.institute_name
                  : items.college_name,
              Location: items.location.toLowerCase(),
              Date: items.drive_date,
              Time: time,
              Status:
                items.status === 2
                  ? "Dismissed"
                  : items.is_hold === 1
                  ? "Hold"
                  : items.drive_running_status,
              ActiveStatus: items.status,
              isHold: items.is_hold,
            };
          });
          // setPageCount(response.data.data.count);
          setpageNumberPending(response.data.data.count);
          setPendingTableData(updatedData);
          setDone(true);
        } else {
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "error",
          });
          setDone(true);
        }
      })
      .catch((error) => {
        // setDone(true);
      });
  };

  const getRecruitmentCompletedDrive = (comStatus, pageNumber) => {
    if (completedtableData.length < 0) {
      setDone(true);
    }
    // setDone(false);

    API.getCompletedDriveData(comStatus, pageNumber)
      .then((response) => {
        if (response.data.status) {
          const data = response.data.data.rows;
          const updatedData = data.map((items, index) => {
            const time = moment(items.drive_time, ["HH:mm"]).format("hh:mm A");
            return {
              // sno: index + 1 +(pageIndex * 5),
              sno: (pageNumber - 1) * 5 + (index + 1),
              id: items.id,
              DriveName: items.drive_name,
              UniversityCollage:
                items.college_name === null
                  ? items.institute_name
                  : items.college_name,
              Location:
                items.location[0].toUpperCase() +
                items.location.toLowerCase().substring(1),
              Date: items.drive_date,
              Time: time,
              Status:
                items.status === 2
                  ? "Dismissed"
                  : items.is_hold === 1
                  ? "Hold"
                  : items.drive_running_status,
              ActiveStatus: items.status,
              isHold: items.is_hold,
            };
          });
          setpageNumberCompleted(response.data.data.count);
          setCompletedTableData(updatedData);
          setDone(true);
        } else {
          setDone(true);
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "error",
          });
        }
      })
      .catch(() => {
        // setDone(true);
      });
  };

  const onClickNavigation = async (driveId) => {
    const driveCall = async () => {
      let variables = 0;
      await API.getOneDriveData(driveId).then(({ response }) => {
        if (response.data.status === true) {
          variables = response.data.data;
        }
      });
      return variables;
    };
    const oneDriveData = await driveCall();

    const allCandidates = oneDriveData.candidates;
    const updatedData = allCandidates.map((items, index) => ({
      id: items.id,
      Candidates: items.first_name,
    }));

    navigate("/recruiter-drive-candidates-result", {
      state: {
        driveData: oneDriveData,
        candidate: updatedData,
        DriveID: driveId,
      },
    });
  };

  const handleCompletedDriveClick = async (driveId) => {
    setDone(false);
    const driveCall = async () => {
      let variables = [];
      await API.getOneDriveData(driveId).then(({ response }) => {
        if (response.data.status === true) {
          variables = response.data.data;
        }
      });
      return variables;
    };
    const oneDriveData = await driveCall();

    const candidateCall = async () => {
      let variable = 0;
      await API.getCandidateOnDrive(driveId).then((response) => {
        if (response.data.status === true) {
          const data = response.data.data.rows;
          const updatedData = data.map((items, index) => ({
            id: index + 1,
            Candidates: items.first_name,
          }));
          variable = updatedData;
        }
      });
      return variable;
    };
    const candidateData = await candidateCall();

    navigate("/recruiter-drive-candidates-result", {
      state: {
        driveData: oneDriveData,
        candidate: candidateData,
        isDone: done,
      },
    });
  };

  const rowColumns = [
    {
      field: "sno",
      headerName: "S.No",
      align: "center",
      headerClassName: "RectSno-header",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "DriveName",
      headerName: "Drive Name",
      align: "left",
      cellClassName: "pending-tableCapital",
      renderCell: (params) => (
        <>
          {/* <span
            style={{ cursor: "pointer", color: "#00728E" }}
            onClick={() => onClickNavigation(params.id)}
          >
            {params.row.DriveName}
          </span> */}

          {params.row.ActiveStatus === 2 ? (
            <div
            // style={{
            //   // pointerEvents: "none",
            //   color: "rgba(0, 0, 0, 0.87)",
            //   textOverflow: "ellipsis",
            //   overflow: "hidden",
            //   whiteSpace: "nowrap",
            // }}
            >
              <span>{params.row.DriveName}</span>
            </div>
          ) : (
            <div
              onClick={() => onClickNavigation(params.id)}
              style={{
                color: "rgba(0, 0, 0, 0.87)",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "100px",
              }}
            >
              <span style={{ cursor: "pointer", color: "#00728E" }}>
                {params.row.DriveName}
              </span>
            </div>
          )}
        </>
      ),
      flex: 1,
    },
    {
      field: "UniversityCollage",
      headerName: "University/Colleges",
      align: "left",
      cellClassName: "pending-tableCapital",
      flex: 1,
    },
    {
      field: "Location",
      headerName: "Location",
      cellClassName: "pending-tableCapital",
      align: "left",
      width: 130,
    },
    { field: "Date", headerName: "Date", align: "left", width: 100 },
    { field: "Time", headerName: "Time", align: "left", width: 100 },
    {
      field: "Status",
      headerName: "Status",
      cellClassName: "pending-tableCapital",
      align: "left",
      width: 100,
    },
  ];

  const completeColumns = [
    {
      field: "sno",
      headerName: "S.No",
      align: "center",
      headerClassName: "RectSno-header",
      headerAlign: "left",
    },
    {
      field: "DriveName",
      headerName: "Drive Name",
      align: "left",
      headerClassName: "recrtSno-header",
      cellClassName: "DriveName-Styles",
      // width: 70,
      //       renderCell: (params) => (
      //         <>
      //           {" "}

      //           <span style={{ cursor: "pointer" }}
      //           onClick={() => handleCompletedDriveClick(params.id)}>{params.row.DriveName}</span>
      //         </>
      //       ),
      //       width: 130,
      //  },
      //  ---------------------------------------------
      renderCell: (params) => (
        <>
          {params.row.ActiveStatus === 2 ? (
            <div
              style={{
                pointerEvents: "none",
                color: "rgba(0, 0, 0, 0.87)",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <span>{params.row.DriveName}</span>
            </div>
          ) : (
            <div
              style={{
                cursor: "pointer",
                color: "#00728E",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "110px",
              }}
            >
              <span onClick={() => handleCompletedDriveClick(params.id)}>
                {params.row.DriveName}
              </span>
            </div>
          )}
        </>
      ),
      flex: 1,
    },

    // ------------------------------------------------
    {
      field: "UniversityCollage",
      headerName: "University/Colleges",
      align: "left",
      headerAlign: "left",
      headerClassName: "unviersty_Styles",
      cellClassName: "pending-tableCapital universty_Cell-Styles",
      // width: 150,
    },
    {
      field: "Location",
      headerName: "Location",
      headerClassName: "unviersty_Styles",
      cellClassName: "universty_Cell-Styles",
      align: "left",
    },
    {
      field: "Date",
      headerName: "Date",
      headerClassName: "unviersty_Styles",
      cellClassName: "universty_Cell-Styles",
      align: "left",
    },
    {
      field: "Time",
      headerName: "Time",
      headerClassName: "unviersty_Styles",
      cellClassName: "universty_Cell-Styles",
      align: "left",
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      getRecruitmentPendingDrive(penStatus, 1);
    } else {
      setDone(false);
      getRecruitmentCompletedDrive(comStatus, 1);
    }
  };

  const handleHomeClick = () => {
    navigate("/recruiter-home");
  };

  const handleCloseMenu = () => {
    navigate("/");
    Cookies.remove("jwtToken");
  };

  const handleCloseMenuBar = () => {
    setAnchorEl(null);
  };

  let pageNumber = 1;

  const handlependingClick = async (e, pageNumber) => {
    // pageNumber = e.selected;
    getRecruitmentPendingDrive("pending", e.selected + 1);
  };

  const handleCompletedClick = async (e, pageNumber) => {
    // pageNumber = e.selected;
    getRecruitmentCompletedDrive("completed", e.selected + 1);
  };

  return (
    <>
      <Box className="Recruiter-main-container">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            marginBottom: "8px",
          }}
        >
          <div
            className="RecruiterDrives-about-breadcrumbs"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "5%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  "&:hover": { textDecoration: "none", color: "#fff" },
                }}
                color="inherit"
                href="/recruiter-home"
              >
                <WestIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Home
              </Link>
              <Link
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  "&:hover": { textDecoration: "none", color: "#fff" },
                }}
                color="inherit"
              >
                Drives
              </Link>
            </Breadcrumbs>
          </div>
          <div style={{ marginRight: "4%" }}>
            <Logout />
          </div>
        </div>
        <Box className="Recruiter-container">
          <Box className="Header">
            <span>Drives</span>
          </Box>
          <Box style={{ display: "flex" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="recruitertabs"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab
                label="Pending"
                style={{ textTransform: "capitalize" }}
                {...a11yProps(0)}
              />
              <Tab
                label="Completed"
                style={{ textTransform: "capitalize" }}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          {!done ? (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#00728E"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <>
              <TabPanel value={value} index={0}>
                <Box
                  className="Recruiter-table"
                  style={{
                    height: "100%",
                    width: "94%",
                    marginTop: "10px",
                    marginLeft: "28px",
                    marginBottom: "60px",
                  }}
                >
                  <DataGrid
                    rows={pendingtableData}
                    autoHeight
                    columns={rowColumns}
                    getRowClassName={(params) =>
                      `dismissed${params.row.ActiveStatus}`
                    }
                    sx={datagridSx}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    hideFooterSelectedRowCount={true}
                    hideFooterPagination={true}
                    hideFooter={true}
                  />
                  {pendingtableData.length !== 0 ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        marginRight: "20px",
                      }}
                    >
                      <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlependingClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageCount={pageNumberPending / 5}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </Box>
                  ) : null}
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box
                  className="Recruiter-table"
                  style={{
                    height: "100%",
                    width: "94%",
                    marginTop: "10px",
                    marginLeft: "28px",
                    marginBottom: "60px",
                  }}
                >
                  <DataGrid
                    rows={completedtableData}
                    columns={completeColumns}
                    pageSize={5}
                    autoHeight
                    getRowClassName={(params) =>
                      `dismissed${params.row.ActiveStatus}`
                    }
                    sx={datagridSx}
                    rowsPerPageOptions={[5]}
                    hideFooterSelectedRowCount={true}
                    hideFooterPagination={true}
                    hideFooter={true}
                  />
                  {completedtableData.length !== 0 ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // paddingBottom:"10%",
                        marginTop: "20px",
                        marginRight: "20px",
                      }}
                    >
                      <ReactPaginate
                        nextLabel=">"
                        onPageChange={handleCompletedClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageCount={pageNumberCompleted / 5}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </Box>
                  ) : null}
                </Box>
              </TabPanel>
            </>
          )}
        </Box>
      </Box>
      {/* --------------------------------- */}

      {/* <RecruiterDriveCandidatesResult>
    open={open}
          handleClose={}
    </RecruiterDriveCandidatesResult> */}
    </>
  );
}
