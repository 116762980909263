import React, { useEffect, useState } from "react";
import { Cards } from "../../../CommonComponents/Cards/Cards";
import CardImgOne from "../../../Assests/Rectangle 33.png";
import CardImgTwo from "../../../Assests/Rec.png";
import CardImgThree from "../../../Assests/verbal.png";
import CardImgFour from "../../../Assests/CardImgFour.png";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "./CandidateQuizSection.css";
import ReusableButton from "../../../CommonComponents/Button/Button";
import WestIcon from "@mui/icons-material/West";
import ResuableDialogBox from "../../../CommonComponents/DialogBox/DialogBox";
import Button from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { API } from "../../../Networking/API";
import ReactLoading from "react-loading";
import Notification from "../../../CommonComponents/Notification/Notification";
import Cookies from "js-cookie";
import moment from "moment";
import { useLocation } from "react-router-dom";

export const CandidateQuizSection = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [attendedDetails, setAttendedDetails] = useState([]);
  const [backBtnOpen, setBackBtnOpen] = useState(false);
  const [sectionType, setSectionType] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [allQuestions, setAllQuestions] = useState([]);
  const [done, setDone] = useState(undefined);
  const [quizCatID, setQuizCatId] = useState("");
  const [candidateId, setCandidateId] = useState(
    localStorage.getItem("candidateId")
  );
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const BaseImageUrl = process.env.REACT_APP_S3_STATIC_URL;

  var sectionClicked = false;
  const handleBackBtnOpen = () => setBackBtnOpen(true);
  const handleBackBtnClose = () => {
    setBackBtnOpen(false);
    Cookies.remove("jwtToken");
    Cookies.remove("role");
    localStorage.removeItem("candidateID");
    localStorage.removeItem("driveID");
    localStorage.removeItem("experience");
  };

  useEffect(() => {
    getQuizCategory();
  }, []);

  const getQuizCategory = () => {
    API.getQuizCategoryByCandidate(candidateId).then((response) => {
      if (response.data.status) {
        setDone(true);
        const data = response.data.data;
        const updatedData = data.map((each) => {
          let secs;
          let timer;
          if (each.tracking !== null) {
            const consumedTime = each.tracking.consumed_time_in_minutes
              .toString()
              .split(".");

            if (consumedTime[1] !== undefined) {
              if (consumedTime[1].length === 1) {
                secs = `${consumedTime[0]}.${consumedTime[1]}0`;
              } else {
                secs = each.tracking.consumed_time_in_minutes;
              }
            } else {
              secs = each.tracking.consumed_time_in_minutes;
            }
            const consumed_timer = secs.toString().replace(".", ":");
            var endTime = moment(`${each.quiz_time_in_minutes}:00`, "mm:ss");
            var startTime = moment(`${consumed_timer}`, "mm:ss");
            var duration = moment.duration(endTime.diff(startTime));
            var minutes = parseInt(duration.asMinutes()) % 60;
            var seconds = parseInt(duration.asSeconds()) % 60;
            timer = { Minutes: minutes, Seconds: seconds };
          } else {
            timer = { Minutes: `${each.quiz_time_in_minutes}`, Seconds: "00" };
          }
          return {
            id: each.id,
            name: each.quiz_category_name,
            quizCategoryImageUrl: each.quiz_category_image_url,
            quizCategoryBgImageUrl: each.quiz_category_bg_image_url,
            status:
              each.status === 0 || each.tracking === null
                ? "Not Started"
                : each.tracking.is_question_submitted
                ? "Completed"
                : "Started",
            duration: each.quiz_time_in_minutes,
            url:
              process.env.REACT_APP_S3_STATIC_URL +
                each.quiz_category_image_url ?? CardImgOne,
            isSubmitted:
              each.tracking !== null
                ? each.tracking.is_question_submitted
                : false,
            isAttended: each.tracking,
            trackingTime:
              each.tracking !== null
                ? localStorage.setItem(
                    "QuizId" + each.id,
                    JSON.stringify(timer)
                  )
                : localStorage.setItem(
                    "QuizId" + each.id,
                    JSON.stringify(timer)
                  ),
            trackingStatus:
              each.tracking !== null
                ? localStorage.setItem("trackingStatus" + each.id, true)
                : localStorage.setItem("trackingStatus" + each.id, false),
          };
        });
        setSectionType(updatedData);

        const onlyForAttended = data.map((items, index) => ({
          id: items.id,
          isAttended: items.tracking,
        }));
        setAttendedDetails(onlyForAttended);
      } else {
        setDone(true);
        setErrorMsg(response.data.message);
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "error",
        });
      }
    });
  };

  const handleLinkClick = (quizCatid) => {
    setQuizCatId(quizCatid);
    sectionClicked = true;

    const filterData = sectionType.find((items) => {
      return items.id === quizCatid;
    });

    const selectedSectionName = filterData.name;
    const selectedSectionBgbefore = filterData.quizCategoryBgImageUrl;

    navigate("/quiz", {
      state: {
        QuizCategoryId: quizCatid,
        sectionType: sectionType,
        sectionQuizCatBg: sectionType.quiz,
        sectionClicked: true,
        attendedDetails: attendedDetails,
        selectedSectionName: selectedSectionName,
        selectedSectionBgbefore: selectedSectionBgbefore,
      },
    });
  };

  return (
    <>
      <div style={{ overflow: "hidde !important" }}>
        <Box
          className="quiz-section-wrapper"
          sx={{
            padding: "10px 90px 90px 90px",
            overflow: "hidden !important",
            "@media screen and (min-width: 320px) and (max-width:768px)": {
              padding: "30px",
            },
          }}
        >
          <ReusableButton
            startIcon={<WestIcon />}
            onClick={handleBackBtnOpen}
            text="Back"
            style={{
              color: "#000",
              background: "none",
              fontWeight: "700",
              border: "none",
              boxShadow: "none",
              textTranform: "capitalize",
              padding: "10px !important",
              "&:hover": {
                background: "none",
                border: "none",
                boxShadow: "none",
                cursor: "pointer",
              },
            }}
          />

          <Paper
            elevation={1}
            sx={{
              padding: "30px 40px 40px 40px",
              background: "#fff",
              borderRadius: "0px",
              "@media screen and (min-width: 320px) and (max-width:768px)": {
                padding: "20px 20px 90px 20px",
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
          >
            <Grid container spacing={5}>
              {!done ? (
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "70vh",
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#00728E"}
                    height={100}
                    width={100}
                  />
                </Box>
              ) : (
                <>
                  {sectionType.length === 0 ? (
                    <>
                      <Box
                        style={{
                          width: "100%",
                          height: "40vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span className="error-text-quiz-category">
                          {errorMsg}
                        </span>
                      </Box>
                    </>
                  ) : (
                    <>
                      {sectionType.map((items, index) => {
                        const images =
                          items.name === "Aptitude"
                            ? CardImgOne
                            : items.name === "Technical"
                            ? CardImgFour
                            : items.name === "Verbal"
                            ? CardImgThree
                            : CardImgTwo;

                        return (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              key={index}
                              style={{ overflow: "hidden !important" }}
                            >
                              <Button
                                underline="none"
                                sx={{
                                  cursor: "pointer",
                                  pointerEvents:
                                    items.isSubmitted === true
                                      ? "none"
                                      : "auto",
                                }}
                                disabled={items.isSubmitted}
                                onClick={() => handleLinkClick(items.id)}
                              >
                                <Cards
                                  className="quiz-section-cards"
                                  // image={`${BaseImageUrl}${items.quizCategoryImageUrl}`}
                                  image={images}
                                  height="110"
                                  width="80"
                                  style={{
                                    borderRadius: "0px",
                                    opacity:
                                      items.isSubmitted === true ? 0.5 : 1,
                                  }}
                                  content={
                                    <div className="card-content">
                                      <span className="section-name-text">
                                        {items.name}
                                      </span>
                                      <div className="card-sub-content">
                                        <span className="status-text">
                                          Status : {items.status}
                                        </span>
                                        <span className="duration-text">
                                          Duration : {items.duration} mins
                                        </span>
                                      </div>
                                    </div>
                                  }
                                />
                              </Button>
                            </Grid>
                          </>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </Grid>
          </Paper>
        </Box>

        <ResuableDialogBox
          open={backBtnOpen}
          handleClose={handleBackBtnClose}
          modalText="Are you sure you want to logout?"
          btnText1="No"
          btnText2="Yes"
          path="/candidate-login"
        />
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
};
