import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import AdminSignUpLogo from "../../../src/Assests/AdminSignUpLogo.png";
import AdminSignUpLogo from "../../../src/Assests/Group286.png";
import CrackItLogo from "../../../src/Assests/CrackItLogo.png";
import Crack from "../../../src/Assests/Crack.png";
import It from "../../../src/Assests/IT.png";
import ReusableButton from "../../CommonComponents/Button/Button";
import ReusableInputfield from "../../CommonComponents/TextField/TextField";
import "./ForgotPassword.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import { API } from "../../Networking/API";
import Notification from "../../CommonComponents/Notification/Notification";
import { MyForm } from "../../CommonComponents/MyForm/MyForm";

const initialValues = {
  email: "",
};

export function ForgotPassword() {
  const navigate = useNavigate();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (inputValues = values) => {
    let code = { ...errors };
    const emailValidation =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if ("email" in inputValues)
      code.email = inputValues.email
        ? emailValidation.test(inputValues.email)
          ? ""
          : "Email is not valid"
        : "Email is required";

    setErrors({
      ...code,
    });
 
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const {
    values,
    // setValues,
    handleInputChange,
    errors,
    setErrors,
    // resetForm,
    // validateOnEdit,
  } = MyForm(initialValues, true, validate);

  const handleSendLink = () => {
    if (validate()) {
      API.sendForgotLink(values.email).then(({ response }) => {
        if (response.status) {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "success",
          });
          setTimeout(() => {
            navigate("/");
          }, 3000);
        } else {
          setNotify({
            isOpen: true,
            message: response.message,
            type: "error",
          });
        }
      });
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="Admin-rest-Page-Outer">
      <Grid container spacing={2} style={{}}>
        <Card
          //   variant="outlined"
          sx={{
            marginTop: "8%",
            Width: "100%",
            borderRadius: 0,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "50px",
          }}
        >
          <CardContent style={{ padding: "0px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} style={{}}>
                <img
                  src={AdminSignUpLogo}
                  alt="AdminContentPic"
                  width="300"
                  height="100%"
                />
              </Grid>
              <Grid
                item
                xs={5}
                // sm={6}
                // md={6}
                style={{
                  padding: "30px 25px",
                  marginLeft: "30px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "50px",
                  }}
                >
                  <img
                    src={CrackItLogo}
                    alt="logo"
                    width="80"
                    height="80"
                    marginTop="32px"
                  ></img>
                </div>
                <div
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <img src={Crack} alt="logo" width="80" height="17"></img>{" "}
                    &nbsp;
                    <img src={It} alt="logo" width="20" height="20"></img>
                  </span>
                </div>
                <form>
                  <div
                    className="text-name"
                    // style={{marginTop:"15px"}}
                  >
                    Forgot Password
                  </div>
                  <div
                    //   className="inputBox_text-name"
                    style={{
                      marginTop: "9px",
                      marginBottom: "7px",
                      display: "flex",
                      justifyContent: "start",
                      fontSize: "16px",
                      fontFamily: "Helvetica",
                      fontStyle: "normal",                     
                      fontWeight: 400,
                      lineHeight: "23px",
                      color: "#424242",
                    }}
                  >
                    Enter Email
                  </div>
                  <>
                    <ReusableInputfield
                      type="text"
                      name="email"
                      placeholder="Enter Email"
                      value={values.email}
                      onChange={handleInputChange}
                      error={errors.email ? true : false}
                      helperText={errors.email}
                    />
                  </>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginTop: "20px" }}>
                      <ReusableButton
                        text={"Get link"}
                        endIcon={<ArrowRightAltIcon />}
                        onClick={handleSendLink}
                        variant="contained"
                        style={{
                          // border: "1px solid #00728E",
                          background: "#00728E",
                          borderRadius: "4px",
                          fontFamily: "Helvetica",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          textTransform: "capitalize !important",
                          "&:hover": { backgroundColor: "#00728E" },
                          padding: "6px 38px",
                        }}
                      />
                    </div>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}
