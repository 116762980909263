import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ReactLoading from "react-loading";
import Fade from "@mui/material/Fade";
import GoogleMapReact from "google-map-react";
import { useCallback, useRef } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindow,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import ReusableButton from "../../../CommonComponents/Button/Button";

const apiMaps = "AIzaSyDwOeav_33UjrA28kwyXPnBa6eEKx_Hm5c";

const libraries = ["places"];
const mapContainerStyle = {
  height: "69vh",
  width: "100%",
  filter: "grayscale(20%)",
};

const options = {
  disableDefaultUI: true,

  zoomControl: true,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 680,
  bgcolor: "background.paper",
  padding: "0px 0px 20px 0px",
};

export default function RecruiterMapModal(props) {
  const { OpenModal, MapLocation, setModalOpen, SelectedCandidate } = props;
  // MapLocation = null;
  const [address, setAddress] = useState({
    city: null,
    Country: null,
    locality: null,
    state: null,
    PostalCode: null,
  });
  const [LocationError, SetLocationError] = useState(false);
  const [Lattitude, setLattitude] = useState(null);
  const [Longitude, setLongitude] = useState(null);
  Geocode.setApiKey(apiMaps);
  if (MapLocation) {
    Geocode.fromLatLng(
      `${MapLocation.split(",")[0]}`,
      `${MapLocation.split(",")[1]}`
    ).then(
      (response) => {
        setLattitude(parseFloat(MapLocation.split(",")[0]));
        setLongitude(parseFloat(MapLocation.split(",")[1]));
        let city = null;
        let country = null;
        let locality = null;
        let state = null;
        let PostalCode = null;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                address.city = city;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                address.Country = country;
              case "administrative_area_level_3":
                if (
                  response.results[0].address_components[i].long_name !==
                  country
                ) {
                  locality =
                    response.results[0].address_components[i].long_name;
                  address.locality = locality;
                }
              case "administrative_area_level_1":
                if (
                  response.results[0].address_components[i].long_name !==
                  country
                ) {
                  state = response.results[0].address_components[i].long_name;
                  address.state = state;
                }
              case "postal_code":
                PostalCode =
                  response.results[0].address_components[i].long_name;
                address.PostalCode = PostalCode;
            }
          }
        }
        SetLocationError(true);
      },
      (error) => {
        SetLocationError(false);
        console.error(error);
      }
    );
  }

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDwOeav_33UjrA28kwyXPnBa6eEKx_Hm5c",
    libraries,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);
  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  const Name = SelectedCandidate?.firstName;
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "10%",
        }}
      ></Box>
      <Modal
        open={OpenModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {MapLocation === null && Lattitude === null && Longitude === null ? (
            <Box
              sx={{ flexGrow: 2 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "55vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#00728E"}
                height={100}
                width={100}
              />
            </Box>
          ) : LocationError ? (
            <Box style={{ padding: "0px 0px 10px 0px" }}>
              <div className="map">
                <GoogleMap
                  id="map"
                  mapContainerStyle={mapContainerStyle}
                  zoom={14}
                  center={{
                    lat: Lattitude,
                    lng: Longitude,
                  }}
                  options={options}
                  onLoad={onMapLoad}
                >
                  <MarkerF
                    key={`${Lattitude * Longitude}`}
                    position={{
                      lat: Lattitude,
                      lng: Longitude,
                    }}
                  />
                  <InfoWindow
                    style={{ backgroundColor: "#f0ebee" }}
                    position={{
                      lat: Lattitude,
                      lng: Longitude,
                    }}
                  >
                    <div
                      style={{
                        height: "100% !important",
                        width: "100% !important",
                        padding: "6px 16px 5px 5px",
                        backgroundColor: "#dfd8d8",
                      }}
                    >
                      <div className="ModalContentDiv">
                        <p className="ModalCandidateName">{Name}</p>
                      </div>
                      <div className="Modaldetails">
                        <p className="CandidateDetailsHeading" style={{marginBottom: "10px"}}>Details</p>
                        <div className="ModalAddress">
                          <p className="AddressHeader">Address</p>
                          <p>
                            {address?.locality} , {address?.city}
                          </p>
                          <p>{address?.state}</p>
                          <p>{address?.Country}</p>
                          <p style={{ marginBottom: "0pc !important" }}>
                            {address?.PostalCode}
                          </p>
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                </GoogleMap>
              </div>
            </Box>
          ) : (
            <Box className="LocationError">
              <p>Error While displaying the Candidate Location</p>
            </Box>
          )}
          <Box style={{ width: "100%", padding: "10px 20px 0px 0px" }}>
            <ReusableButton
              text={"cancel"}
              variant="outlined"
              onClick={() => {
                setModalOpen(false);
              }}
              style={{
                border: "1px solid #00728E",
                borderRadius: "4px",
                fontFamily: "Helvetica",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                color: "White",
                backgroundColor: "#00728E",
                className: "ModalCancelBtn",
                textTransform: "capitalize !important",
                "&:hover": { backgroundColor: "#00728E" },
                float: "right",
              }}
            />
          </Box>
        </Box>

        {/* </Fade> */}
      </Modal>
    </div>
  );
}
