import "./App.css";
import Routers from "./Router/router";

// write all the routes in Routers file
// all resuable components will be in common component folder

import { RecruiterLandingPage } from "./Screens/Recruiter/RecruiterLandingPage/RecruiterLandingPage.jsx";

import { RecruiterUploadCandidatesList } from "./Screens/Recruiter/RecruiterUploadCandidatesList/RecruiterUploadCandidatesList.jsx";

function App() {
  return (
    <>
      <Routers />

      {/* <AdminCompletedCandidatesRecruited/> */}
      {/* <RecruiterUploadCandidatesList/> */}
    </>
  );
}

export default App;
