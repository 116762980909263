import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import CandidateInstructions from "../Screens/Candidate/CandidateLandingPage/CandidateInstructions";
import CandidateLandingPage from "../Screens/Candidate/CandidateLandingPage/CandidateLandingPage";
import { Quiz } from "../Screens/Candidate/CandidateQuizSection/Quiz";
import { CandidateQuizSection } from "../Screens/Candidate/CandidateQuizSection/CandidateQuizSection";
// import { AdminForgetPassword } from "../Screens/AdminForgetPassword/AdminForgetPassword";
// import { RecruiterLandingPage } from "../Screens/Recruiter/RecruiterLandingPage/RecruiterLandingPage";
import { QuizCompleted } from "../Screens/Candidate/CandidateQuizSection/QuizCompleted";
import Login from "../Screens/Login/Login";
import ResetPassword from "../Screens/SignUp/SignUp";
import ProtectedRoute from "../Screens/ProtectedRoute/ProtectedRoute";
import AdminLandingPage from "../Screens/Admin/AdminLandingPage/AdminLandingPage";
import { AdminDriveTable } from "../Screens/Admin/AdminRecuitmentDrivePage/AdminDriveTable";
import { AdminRecruitersList } from "../Screens/Admin/AdminRecruiterPage/AdminRecruitersList";
import { AdminForgetPassword } from "../Screens/AdminForgetPassword/AdminForgetPassword.jsx";
import { AdminCompletedCandidatesRecruited } from "../Screens/Admin/AdminRecuitmentDrivePage/AdminCompletedCandidatesRecruited";
import { ForgotPassword } from "../Screens/ForgotPassword/ForgotPassword";
import { RecruiterRecruiterDrivePage } from "../Screens/Recruiter/RecruiterRecruitmentDrivePage/RecruiterRecruitmentDrivePage";
import { RecruitmentDriveDetails } from "../Screens/Recruiter/RecruiterRecruitmentDrivePage/RecruitmentDriveDetails";
import { RecruiterUploadCandidatesList } from "../Screens/Recruiter/RecruiterUploadCandidatesList/RecruiterUploadCandidatesList";
import { RecruiterLandingPage } from "../Screens/Recruiter/RecruiterLandingPage/RecruiterLandingPage";
import { RecruiterCandidateHired } from "../Screens/Recruiter/RecruiterCandidateHired/RecruiterCandidateHired";
import SignUp from "../Screens/SignUp/SignUp.jsx";
import { RecruiterCandidateHiredAddCandidates } from "../Screens/Recruiter/RecruiterCandidateHired/RecruiterCandidateHiredAddCandidates";
import { AdminUploadQuiz } from "../Screens/Admin/AdminUploadQuestions/AdminUploadQuiz";
import { RecruiterDriveCandidatesResult } from "../Screens/Recruiter/RecruiterRecruitmentDrivePage/RecruiterDriveCandidatesResult";
import { RecruiterOnGoingDrive } from "../Screens/Recruiter/RecruiterOnGoingDrive/RecruiterOnGoingDrive";
import MyComponent from "../Screens/Candidate/TestingComponent";
import RedirectingPage from "../Screens/Candidate/CandidateLandingPage/RedirectingPage";
import CandidateRegistrationFresher from "../Screens/Candidate/CandidateRegistration/CandidateRegistrationFresher";
import CandidateRegistrationTrained from "../Screens/Candidate/CandidateRegistration/CandidateRegistrationTrained";
import IsExpired from "../Screens/Candidate/CandidateRegistration/isExpired";

const roles = {
  ADMIN: 0,
  RECRUITER: 1,
  USER: 2,
};

const Routers = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/candidate-registration-fresher" element={<CandidateRegistrationFresher />} />
          <Route exact path="/candidate-registration-trained" element={<CandidateRegistrationTrained />} />
          <Route exact path="/candidate-registration" element={<IsExpired />} />
          <Route exact path="/redirecting" element={<RedirectingPage />} />

          <Route exact path="/reset_password" element={<ResetPassword />} />

          <Route
            exact
            path="/reset-password"
            element={<AdminForgetPassword />}
          />
          <Route exact path="/forget-Password" element={<ForgotPassword />} />

          <Route
            exact
            path="/candidate-login"
            element={<CandidateLandingPage />}
          />

          {/* =======================================================================
                  Candidate Routing
              ======================================================================= */}
          <Route element={<ProtectedRoute allowedRoles={roles.USER} />}>
            <Route
              exact
              path="/user-instruction"
              element={<CandidateInstructions />}
            />
            <Route
              exact
              path="/quiz-section"
              element={<CandidateQuizSection />}
            />
            <Route exact path="/quiz" element={<Quiz />} />
            <Route exact path="/quiz-completed" element={<QuizCompleted />} />
            <Route exact path="/testing" element={<MyComponent />} />
            <Route path="*" element={<Navigate to="/quiz-section" />} />
          </Route>

          {/* =======================================================================
                  Admin Routing
              ======================================================================= */}

          <Route element={<ProtectedRoute allowedRoles={roles.ADMIN} />}>
            <Route exact path="/admin-home" element={<AdminLandingPage />} />
            <Route
              exact
              path="/admin-recruiting-drive"
              element={<AdminDriveTable />}
            />
            <Route
              exact
              path="/admin-recruiters-list"
              element={<AdminRecruitersList />}
            />
            <Route
              exact
              path="/admin-completed-candidates-list"
              element={<AdminCompletedCandidatesRecruited />}
            />
            <Route
              exact
              path="/admin-Upload-Quiz"
              element={<AdminUploadQuiz />}
            />

            <Route path="*" element={<Navigate to="/admin-home" />} />
          </Route>

          {/* ------RECRUITER----- */}

          <Route element={<ProtectedRoute allowedRoles={roles.RECRUITER} />}>
            <Route
              exact
              path="/recruiter-home"
              element={<RecruiterLandingPage />}
            />
            <Route
              exact
              path="/recruiter-candidate-list"
              element={<RecruiterUploadCandidatesList />}
            />
            <Route
              exact
              path="/recruiter-recruitment-Drive"
              element={<RecruiterRecruiterDrivePage />}
            />
            <Route
              exact
              path="/recruiter-candidates-hired"
              element={<RecruiterCandidateHired />}
            />
            <Route
              exact
              path="/recruiter-candidates-hired-add-candidates"
              element={<RecruiterCandidateHiredAddCandidates />}
            />
            <Route
              exact
              path="/recruiter-drive-candidates-result"
              element={<RecruiterDriveCandidatesResult />}
            />
            <Route
              exact
              path="/recruiter-ongoing-drive"
              element={<RecruiterOnGoingDrive />}
            />

            <Route path="*" element={<Navigate to="/recruiter-home" />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default Routers;
