import React, { useEffect, useState } from "react";
import "./Quiz.css";
import Box from "@mui/material/Box";
import ReusableButton from "../../../CommonComponents/Button/Button";
import Grid from "@mui/material/Grid";
import ResuableDialogBox from "../../../CommonComponents/DialogBox/DialogBox";
import WestIcon from "@mui/icons-material/West";
import { Link, useLocation } from "react-router-dom";
import { API } from "../../../Networking/API";
import QuizHeader from "./QuizHeader";
import Notification from "../../../CommonComponents/Notification/Notification";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText } from "@material-ui/core";
import ReactLoading from "react-loading";
import Aptitudebg from "../../../Assests/aptitudebg.png";
import Verbalbg from "../../../Assests/verbalbg.png";
import BG from "../../../Assests/QuizBackgroundimage.png";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { lightGreen } from "@mui/material/colors";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const checkboxStyle = {
  width: "2vw",
  height: "3vh",
  // border: "1px solid #00728E",
  color: "#fff",
};

const msqOptionsContainer = {
  display: "flex",

  alignItems: "center",
};

const QuizQuestions = React.memo(function QuizQuestions(props) {
  const {
    selectedSection,
    loading,
    handleBackBtnOpen,
    done,
    allQuestionData,
    qstnState,
    countErrMsg,
    lengthOfQuestion,
    handleOptionClick,
    clicked,
    handleAnswerClick,
    handlePrevious,
    open,
    handleClose,
    submitButton,
    handleSubmit,
    handleNext,
    notify,
    setNotify,
    backBtnOpen,
    handleBackBtnClose,
    showModal,
    backFuncApi,
    handleDeslecting,
    msqOption,
    quizCategoryId,
    setTimer,
    setTimerOver,
    selectedSectionBgImage,
    handleGobackFunc,
    unansweredCount,
  } = props;

  const BaseImageUrl = process.env.REACT_APP_S3_STATIC_URL;
  const selectedSectionBg = `${BaseImageUrl}${selectedSectionBgImage}`;

  const BaseImageMobileUrl = process.env.REACT_APP_S3_STATIC_URL;
  const selectedSectionMobileBgImage = `${BaseImageMobileUrl}mobile/${selectedSectionBgImage}`;

  return (
    <>
      <Box
        // className={selectedSection === "Aptitude" ? "apt-container"
        // : selectedSection === "Technical" ? "tech-container"
        // : selectedSection === "Verbal" ? "verbal-container"
        // : selectedSection === "Reasoning" ? "reasoning-container"
        // : "apt-container" }
        className="background-image-for-quiz"
        sx={{
          display: "flex",
          justifyContent: "flexstart",
          backgroundImage: {
            xs: `url(${selectedSectionMobileBgImage})`,
            sm: `url(${selectedSectionBg})`,
            md: `url(${selectedSectionBg})`,
          },
        }}
      >
        {loading === true ? (
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#00728E"}
              height={60}
              width={100}
            />
          </Box>
        ) : (
          <>
            <Box
              className="Quetion-content"
              sx={{
                justifyContent: { xs: "space-around", md: "end" },
                width: { xs: "width: 100%", md: "80%" },
              }}
            >
              <div className="aptitude-text-div">
                <span className="aptitude-text">{selectedSection}</span>
              </div>

              <div
                style={{ marginTop: "10px", marginBottom: "-20px !important" }}
              >
                <p style={{ color: "#fff" }}>
                  <QuizHeader
                    Id={quizCategoryId}
                    setTimer={setTimer}
                    setFinish={setTimerOver}
                  />
                </p>
              </div>
            </Box>

            <Box className="outer-container line-break">
              {done === true ? (
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "85vh",
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#00728E"}
                    height={100}
                    width={100}
                  />
                </Box>
              ) : (
                <>
                  {allQuestionData.map((items, index) => {
                    if (qstnState === index) {
                      return (
                        <>
                          <div
                            className="question-text"
                            onContextMenu={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Box
                              className="question-tag"
                              sx={{
                                fontSize: {
                                  xs: "14px",
                                  sm: "24px",
                                  md: "24px",
                                },
                              }}
                            >
                              <span className="question-no">
                                Question No {index + 1}/{lengthOfQuestion}
                              </span>
                            </Box>
                            <Box
                              className="text"
                              sx={{
                                fontSize: {
                                  xs: "14px",
                                  sm: "24px",
                                  md: "24px",
                                },
                                marginBottom: "2px",
                              }}
                            >
                              <p
                                className="questions-text"
                                onCopy="return false"
                                style={{
                                  paddingBottom: "8px",
                                  fontWeight: 500,
                                  fontSize: "20px",
                                }}
                              >
                                {items.Question}
                              </p>
                              {items.QuestionType === "MSQ" && (
                                <span
                                  style={{
                                    marginTop: "0px",
                                    fontWeight: 200,
                                    fontSize: "18px",
                                  }}
                                  sx={{
                                    fontSize: {
                                      xs: "14px",
                                      sm: "18px",
                                      md: "18px",
                                    },
                                  }}
                                >
                                  Please select one or more options
                                </span>
                              )}
                            </Box>
                          </div>
                          <div
                            className="options-text"
                            onContextMenu={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Grid
                              container
                              spacing={2}
                              className="quiz-buttons"
                            >
                              <>
                                {items.QuestionType === "MSQ" ? (
                                  <>
                                    {items.QuestionOptions?.map(
                                      (each, index) => {
                                        const bgValue =
                                          items.QuestionAnswerId?.includes(
                                            each.id
                                          )
                                            ? "quiz-options-bg-color"
                                            : "quiz-deselcting-color";
                                        return (
                                          <Grid
                                            item
                                            xs={12}
                                            key={index}
                                            style={{
                                              margin: "0px 0px 6px 0px",
                                              paddingTop: "8px",
                                              fontSize: {
                                                xs: "14px",
                                                sm: "24px",
                                                md: "24px",
                                              },
                                            }}
                                            sx={{
                                              fontSize: {
                                                xs: "14px",
                                                sm: "24px",
                                                md: "24px",
                                              },
                                            }}
                                          >
                                            <ReusableButton
                                              key={each}
                                              onClick={() =>
                                                handleOptionClick(
                                                  each.id,
                                                  items.QuestionType,
                                                  qstnState
                                                )
                                              }
                                              variant="contained"
                                              className={bgValue}
                                              style={{
                                                width:
                                                  each.question_answer.length >
                                                  16
                                                    ? "50%"
                                                    : "26%",
                                                margin: "0px 0px 0px 0px",
                                                border: "1px solid #00728E",
                                                boxShadow: "none",
                                                fontSize: "14px",
                                                "&:hover": {
                                                  backgroundColor: "#00728E",
                                                  boxShadow:
                                                    "0px 1px 15px rgba(0, 0, 0, 0.25)",
                                                  color: "#fff",
                                                },
                                              }}
                                              text={
                                                <div
                                                  style={msqOptionsContainer}
                                                >
                                                  <div
                                                  // style={{ marginTop: "5px" }}
                                                  >
                                                    {/* <input
                                                      style={checkboxStyle}
                                                      type="checkbox"
                                                      checked={items.QuestionAnswerId?.includes(
                                                        each.id
                                                      )}
                                                    /> */}
                                                    <Checkbox
                                                      style={checkboxStyle}
                                                      checked={items.QuestionAnswerId?.includes(
                                                        each.id
                                                      )}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      textAlign: "center",
                                                      width: "17vw",
                                                    }}
                                                  >
                                                    {each.question_answer}
                                                  </div>
                                                </div>
                                              }
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {items.QuestionOptions.map(
                                      (each, index) => (
                                        <Grid
                                          item
                                          xs={12}
                                          key={index}
                                          style={{
                                            margin: "0px 0px 6px 0px",
                                            paddingTop: "8px",
                                          }}
                                        >
                                          <ReusableButton
                                            variant="contained"
                                            onClick={() =>
                                              handleAnswerClick(
                                                each.id,
                                                each.question_answer,
                                                items.QuestionAnswerId,
                                                qstnState,
                                                items.QuestionType
                                              )
                                            }
                                            style={{
                                              // width:
                                              //   each.question_answer.length > 16
                                              //     ? "50%"
                                              //     : "26%",
                                              margin: "0px 0px 0px 0px",
                                              backgroundColor:
                                                each.id ===
                                                items.QuestionAnswerId
                                                  ? "#00728E"
                                                  : clicked === each.id
                                                  ? "#00728E"
                                                  : "#fff",

                                              color:
                                                each.id ===
                                                items.QuestionAnswerId
                                                  ? "#fff"
                                                  : clicked === each.id
                                                  ? "#fff"
                                                  : "#424242",

                                              border: "1px solid #00728E",
                                              boxShadow: "none",
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                              wordBreak: "break-word",
                                              width: "260px",
                                              "&:hover": {
                                                backgroundColor: "#00728E",
                                                boxShadow:
                                                  "0px 1px 15px rgba(0, 0, 0, 0.25)",
                                                color: "#fff",
                                              },
                                            }}
                                            text={each.question_answer}
                                          />
                                        </Grid>
                                      )
                                    )}
                                  </>
                                )}
                              </>
                            </Grid>
                          </div>
                          <Box className="toggle-Buttons">
                            {index === 0 ? (
                              <>
                                <Box
                                  className="Button-previous"
                                  style={{ display: "none" }}
                                >
                                  <ReusableButton
                                    variant="outlined"
                                    style={{
                                      width: "140px",
                                      height: "38px",
                                      color: "#00728E",
                                    }}
                                    text="Previous"
                                  />
                                </Box>
                              </>
                            ) : (
                              <>
                                <Box className="Button-previous">
                                  <ReusableButton
                                    variant="outlined"
                                    style={{
                                      width: "140px",
                                      height: "38px",
                                      color: "#00728E",
                                    }}
                                    text="Previous"
                                    onClick={() =>
                                      handlePrevious(items.QuestionId)
                                    }
                                  />
                                </Box>
                              </>
                            )}

                            {index === lengthOfQuestion - 1 ? (
                              <>
                                <Box className="button-next">
                                  <ReusableButton
                                    variant="outlined"
                                    style={{
                                      width: "140px",
                                      height: "38px",
                                      backgroundColor: "#00728E",
                                      color: "white",
                                      "&:hover": {
                                        background: "#00728E",
                                        border: "none",
                                        boxShadow: "none",
                                        cursor: "pointer",
                                      },
                                    }}
                                    text="Submit"
                                    onClick={() =>
                                      handleSubmit(items.QuestionId)
                                    }
                                  ></ReusableButton>
                                  <ResuableDialogBox
                                    open={open}
                                    handleClose={handleClose}
                                    modalText="Are you sure you want to submit this quiz ?"
                                    btnText1="Go back"
                                    btnText2="Continue"
                                    path="/quiz-completed"
                                    submitApiCall={submitButton}
                                    state={quizCategoryId}
                                    handleGoback={handleGobackFunc}
                                    unansweredCount={unansweredCount}
                                  />
                                </Box>
                              </>
                            ) : (
                              <>
                                <Box className="button-next">
                                  <ReusableButton
                                    variant="outlined"
                                    style={{
                                      width: "140px",
                                      height: "38px",
                                      backgroundColor: "#00728E",
                                      color: "white",
                                      "&:hover": {
                                        background: "#00728E",
                                        border: "none",
                                        boxShadow: "none",
                                        cursor: "pointer",
                                      },
                                    }}
                                    text="Next"
                                    onClick={() => handleNext(items.QuestionId)}
                                  ></ReusableButton>
                                </Box>
                              </>
                            )}
                          </Box>
                        </>
                      );
                    }
                  })}
                </>
              )}
            </Box>

            <ResuableDialogBox
              open={backBtnOpen}
              handleClose={handleBackBtnClose}
              modalText="Are you sure you want to go back to previous screen ?"
              btnText1="No"
              btnText2="Yes"
              path="/quiz-section"
              submitApiCall={backFuncApi}
            />
            <Notification notify={notify} setNotify={setNotify} />

            <Dialog
              open={showModal}
              BackdropProps={{
                style: {
                  backgroundColor: "black",
                  opacity: "0.5",
                  boxShadow: "0px 0px 0px 0px !important",
                },
              }}
            >
              <DialogContent>
                <DialogContentText>
                  Quiz Timed Out!! Please click ok to submit
                </DialogContentText>
                <DialogActions>
                  <Link to="/quiz-section" style={{ textDecoration: "none" }}>
                    <ReusableButton
                      style={{
                        float: "right",
                        backgroundColor: "#00728E",
                        textTransform: "capitalize",
                        "&:hover": { backgroundColor: "#00728E" },
                      }}
                      text="Ok"
                      onClick={submitButton}
                    />
                  </Link>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </>
        )}
      </Box>
    </>
  );
});

export default QuizQuestions;
