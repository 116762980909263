import React, {useState} from "react";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled, alpha } from "@mui/material/styles";
import Cookies from "js-cookie";
import ProfilePic from "../../Assests/Group 766.png";
import LogoutIcon from "@mui/icons-material/Logout";
import "./Logout.css";
import logoutIcon from "../../Assests/Group 773.png";
import { useEffect } from "react";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    width: 200,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Logout = () => {
  const [firstName, setFirstName] = useState("");
  useEffect(()=>{
    if(localStorage.getItem("name")!==null)
       setFirstName(localStorage.getItem("name").split(" ")[0]);
  });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const image = localStorage.getItem("image");
  const roleChanges = localStorage.getItem("role");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    navigate("/");
    Cookies.remove("jwtToken");
    Cookies.remove("role");
  };

  const handleCloseMenuBar = () => {
    setAnchorEl(null);
  };

  return (
    // <div className="Admin-section-wrapper">
    <div
      style={{
        display: "flex",
        // width:"84%",
        justifyContent: "end",
      }}
      className="Logout-section-wrapper"
    >
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        startIcon={
          <img
            src={image !== "null" ? image : ProfilePic}
            alt="profileIcon"
            width="24"
            height="24"
          />
        }
        endIcon={<KeyboardArrowDownIcon />}
        className="account-menu-button"
      >
        {firstName}
      </Button>
           
      <StyledMenu
        className="section"
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuBar}
      >
        <MenuItem
          disableRipple
          style={{
            background: "white",
            width: "180px",
            border: "none",
            padding: "0px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div
              style={{
                textAlign: "center",
                background: "#F7F7F7",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "16px 14px 28px 14px",
                pointerEvents: "none",
              }}
            >
              <img
                src={image !== "null" ? image : ProfilePic}
                alt="profileIcon"
                width="30"
                height="30"
                style={{ marginTop: "6px" }}
              />
              <span
                className="profile-name-text"
                style={{ marginTop: "10px", textTransform: "capitalize" }}
              >
                {firstName}
              </span>
              <span className="profile-role-text" style={{ marginTop: "8px" }}>
                {roleChanges === "0" ? "Admin" : "Recruiter"}
              </span>
            </div>

            <Button
              onClick={handleClose}
              style={{
                textAlign: "left",
                padding: "6px 10px",
                display: "flex",
                justifyContent: "start",
                color: "#02215B",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              <img
                src={logoutIcon}
                alt="logout logo"
                style={{ marginRight: "10px" }}
              />
              Log Out
            </Button>
          </div>
        </MenuItem>
      </StyledMenu>
    </div>
    // </div>
  );
};

export default Logout;
