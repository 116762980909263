import { useState, useEffect } from "react";

function MyComponent() {
  const [inactiveTime, setInactiveTime] = useState(0);
  const [lastActive, setLastActive] = useState(Date.now());
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let intervalId;

    function handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        // clear the interval when the tab is hidden
        clearInterval(intervalId);

        // set the last active time
        setLastActive(Date.now());
        setIsActive(false);
      } else if (document.visibilityState === "hidden") {
        // start the interval when the tab is visible
        if (!isActive) {
          setInactiveTime(inactiveTime + (Date.now() - lastActive));
          setIsActive(true);
        }
        intervalId = setInterval(() => {
          setInactiveTime(inactiveTime + (Date.now() - lastActive));
        }, 1000);
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [inactiveTime, lastActive, isActive]);

  const hours = Math.floor(inactiveTime / 3600);
  const minutes = Math.floor((inactiveTime % 3600) / 60);
  const seconds = inactiveTime % 60;

  return (
    <div>
      <p>
        Inactive time: {hours}:{minutes}:{seconds}
      </p>
    </div>
  );
}

export default MyComponent;
