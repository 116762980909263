import React, { useState, useEffect } from "react";
import "./CandidateRegistration.css";
import { API } from "../../../Networking/API";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const IsExpired = () => {
  const [status, setStatus] = useState();
  const [experience, setExperience] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const navigate = useNavigate();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const drive_Id = searchParams.get("drive");

  useEffect(() => {
    const handleCheckStatus = (drive_Id) => {
      API.checkCandidateStatus(drive_Id).then((response) => {
        const result = response;
        if (result !== undefined) {
          if (result.data.status) {
            setStatus(true);
            setExperience(result.data.data.candidate_experience_level);
            setCollegeName(result.data.data.college_name);
          } else {
            setStatus(false);
          }
        } else {
          setStatus(null);
        }
      });
    };

    handleCheckStatus(drive_Id);
  }, []);

  const whatIstheStatus = () => {
    if (status === true) {
      if (experience === "TRAINED") {
        return navigate("/candidate-registration-trained", { state: drive_Id });
      } else if (experience === "FRESHER") {
        return navigate("/candidate-registration-fresher", {
          state: { state: drive_Id, collegeName: collegeName },
        });
      }
    } else if (status === false) {
      return (
        <Box className="expired_box">
          <span className="expired_text">Your link has been expired!</span>
        </Box>
      );
    } else if (status === null) {
      return (
        <Box className="expired_box">
          <span className="expired_text">No drive found!</span>
        </Box>
      );
    }
  };

  return (
    <div className="candidate-Landing-Page-Outer-expired">
      {whatIstheStatus()}
    </div>
  );
};

export default IsExpired;
