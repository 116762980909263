// write all the error messages here

// =======================================================================
// LOGIN && REGISTER && WELCOME SCREENS
// =======================================================================

export const enterUserName = "Please enter email";
export const enterPassword = "Please enter password";
export const emailFailed = "Enter Correct Email-ID";
export const passwordFailed = "Enter Correct Password";
export const enterFirstName = "Please enter firstname";
export const enterLastName = "Please enter lastname";
export const loginSucess = "You have been successfully loggedIn!";
export const loginFailed = "Something went wrong, try again!";
