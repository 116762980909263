import Cookies from "js-cookie";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ allowedRoles }) => {
  const token = Cookies.get("jwtToken");
  const role = Cookies.get("role");
  if (role === allowedRoles) {
   
  }
  return role == allowedRoles && token !== undefined ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoute;
