import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ReusableButton from "../../../CommonComponents/Button/Button";
import ReusableInputfield from "../../../CommonComponents/TextField/TextField";
import { API } from "../../../Networking/API";
import Grid from "@mui/material/Grid";
import "./AdminRecuitmentDriveDialogueBox.css";
import AddIcon from "@mui/icons-material/Add";
import ResuableDropdown from "../../../CommonComponents/DropDown/DropDown";
import { MyForm } from "../../../CommonComponents/MyForm/MyForm";
import Notification from "../../../CommonComponents/Notification/Notification";
import moment from "moment";
import DatePicker from "../../../CommonComponents/DatePicker/DatePicker";
import ReactLoading from "react-loading";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const competencyCandidate = {
  Trained: "TRAINED",
  Fresher: "FRESHER",
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    textTransform: "capitalize",
  };
}

export default function ResuableAdminDialogBox(props) {
  const {
    open,
    handleClose,
    getRecruitmentCompletedDrive,
    getRecruitmentPendingDrive,
    pageNumber,
    penStatus,
    comStatus,
  } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "85%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "0.5%",
    p: 4,
  };

  const theme = useTheme();
  const [categories, setCategories] = useState([]);
  const [recruitersCount, setRecruiterCount] = useState([]);
  const [count, setCount] = useState(1);
  const [fields, setFields] = useState([{ value: "" }]);
  const [recruiterData, setRecruiterData] = useState("");
  const [addMoreCss, setAddmoreCss] = useState();
  const [courseArray, setCourseArray] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [DropDownState, setDropDownState] = useState([]);
  const [DuplicateDropDownState, SetDupDropdownstate] = useState([]);
  const [done, setDone] = useState(false);
  const [errorCatagories, setErrorCategories] = useState("");
  const [errorRecruiterCount, setErrorRecruiterCount] = useState("");
  const [driveTypeOptions, setdriveTypeOptions] = useState([]);
  const [competency_level_data, setCompetencyLevelData] = useState([]);

  let arrlength = categories.length;
  const useStyles = makeStyles({
    dropdownStyle: {
      width: "200px !important",
      maxHeight: "200px !important",
      height: "fit-content !important",
    },
  });

  const competency = [
    { id: "EASY", full_name: "Easy" },
    { id: "MEDIUM", full_name: "Medium" },
    { id: "HARD", full_name: "Hard" },
  ];

  const CandidateOptions = [
    { id: "FRESHER", full_name: "Fresher" },
    { id: "TRAINED", full_name: "Trained" },
    // { id: "EXPERIENCED", full_name: "Experienced" },
  ];

  const DriveOptions = [
    { id: "CAMPUS", full_name: "Campus" },
    { id: "INHOUSE", full_name: "In House" },
  ];

  const DriveOptions2 = [
    { id: "INHOUSE", full_name: "In House" },
    { id: "INSTITUTION", full_name: "Institution" },
  ];
  const categorieOptions = [
    { id: "1", full_name: "Digital" },
    { id: "2", full_name: "Salesforce" },
  ];

  const names = [
    { id: 1, full_name: "Aptitude" },
    { id: 2, full_name: "Technical" },
    { id: 3, full_name: "Verbal" },
    { id: 4, full_name: "Reasoning" },
    // { id: "5", full_name:"GK"}
  ];

  const digitalOptions = [
    { id: 1, full_name: "General" },
    { id: 2, full_name: "React Js" },
    { id: 3, full_name: "Node Js" },
    { id: 4, full_name: "React Native" },
    { id: 5, full_name: "AR&VR" },
  ];

  const salesforceOptions = [
    { id: "1", full_name: "Lwc" },
    { id: "2", full_name: "Apex" },
    { id: "3", full_name: "Aura" },
  ];

  const date = new Date();
  const mindate = moment(date).format("L");

  const time = new Date();
  const mintime = moment(time).format("LT");

  const initialValues = {
    firstName: "",
    universityName: "",
    location: "",
    driveDate: mindate,
    driveTime: "10:00",
    candidate: "",
    competency_level_id: "",
    drive: "",
    platform: "",
    technology: "",
    institution: "",
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleCloseModal = () => {
    handleClose();
    resetForm();
    setErrorCategories("");
    setErrorRecruiterCount("");
    setFields([{ value: "" }]);
    setCount(1);
    setAddmoreCss(1);
    setCategories([]);
    setRecruiterCount([]);
  };

  // const getRecruiterReset = () =>{
  //   // setFields("");
  // }

  useEffect(() => {
    getAllRecruiter();
    getAllCourseData();
    getCompetencyLevels();
  }, []);

  const getCompetencyLevels = async () => {
    await API.getCompetencyLevel().then(({ response }) => {
      if (response) {
        // setCompetencyLevelData(response.data.data)
        const newData = response.data.data.map((item) => ({
          id: item.id,
          full_name: item.competency_level_name.toLowerCase(),
        }));
        setCompetencyLevelData(newData);
      }
    });
  };

  const getAllCourseData = () => {
    API.getCoursesData().then(({ response }) => {
      if (response.status === 200) {
        const data = response.data.data.rows;
        const updatedData = data.map((items, index) => ({
          id: items.id,
          full_name: items.course_name,
        }));
        setCourseArray(updatedData);
      }
    });
  };

  const getAllRecruiter = async () => {
    API.getRecruiters().then(({ response }) => {
      const Value = response.data.data.rows;
      const updatedData = Value.map((items, index) => ({
        id: items.id,
        full_name: items.full_name,
      }));
      setRecruiterData(updatedData);
    });
  };

  const validate = (inputValues = values) => {
    let code = { ...errors };

    if ("firstName" in inputValues)
      code.firstName = inputValues.firstName ? "" : "Drive Name is required";
    if (inputValues.candidate === "FRESHER") {
      if ("universityName" in inputValues)
        code.universityName = inputValues.universityName
          ? ""
          : "University/Collage Name is required";
    } else {
      code.universityName = "";
    }
    if ("location" in inputValues)
      code.location = inputValues.location ? "" : "Drive Location is required";
    if ("driveTime" in inputValues)
      code.driveTime = inputValues.driveTime ? "" : "Drive Time is required";

    if ("driveDate" in inputValues)
      code.driveDate = inputValues.driveDate ? "" : "Drive Date is required";

    // if ("categories" in inputValues)
    //   code.categories =
    //     inputValues.categories.length > 0 ? "" : "Employement Type is required";

    if ("competency_level_id" in inputValues)
      code.competency_level_id = inputValues.competency_level_id
        ? ""
        : "Competency level is required";

    if ("candidate" in inputValues)
      code.candidate = inputValues.candidate
        ? ""
        : "candidate Type is required";

    if ("drive" in inputValues)
      code.drive = inputValues.drive ? "" : "Drive Type is required";
    if (inputValues.candidate !== "FRESHER") {
      if ("platform" in inputValues)
        code.platform = inputValues.platform ? "" : "platform is required";
    } else {
      code.platform = "";
    }
    if (inputValues.candidate !== "FRESHER") {
      if ("technology" in inputValues)
        code.technology = inputValues.technology
          ? ""
          : "technology is required";
    } else {
      code.technology = "";
    }
    if (inputValues.candidate !== "FRESHER") {
      if ("institution" in inputValues)
        code.institution = inputValues.institution
          ? ""
          : "institution name is required";
    } else {
      code.institution = "";
    }
    setErrors({
      ...code,
    });

    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    validateOnEdit,
  } = MyForm(initialValues, true, validate);

  const handleCreateDrive = () => {
    let CategoryId = HandleMultiSelectValues(categories, "Category");
    let RecruitersId = HandleMultiSelectValues(recruitersCount, "Recruiters");

    if (validate() && categories.length > 0 && recruitersCount.length > 0) {
      setDone(true);
      setErrorCategories("");
      setErrorRecruiterCount("");

      var finalArray = [];
      finalArray = fields.map(function (obj) {
        return obj.value;
      });
      var set = new Set(finalArray);
      const array = Array.from(set);

      API.createNewDrive(
        values.firstName,
        values.universityName,
        values.location,
        values.institution,
        values.driveDate,
        values.driveTime,
        CategoryId,
        // values.competencyLevel,
        values.candidate,
        values.drive,
        values.competency_level_id,
        RecruitersId,
        values.platform,
        values.technology
        // array
      ).then(({ response }) => {
        if (response.status === true) {
          handleClose();
          resetForm();
          setCategories([]);
          setRecruiterCount([]);
          getRecruitmentCompletedDrive(comStatus, pageNumber);
          getRecruitmentPendingDrive(penStatus, pageNumber);
          setDone(false);
          setFields([{ value: "" }]);
          setCount(0);
          setCount(1);
          setAddmoreCss(1);
          setNotify({
            isOpen: true,
            message: `${response.message}`,
            type: "success",
          });
        } else if (response.status === false) {
          setNotify({
            isOpen: true,
            message: `${
              response.message ? response.message : "Something went wrong"
            }`,
            type: "error",
          });
          resetForm();
          setCategories([]);
          setRecruiterCount([]);
          handleClose();
          setDone(false);
          handleClose();
        } else {
          resetForm();
          setCategories([]);
          setRecruiterCount([]);
          setNotify({
            isOpen: true,
            message: `${"Something went wrong please retry"}`,
            type: "error",
          });

          handleClose();
          setFields([{ value: "" }]);
        }
      });
    } else {
      setErrorCategories("Categories Type is required");
      setErrorRecruiterCount("Recruiter Name is required");
    }
  };

  function HandleMultiSelectValues(SelectValue, SelectedFieldName) {
    if (SelectedFieldName === "Category") {
      let FinalResult = names
        .filter((Category) => SelectValue.includes(Category.full_name))
        .map((FiltredCategory) => {
          return FiltredCategory.id;
        });
      return FinalResult;
    } else {
      let FinalResult = SelectValue.map((RecruiterId) => {
        return RecruiterId.id;
      });
      return FinalResult;
    }
  }

  function handleChange(i, event) {
    let finalArray = DuplicateDropDownState;

    const values = [...fields];
    values[i].value = event.target.value;
    setFields(values);
  }

  function handleAdd() {
    setClicked(true);
    setCount(count + 1);
    // if(count>6){
    const values = [...fields];
    values.push({ value: null });
    setFields(values);
    setAddmoreCss(addMoreCss + 1);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
    setCount(count - 1);
    setAddmoreCss(addMoreCss - 1);
  }

  let countPosition;
  if (addMoreCss === 1) {
    countPosition = "valueOne";
  } else if (addMoreCss === 2) {
    countPosition = "valueTwo";
  } else if (addMoreCss === 3) {
    countPosition = "valueThree";
  } else if (addMoreCss === 4) {
    countPosition = "valueFour";
  } else if (addMoreCss === 5) {
    countPosition = "valueFive";
  } else if (addMoreCss === 6) {
    countPosition = "valueSix";
  } else if (addMoreCss === 7) {
    countPosition = "valueSeven";
  } else {
    countPosition = "defaultValue";
  }

  const handleFromDate = (e) => {
    const midata = moment(e.target.value).format("L");

    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: midata,
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }

    setErrors({ ...errors, driveDate: "" });
  };

  const handlecandidate = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, candidate: "" });
    if (
      value === "TRAINED" //  || value === "EXPERIENCED"
    ) {
      setCategories(["Technical"]);
    } else {
      setCategories([]);
    }
    // setRecruiterCount([])
  };

  const handledrive = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, drive: "" });
  };

  const handlePlatform = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, platform: "" });
  };

  const handletechnology = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: [value],
    });
    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, technology: "" });
  };

  const handleCompetencyLevel = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, competency_level_id: "" });
  };

  // const handleplatform = (e) => {
  //   const { name, value } = e.target;
  //   setValues({
  //     ...values,
  //     [name]: value,
  //   });
  //   if (validateOnEdit) {
  //     validate({ [name]: value });
  //   }
  //   setErrors({ ...errors, employeeType: "" });
  // };

  const handleCategories = (event) => {
    const {
      target: { value },
    } = event;
    let FinalCategoryId = names?.map((Category, index) => {
      if (event.target.value.includes(Category.full_name)) {
        return Category.id;
      }
    });
    let FiltredCategory = FinalCategoryId.filter(
      (CategoryId) => CategoryId !== undefined
    );
    setErrorCategories("");
    if (categories.includes("0")) {
      setCategories(
        // On autofill we get a stringified value.
        typeof value === "string" ? value : value
      );
    }
    setCategories(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    if (value.includes("Technical") === false) {
      setValues({ ...values, candidate: "FRESHER" });
    }
  };

  const handlerecruiters = (event) => {
    const {
      target: { value },
    } = event;
    let FinalRecruiterId = recruiterData?.map((data, index) => {
      if (event.target.value.includes(data.full_name)) {
        return data.id;
      }
    });
    let FiltredRecruiters = FinalRecruiterId.filter(
      (RecruiterId) => RecruiterId !== undefined
    );
    setErrorRecruiterCount("");

    setRecruiterCount(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleCloseModal}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box sx={style}>
              <Box>
                <Box style={{ display: "flex", paddingBottom: "20px" }}>
                  <Box
                    style={{
                      width: "30vw",
                      fontSize: "22px",
                      fontWeight: "700",
                      color: "#424242",
                    }}
                  >
                    <span>Create Drive</span>
                  </Box>
                </Box>
                {done ? (
                  <Box
                    sx={{ flexGrow: 1 }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70vh",
                    }}
                  >
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={"#00728E"}
                      height={100}
                      width={100}
                    />
                  </Box>
                ) : (
                  <>
                    <Grid container columnGap={5} rowGap={2}>
                      <Grid item xs={2.5}>
                        <Box
                          className=""
                          style={{ display: "grid", marginTop: "2%" }}
                        >
                          <label style={{ paddingBottom: "2%" }} htmlFor="">
                            Drive Name
                          </label>
                          <ReusableInputfield
                            variant="outlined"
                            size="small"
                            type="text"
                            name="firstName"
                            placeholder="Create Drive Name"
                            value={values.firstName}
                            error={errors.firstName ? true : false}
                            helperText={errors.firstName}
                            onChange={handleInputChange}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2.5}>
                        <Box
                          className=""
                          style={{ display: "grid", marginTop: "2%" }}
                        >
                          <label style={{ paddingBottom: "3.5%" }}>
                            Candidates Type
                          </label>
                          <ResuableDropdown
                            type="text"
                            placeholder="Select Candidates"
                            spanText="Select Candidates"
                            style={{ paddingRight: "35px" }}
                            name="candidate"
                            error={errors.candidate ? true : false}
                            value={values.candidate}
                            helperText={errors.candidate}
                            options={CandidateOptions}
                            // disabled={disable}
                            onChange={handlecandidate}
                            MenuProps={useStyles}
                          />
                        </Box>
                      </Grid>
                      {values.candidate !== "FRESHER" &&
                      values.candidate !== "" ? (
                        <>
                          <Grid item xs={2.5}>
                            <Box
                              className=""
                              style={{ display: "grid", marginTop: "2%" }}
                            >
                              <label
                                style={{ paddingBottom: "3.5%" }}
                                htmlFor=""
                              >
                                Platform
                              </label>
                              <ResuableDropdown
                                size="small"
                                type="text"
                                placeholder="Select platform"
                                style={{ paddingRight: "35px" }}
                                name="platform"
                                spanText="Select Platform"
                                error={errors.platform ? true : false}
                                value={values.platform}
                                helperText={errors.platform}
                                options={categorieOptions}
                                // disabled={disable}
                                MenuProps={useStyles}
                                onChange={handlePlatform}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={2.5}>
                            <Box
                              className=""
                              style={{ display: "grid", marginTop: "2%" }}
                            >
                              <label
                                style={{ paddingBottom: "3.5%" }}
                                htmlFor=""
                              >
                                Technology
                              </label>
                              <ResuableDropdown
                                size="small"
                                type="text"
                                // placeholder="Select Technology"
                                displayEmpty
                                style={{ paddingRight: "35px" }}
                                name="technology"
                                spanText="Select Technology"
                                error={errors.technology ? true : false}
                                value={values.technology}
                                MenuProps={useStyles}
                                helperText={errors.technology}
                                options={
                                  values.platform === "1"
                                    ? digitalOptions
                                    : salesforceOptions
                                }
                                // disabled={disable}
                                // renderValue={(selected) => {
                                //   if (selected.length === 0) {
                                //     return <em>Select Technology</em>;
                                //   }
                                // }}
                                onChange={handletechnology}
                              />
                            </Box>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}

                      <Grid item xs={2.5}>
                        <Box
                          className=""
                          style={{ display: "grid", marginTop: "2%" }}
                        >
                          <label style={{ paddingBottom: "3.5%" }} htmlFor="">
                            Drive Type
                          </label>
                          <ResuableDropdown
                            size="small"
                            type="text"
                            placeholder="Select Drive Type  "
                            spanText="Select Drive Type "
                            style={{ paddingRight: "35px" }}
                            name="drive"
                            error={errors.drive ? true : false}
                            value={values.drive}
                            helperText={errors.drive}
                            MenuProps={useStyles}
                            options={
                              values.candidate === "FRESHER"
                                ? DriveOptions
                                : DriveOptions2
                            }
                            // disabled={disable}
                            onChange={handledrive}
                          />
                        </Box>
                      </Grid>

                      {values.candidate !== "FRESHER" ? (
                        ""
                      ) : (
                        <Grid item xs={2.5}>
                          <Box
                            className=""
                            style={{ display: "grid", marginTop: "2%" }}
                          >
                            <label style={{ paddingBottom: "2%" }} htmlFor="">
                              University/College
                            </label>
                            <ReusableInputfield
                              variant="outlined"
                              size="small"
                              type="text"
                              name="universityName"
                              placeholder="University/Collage Name"
                              value={values.universityName}
                              error={errors.universityName ? true : false}
                              helperText={errors.universityName}
                              onChange={handleInputChange}
                            />
                          </Box>
                        </Grid>
                      )}

                      {values.candidate !== "FRESHER" &&
                      values.candidate !== "" ? (
                        <Grid item xs={2.5}>
                          <Box
                            className=""
                            style={{ display: "grid", marginTop: "2%" }}
                          >
                            <label style={{ paddingBottom: "2%" }} htmlFor="">
                              Institution name
                            </label>
                            <ReusableInputfield
                              variant="outlined"
                              size="small"
                              type="text"
                              name="institution"
                              placeholder="institution Name"
                              value={values.institution}
                              error={errors.institution ? true : false}
                              helperText={errors.institution}
                              onChange={handleInputChange}
                            />
                          </Box>
                        </Grid>
                      ) : (
                        ""
                      )}

                      <Grid item xs={2.5}>
                        <Box
                          className=""
                          style={{ display: "grid", marginTop: "2%" }}
                        >
                          <label style={{ paddingBottom: "2%" }} htmlFor="">
                            Drive Location
                          </label>

                          <ReusableInputfield
                            variant="outlined"
                            size="small"
                            type="text"
                            name="location"
                            // label="Drive Name"
                            placeholder="Drive Location"
                            value={values.location}
                            error={errors.location ? true : false}
                            helperText={errors.location}
                            onChange={handleInputChange}
                          />
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={2.5}
                        className="multiselect"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Box
                          className=""
                          style={{ display: "grid", marginTop: "2%" }}
                        >
                          <label style={{ paddingBottom: "1%" }} htmlFor="">
                            Categories
                          </label>
                          <div>
                            <FormControl sx={{ width: "186px" }}>
                              <Select
                                // labelId="demo-multiple-name-label"
                                // id="demo-multiple-name"
                                multiple
                                displayEmpty
                                value={categories}
                                onChange={handleCategories}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  if (selected.length === 0) {
                                    return (
                                      <em
                                        style={{
                                          opacity: "0.7",
                                          color: "#a2a2a2",
                                          fontWeight: "100",
                                          fontFamily: "sans-serif",
                                        }}
                                      >
                                        Select Categories
                                      </em>
                                    );
                                  }

                                  return selected.join(", ");
                                }}
                                MenuProps={MenuProps}
                                // defaultValue="none"
                              >
                               
                                {names.map((name) => {
                                  return (
                                    <MenuItem
                                      key={name.id}
                                      value={name.full_name}
                                      style={getStyles(name, categories, theme)}
                                    >
                                      {name.full_name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </Box>
                        {categories.length === 0 ? (
                          <span
                            style={{
                              fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                              fontWeight: "400",
                              fontSize: "0.75rem",
                              lineHeight: "1.66",
                              letterSpacing: "0.03333em",
                              textAlign: "left",
                              marginTop: "4px",
                              marginRight: "14px",
                              marginBottom: "0",
                              color: "#d32f2f",
                            }}
                          >
                            {errorCatagories}
                          </span>
                        ) : null}
                      </Grid>

                      <Grid item xs={2.5}>
                        <Box
                          className=""
                          style={{ display: "grid", marginTop: "2%" }}
                        >
                          <label style={{ paddingBottom: "3.5%" }} htmlFor="">
                            Competency Level
                          </label>
                          <ResuableDropdown
                            size="small"
                            type="text"
                            // placeholder="Select Recruiter"
                            style={{ paddingRight: "35px" }}
                            name="competency_level_id"
                            spanText="Select Competency Level"
                            error={errors.competency_level_id ? true : false}
                            value={values.competency_level_id}
                            helperText={errors.competency_level_id}
                            options={competency_level_data}
                            MenuProps={useStyles}
                            // disabled={disable}
                            onChange={handleCompetencyLevel}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={2.5}>
                        <Box
                          className=""
                          style={{ display: "grid", marginTop: "2%" }}
                        >
                          <label style={{ paddingBottom: "3.5%" }} htmlFor="">
                            Drive Date
                          </label>
                          <DatePicker
                            id="date"
                            type="date"
                            className="driveDate-field"
                            placeholder="Select Drive Date"
                            name="driveDate"
                            // format={'YYYY-MM-DD'}
                            sx={{ width: 220 }}
                            value={values.driveDate}
                            // defaultValue={}
                            error={errors.driveDate ? true : false}
                            helperText={errors.driveDate}
                            onChange={handleFromDate}
                            minDate={mindate}
                            // maxDate="20-12-2022"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size="small"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2.5}>
                        <Box className="" style={{ display: "grid" }}>
                          <label style={{ paddingBottom: "2%" }} htmlFor="">
                            Drive Time
                          </label>
                          <ReusableInputfield
                            sx={{ width: "100%" }}
                            id="time"
                            type="time"
                            defaultValue="07:30"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="driveTime"
                            value={values.driveTime}
                            error={errors.driveTime ? true : false}
                            helperText={errors.driveTime}
                            onChange={handleInputChange}
                            placeholder="Select Drive Tine"
                            size="small"
                            inputProps={{
                              step: 300, // 5 min
                            }}
                          />
                        </Box>
                      </Grid>
                      {recruiterData.length !== 0 ? (
                        <Grid item xs={2.5}>
                          <Box
                            className="recruitercount"
                            style={{ display: "grid" }}
                          >
                            <label style={{ paddingBottom: "1.5%" }} htmlFor="">
                              Recruiters
                            </label>
                            {recruiterData.length > 0 ? (
                              <>
                                <FormControl sx={{ width: "184px" }}>
                                  <Select
                                    // labelId="demo-multiple-name-label"
                                    // id="demo-multiple-name"
                                    multiple
                                    displayEmpty
                                    value={recruitersCount}
                                    onChange={handlerecruiters}
                                    input={<OutlinedInput />}
                                    MenuProps={MenuProps}
                                    defaultValue="none"
                                    style={{ textTransform: "capitalize" }}
                                    renderValue={(selected) => {
                                      if (selected.length === 0) {
                                        return (
                                          <em
                                            style={{
                                              opacity: "0.7",
                                              color: "#a2a2a2",
                                              fontWeight: "100",
                                              fontFamily: "sans-serif",
                                            }}
                                          >
                                            Select Recruiters
                                          </em>
                                        );
                                      }
                                      let selectedRecuriter = selected.map(
                                        (data) => {
                                          return data.full_name;
                                        }
                                      );

                                      return selectedRecuriter.join(" , ");
                                    }}
                                  >
                                    <MenuItem value="" disable hidden selected>
                                      <span style={{ opacity: "0.5" }}>
                                        Recruiters
                                      </span>
                                    </MenuItem>
                                    {recruiterData.map((name) => (
                                      <MenuItem
                                        key={name.id}
                                        value={name}
                                        style={getStyles(
                                          name,
                                          recruitersCount,
                                          theme
                                        )}
                                      >
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {name.full_name}
                                        </span>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {recruitersCount.length === 0 && (
                                    <span
                                      style={{
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        fontWeight: "400",
                                        fontSize: "0.75rem",
                                        lineHeight: "1.66",
                                        letterSpacing: "0.03333em",
                                        textAlign: "left",
                                        marginTop: "4px",
                                        marginRight: "14px",
                                        marginBottom: "0",
                                        color: "#d32f2f",
                                      }}
                                    >
                                      {errorRecruiterCount}
                                    </span>
                                  )}
                                </FormControl>
                              </>
                            ) : (
                              <>
                                <div className="label-select-class-recruiters">
                                  <FormControl
                                    sx={{ m: 1, minWidth: 184 }}
                                    disabled
                                  >
                                    <InputLabel
                                      id="demo-simple-select-disabled-label"
                                      className="select-class-drive-admin-add"
                                    >
                                      <em>No Recruiters</em>
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-disabled-label"
                                      id="demo-simple-select-disabled"
                                      label="Age"
                                    ></Select>
                                  </FormControl>
                                </div>
                              </>
                            )}
                          </Box>
                        </Grid>
                      ) : (
                        " "
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        flexDirection: "column",
                        marginTop: "12px",
                        marginRight: "20px",
                      }}
                    >
                      <Box
                        style={
                          values.candidate === competencyCandidate.Trained
                            ? { marginTop: "3%" }
                            : null
                        }
                      >
                        <Box>
                          <ReusableButton
                            style={{
                              float: "right",
                              backgroundColor: "#00728E",
                              textTransform: "capitalize",
                              "&:hover": { backgroundColor: "#00728E" },
                            }}
                            text="Create Drive"
                            onClick={handleCreateDrive}
                          />
                        </Box>
                        <Box>
                          <ReusableButton
                            style={{
                              float: "right",
                              Color: "#00728E",
                              marginRight: "20px",
                              textTransform: "capitalize",
                            }}
                            text="cancel"
                            variant="outlined"
                            onClick={handleCloseModal}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </>
                )}
              </Box>
            </Box>
          </Fade>
        </Modal>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
}
