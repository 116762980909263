import HomeIcon from "@mui/icons-material/Home";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const Icons = {
  Home: <HomeIcon />,
  Close: <CloseIcon />,
  Back: <ArrowBackIcon />,
  Edit: <EditOutlinedIcon />,
  Delete: <DeleteOutlineOutlinedIcon />,
  ThreeVerticalDots: <MoreVertIcon />,
};
