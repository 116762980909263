import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AdminSignUpLogo from "../../../src/Assests/Group286.png";
import CrackItLogo from "../../../src/Assests/CrackItLogo.png";
import Crack from "../../../src/Assests/Crack.png";
import It from "../../../src/Assests/IT.png";
import ReusableButton from "../../CommonComponents/Button/Button";
import ReusableInputfield from "../../CommonComponents/TextField/TextField";
import "./AdminForgetPassword.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import { API } from "../../Networking/API";
import Notification from "../../CommonComponents/Notification/Notification";
import { useSearchParams } from "react-router-dom";

export function AdminForgetPassword() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  let token = searchParams.get("token");

  const [input, setInput] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handleCreatePassword = () => {
    API.forgotPassword(token, input.password).then(({ response }) => {
      if (response.status === true) {
        setNotify({
          isOpen: true,
          message: `${response.message}`,
          type: "success",
        });
        const timer = setTimeout(() => {
          navigate("/");
        }, 2000);
        return () => clearTimeout(timer);
      }
    });
  };

  return (
    <Box
      sx={{ flexGrow: 1 }}
      //   style={{ marginTop: "5%" }}
      className="Admin-rest-Page-Outer"
    >
      <Grid container spacing={2} style={{}}>
        <Card
          //   variant="outlined"
          sx={{
            marginTop: "8%",
            Width: "100%",
            borderRadius: 0,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "50px",
          }}
        >
          <CardContent style={{ padding: "0px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} style={{}}>
                <img
                  src={AdminSignUpLogo}
                  alt="AdminContentPic"
                  width="300"
                  height="100%"
                />
              </Grid>
              <Grid
                item
                xs={5}
                // sm={6}
                // md={6}
                style={{
                  padding: "30px 25px",
                  marginLeft: "30px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "50px",
                  }}
                >
                  <img
                    src={CrackItLogo}
                    alt="logo"
                    width="80"
                    height="80"
                    marginTop="32px"
                  ></img>
                </div>
                <div
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <img src={Crack} alt="logo" width="80" height="17"></img>{" "}
                    &nbsp;
                    <img src={It} alt="logo" width="20" height="20"></img>
                  </span>
                </div>
                <form>
                  <div
                    className="text-name"
                    // style={{marginTop:"15px"}}
                  >
                    Forgot Password
                  </div>
                  <div
                    //   className="inputBox_text-name"
                    style={{
                      marginTop: "9px",
                      marginBottom: "7px",
                      display: "flex",
                      justifyContent: "start",
                      fontSize: "14px",
                      fontFamily: "Helvetica",
                    }}
                  >
                    Create New Password:
                  </div>
                  <>
                    <ReusableInputfield
                      type="password"
                      name="password"
                      placeholder="Enter Password"
                      value={input.password}
                      onChange={onInputChange}
                      onBlur={validateInput}
                    />
                    {error.password && (
                      <span
                        className="err"
                        style={{
                          display: "flex",
                          color: "red",
                          fontSize: "13px",
                          paddingTop: "5px",
                        }}
                      >
                        {error.password}
                      </span>
                    )}
                  </>
                  <div
                    //   className="inputBox_text-name"
                    style={{
                      marginTop: "12px",
                      marginBottom: "7px",
                      display: "flex",
                      justifyContent: "start",
                      fontSize: "14px",
                      fontFamily: "Helvetica",
                    }}
                  >
                    Re-enter Password:
                  </div>
                  <>
                    <ReusableInputfield
                      type="password"
                      name="confirmPassword"
                      placeholder="Enter confirm-Password"
                      value={input.confirmpassword}
                      onChange={onInputChange}
                      onBlur={validateInput}
                    />
                  </>
                  {error.confirmPassword && (
                    <span
                      className="err"
                      style={{
                        display: "flex",
                        color: "red",
                        fontSize: "13px",
                      }}
                    >
                      {error.confirmPassword}
                    </span>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginTop: "20px" }}>
                      <ReusableButton
                        text={"create"}
                        endIcon={<ArrowRightAltIcon />}
                        variant="contained"
                        onClick={handleCreatePassword}
                        style={{
                          // border: "1px solid #00728E",
                          background: "#00728E",
                          borderRadius: "4px",
                          fontFamily: "Helvetica",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          textTransform: "capitalize !important",
                          "&:hover": { backgroundColor: "#00728E" },
                          padding: "6px 38px",
                        }}
                      />
                    </div>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}
