import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ReusableButton from "../../../src/CommonComponents/Button/Button";
import ReusableInputfield from "../../CommonComponents/TextField/TextField";

import AdminSignUpLogo from "../../../src/Assests/Group286.png";
import CrackItLogo from "../../../src/Assests/CrackItLogo.png";
import Crack from "../../../src/Assests/Crack.png";
import It from "../../../src/Assests/IT.png";
import "./Login.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { emailValidation, passwordValidation } from "../../Utilis/validators";
import {
  emailFailed,
  passwordFailed,
  enterUserName,
  enterPassword,
} from "../../Utilis/constants";
import { API } from "../../Networking/API";
import Cookies from "js-cookie";
import Notification from "../../CommonComponents/Notification/Notification";
import { useEffect } from "react";
import AbsyzNewLogo from "../../Assests/absyz.svg";

const inputFieldData = {
  email: "",
  password: "",
};

const inputErrorMsg = {
  forEmail: "",
  forPassword: "",
};

const inputFieldErrorStatus = {
  emailStatus: false,
  passwordStatus: false,
};

export default function Login() {
  const [inputFieldValues, setInputFieldValues] = useState(inputFieldData);
  const [inputFieldErrors, setInputFieldErrors] = useState(inputErrorMsg);
  const [errorStatus, setErrorStatus] = useState(inputFieldErrorStatus);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  // const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();

  const handleonChange = (e) => {
    const Name = e.target.name;
    setInputFieldValues({ ...inputFieldValues, [Name]: e.target.value });
  };

  const handleonChangePassword = (event, value) => {
    setInputFieldValues({ ...inputFieldValues, password: event.target.value });
  };

  const validators = () => {
    if (inputFieldValues.email === "") {
      setErrorStatus({ ...errorStatus, emailStatus: true });
      setInputFieldErrors({ forEmail: enterUserName });
    } else if (emailValidation.test(inputFieldValues.email) === false) {
      setErrorStatus({ ...errorStatus, emailStatus: true });
      setInputFieldErrors({ forEmail: emailFailed });
    } else if (inputFieldValues.password === "") {
      setErrorStatus({
        ...errorStatus,
        passwordStatus: true,
        emailStatus: false,
      });
      setInputFieldErrors({
        ...inputFieldErrors,
        forPassword: enterPassword,
        forEmail: "",
      });
    } else {
      setErrorStatus({
        ...errorStatus,
        passwordStatus: false,
        emailStatus: false,
      });
      setInputFieldErrors({
        ...inputFieldErrors,
        forPassword: "",
        forEmail: "",
      });
      return true;
    }
  };

  const onSubmitSuccess = (
    token,
    role,
    passwordReset,
    email,
    fullname,
    image
  ) => {
    Cookies.set("jwtToken", token, { expires: 30 });
    Cookies.set("role", role, { expires: 30 });
    localStorage.setItem("email", email);
    localStorage.setItem("name", fullname);
    localStorage.setItem("image", image);
    localStorage.setItem("role", role);

    if (passwordReset === 1) {
      if (role === 0) {
        navigate("/admin-home");
      } else if (role === 1) {
        navigate("/recruiter-home");
      } else if (role === 2) {
        navigate("/user-instruction");
      }
    } else {
      navigate("/reset_password");
    }
  };

  const handleSubmit = async () => {
    setErrorStatus({ ...errorStatus, emailStatus: false });
    setErrorStatus({ ...errorStatus, passwordStatus: false });

    if (validators()) {
      API.adminLogin(inputFieldValues.email, inputFieldValues.password).then(
        ({ response }) => {
          const data = response;
          if (data.status) {
            setNotify({
              isOpen: true,
              message: `Successfully logged in`,
              type: "success",
            });

            setTimeout(() => {
              onSubmitSuccess(
                data.data.token,
                data.data.user.roles,
                data.data.user.is_password_reset,
                data.data.user.email,
                data.data.user.full_name,
                data.data.user.profile_image
              );
            }, 1000);
          } else {
            setNotify({
              isOpen: true,
              message: `${response.message}`,
              type: "error",
            });
            setErrorStatus({ ...errorStatus, passwordStatus: true });
            setInputFieldErrors({
              ...inputFieldErrors,
              forPassword: data.message,
              forEmail: "",
            });
          }
        }
      );
    }
  };

  const jwtToken = Cookies.get("jwtToken");
  const userRole = Cookies.get("role");
  if (jwtToken !== undefined) {
    if (userRole === "0") {
      return <Navigate to="/admin-home" />;
    } else if (userRole === "1") {
      return <Navigate to="/recruiter-home" />;
    } else if (userRole === "2") {
      return <Navigate to="/user-instruction" />;
    }
  }

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Box className="Landing-Page-Outer">
        <Grid container spacing={2} style={{}}>
          <Card
            //   variant="outlined"
            sx={{
              marginTop: "8%",
              Width: "100%",
              borderRadius: 0,
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "50px",
            }}
          >
            <CardContent style={{ padding: "0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6} style={{}}>
                  <img
                    src={AdminSignUpLogo}
                    alt="AdminContentPic"
                    width="300"
                    height="100%"
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  // sm={6}
                  // md={6}
                  style={{
                    padding: "30px 25px",
                    marginLeft: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "50px",
                    }}
                  >
                    {/* <img
                    src={CrackItLogo}
                    alt="logo"
                    width="80"
                    height="80"
                    marginTop="32px"
                  ></img>
                  <span style={{ marginTop: "20px" }}>
                    <img src={Crack} alt="logo" width="80" height="17"></img>{" "}
                    &nbsp;
                    <img src={It} alt="logo" width="20" height="20"></img>
                  </span> */}
                    {/* <Box sx={{ marginTop: "20px" }}> */}
                    <img
                      src={AbsyzNewLogo}
                      alt="logo"
                      // width="80"
                      // height="80"
                    ></img>
                    {/* </Box> */}
                  </div>

                  <div className="Admintext-name">Login</div>

                  <div
                    className="adminLoginHeadingThree"
                    style={{
                      marginTop: "14px",
                      marginBottom: "7px",
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    User Name
                  </div>
                  <>
                    <ReusableInputfield
                      variant="outlined"
                      size="small"
                      type="text"
                      name="email"
                      required
                      value={inputFieldValues.email}
                      inputProps={{
                        autoComplete: "off",
                      }}
                      onChange={handleonChange}
                      error={errorStatus.emailStatus}
                      helperText={inputFieldErrors.forEmail}
                    />
                  </>
                  <div
                    className="adminLoginHeadingThree"
                    style={{
                      marginTop: "14px",
                      marginBottom: "7px",
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    Password{" "}
                    <Link to="/forget-Password" className="forgotPassword_text">
                      Forgot Password?
                    </Link>
                    {/* <span className="forgotPassword_text">Forgot Password?</span> */}
                  </div>
                  <>
                    <ReusableInputfield
                      variant="outlined"
                      size="small"
                      type="password"
                      id="password"
                      required
                      name="password"
                      value={inputFieldValues.password}
                      onChange={handleonChangePassword}
                      error={errorStatus.passwordStatus}
                      helperText={inputFieldErrors.forPassword}
                    />
                  </>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginTop: "20px" }}>
                      <ReusableButton
                        text={"Login"}
                        endIcon={<ArrowRightAltIcon />}
                        variant="contained"
                        onClick={handleSubmit}
                        style={{
                          // border: "1px solid #00728E",
                          background: "#00728E",
                          borderRadius: "4px",
                          fontFamily: "Helvetica",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          textTransform: "capitalize !important",
                          "&:hover": { backgroundColor: "#00728E" },
                          padding: "6px 38px",
                        }}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </>
  );
}
