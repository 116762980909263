import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import TimerImage from "../../../Assests/Group.png";
import QuizTimer from "./QuizTimer";

const QuizHeader = (props) => {
  const { Id, setTimer, setFinish } = props;
  const location = useLocation();

  const timelimit = localStorage.getItem("QuizId" + Id);

  const [timeForTest, setTimeForTest] = useState(timelimit);
  const [MinSecs, setMinSecs] = useState();

  useEffect(() => {
    if (localStorage.getItem("minsecs" + Id)) {
      setMinSecs({
        minutes: JSON.parse(localStorage.getItem("minsecs" + Id)).Minutes,
        seconds: JSON.parse(localStorage.getItem("minsecs" + Id)).Seconds,
      });
    } else {
      setMinSecs({
        minutes: `${JSON.parse(timeForTest).Minutes}`,
        seconds: `${JSON.parse(timeForTest).Seconds}`,
      });
    }
  }, []);

  const setNewData = (data) => {
    setTimer(data);
    // setMinSecs  ({ minutes: childdata[0], seconds: childdata[1] });

    if (data[0] === 0 && data[1] === 0) {
      setFinish(true);
    }
  };

  return (
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <img
        src={TimerImage}
        alt="loading..."
        style={{ height: "22px", width: "18px" }}
      />
      {/* <p>10:12</p> */}

      {MinSecs ? (
        <QuizTimer
          Id={Id}
          MinSecs={MinSecs}
          start={true}
          parentcallback={setNewData}
        />
      ) : null}
    </Box>
  );
};

export default QuizHeader;
