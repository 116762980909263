import * as React from "react";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ReusableButton from "../../../CommonComponents/Button/Button";
import ReusableInputfield from "../../../CommonComponents/TextField/TextField";
import Grid from "@mui/material/Grid";
// import './AdminRecuitmentDriveDialogueBox.css'
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import "./AdminAddRecruiters.css";
import ResuableDropdown from "../../../CommonComponents/DropDown/DropDown";
import { Form, MyForm } from "../../../CommonComponents/MyForm/MyForm";
import { API } from "../../../Networking/API";
import { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Notification from "../../../CommonComponents/Notification/Notification";
import ReactLoading from "react-loading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 810,
  height: 403,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const initialValues = {
  firstname: "",
  RecruitersDesignation: "",
  Experience: "",
  Email: "",
  PhoneNumber: "",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AdminAddRecruiters(props) {
  const { open, handleClose, RecruiterDrive, pageNumber } = props;
  //const [value1,setValues1] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [recruiterDesignations, setRecruiterDesignation] = useState([]);
  const [done, setDone] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    getDropdown();
  }, []);

  const getDropdown = () => {
    API.getDesignation().then(({ response }) => {
      if (response.data.status) {
        const ResponseData = response.data.data;
        const updatedData = ResponseData.map((each) => ({
          id: each.id,
          title: each.designation_name,
        }));
        setRecruiterDesignation(updatedData);
      }
    });
  };

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("firstname" in inputValues)
      code.firstname = inputValues.firstname
        ? ""
        : "Recruiter Name is required";
    if ("RecruitersDesignation" in inputValues)
      code.RecruitersDesignation = inputValues.RecruitersDesignation
        ? ""
        : "Recruiter Designation is required";
    if ("Experience" in inputValues)
      code.Experience = inputValues.Experience ? "" : "Experience is required";
    const emailValidation =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if ("Email" in inputValues)
      code.Email = inputValues.Email
        ? emailValidation.test(inputValues.Email)
          ? ""
          : "Email is not valid"
        : "Email is required";
    if ("PhoneNumber" in inputValues)
      code.PhoneNumber = inputValues.PhoneNumber
        ? inputValues.PhoneNumber.length < 10
          ? "Invalid Phone number"
          : ""
        : "Phone Number is required";
    setErrors({
      ...code,
    });

    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    validateOnEdit,
  } = MyForm(initialValues, true, validate);

  const handleCreateDrive = (e) => {
    if (validate()) {
      setDone(true);

      API.adminAddRecruiter(values).then(({ response }) => {
        if (response.status === true) {
          setDone(false);
          RecruiterDrive(pageNumber);
          handleClose();
          resetForm();
          setNotify({
            isOpen: true,
            message: `${response.message}`,
            type: "success",
          });
        } else if (response.status === false) {
          setNotify({
            isOpen: true,
            message: "recruiter already exists",
            type: "error",
          });
          setDone(false);
          resetForm();
          handleClose();
        }
      });
    }
  };

  const handleCloseModal = () => {
    handleClose();
    resetForm();
  };

  const handleInputChangePhno = (e) => {
    const { name, value } = e.target;
    const Phvalue = value.replace(/\D/g, "");

    setValues({
      ...values,
      [name]: Phvalue,
    });
    // if (validateOnEdit) {
    validate({ [name]: Phvalue });
    // }
  };

  const handleInputChangeDropdown = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, RecruitersDesignation: "" });
  };

  return (
    <>
      <div className="add-recruiter-module">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleCloseModal}
          closeAfterTransition
          // BackdropComponent={Backdrop}
          // BackdropProps={{
          //   timeout: 500,
          // }}
        >
          <Fade in={open} className="popup-Box-Styles">
            <Box sx={style}>
              <Box>
                <Box
                  style={{
                    display: "flex",
                    paddingBottom: "20px",
                  }}
                >
                  <Box
                    style={{
                      width: "30vw",
                      fontSize: "22px",
                      fontWeight: "700",
                      color: "#424242",
                    }}
                  >
                    <spam>Add Recruiters</spam>
                  </Box>
                </Box>
                {done ? (
                  <Box
                    sx={{ flexGrow: 1 }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "50vh",
                    }}
                  >
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={"#00728E"}
                      height={100}
                      width={100}
                    />
                  </Box>
                ) : (
                  <>
                    <Grid spacing={2}>
                      <Grid item xs={4} sx={{ height: "120px" }}>
                        <Box className="recruiter-AdminFelidTextBox">
                          <label style={{ paddingBottom: "5px" }} htmlFor="">
                            Recruiters Name
                          </label>
                          <ReusableInputfield
                            variant="outlined"
                            size="small"
                            type="text"
                            name="firstname"
                            // label="Drive Name"
                            placeholder="Enter Recruiter Name"
                            value={values.firstname}
                            error={errors.firstname ? true : null}
                            helperText={errors.firstname}
                            onChange={handleInputChange}
                          />
                        </Box>
                        <Box className="recruiter-AdminFelidTextBox">
                          <label style={{ paddingBottom: "4%" }} htmlFor="">
                            Recruiters Designation
                          </label>
                          <ResuableDropdown
                            size="small"
                            type="text"
                            optionText="Select Recruiter"
                            spanText="Select Recruiter designation"
                            style={{ width: "232px" }}
                            name="RecruitersDesignation"
                            error={errors.RecruitersDesignation ? true : false}
                            value={values.RecruitersDesignation}
                            helperText={errors.RecruitersDesignation}
                            options={recruiterDesignations}
                            onChange={handleInputChangeDropdown}
                          />
                        </Box>
                        <Box className="recruiter-AdminFelidTextBox">
                          <label style={{ paddingBottom: "5px" }} htmlFor="">
                            Experience
                          </label>
                          <ReusableInputfield
                            variant="outlined"
                            size="small"
                            type="text"
                            name="Experience"
                            inputProps={{
                              maxLength: 2,
                            }}
                            placeholder="In Years"
                            value={values.Experience}
                            error={errors.Experience ? true : false}
                            helperText={errors.Experience}
                            onChange={handleInputChangePhno}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4} style={{ height: "120px" }}>
                        <Box className="recruiter-AdminFelidTextBox">
                          <label style={{ paddingBottom: "5px" }} htmlFor="">
                            Email Address
                          </label>
                          <ReusableInputfield
                            variant="outlined"
                            size="small"
                            type="email"
                            name="Email"
                            placeholder="Enter Email Address"
                            value={values.Email}
                            error={errors.Email ? true : false}
                            helperText={errors.Email}
                            onChange={handleInputChange}
                          />
                        </Box>
                        <Box className="recruiterPhoneNumberFeld">
                          <label style={{ paddingBottom: "5px" }} htmlFor="">
                            Phone Number
                          </label>
                          <ReusableInputfield
                            // className="input"
                            variant="outlined"
                            size="small"
                            type="text"
                            name="PhoneNumber"
                            placeholder="Enter Phone Number"
                            value={values.PhoneNumber}
                            error={errors.PhoneNumber ? true : false}
                            helperText={errors.PhoneNumber}
                            inputProps={{
                              maxLength: 10,
                            }}
                            onChange={handleInputChangePhno}
                          />
                        </Box>
                      </Grid>

                      <Box>
                        <Box>
                          <ReusableButton
                            style={{
                              float: "right",
                              marginBottom: "20px",
                              backgroundColor: "#00728E",
                              "&:hover": { backgroundColor: "#00728E" },
                            }}
                            text="Add"
                            onClick={handleCreateDrive}
                          />
                        </Box>
                        <Box>
                          <ReusableButton
                            style={{
                              float: "right",
                              Color: "#00728E",
                              marginRight: "20px",
                              marginBottom: "20px",
                            }}
                            text="cancel"
                            variant="outlined"
                            onClick={handleCloseModal}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </>
                )}
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
