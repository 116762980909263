import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import ReusableButton from "../../../CommonComponents/Button/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { API } from "../../../Networking/API";
import ResuableDropdown from "../../../CommonComponents/DropDown/DropDown";
import "./RecruiterCandidateHired.css";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import Notification from "../../../CommonComponents/Notification/Notification";
import Grid from "@mui/material/Grid";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Logout from "../../../CommonComponents/Logout/Logout";
import ReactLoading from "react-loading";
import { DataTable } from "../../../CommonComponents/DataTable/DataTable";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function RecruiterCandidateHired() {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [DriveNameValue, setDriveNameValue] = useState();
  const [DriveArray, setDriveArray] = useState([]);
  const [candidatesArray, setcandidatesArray] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropValue, setDropValue] = useState(false);
  const [candidateId, setCandidateId] = useState();
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.

  const [loaderstate, setloderstate] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [disableStatus, setDisableStatus] = useState();
  const [done, setDone] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const openDrop = Boolean(anchorEl);

  const handleSubmit = () => {
    // setOpen(true);
    // navigate("/recruiter-candidates-hired-add-candidates");
  };
  useEffect(() => {
    getAllDriveData();
  }, []);

  useEffect(() => {
    if (DriveNameValue !== undefined) {
      getCandidatesDataByDriveID(DriveNameValue);
    }
  }, [DriveNameValue]);

  let pageNumber = 1;

  const handlePageClick = async (e, pageNumber) => {
    pageNumber = e.selected;
  };

  const getAllDriveData = async () => {
    API.getDriveData().then(({ response }) => {
      if (response.data.status) {
        const data = response.data.data.rows;
        const firstIndex = response.data.data.rows[0].id;
        setDriveNameValue(firstIndex);
        const updatedData = data.map((items, index) => ({
          id: items.id,
          full_name: items.drive_name,
        }));
        setDriveArray(updatedData);
      }
    });
  };

  const handleClick = (event, id, status) => {
    setCandidateId(id);
    setAnchorEl(event.currentTarget);
    setDisableStatus(status);
  };

  const handleDriveData = async (e) => {
    setDriveNameValue(e.target.value);
  };

  const getCandidatesDataByDriveID = async (DriveNameValue) => {
    API.getCandidatesDataByDrive(DriveNameValue).then((response) => {
      if (response.data.status) {
        const data = response.data.data.rows;
        const updatedData = data.map((items, index) => ({
          sno: index + 1,
          id: items.id,
          CandidateName: `${items.first_name} ${items.last_name}`,
          UniversityCollege: items.college_name,
          Location: items.location,
          CandidateEmail: items.email,
          PhoneNumber: items.phone_no,
          Score: items.cgpa,
        }));
        setcandidatesArray(updatedData);
        setLoading(false);
      } else {
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "error",
        });
        setcandidatesArray([]);
        setLoading(false);
      }
    });
  };

  const disableEnableDrive = (dropValue, candidateId, disableStatus) => {
    const value = disableStatus === 0 ? true : false;
    const message = value
      ? "Candidate Enabled Successfully"
      : "Candidate Disable Successfully";
  };

  const handleCloseDrop = () => {
    setAnchorEl(null);
  };

  const handleDisable = () => {
    setDropValue(!dropValue);
    setAnchorEl(null);
    disableEnableDrive(dropValue, candidateId, disableStatus);
  };

  const handleDeleteData = () => {
    setAnchorEl(null);
  };


  const handleEditData = () => {
    setAnchorEl(null);
    setModalOpen(true);

    editCandidateData(candidateId);
  };

  const editCandidateData = (candidateId) => {
    // API.editOneCandidate(candidateId).then(({ response }) => {
    //   if (response.data.status === true) {
    //     setEditData(response.data.data);
    //     setloderstate(true);
    //     setCourseData(response.data.data.courses);
    //     setBranchData(response.data.data.branches);
    //   }
    // });
  };

  const navigation = (id) => {
    navigate("/recruiter-candidates-hired-add-candidates", {
      state: { data: id },
    });
  };

  const rowColumns = [
    {
      field: "sno",
      headerName: "S.No",
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      flex: 1,
      disable: true,
    },
    {
      field: "id",
      headerName: "id",
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },

    {
      field: "CandidateName",
      headerName: "Candidate Name",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <span
            style={{ cursor: "pointer", color: "#00728E" }}
            onClick={() => navigation(params.id)}
          >
            {params.row.CandidateName}
          </span>
        </>
      ),
      flex: 1,
    },
    {
      field: "UniversityCollege",
      headerName: "University/College",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    { field: "Location", headerName: "Location", align: "center", flex: 1 },
    {
      field: "CandidateEmail",
      headerName: "Candidate Email",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      align: "center",
      headerAlign: "center",
      flex: 1,
      disable: true,
    },

    {
      field: "Score",
      headerName: "Score",
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      flex: 1,
    },

    {
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              id="positioned-demo-button"
              aria-controls={openDrop ? "positioned-demo-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openDrop ? "true" : undefined}
              variant="outlined"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#00728E",
              }}
              color="neutral"
              onClick={(event) =>
                handleClick(event, params.id, params.row.status)
              }
            >
              <MoreVert />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box
        className="Candidate-main-container"
        style={{ marginBottom: "10px" }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <div
            className="about-breadcrumbs"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  "&:hover": { textDecoration: "none", color: "#fff" },
                }}
                color="inherit"
                href="/recruiter-home"
              >
                <WestIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Home
              </Link>
              <Link
                // underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "#fff",
                  "&:hover": { textDecoration: "none", color: "#fff" },
                }}
                color="inherit"
              >
                Candidates Hired
              </Link>
            </Breadcrumbs>
          </div>
          <Logout />
        </div>

        <Box className="Candidate-container">
          <>
            {/* {!done ? ( */}
            {done !== undefined ? (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80vh",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#00728E"}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <Box className="Recruiters_Select_section">
                <Box
                  className="Candidate-Header"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Candidates Hired</span>

                  <ReusableButton
                    style={{
                      float: "right",
                      backgroundColor: "#00728E",
                      textTransform: "capitalize",
                      "&:hover": { backgroundColor: "#00728E" },
                    }}
                    endIcon={<ArrowRightAltIcon />}
                    text="Add Candidate"
                    onClick={handleSubmit}
                  ></ReusableButton>
                </Box>
                <Grid>
                  <Box
                    style={{
                      display: "flex",
                      paddingTop: "20px",
                      width: "100%",
                    }}
                  >
                    {DriveNameValue !== undefined ? (
                      <>
                        <Grid item xs={2}>
                          <div>
                            <div style={{ marginBottom: "10px" }}>
                              <label>Select Drive</label>
                            </div>
                            <ResuableDropdown
                              className="dropDown_Boder_ColorChanges"
                              style={{
                                height: "40px",
                                width: "180px",
                                marginRight: "10px",
                                // color: DriveNameValue === "none" ? "grey" : "",
                              }}
                              name="drive"
                              options={DriveArray}
                              value={DriveNameValue}
                              onChange={handleDriveData}
                              // helperText="asasdsa"
                              size="small"
                              spanText="Select Drive"
                            />
                          </div>
                        </Grid>
                      </>
                    ) : null}
                  </Box>
                </Grid>
              </Box>
            )}
          </>
          \
          {loading === true ? (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#00728E"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <>
              {done === undefined && (
                <>
                  <Box>
                    <Box
                      className="Candidate-table"
                      style={{ height: "100%", width: "100%" }}
                    >
                      <DataTable
                        rows={candidatesArray}
                        columns={rowColumns}
                        rowHeight={40}
                        autoHeight
                        pageSize={5}
                        hideFooter={true}
                        hideFooterPagination={true}
                        hideFooterSelectedRowCount={true}
                        className="assigntable"
                      />
                      <Box
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ReactPaginate
                          nextLabel=">"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={2}
                          marginPagesDisplayed={1}
                          pageCount={pageCount / 5}
                          previousLabel="<"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Box>

      <StyledEngineProvider injectFirst>
        <CssVarsProvider>
          <Menu
            id="positioned-demo-menu"
            anchorEl={anchorEl}
            open={openDrop}
            onClose={handleCloseDrop}
            aria-labelledby="positioned-demo-button"
            placement="bottom-end"
          >
            <MenuItem onClick={handleEditData}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteData}>Delete</MenuItem>
          </Menu>
        </CssVarsProvider>
      </StyledEngineProvider>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
