import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { API } from "../../../Networking/API";
import ResuableDropdown from "../../../CommonComponents/DropDown/DropDown";
import IconButton from "@mui/joy/IconButton";
import Notification from "../../../CommonComponents/Notification/Notification";
import Grid from "@mui/material/Grid";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";
import Logout from "../../../CommonComponents/Logout/Logout";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import "./RecruiterOnGoingDrive.css";
import Collapse from "@mui/material/Collapse";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FlagIcon from "@mui/icons-material/Flag";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import { makeStyles } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import RecruiterMapModal from "./RecruiterMapTrack";
import { unstable_resetCleanupTracking } from "@mui/x-data-grid";
const competencyTypes = ["Aptitude", "Reasoning", "Verbal", "Technical"];

function Row(props) {
  const {
    driveData,
    time,
    dataDrive,
    index,
    setModalOpen,
    setMapLocation,
    SetSelectedCandidate,
    pageNo,
    NoofRows,
    serialNo,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState(false);
  const getFinalData = (EachValue, EntireRowValue) => {
    if (EachValue.category.id === EntireRowValue.category.id) {
      return "Started";
    } else {
      return "Not Started";
    }
  };

  let FinalSortedRowResult = [];
  let Tracking = [];
  // let quizIndex = [];

  let row = driveData;

  for (var EachTrackingdata of row.tracking) {
    Tracking.push(EachTrackingdata.category.id);
  }

  //   for(var Rowdata of row.results){

  //   row.results[Rowdata.category.id - 1] = Rowdata;
  // };

  let NextResult = row.results.map((items, index) => {
    if (items.calculated_percentage !== 0) {
      let CategoryName = items.category.quiz_category_name;
      FinalSortedRowResult.push({
        CategoryName: items.category.quiz_category_name,
        ResultData: items.total_no_of_correct_answers,
      });
    } else if (Tracking.includes(items.category.id)) {
      const TrackedQuizIndex = row.tracking.findIndex(
        (TrackingData) => TrackingData.category.id === items.category.id
      );
      if (row.tracking[TrackedQuizIndex]) {
        FinalSortedRowResult.push({
          CategoryName: items.category.quiz_category_name,
          ResultData: 0,
        });
      } else {
        FinalSortedRowResult.push({
          CategoryName: items.category.quiz_category_name,
          ResultData: "OnGoing",
        });
      }
    } else {
      FinalSortedRowResult.push({
        CategoryName: items.category.quiz_category_name,
        ResultData: "Not Started",
      });
    }
  });

  const CheckRowData = (tracking, result) => {
    if (result.length === tracking.length) {
      result.map((each) => {
        return tracking.map((item) => {
          if (
            each.category.quiz_category_name ===
              item.category.quiz_category_name &&
            item.is_question_submitted === true
          ) {
            return false;
          } else {
            return true;
          }
        });
      });
    } else {
      return true;
    }
  };
  const getFixedValue = (value) => {
    const toRound = Number(value).toPrecision(3);
    return toRound;
  };

  const getSerialNo = (index) => {
   let value = ((serialNo - 1) * NoofRows) + index;
   return value;
   
  };
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton>
          {parseFloat((time[index])*60) >= 10.0 ? (
              <FlagIcon style={{ color: "#BB0000" }} />
            ) : (
              ""
            )}
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {/* {index + 1} */}

          {getSerialNo(index + 1,serialNo)}
        </TableCell>
        <TableCell align="center" style={{ textTransform: "capitalize" }}>
          {row.first_name}
        </TableCell>
        <TableCell align="center">{row.email}</TableCell>
        <TableCell align="center">
          {row.results.length === 0
            ? "Not Started"
            : CheckRowData(row.tracking, row.results)
            ? "OnGoing"
            : "Completed"}
        </TableCell>
        <TableCell align="center" style={{ textTransform: "capitalize" }}>
          {dataDrive.location.toLowerCase()}
        </TableCell>
        <TableCell align="center">
          <IconButton>
            {
              <div
                onMouseEnter={() => {
                  setModalOpen(true);
                  setMapLocation(driveData.location);
                  SetSelectedCandidate({
                    firstName: driveData.first_name,
                    lastName: driveData.last_name,
                  });
                }}
              >
                <VisibilityIcon />
              </div>
            }
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow
                    style={{
                      borderBottom: "none",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {row.tracking.length > 0
                      ? row.results.map((each, index) => {
                          let OuterIndex = index;
                          return (
                            <TableRow
                              style={{
                                borderBottom: "none",
                                display: "flex",
                              }}
                            >
                              <TableCell
                                align="right"
                                style={{ fontWeight: "700" }}
                              >
                                {isNaN(
                                  FinalSortedRowResult[OuterIndex].ResultData
                                ) ? (
                                  <span>
                                    {
                                      FinalSortedRowResult[OuterIndex]
                                        .CategoryName
                                    }{" "}
                                    :{" "}
                                    {
                                      FinalSortedRowResult[OuterIndex]
                                        .ResultData
                                    }
                                  </span>
                                ) : (
                                  <span>
                                    {
                                      FinalSortedRowResult[OuterIndex]
                                        .CategoryName
                                    }{" "}
                                    :{" "}
                                    {
                                      FinalSortedRowResult[OuterIndex]
                                        .ResultData
                                    }{" "}
                                  </span>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : [
                          { name: "Technical" },
                          { name: "Aptitude" },
                          { name: "Verbal" },
                          { name: "Reasoning" },
                        ].map((data, index) => (
                          <TableCell
                            align="right"
                            style={{ fontWeight: "700" }}
                          >
                            <span>{data.name} : NA</span>
                          </TableCell>
                        ))}
                  </TableRow>
                </TableHead>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export function RecruiterOnGoingDrive() {
  const [DriveNameValue, setDriveNameValue] = useState("");
  const [DriveArray, setDriveArray] = useState([]);
  const [courseId, setCourseId] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [driveDatas, setDriveData] = useState([]);
  const [dataDrive, setDataDrive] = useState([]);
  const [NoofRows, setNoofrows] = useState(5);
  const [time, setTime] = useState();
  const [loader, setLoader] = useState(false);
  const DriveError = [{ id: 1, full_name: "No Drives" }];
  const [OpenModal, setModalOpen] = useState(false);
  const [MapLocation, setMapLocation] = useState(null);
  const [SelectedCandidate, SetSelectedCandidate] = useState(null);
  const [showErr, setShowErr] = useState(false);
  const [serialNo, setSerialNo] = useState(1);
  const useStyles = makeStyles({
    dropdownStyle: {
      width: "fit-content !important",
      maxHeight: "200px !important",
      height: "fit-content !important",
      top: "230px !important",
    },
  });
  // Here we use item offsets; we could also use page offsets

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });


  useEffect(() => {
    // const courseId = 1;
    if (courseId !== undefined) {
    }
  }, [courseId]);

  useEffect(() => {
    setLoader(false);
    getAllDriveData();
  }, []);

  // let pageNumber = 1;

  const getAllDriveData = async () => {
    await API.getOnGoingDrives().then((response) => {
      setLoader(true);
      if (response.status) {
        const data = response.data.data.rows;
        const updatedData = data
          .filter((items) => items.status === 1)
          .map((Filtreddata) => {
            return { id: Filtreddata.id, full_name: Filtreddata.drive_name };
          });

        if (updatedData.length > 0) {
          setLoader(false);
          setDriveArray(updatedData);
          setDriveNameValue(updatedData[0].id);
          getCandidate(updatedData[0].id);
        } else {
          setLoader(true);
        }
      } else {
        setLoader(true);
        setErrorMsg("No Rows");
      }
    });
  };

  const handleDriveData = async (e) => {
    setLoader(false);
    setDriveNameValue(e.target.value);
    const selected = e.target.value;
    getCandidate(selected);
  };

  const getCandidate = (value) => {
    let FinalcountinMin = [];
    setErrorMsg("");
    // setDriveData([]);
    API.onGoingTrack(value, true, pageNo, NoofRows).then((response) => {
      setLoader(true);
      setShowErr(true);
      if (response.status === 200) {
        setShowErr(false);
        setLoader(true);
        const receivedData = response.data.data;
        receivedData.candidateInfo.rows.map((x) => {
          let word = 0;

          x.backgroundActivity.map((x) => {
            word += x.time_duration_in_minutes;
          });

          FinalcountinMin.push(word.toFixed(2));
        });
        setPageCount(receivedData.candidateInfo.count);
        setTime(FinalcountinMin);
        setDriveData(receivedData.candidateInfo.rows);
        setDataDrive(receivedData.driveInfo);
      } else {
        setDriveData([]);
        setShowErr(true);
        setLoader(true);
        setErrorMsg(response.data.message);
      }
    });
  };
  const handlePageClick = async (e, pageNumber) => {
    pageNumber = e.selected;
    setSerialNo(pageNumber + 1);

    setErrorMsg("");
    // setDriveData([]);
    OnGoingtrackCandidateData(DriveNameValue, true, pageNumber + 1, NoofRows);
  };
  const OnGoingtrackCandidateData = (
    DriveNameValue,
    PaginationValue,
    PageNumber,
    ContentLength
  ) => {
    let FinalcountinMin = [];
    API.onGoingTrack(
      DriveNameValue,
      PaginationValue,
      PageNumber,
      ContentLength
    ).then((response) => {
      if (response.status === 200) {
        setLoader(true);
        const receivedData = response.data.data;

        receivedData.candidateInfo.rows.map((x) => {
          let word = 0;

          x.backgroundActivity.map((x) => {
            word += x.time_duration_in_minutes;
          });

          FinalcountinMin.push(word.toFixed(2));
        });
        setPageCount(receivedData.candidateInfo.count);

        setTime(FinalcountinMin);
        setDriveData(receivedData.candidateInfo.rows);
        setDataDrive(receivedData.driveInfo);
      } else {
        setDriveData([]);
        setLoader(true);
        setErrorMsg(response.data.message);
      }
    });
  };

  return (
    <>
      <Box className="OnGoingCandidate-main-container">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
            marginBottom: "8px",
          }}
        >
          <div
            className="onGoingRecruiterCndidates-about-breadcrumbs"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  "&:hover": { textDecoration: "none", color: "#fff" },
                }}
                color="inherit"
                href="/recruiter-home"
              >
                <WestIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Home
              </Link>
              <Link
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  "&:hover": { textDecoration: "none", color: "#fff" },
                }}
                color="inherit"
              >
                Candidates Ongoing Drive
              </Link>
            </Breadcrumbs>
          </div>

          <div style={{ marginRight: "8.5%" }}>
            <Logout />
          </div>
        </div>

        <Box className="onGoingCandidate-containers">
          <>
            <Box className="onGoingRecruiters_Select_section">
              <Box className="onGoingCandidate-Header">
                <span>Candidates Ongoing Drive</span>
              </Box>
              <Grid>
                <Box
                  style={{
                    display: "flex",
                    paddingTop: "20px",
                    width: "100%",
                  }}
                >
                  <Grid item xs={2}>
                    <div>
                      <div>
                        <label style={{ paddingBottom: "10px" }}>
                          Select Drive
                        </label>
                      </div>
                      {DriveArray.length > 0 ? (
                        <>
                          <ResuableDropdown
                            className="dropDown_Boder_ColorChanges"
                            MenuProps={useStyles}
                            style={{
                              height: "40px",
                              width: "180px",
                              marginRight: "10px",
                              color: DriveNameValue === "none" ? "grey" : "",
                            }}
                            name="Select Drive"
                            // label="Select Source"
                            options={DriveArray}
                            value={DriveNameValue}
                            onChange={handleDriveData}
                            helperText="asasdsa"
                            size="small"
                            spanText="Select Drive"
                          />
                        </>
                      ) : (
                        <>
                          <div className="label-select-class-ongoing">
                            <FormControl sx={{ m: 1, minWidth: 180 }} disabled>
                              <InputLabel
                                id="demo-simple-select-disabled-label"
                                className="select-class-drive-ongoing"
                              >
                                <em>No Drives</em>
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-disabled-label"
                                id="demo-simple-select-disabled"
                                label="Age"
                              ></Select>
                            </FormControl>
                          </div>
                        </>
                      )}
                    </div>
                  </Grid>
                </Box>
              </Grid>
            </Box>
            {!loader ? (
              <Box
                // sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "60vh",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#00728E"}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <>
                {driveDatas.length === 0 ? (
                  <div
                    style={{
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span className="onGoingerror-text">
                      {showErr
                        ? "Drive test result not found"
                        : "No Drives Found"}
                    </span>
                  </div>
                ) : (
                  // <TableContainer component={Paper}>
                  <>
                    <div className="OngoingDriveTrackTableHeader">
                      <Table
                        aria-label="collapsible table "
                        className="ongoingTable"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                color: "white",
                                backgroundColor: "#00728e",
                                borderTopLeftRadius: "7%",
                              }}
                            />
                            <TableCell
                              style={{
                                color: "white",
                                backgroundColor: "#00728e",
                              }}
                            />
                            <TableCell
                              align="center"
                              style={{
                                color: "white",
                                backgroundColor: "#00728e",
                              }}
                            >
                              S.No
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: "white",
                                backgroundColor: "#00728e",
                              }}
                            >
                              Candidate Name
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: "white",
                                backgroundColor: "#00728e",
                              }}
                            >
                              Email
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: "white",
                                backgroundColor: "#00728e",
                              }}
                            >
                              Status
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: "white",
                                backgroundColor: "#00728e",
                              }}
                            >
                              Location
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: "white",
                                backgroundColor: "#00728e",
                                borderTopRightRadius: "7%",
                              }}
                            >
                              Track
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {driveDatas.length > 0
                            ? driveDatas.map((data, index) => {
                                return (
                                  <Row
                                    driveData={data}
                                    index={index}
                                    time={time}
                                    dataDrive={dataDrive}
                                    setModalOpen={setModalOpen}
                                    setMapLocation={setMapLocation}
                                    SetSelectedCandidate={SetSelectedCandidate}
                                    pageNo={pageNo}
                                    NoofRows={NoofRows}
                                    serialNo={serialNo}
                                    // pageNumber={pageNumber}
                                  />
                                );
                              })
                            : null}
                        </TableBody>
                      </Table>
                    </div>

                    {driveDatas.length > 0 ? (
                      <>
                        <Box
                          style={{
                            display: "flex",
                            // justifyContent: "flex-end",
                            justifyContent: "center",
                            // position: "absolute",
                            bottom: "0px",
                            width: "94%",
                            marginTop: "20px",
                            marginRight: "20px",
                          }}
                        >
                          <ReactPaginate
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageCount={pageCount / NoofRows}
                            previousLabel="<"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        </Box>
                        <RecruiterMapModal
                          OpenModal={OpenModal}
                          MapLocation={MapLocation}
                          setModalOpen={setModalOpen}
                          SelectedCandidate={SelectedCandidate}
                        />
                      </>
                    ) : null}
                  </>
                )}
              </>
            )}
          </>
        </Box>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
