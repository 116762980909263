import React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ReusableInputfield from "../../../CommonComponents/TextField/TextField";
import ReusableButton from "../../../CommonComponents/Button/Button";
import CandidateLandingPageImageOne from "../../../Assests/CandidateLandingPageImageOne.png";
import loginwebbg from "../../../Assests/loginwebbg.png";
import CrackItLogo from "../../../Assests/CrackItLogo.png";
import AbsyzNewLogo from "../../../Assests/absyz.svg";
import MobileCrackItLogo from "../../../Assests/Group 337.png";
import Crack from "../../../Assests/Crack.png";
import It from "../../../Assests/IT.png";
import { useLocation } from "react-router-dom";
import "./CandidateLandingPage.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { API } from "../../../Networking/API";
import Notification from "../../../CommonComponents/Notification/Notification";
import { MyForm } from "../../../CommonComponents/MyForm/MyForm";
import { Typography } from "@mui/material";

export default function CandidateLandingPage() {
  const location = useLocation();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  const [locationError, setLocationError] = useState(true);
  const [watchId, setWatchId] = useState(null);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    getLocation();
  }, [locationError]);

  const initialValues = {
    emailId: "",
    passcode: "",
  };

  const validate = (inputValues = values) => {
    let code = { ...errors };

    if ("emailId" in inputValues)
      code.emailId = inputValues.emailId ? "" : "Email ID is required";

    if ("passcode" in inputValues)
      code.passcode = inputValues.passcode ? "" : "Passcode is required";

    setErrors({
      ...code,
    });

    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    validateOnEdit,
  } = MyForm(initialValues, true, validate);

  const getLocation = () => {
    if ("geolocation" in navigator) {
      setWatchId(
        navigator.geolocation.watchPosition(
          (position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
            setLocationError(false);
          },
          (error) => {
            setLocationError(true);
          },
          { enableHighAccuracy: true }
        )
      );
      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    } else {
      setLocationError(true);
    }
  };

  const onHittingSuccess = (token, candidateId, driveId, experience) => {
    const role = 2; //not getting role in api;

    Cookies.set("jwtToken", token, { expires: 30 });
    Cookies.set("role", role, { expires: 30 });
    localStorage.setItem("candidateId", candidateId);
    localStorage.setItem("driveId", driveId);
  };

  const handleGetStartedClick = () => {
    const location = `${[lat, lng]}`;

    getLocation();
    if (validate()) {
      // const location = "Hyderabad";
      if (!locationError) {
        API.candidateLogin(values.passcode, values.emailId, location).then(
          ({ response }) => {
            if (response.status === true) {
              setNotify({
                isOpen: true,
                message: response.message,
                type: "success",
              });
              const timer = setTimeout(() => {
                const tokenData = response.data.token;
                const candidateId = response.data.user.id;
                const driveId = response.data.user.drive_id;
                const experience =
                  response.data.user.candidate_experience_level;
                onHittingSuccess(tokenData, candidateId, driveId, experience);
                const jwtToken = Cookies.get("jwtToken");
                const userRole = Cookies.get("role");

                if (jwtToken !== "") {
                  if (userRole === "2") {
                    navigate("/user-instruction");
                  }
                }
              }, 1000);
              return () => clearTimeout(timer);
            } else {
              setNotify({
                isOpen: true,
                message: response.message,
                type: "error",
              });
            }
          }
        );
      }
    }
  };

  return (
    <>
      <Box
        sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}
        //   style={{ marginTop: "5%" }}
        className="Landing-Page-Outer"
      >
        <Grid container spacing={2}>
          <Card
            //   variant="outlined"
            sx={{
              marginTop: "8%",
              Width: "100%",
              borderRadius: 0,
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "50px",
            }}
          >
            <CardContent style={{ padding: "0px" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={6}
                  md={6}
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <img
                    src={loginwebbg}
                    alt="AdminContentPic"
                    width="300"
                    height="100%"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  sx={{
                    display: { xs: "flex" },
                    flexDirection: "column",
                    alignItems: "center",
                    height: { xs: "100%", sm: "100%" },
                  }}
                  style={{
                    padding: "30px 25px",
                    marginLeft: "30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <img
                    src={CrackItLogo}
                    alt="logo"
                    width="80"
                    height="80"
                  ></img>
                  <span style={{ marginTop: "20px" }} className="recruiter_text" >
                    Recruitment 2023
                    <img src={Crack} alt="logo" width="80" height="17"></img>{" "}
                    &nbsp;
                    <img src={It} alt="logo" width="20" height="20"></img>
                  </span> */}
                  <Box sx={{ marginTop: "30px" }}>
                    <img
                      src={AbsyzNewLogo}
                      alt="logo"
                      // width="80"
                      // height="80"
                    ></img>
                  </Box>

                  <Box
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignContent: "center",
                      width: "100%",
                    }}
                  >
                    <label style={{}}>Enter Email ID</label>
                    <ReusableInputfield
                      variant="outlined"
                      size="small"
                      name="emailId"
                      type="text"
                      value={values.emailId}
                      onChange={handleInputChange}
                      error={errors.emailId ? true : false}
                      helperText={errors.emailId}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <label
                      className="adminLoginHeadingThree"
                      style={{
                        marginTop: "14px",
                        marginBottom: "7px",
                        width: "100%",
                      }}
                    >
                      Please Enter Passcode
                    </label>
                    {!locationError ? (
                      <>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="text"
                          name="passcode"
                          value={values.passcode}
                          maxLength="6"
                          onChange={handleInputChange}
                          error={errors.passcode ? true : false}
                          helperText={errors.passcode}
                          inputProps={{
                            maxLength: 6,
                            style: {
                              textAlign: "center",
                              letterSpacing: "15px",
                            },
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <ReusableInputfield
                          variant="outlined"
                          size="small"
                          type="number"
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                          disabled
                        />
                        <small
                          style={{
                            fontSize: "10px",
                            color: "red",
                            marginTop: "2px",
                          }}
                        >
                          Please allow location access to proceed.
                        </small>
                      </>
                    )}

                    <div style={{ marginTop: "20px" }}>
                      <ReusableButton
                        text={"Get Started"}
                        variant="contained"
                        onClick={handleGetStartedClick}
                        endIcon={<ArrowRightAltIcon />}
                        // state={{candidateId:}}
                        style={{
                          // border: "1px solid #00728E",
                          background: "#00728E",
                          borderRadius: "4px",
                          fontFamily: "Helvetica",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          textTransform: "capitalize !important",
                          "&:hover": { backgroundColor: "#00728E" },
                          padding: "6px 38px",
                        }}
                      />
                      {/* </Link> */}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Notification notify={notify} setNotify={setNotify} />
      </Box>

      <Box
        sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}
        //   style={{ marginTop: "5%" }}
        className="mobile-Landing-Page-Outer"
      >
        <Grid container spacing={2} style={{}}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Box sx={{ marginTop: "20px" }}>
                <img
                  src={AbsyzNewLogo}
                  alt="logo"
                  // width="80"
                  // height="80"
                ></img>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  height: "100vh",
                }}
              >
                <Card
                  sx={{
                    width: "100%",
                    // height: "50vh",
                    borderRadius: "20px 20px 0px 0px",
                  }}
                >
                  <CardContent style={{ padding: "0px" }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={5}
                        sx={{
                          display: { xs: "flex" },
                          flexDirection: "column",
                          alignItems: "center",
                          height: { xs: "100%", sm: "100%" },
                        }}
                        style={{
                          padding: "30px 25px",
                          marginLeft: "30px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          style={{
                            marginTop: "15px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignContent: "center",
                            width: "100%",
                            marginBottom: "30px",
                          }}
                        >
                          <label
                            style={{
                              textAlign: "center",
                              marginBottom: "10px",
                              fontFamily: "Helvetica",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "20px",
                              lineHeight: "23px",
                            }}
                          >
                            Enter Email ID
                          </label>
                          <ReusableInputfield
                            variant="outlined"
                            size="small"
                            name="emailId"
                            type="text"
                            style={{
                              border: "1px solid #00728E",
                              borderRadius: "8px",
                            }}
                            value={values.emailId}
                            onChange={handleInputChange}
                            error={errors.emailId ? true : false}
                            helperText={errors.emailId}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <label
                            className="adminLoginHeadingThree"
                            style={{
                              textAlign: "center",
                              marginBottom: "10px",
                              fontFamily: "Helvetica",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "20px",
                              lineHeight: "23px",
                            }}
                          >
                            Please Enter Passcode
                          </label>
                          {!locationError ? (
                            <>
                              <ReusableInputfield
                                variant="outlined"
                                size="small"
                                type="text"
                                name="passcode"
                                value={values.passcode}
                                maxLength="6"
                                style={{ width: "100%" }}
                                onChange={handleInputChange}
                                error={errors.passcode ? true : false}
                                helperText={errors.passcode}
                                inputProps={{
                                  maxLength: 6,
                                  style: {
                                    textAlign: "center",
                                    letterSpacing: "15px",
                                    border: "1px solid #00728E",
                                    borderRadius: "8px",
                                  },
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <ReusableInputfield
                                variant="outlined"
                                size="small"
                                type="number"
                                inputProps={{
                                  min: 0,
                                  style: { textAlign: "center" },
                                }}
                                disabled
                              />
                              <small
                                style={{
                                  fontSize: "10px",
                                  color: "red",
                                  marginTop: "2px",
                                }}
                              >
                                Please allow location access to proceed.
                              </small>
                            </>
                          )}
                        </Box>
                        <Box style={{ marginTop: "40px" }}>
                          <ReusableButton
                            text={"Get Started"}
                            variant="contained"
                            onClick={handleGetStartedClick}
                            endIcon={<ArrowRightAltIcon />}
                            // state={{candidateId:}}
                            style={{
                              // border: "1px solid #00728E",
                              width: "260px",
                              height: "48px",
                              background: "#00728E",
                              borderRadius: "4px",
                              fontFamily: "Helvetica",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#FFFFFF",
                              textTransform: "capitalize !important",
                              "&:hover": { backgroundColor: "#00728E" },
                              padding: "6px 38px",
                            }}
                          />
                          {/* </Link> */}
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
