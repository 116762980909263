import BaseApiService from "./BaseApiServices";
import { APIRequests, BaseURL } from "./NetworkConstants";

function adminLogin(email, password) {
  // const header = [{ "Content-Type": "application/json" }];
  const header = true;
  const params = JSON.stringify({
    email: email,
    password: password,
  });
  return BaseApiService.post(BaseURL + APIRequests.login, params, header);
}

function candidateLogin(passcode, email, location) {
  // const header = [{ "Content-Type": "application/json" }];
  const header = true;

  const params = JSON.stringify({
    passcode: passcode,
    email: email,
    location: location,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.candidateLoginApi,
    params,
    header
  );
}

async function getDriveData() {
  //  const header = true;
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.getAllDrive
  );
  return response;
}

async function getAllQuizCategories() {
  //  const header = true;

  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.getQuizCategories
  );

  // return BaseApiService.get(BaseURL + APIRequests.getAllDrive);

  return response;
}

// async function getDriveData() {

//   // const page = `&page=${pageNumber}&count=5`;
//   const response = await BaseApiService.axiosInstance(
//     BaseURL + APIRequests.getAllDrive,
//     "get"
//   );

//   return response;
// }

function getCoursesData() {
  const header = true;
  return BaseApiService.get(BaseURL + APIRequests.getAllCourses, header);
}

function getQuizAllQuestions(pageNumber) {
  // const header = true;
  const page = `&page=${pageNumber}&count=5`;
  return BaseApiService.get(BaseURL + APIRequests.getAllQuizQuestions + page);
}

function getBranchData(id) {
  return BaseApiService.get(
    BaseURL + APIRequests.getAllBranchesByCoursesID + id
  );
}

function getCandidatesData(pageNumber) {
  // const header = false;
  const page = `&page=${pageNumber}&count=5`;
  return BaseApiService.get(BaseURL + APIRequests.getAllCandidates + page);
}

async function getCandidatesDataByDrive(id, pageNumber) {
  const page = `&pagination=true&page=${pageNumber}&count=5`;
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.getAllCandidatesByDriveID + id + page,
    "get"
  );

  return response;
}

async function getQuestionsDataBycategory(id, pageNumber) {
  const page = `&pagination=true&page=${pageNumber}&count=5`;
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.getAllQusetionsWithCategory + id + page,
    "get"
  );

  return response;
}

async function getallquestionCategoriesByCategory(id) {
  // const page = `&page=${pageNumber}&count=5`;
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.getQuestionAllCategories + id,
    "get"
  );

  return response;
}

async function getSubCategoriesByCategory(id) {
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.getQuestionSubCategories + id,
    "get"
  );

  return response;
}

// function getPendingDriveData(penStatus, pageNumber) {
//   const header = true;

//   const status = `${penStatus}`;
//   const pagination = `&pagination=true`;
//   const page = `&page=${pageNumber}&count=5`;
//   return BaseApiService.get(
//     BaseURL + APIRequests.getPendingDrive + status + pagination + page,
//     header
//   );
// }

async function getPendingDriveData(penStatus, pageNumber) {
  const header = true;

  const status = `${penStatus}`;
  const pagination = `&pagination=true`;
  const page = `&page=${pageNumber}&count=5`;
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.getPendingDrive + status + pagination + page,
    header,
    "get"
  );

  return response;
}

function getCompletedDriveData(comStatus, pageNumber) {
  const header = true;

  const status = `${comStatus}`;
  const pagination = `&pagination=true`;
  const page = `&page=${pageNumber}&count=5`;
  let ApiResult = BaseApiService.axiosInstance(
    BaseURL + APIRequests.getPendingDrive + status + pagination + page,
    header
  );
  return ApiResult;
}

async function getOnGoingDrives() {
  const header = true;
  const status = "onGoing";
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.getOngoingDrive + status,
    header,
    "get"
  );
  return response;
}

function getAllRecruiters(pageNumber) {
  // authorization
  const header = true;
  const page = `&page=${pageNumber}&count=5`;
  return BaseApiService.axiosInstance(
    BaseURL + APIRequests.recruiter + page,
    header
  );
}

function getAllRecruitersWithoutPagination() {
  // authorization
  // const header = true;
  return BaseApiService.get(BaseURL + APIRequests.recruiter);
}

function getRecruiters() {
  // authorization
  const header = true;

  return BaseApiService.get(BaseURL + APIRequests.oneRecruiter, header);
}

function getOneRecruiters(id) {
  const Id = `${id}`;
  const header = true;
  // authorization
  return BaseApiService.get(BaseURL + APIRequests.oneRecruiter + Id, header);
}

function forgotPassword(token, password) {
  // const header = [{ "Content-Type": "application/json" }];
  const header = true;
  const params = JSON.stringify({
    token: token,
    password: password,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.forgotPassword,
    params,
    header
  );
}

function makeIsHold(driveId, isHoldValue) {
  const header = true;
  const params = JSON.stringify({
    drive_id: driveId,
    is_hold: isHoldValue,
  });

  return BaseApiService.post(BaseURL + APIRequests.isHold, params, header);
}

function makeDismiss(driveId, status) {
  const header = true;
  const params = JSON.stringify({
    drive_id: driveId,
    status: status,
  });

  return BaseApiService.post(BaseURL + APIRequests.isDismiss, params, header);
}

function initiateQuiz(driveId) {
  const header = true;
  const params = JSON.stringify({
    drive_id: driveId,
    // drive_id: 84
  });

  return BaseApiService.post(
    BaseURL + APIRequests.initiateQuizApi,
    params,
    header
  );
}

function createNewDrive(
  firstName,
  universityName,
  location,
  institution,
  driveDate,
  driveTime,
  categories,
  candidate,
  drive,
  competency_level_id,
  recruitersCount,
  platform,
  technology
) {
  const header = true;
  let params;
  if (candidate === "FRESHER") {
    params = JSON.stringify({
      drive_name: firstName,
      college_name: universityName,
      location: location,
      drive_date: driveDate,
      drive_time: driveTime,
      competency_level_id: Number(competency_level_id),
      candidate_experience_level: candidate,
      drive_type: drive,
      quiz_category_id: categories,
      recruiter_ids: recruitersCount,
      question_category_id: platform,
      question_sub_category_id: technology,
    });
  } else {
    params = JSON.stringify({
      drive_name: firstName,
      location: location,
      institute_name: institution,
      drive_date: driveDate,
      drive_time: driveTime,
      // eslint-disable-next-line no-new-wrappers
      competency_level_id: Number(competency_level_id),
      candidate_experience_level: candidate,
      drive_type: drive,
      quiz_category_id: categories,
      recruiter_ids: recruitersCount,
      question_category_id: platform,
      question_sub_category_id: technology,
    });
  }

  return BaseApiService.post(BaseURL + APIRequests.createDrive, params, header);
}

function createNewCandidate(
  firstName,
  lastName,
  email,
  phoneNo,
  universityCollegeName,
  // location,
  DriveNameValue,
  courseType,
  branch,
  year,
  // universityCollegeType,
  cgpa
  // employeeType
) {
  const header = true;
  let params;
  if (universityCollegeName !== "") {
    params = JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_no: phoneNo,
      college_name: universityCollegeName,
      // location: location,
      drive_id: DriveNameValue,
      department: courseType,
      branch: branch,
      college_year: year,
      // college_type: universityCollegeType,
      cgpa: cgpa,
      // employment_type: employeeType,
    });
  } else {
    params = JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_no: phoneNo,
      // college_name: universityCollegeName,
      // location: location,
      drive_id: DriveNameValue,
      // course_type_id: courseType,
      // branch_id: branch,
      college_year: year,
      // college_type: universityCollegeType,
      // cgpa: cgpa,
      // employment_type: employeeType,
    });
  }

  return BaseApiService.post(
    BaseURL + APIRequests.createCandidate,
    params,
    header
  );
}

function updateDriveData(
  first_Name,
  university_Name,
  location,
  institution,
  drive_Date,
  drive_Time,
  competency_level,
  candidate,
  drive,
  categories_Id_Data,
  platform,
  technology,
  recruiter_Id_Data,
  id
) {
  const header = true;
  let params;
  if (candidate === "FRESHER") {
    params = JSON.stringify({
      drive_name: first_Name,
      college_name: university_Name,
      location: location,
      drive_date: drive_Date,
      drive_time: drive_Time,
      competency_level_id: competency_level,
      candidate_experience_level: candidate,
      drive_type: drive,
      quiz_category_id: categories_Id_Data,
      recruiter_ids: recruiter_Id_Data,
      // question_category_id:platform,
      // question_sub_category_id:technology,
    });
  } else {
    let tech = [technology];
    params = JSON.stringify({
      drive_name: first_Name,
      location: location,
      institute_name: institution,
      drive_date: drive_Date,
      drive_time: drive_Time,
      competency_level_id: competency_level,
      candidate_experience_level: candidate,
      drive_type: drive,
      quiz_category_id: categories_Id_Data,
      recruiter_ids: recruiter_Id_Data,
      question_category_id: platform,
      question_sub_category_id: tech,
    });
  }
  const Id = `${id}`;

  return BaseApiService.put(
    BaseURL + APIRequests.updateDrive + Id,
    params,
    header
  );
}

function editCandidateData(
  firstName,
  lastname,
  Email,
  PhoneNumber,
  UniversityCollegeName,
  // location,
  candidateDriveId,
  coursetype,
  branch,
  year,
  // UniversityCollegeType,
  cgpa,
  // employeeType,
  candidateId
) {
  const header = true;
  let params;

  if (UniversityCollegeName !== null) {
    params = JSON.stringify({
      first_name: firstName,
      last_name: lastname,
      email: Email,
      phone_no: PhoneNumber,
      college_name: UniversityCollegeName,
      // location: location,
      drive_id: candidateDriveId,
      department: coursetype,
      branch: branch,
      college_year: year,
      // college_type: UniversityCollegeType,
      cgpa: cgpa,
      // employment_type: employeeType,
    });
  } else {
    params = JSON.stringify({
      first_name: firstName,
      last_name: lastname,
      email: Email,
      phone_no: PhoneNumber,
      // college_name: UniversityCollegeName,
      // location: location,
      drive_id: candidateDriveId,
      // course_type_id: coursetype,
      // branch_id: branch,
      college_year: year,
      // college_type: UniversityCollegeType,
      // cgpa: cgpa,
      // employment_type: employeeType,
    });
  }
  const Id = `${candidateId}`;

  return BaseApiService.put(
    BaseURL + APIRequests.updateCandidate + Id,
    params,
    header
  );
}

function resendTestLinkToCandidate(Id) {
  const header = true;
  const params = JSON.stringify({
    candidate_id: Id,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.resendTestLinkToCandidate,
    params,
    header
  );
}

function adminAddRecruiter(values) {
  const header = true;
  const params = JSON.stringify({
    full_name: values.firstname,
    email: values.Email,
    phone_no: values.PhoneNumber,
    year_of_experience: values.Experience,
    designation_id: values.RecruitersDesignation,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.adminAddRecruiter,
    params,
    header
  );
}

function bulkUploadCandidatesData(file, driveID) {
  const header = false;
  let fd = new FormData();
  fd.append("file", file);
  fd.append("drive_id", driveID);
  // fd.append("course_id", courseID);
  // fd.append("branch_id", branchID);

  return BaseApiService.post(
    BaseURL + APIRequests.bulkUploadCandidate,
    fd,
    header
  );
}

function bulkUploadQuizData(fileMerge, categoryNameValue, subCategoryId) {
  const header = false;
  let fd = new FormData();
  if (subCategoryId.length !== 0) {
    fd.append("file", fileMerge);
    fd.append("quiz_category_id", categoryNameValue);
    fd.append("question_category_id", subCategoryId);
    return BaseApiService.post(
      BaseURL + APIRequests.bulkUploadQuiz,
      fd,
      header
    );
  } else {
    fd.append("file", fileMerge);
    fd.append("quiz_category_id", categoryNameValue);
    return BaseApiService.post(
      BaseURL + APIRequests.bulkUploadQuiz,
      fd,
      header
    );
  }
}

function bulkUploadQuizDataNonTech(fileMerge, categoryNameValue) {
  const header = false;
  let fd = new FormData();
  fd.append("file", fileMerge);
  fd.append("quiz_category_id", categoryNameValue);
  return BaseApiService.post(BaseURL + APIRequests.bulkUploadQuiz, fd, header);
}

function uploadRecruitedCandidatesList(fileMerge, driveId) {
  const header = false;
  let fd = new FormData();
  fd.append("file", fileMerge);
  fd.append("drive_id", driveId);

  return BaseApiService.post(
    BaseURL + APIRequests.uploadRecruitedCandidatesListApi,
    fd,
    header
  );
}

function getOneDriveData(id) {
  const header = true;
  const Id = `${id}`;
  return BaseApiService.get(BaseURL + APIRequests.getOneDriveData + Id, header);
}

async function getCompetencyLevel() {
  const header = true;
  return BaseApiService.get(BaseURL + APIRequests.getCompetencyLevel, header);
}

async function getCandidateOnDrive(id) {
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.getAllCandidatesByDriveID + id,
    "get"
  );

  return response;
}

async function getHiredCandidateList(driveId) {
  const response = await BaseApiService.axiosInstance(
    BaseURL +
    APIRequests.getConsolidatedCandidateOnDrive +
    driveId +
    "&status=HIRED",
    "get"
  );

  return response;
}

async function getQuizCategoryByCandidate(candidateId) {
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.quizCategoryBasedCandidate + candidateId,
    "get"
  );

  return response;
}

async function getAllQuizQuestionsCandidate(id, candidateId) {
  const response = await BaseApiService.axiosInstance(
    BaseURL +
    APIRequests.quizQuestionBasedCandidates +
    candidateId +
    `&quiz_category_id=${id}`,
    "get"
  );
  return response;
}

function getDesignation() {
  const header = true;
  return BaseApiService.get(BaseURL + APIRequests.getDesignations, header);
}

function adminUpdateRecruiter(values, id) {
  const Id = `${id}`;
  const header = true;
  const params = JSON.stringify({
    full_name: values.firstname,
    email: values.Email,
    phone_no: values.PhoneNumber,
    year_of_experience: values.Experience,
    designation_id: values.RecruitersDesignation,
  });

  return BaseApiService.put(
    BaseURL + APIRequests.oneRecruiter + Id,
    params,
    header
  );
}

function sendForgotLink(email) {
  const header = true;
  const params = JSON.stringify({
    email: email,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.sendForgotLink,
    params,
    header
  );
}

function resetpassword(password) {
  const header = true;
  const params = JSON.stringify({
    password: password,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.resetpassword,
    params,
    header
  );
}

const createTrack = (candiateID, quizCatID, curQuestionID, timeConsumed) => {
  const header = true;
  const params = JSON.stringify({
    candidate_id: candiateID,
    quiz_category_id: quizCatID,
    current_question_id: curQuestionID,
    consumed_time_in_minutes: timeConsumed,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.createTrackAPI,
    params,
    header
  );
};

function nextPrevFunc(
  candidateId,
  quizCategoryId,
  timeConsumed,
  currentQstnID,
  nextPrevQstnId,
  optionvalue,
  qstnType
) {
  const header = true;
  const params = JSON.stringify({
    candidate_id: candidateId,
    quiz_category_id: quizCategoryId,
    consumed_time_in_minutes: timeConsumed,
    current_question_id: currentQstnID,
    next_or_previous_question_id: nextPrevQstnId,
    current_answer_id: optionvalue,
    question_type: qstnType,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.nextPrevFuncApi,
    params,
    header
  );
}

function submitQuiz(
  candidateId,
  quizCategoryId,
  timeConsumed,
  currentQstnID,
  clicked,
  questionType
) {
  const header = true;
  const params = JSON.stringify({
    candidate_id: candidateId,
    quiz_category_id: quizCategoryId,
    consumed_time_in_minutes: timeConsumed,
    current_question_id: currentQstnID,
    current_answer_id: clicked,
    question_type: questionType,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.submitQuizApi,
    params,
    header
  );
}

function backFunc(candidateId, quizCatId, timeConsumed) {
  const header = true;
  const params = JSON.stringify({
    candidate_id: candidateId,
    quiz_category_id: quizCatId,
    consumed_time_in_minutes: timeConsumed,
  });
  return BaseApiService.post(BaseURL + APIRequests.backFuncApi, params, header);
}

async function GetCreateTrack(candidateId, quizCatid) {
  const addUrl = `${candidateId}&quiz_category_id=${quizCatid}`;
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.GetCreateTrackApi + addUrl,
    "get"
  );
  return response;
}

async function getActiveSession(candidateId) {
  const addUrl = `${candidateId}&status=true`;
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.getActiveSessionApi + addUrl,
    "get"
  );
  return response;
}

async function getAllActiveSession(candidateId) {
  const addUrl = `${candidateId}`;
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.getActiveSessionApi + addUrl,
    "get"
  );

  return response;
}

function getDisableEnableDrive(status, id) {
  const header = true;

  const params = JSON.stringify({
    candidate_id: id,
    status: status,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.disableCandidate,
    params,
    header
  );
}

function deleteCandidateData(id) {
  const header = true;
  const Id = `${id}`;

  return BaseApiService.Delete(
    BaseURL + APIRequests.deleteCandidate + Id,
    header
  );
}

function deleteRecruiterCall(id) {
  const header = true;

  const Id = `${id}`;

  return BaseApiService.Delete(
    BaseURL + APIRequests.deleteRecruiter + Id,
    header
  );
}

function editOneCandidate(id) {
  const header = true;
  const Id = `${id}`;

  return BaseApiService.get(BaseURL + APIRequests.getOneCandidate + Id, header);
}

function onecandidate(id) {
  const header = true;
  const Id = `${id}`;

  return BaseApiService.get(BaseURL + APIRequests.getOneCandidate + Id, header);
}

function sendLinkCandidate(driveId, courseId, competencyLevel) {
  const header = true;
  const params = JSON.stringify({
    drive_id: driveId,
    course_id: courseId,
    competency_level: competencyLevel,
  });
  return BaseApiService.post(BaseURL + APIRequests.sendLink, params, header);
}

const getQuizCategoryData = () => {
  return BaseApiService.get(BaseURL + APIRequests.quizCategory);
};
// function getConsolidatedCandidateList(driveId) {
//   const header = true;

//   return BaseApiService.get(
//     BaseURL + APIRequests.getConsolidatedCandidateOnDrive + driveId,
//     header
//   );
// }

async function getConsolidatedCandidateList(driveId) {
  const header = true;
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.getConsolidatedCandidateOnDrive + driveId,
    header,
    "get"
  );

  return response;
}

function getConsolidatedRecruitedCandidateList(driveId) {
  const header = true;

  return BaseApiService.get(
    BaseURL +
    APIRequests.getConsolidatedCandidateOnDrive +
    driveId +
    "&status=HIRED",
    header
  );
}

// const getQuizCategoryByCandidate = () => {
//   return BaseApiService.get(BaseURL + APIRequests.quizCategoryBasedCandidate);
// };

const getQuizCategoryQuestions = (id) => {
  return BaseApiService.get(BaseURL + APIRequests.quizQuestionsId + id);
};

const getCsvDataToDownload = (id) => {
  return BaseApiService.get(BaseURL + APIRequests.getCsvData + id);
};

const createBackgroundTracking = (candidateId, fromTime, toTime) => {
  const header = true;

  const params = JSON.stringify({
    candidate_id: candidateId,
    from_time: fromTime,
    to_time: toTime,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.backgroundTracking,
    params,
    header
  );
};

const submitFresherRegistration = (drive_id,
  firstName,
  lastName,
  email,
  phNo,
  rollNo,
  // collegeEmail,
  cgpa,
  collegeName,
  selectedCourse,
  selectedBranch,
  collegeYear) => {
  const header = true;
  const params = JSON.stringify({
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone_no: phNo,
    college_name: collegeName,
    department: selectedCourse,
    college_id: rollNo,
    college_year: collegeYear,
    branch: selectedBranch,
    cgpa: cgpa,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.submitFresherRegistration + drive_id,
    params,
    header
  );
};

const submitTrainedRegistration = (drive_id,
  firstName,
  lastName,
  email,
  phNo,
  collegeYear) => {
  const header = true;
  const params = JSON.stringify({
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone_no: phNo,
    college_year: collegeYear,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.submitFresherRegistration + drive_id,
    params,
    header
  );
};


// const checkCandidateStatus = (id) => {
//   return BaseApiService.get(BaseURL + APIRequests.checkCandidateStatus + id);
// };

async function checkCandidateStatus(id) {
  const Id = `${id}`;
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.checkCandidateStatus + Id,
    "get"
  );
  return response;
}

// const onGoingTrack = (id) => {
//   const Id = `${id}`;

//   return BaseApiService.get(BaseURL + APIRequests.onGoingTrack + Id);
// };

async function onGoingTrack(id, paginationValue, pageNumber, ContentSize) {
  const Id = `${id}`;
  const pagination = `&pagination=${paginationValue}`;
  const page = `&page=${pageNumber}`;
  const count = `&count=${ContentSize}`;
  const response = await BaseApiService.axiosInstance(
    BaseURL + APIRequests.onGoingTrack + Id + pagination + page + count,
    "get"
  );

  return response;
}

const candidateSummary = async (candidateId, quizCategoryId) => {
  const header = true;

  const params = JSON.stringify({
    candidate_id: candidateId,
    quiz_category_id: quizCategoryId,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.candidateSummary,
    params,
    header
  );
};

export const API = {
  adminLogin,
  getDriveData,
  getAllRecruiters,
  getAllRecruitersWithoutPagination,
  getOneRecruiters,
  forgotPassword,
  getPendingDriveData,
  getOnGoingDrives,
  getCompletedDriveData,
  createNewDrive,
  adminAddRecruiter,
  getDesignation,
  adminUpdateRecruiter,
  sendForgotLink,
  resetpassword,
  getOneDriveData,
  updateDriveData,
  makeIsHold,
  getCoursesData,
  getBranchData,
  getCandidatesData,
  getDisableEnableDrive,
  deleteCandidateData,
  editOneCandidate,
  editCandidateData,
  sendLinkCandidate,
  getCandidatesDataByDrive,
  createNewCandidate,
  bulkUploadCandidatesData,
  makeDismiss,
  deleteRecruiterCall,
  getRecruiters,
  getAllQuizCategories,
  getSubCategoriesByCategory,
  bulkUploadQuizData,
  bulkUploadQuizDataNonTech,
  getQuizAllQuestions,
  getallquestionCategoriesByCategory,
  initiateQuiz,
  uploadRecruitedCandidatesList,
  resendTestLinkToCandidate,
  candidateLogin,
  getQuestionsDataBycategory,
  getConsolidatedCandidateList,
  getConsolidatedRecruitedCandidateList,
  getQuizCategoryByCandidate,
  getQuizCategoryQuestions,
  getCandidateOnDrive,
  getHiredCandidateList,
  getAllQuizQuestionsCandidate,
  createTrack,
  GetCreateTrack,
  nextPrevFunc,
  onGoingTrack,
  onecandidate,
  backFunc,
  submitQuiz,
  getActiveSession,
  getAllActiveSession,
  createBackgroundTracking,
  onGoingTrack,
  getCompetencyLevel,
  candidateSummary,
  getCsvDataToDownload,
  submitFresherRegistration,
  submitTrainedRegistration,
  checkCandidateStatus,
};
