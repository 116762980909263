import * as React from "react";
import { useState, useEffect } from "react";
// import axios from "axios";
import Box from "@mui/material/Box";
// import PropTypes from "prop-types";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
// import "./AdminDriveTable.css";
import ReusableButton from "../../../CommonComponents/Button/Button";
import { Icons } from "../../../Utilis/icons";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import AdminAddRecruiters from "./AdminAddRecruiters";
import "./AdminRecruitersList.css";
import WestIcon from "@mui/icons-material/West";
import { API } from "../../../Networking/API";
import { Link } from "react-router-dom";
import { AdminRecruiterEditPage } from "./AdminRecruiterEditPage";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import Cookies from "js-cookie";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import LinkM from "@mui/material/Link";
import ProfilePic from "../../../Assests/Group 766.png";
import ReactLoading from "react-loading";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListDivider from "@mui/joy/ListDivider";
import MoreVert from "@mui/icons-material/MoreVert";
import Edit from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
// import ProfilePic from '../../../Assests/Group 766.png';
import IconButton from "@mui/joy/IconButton";
import Notification from "../../../CommonComponents/Notification/Notification";
import ReactPaginate from "react-paginate";
import Logout from "../../../CommonComponents/Logout/Logout";
import { DataTable } from "../../../CommonComponents/DataTable/DataTable";

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export function AdminRecruitersList() {
  const [open, setOpen] = useState(false);
  const [dialogue, setDialogue] = useState(false);
  const [page, setPage] = useState([]);
  const [tableData1, setTableData1] = useState("");
  const [designation, setDesignation] = useState("");
  const [done, setDone] = useState(true);
  const [id, setId] = useState();
  const [IdValue, setIdValue] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const image = localStorage.getItem("image");
  const [done1, setDone1] = useState(undefined);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [anchorEll, setAnchorEll] = useState(null);
  const openDD = Boolean(anchorEll);
  const [recruiterDesignations, setRecruiterDesignation] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getDropdown();
  }, []);

  const getDropdown = () => {
    API.getDesignation().then(({ response }) => {
      if (response.data.status) {
        const ResponseData = response.data.data;
        const updatedData = ResponseData.map((each) => ({
          id: each.id,
          title: each.designation_name,
        }));
        setRecruiterDesignation(updatedData);
      }
    });
  };

  const handleSubmit = () => {
    setOpen(true);
  };

  const handleDialogueOpen = async (IdParams) => {
    setId(IdParams);
    setAnchorEll(null);
    API.getOneRecruiters(IdValue).then(({ response }) => {
      if (response.data.data.status) {
        setDesignation(response.data.data.designation.id);
        setPage(response.data.data);
        setDone(false);
        setDialogue(true);
      } else {
      }
    });
    // setParamsId(IdParams)
  };

  const handleClose = () => setOpen(false);

  const handleDialogueClose = () => {
    setDialogue(false);
  };

  useEffect(() => {
    RecruiterDrive(pageNumber);
  }, []);

  const RecruiterDrive = (pageNumber) => {
    API.getAllRecruiters(pageNumber)
      .then((response) => {
        if (response.data.status) {
          const data = response.data.data.rows;

          const updatedData = data.map((items, index) => ({
            sno: (pageNumber - 1) * 5 + (index + 1),
            id: items.id,
            RecruiterName: items.full_name,
            Designation: items.designation?.designation_name,
            Experience: items.year_of_experience,
            EmailAddress: items.email.toLowerCase(),
            PhoneNumber: items.phone_no,
          }));

          setPageCount(response.data.data.count);
          setTableData1(updatedData);
          setDone1(true);
        } else {
          setDone1(true);
        }
      })
      .catch(() => {
        setDone1(true);
      });
  };

  const handleClick = (event, id) => {
    setAnchorEll(event.currentTarget);
    setIdValue(id);
  };

  const handleCloseDots = () => {
    setAnchorEll(null);
  };

  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      width: 50,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "RecruiterName",
      headerName: "Recruiter Name",
      flex: 1,
      cellClassName: "pending-table",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box>
          <p
            style={{
              color: "#00728E",
              cursor: "pointer",
              display: "contents",
            }}
            onClick={handleDialogueOpen}
          >
            {params.row.RecruiterName}
          </p>
        </Box>
      ),
    },

    {
      field: "Designation",
      headerName: "Designation",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "Experience",
      headerName: "Experience",
      width: 100,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "EmailAddress",
      headerName: "Email Address",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              id="positioned-demo-button"
              aria-controls={openDD ? "positioned-demo-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openDD ? "true" : undefined}
              variant="outlined"
              color="neutral"
              sx={{ border: "none" }}
              onClick={(event) => handleClick(event, params.id)}
            >
              <MoreVert />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleDeleteData = () => {
    setAnchorEll(null);
    setDone1(undefined);

    API.deleteRecruiterCall(IdValue).then((response) => {
      if (response.result.status === true) {
        setNotify({
          isOpen: true,
          message: response.result.message,
          type: "success",
        });
        RecruiterDrive(pageNumber);
      } else {
        setDone1(true);
      }
    });
  };

  let pageNumber = 1;

  const handlerecruiterspaginationClick = async (e, pageNumber) => {
    pageNumber = e.selected;
    RecruiterDrive(e.selected + 1);
  };

  return (
    <Box className="recruiter-main-container">
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "space-between",
          marginTop: 0,
        }}
      >
        <div
          className="AdminRecruiter-about-breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <LinkM
              underline="hover"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#fff",
                "&:hover": { textDecoration: "none", color: "#fff" },
              }}
              color="inherit"
              href="/admin-home"
            >
              <WestIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </LinkM>
            <LinkM
              className="sectionline"
              underline="hover"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#fff",
                "&:hover": { textDecoration: "none", color: "#fff" },
              }}
              color="inherit"
            >
              Recruiters
            </LinkM>
          </Breadcrumbs>
        </div>
        <div style={{ width: "10%" }}>
          <Logout />
        </div>
      </div>
      <Box className="recruiter-container">
        <Box className="recruiter-Header">
          <spam>Recruiters</spam>
        </Box>
        <Box style={{ display: "flex" }}>
          <Box
            style={{
              width: "100%",
              margin: "0px 20px 20px 0px",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <ReusableButton
              style={{
                float: "right",
                backgroundColor: "#00728E",
                textTransform: "capitalize",
                "&:hover": { backgroundColor: "#00728E" },
              }}
              endIcon={<ArrowRightAltIcon />}
              text="Add Recruiter"
              onClick={handleSubmit}
              size="medium"
            ></ReusableButton>
          </Box>
        </Box>
        {/* <TabPanel value={value} index={0}> */}
        <Box
          className="tables"
          style={{ height: "75%", width: "94.5%", marginLeft: "35px" }}
        >
          {!done1 ? (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#00728E"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <>
              <DataTable
                className="LinerRemove"
                rows={tableData1}
                columns={columns}
                pageSize={5}
                rowHeight={50}
                autoHeight
                rowsPerPageOptions={[5]}
                //checkboxSelection
                hideFooterPagination={true}
                hideFooter={true}
              />
              {tableData1.length !== 0 ? 
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "25px",
                  marginRight: "20px",
                }}
              >
                <ReactPaginate
                  nextLabel=">"
                  onPageChange={handlerecruiterspaginationClick}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  pageCount={pageCount / 5}
                  previousLabel="<"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </Box> : null
}
            </>
          )}
        </Box>
        {/* </TabPanel> */}
      </Box>
      <AdminAddRecruiters
        open={open}
        handleClose={handleClose}
        RecruiterDrive={RecruiterDrive}
        pageNumber={pageNumber}
      ></AdminAddRecruiters>

      {dialogue && (
        <AdminRecruiterEditPage
          id={IdValue}
          designation={recruiterDesignations}
          designationName={designation}
          done={done}
          page={page}
          open={dialogue}
          RecruiterDrive={RecruiterDrive}
          handleClose={handleDialogueClose}
          pageNumber={pageNumber}
        />
      )}

      <Menu
        id="positioned-demo-menu"
        anchorEl={anchorEll}
        open={openDD}
        onClose={handleCloseDots}
        aria-labelledby="positioned-demo-button"
        placement="bottom-end"
      >
        <MenuItem onClick={handleDialogueOpen}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteData}>Delete</MenuItem>
      </Menu>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}
