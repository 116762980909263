// eslint-disable-next-line import/no-anonymous-default-export
// const BaseURL = "http://ec2-54-226-35-196.compute-1.amazonaws.com:5000/api";

let BaseURL = process.env.REACT_APP_BACKEND_URL;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  BaseURL = "http://ec2-54-226-35-196.compute-1.amazonaws.com:5000/api";
}

const APIRequests = {
  login: "/auth/login",
  getAllDrive: "/drive",
  recruiter: "/recruiter?pagination=true",
  oneRecruiter: `/recruiter/`,
  createDrive: "/drive",
  resetpassword: "/auth/reset-password",
  getPendingDrive: "/drive?status=",
  getCompletedDrive: "/drive?status=",
  getOngoingDrive: "/drive?status=",
  adminAddRecruiter: "/recruiter",
  getDesignations: "/designation",
  sendForgotLink: "/auth/send-password-reset-email",
  forgotPassword: "/auth/forgot-password",
  getOneDriveData: "/drive/",
  updateDrive: "/drive/",
  isHold: "/drive/hold",
  getAllCourses: "/course-type",
  getAllBranchesByCoursesID: "/branches?course_id=",
  getAllCandidates: "/candidate?pagination=true",
  disableCandidate: "/candidate/disable",
  deleteCandidate: "/candidate/",
  getOneCandidate: "/candidate/",
  createCandidate: "/candidate",
  updateCandidate: "/candidate/",
  sendLink: "/drive/send-invitation",
  getAllCandidatesByDriveID: "/candidate?drive_id=",
  bulkUploadCandidate: "/candidate/bulk-upload",
  isDismiss: "/drive/change/status",
  deleteRecruiter: "/recruiter/",
  getQuizCategories: "/quiz-category",
  getQuestionAllCategories: "/question-category/",
  getQuestionSubCategories: "/question-sub-category/",
  bulkUploadQuiz: "/quiz/upload",
  getAllQuizQuestions: "/quiz?pagination=true",
  initiateQuizApi: "/drive/send-invitation",
  uploadRecruitedCandidatesListApi: "/recruiter/upload/hired-candidates",
  resendTestLinkToCandidate: "/recruiter/resend/test-link",
  candidateLoginApi: "/candidate/login",
  getAllQusetionsWithCategory: "/quiz?quiz_category_id=",
  getConsolidatedCandidateOnDrive:
    "/drive/candidate/consolidated-list?driveId=",
  quizCategory: "/quiz-category",
  quizQuestionsId: "/quiz?quiz_category_id=",
  quizCategoryBasedCandidate: "/quiz/candidate/quiz-category?candidate_id=",
  quizQuestionBasedCandidates: "/quiz/candidate/questions?candidate_id=",
  createTrackAPI: "/quiz/candidate/create-track",
  GetCreateTrackApi: "/quiz/candidate/candidate-tracking?candidate_id=",
  nextPrevFuncApi: "/quiz/candidate/question-change",
  backFuncApi: "/quiz/candidate/section-back",
  submitQuizApi: "/quiz/candidate/submit",
  getActiveSessionApi: "/quiz/candidate/current-session?candidate_id=",
  backgroundTracking: "/candidate-background-tracking",
  onGoingTrack: "/recruiter/drive/ongoing-track?driveId=",
  getCompetencyLevel: "/competency-level",
  candidateSummary: "/quiz/candidate/quiz-summary",
  getCsvData: "/drive/drive-reports/",
  submitFresherRegistration: "/candidate/self-registration/",
  checkCandidateStatus: "/candidate/check-drive-url/",
};

export { BaseURL, APIRequests };
