import * as React from "react";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
// import axios from "axios";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import "./AdminDriveTable.css";
import ReusableButton from "../../../CommonComponents/Button/Button";
// import { Icons } from "../../../Utilis/icons";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import Grid from "@mui/material/Grid";
import ResuableAdminDialogBox from "./AdminRecuitmentDriveDialogueBox";
import ResuableAdminEditDialogBox from "./AdminRecuitmentDriveEditPage";
import { API } from "../../../Networking/API";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import Cookies from "js-cookie";
import ProfilePic from "../../../Assests/Group 766.png";
import moment from "moment";
import WestIcon from "@mui/icons-material/West";
import ReactLoading from "react-loading";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import MoreVert from "@mui/icons-material/MoreVert";
import IconButton from "@mui/joy/IconButton";
import Notification from "../../../CommonComponents/Notification/Notification";
import ReactPaginate from "react-paginate";
import Logout from "../../../CommonComponents/Logout/Logout";
import LinkM from "@mui/material/Link";
import { DataTable } from "../../../CommonComponents/DataTable/DataTable";
let pageNumber = 1;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    textTransform: "capitalize",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& > .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const datagridSx = {
  "& .MuiDataGrid-autoHeight": {
    marginRight: "12px !important",
    marginLeft: "12px !important",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "rgb(0, 114, 142) !important",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    color: "#ffffff !important",
    fontSize: "0.9rem !important",
  },
};

export function AdminDriveTable() {
  // const [dataHandler, setDataHandler] = useState([]);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [dialogue, setDialogue] = useState(false);
  const [pendingtableData, setPendingTableData] = useState("");
  const [completedtableData, setCompletedTableData] = useState("");
  const [penStatus, setPenStatus] = useState("pending");
  const [comStatus, setComStatus] = useState("completed");
  const [recruiterId, setRecruiterId] = useState("");
  const [recruitedAlone, setRecruiterAlone] = useState([]);
  const [selectedRecruiterData, setSelectedRecruiterData] = useState([]);
  const [totalRecruitersData, setTotalRecruitersData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [driveIdData, setDriveId] = useState("");
  const [driveStatus, setDriveStatus] = useState("");
  const [HoldStatus, setHoldStatus] = useState("");
  const openMenu = Boolean(anchorEl);
  const image = localStorage.getItem("image");
  const [pageCount, setPageCount] = useState(0);
  const [done, setDone] = useState(false);
  const [pageNoCount, setPageNoCount] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [recruiterFinalData, setRecruiterFinaldata] = useState();
  const [completedPage, setCompletedPage] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [anchor, setAnchor] = React.useState(null);
  const openThreeDots = Boolean(anchor);
  const navigate = useNavigate();

  const handleClickThreeDots = (event, id, status, hold) => {
    setAnchor(event.currentTarget);
    setDriveId(id);
    setDriveStatus(status);
    setHoldStatus(hold);
  };
  const handleCloseThreeDots = () => {
    setAnchor(null);
  };

  // var is_Hold = selectedRecruiterData.is_hold;
  // var holdText;
  // if(is_Hold === 1){
  //   holdText = "Unhold Drive";
  // }
  // else if(is_Hold === 0){
  //   holdText="Hold Drive";
  // }

  var isHolddd = HoldStatus;
  var valueSend;
  // let forText = selectedRecruiterData.is_hold;

  const handleThreeDotsHold = () => {
    setDone(false);
    setAnchor(null);

    if (isHolddd === 0) {
      valueSend = true;
      isHolddd = 1;
      setHoldStatus(1);
      // forText = 1;
    } else if (isHolddd === 1) {
      valueSend = false;
      isHolddd = 0;
      setHoldStatus(0);
      // forText = 0;
    }

    API.makeIsHold(driveIdData, valueSend).then(({ response }) => {
      if (response.status === true) {
        setDone(true);
        setNotify({
          isOpen: true,
          message: `${HoldStatus === 0 ? "Hold" : "UnHold"} Successfully`,
          type: "success",
        });
        const timer = setTimeout(() => {
          handleClose();
          getRecruitmentPendingDrive(penStatus, pageNumber);
        }, 1000);
        return () => clearTimeout(timer);
      }
    });
  };

  const handleThreeDotsDismiss = () => {
    setDone(false);
    setAnchor(null);
    const dismissStatus = 2;
    API.makeDismiss(driveIdData, dismissStatus).then(({ response }) => {
      if (response.status === true) {
        setDone(true);
        setNotify({
          isOpen: true,
          message: "Dismissed Succesfully",
          type: "success",
        });
        const timer = setTimeout(() => {
          handleClose();
          getRecruitmentPendingDrive(penStatus, pageNumber);
        }, 1000);
        return () => clearTimeout(timer);
      }
    });
  };

  const handleSubmit = () => {
    setOpen(true);
  };

  const handlePendingDriveClick = async (id) => {
    setRecruiterId(id);
    // set(true)
    API.getOneDriveData(id).then(({ response }) => {
      let recruitersIds = [];
      let categoriesIds = [];
      if (response.data.status === true) {
        const receivedData = response.data.data;

        const dropdownData = response.data.data.recruiters;

        setDialogue(true);

        setTotalRecruitersData(response.data.data.recruiters);

        setSelectedRecruiterData(receivedData);
        const updatedData = dropdownData.map((items, index) => ({
          // id: items.id,
          value: items.id,
        }));
        setRecruiterAlone(updatedData);

        const recruiterData1 = receivedData.recruiters.map((items, id) => {
          recruitersIds.push(items.id);
          return { id: items.id };
        });

        setRecruiterFinaldata(recruitersIds);

        const categoryData1 = receivedData.quizCategory.map((items, id) =>
          categoriesIds.push(items.quiz_category_name)
        );

        setCategoryData(categoriesIds);
      }
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleCompletedDriveClick = async (id) => {
  //   setRecruiterId(id);
  //   API.getOneDriveData(id).then(async ({ response }) => {
  //     if (response.data.status === true) {
  //       const receivedData = response.data.data;
  //       const dropdownData = response.data.data.recruiters;

  //       setDialogue(true);
  //       let array = [];
  //       setSelectedRecruiterData(receivedData);
  //       const hoo = dropdownData[0].id;
  //       setFirstIndexData(hoo);

  //       dropdownData.map((items, index) => {
  //         return array.push(items.id);
  //       });

  //       // const firstElement = array.shift()

  //       // const
  //       let i = 1;
  //       let dummy_array = [];
  //       while (i < array.length) {

  //         dummy_array.push({ value: array[i] });
  //         let Object = { value: array[i] };
  //         setRecruiterAlone((recruitedAlone) => [...recruitedAlone, Object]);
  //         i++;
  //       }
  //     }
  //   });
  // };

  const handleCompletedDriveClick = async (driveId) => {
    const driveCall = async () => {
      let variables = 0;
      await API.getOneDriveData(driveId).then(({ response }) => {
        if (response.data.status === true) {
          variables = response.data.data;
        }
      });
      return variables;
    };
    const oneDriveData = await driveCall();

    const candidateCall = async () => {
      let variable = 0;
      await API.getCandidateOnDrive(driveId).then((response) => {
        if (response.data.status === true) {
          const data = response.data.data.rows;
          const updatedData = data.map((items, index) => ({
            id: index + 1,
            Candidates: items.first_name,
          }));
          variable = updatedData;
        }
      });
      return variable;
    };
    const candidateData = await candidateCall();

    navigate("/admin-completed-candidates-list", {
      state: { driveData: oneDriveData, candidate: candidateData },
    });
  };

  const handleClose = () => setOpen(false);

  const handleDialogueClose = () => {
    setDialogue(false);
  };

  useEffect(() => {
    getRecruitmentPendingDrive(penStatus, pageNumber);
    getRecruitmentCompletedDrive(comStatus, pageNumber);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRecruitmentPendingDrive = (penStatus, pageNumber) => {
    // setDone(false);

    let pageIndex = 0;
    API.getPendingDriveData(penStatus, pageNumber).then((response) => {
      if (pageNumber < 1) {
        pageIndex = pageNumber - 1;
      }
      if (response.data.status) {
        // setDone(true);
        const data = response.data.data.rows;
        const updatedData = data.map((items, index) => {
          const time = moment(items.drive_time, ["HH:mm"]).format("hh:mm A");

          return {
            // sno: index + 1 +(pageIndex * 5),
            sno: (pageNumber - 1) * 5 + (index + 1),
            id: items.id,
            DriveName: items.drive_name,
            UniversityCollage:
              items.college_name === null
                ? items.institute_name
                : items.college_name,
            Location: items.location.toLowerCase(),
            Date: items.drive_date,
            Time: time,
            Status:
              items.status === 2
                ? "Dismissed"
                : items.is_hold === 1
                ? "Hold"
                : items.drive_running_status,
            ActiveStatus: items.status,
            isHold: items.is_hold,
          };
        });
        setPageCount(response.data.data.count);
        setPendingTableData(updatedData);
        setDone(true);
      } else {
        // setNotify({
        //   isOpen: true,
        //   message:`Pending ${response.data.message}`,
        //   type: "error",
        // });
        setDone(true);
      }
    });
  };

  const getRecruitmentCompletedDrive = (comStatus, pageNumber) => {
    // setDone(false);

    API.getCompletedDriveData(comStatus, pageNumber).then((response) => {
      if (response.data.status) {
        setDone(true);
        // setDone(true)

        const data = response.data.data.rows;
        //  const time = moment(items.drive_time, ["HH:mm"]).format("hh:mm A");

        const updatedData = data.map((items, index) => {
          const time = moment(items.drive_time, ["HH:mm"]).format("hh:mm A");
          return {
            sno: (pageNumber - 1) * 5 + (index + 1),
            id: items.id,
            DriveName: items.drive_name,
            UniversityCollage:
              items.college_name === null
                ? items.institute_name
                : items.college_name,
            Location: items.location.toLowerCase(),
            Date: items.drive_date,
            Time: time,
            Status:
              items.status === 2
                ? "Dismissed"
                : items.is_hold === 1
                ? "Hold"
                : items.drive_running_status,
            ActiveStatus: items.status,
          };
        });
        setCompletedPage(response.data.data.count);
        setCompletedTableData(updatedData);
      } else {
        // setNotify({
        //   isOpen: true,
        //   message: `Completed ${response.data.message}`,
        //   type: "error",
        // });
        setDone(true);
      }
    });
  };

  const rowColumns = [
    {
      field: "sno",
      headerName: "S.No",
      align: "center",
      headerClassName: "AdminSno1-header",
      cellClassName: "pending-table",
      // width: 130,
      flex: 1,
    },
    {
      field: "DriveName",
      headerName: "Drive Name",
      headerClassName: "AdminSno-header",
      align: "left",
      cellClassName: "pending-table",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.ActiveStatus === 2 ? (
            <div
              style={{
                pointerEvents: "none",
                color: "rgba(0, 0, 0, 0.87)",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <span>{params.row.DriveName}</span>
            </div>
          ) : (
            <div
              style={{
                cursor: "pointer",
                color: "#00728E",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <span onClick={() => handlePendingDriveClick(params.id)}>
                {params.row.DriveName}
              </span>
            </div>
          )}
        </>
      ),
    },
    {
      field: "UniversityCollage",
      headerName: "University/Institutions",
      headerClassName: "AdminSno-header AdminHeader_Styles",
      cellClassName: "pending-table AdminUnviersty_Styles",
      align: "left",
      flex: 1,
    },
    {
      field: "Location",
      headerName: "Location",
      headerClassName: "AdminSno-header",
      cellClassName: "pending-table",
      align: "left",
      flex: 1,
    },
    {
      field: "Date",
      headerName: "Date",
      headerClassName: "AdminSno-header",
      cellClassName: "pending-table",
      align: "left",
      flex: 1,
    },
    {
      field: "Time",
      headerName: "Time",
      headerClassName: "AdminSno-header",
      cellClassName: "pending-table",
      align: "left",
      flex: 1,
    },
    {
      field: "Status",
      headerName: "Status",
      headerClassName: "AdminSno-header",
      align: "left",
      cellClassName: "pending-table",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "Action",
      cellClassName: "action-sub",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            {params.row.ActiveStatus === 2 ? (
              <IconButton
                id="positioned-demo-button"
                aria-controls={
                  openThreeDots ? "positioned-demo-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openThreeDots ? "true" : undefined}
                variant="outlined"
                color="neutral"
                sx={{
                  pointerEvents: "none",
                  color: "rgb(0 0 0 / 39%)",
                  border: "none",
                }}
              >
                <MoreVert />
              </IconButton>
            ) : (
              <IconButton
                id="positioned-demo-button"
                aria-controls={
                  openThreeDots ? "positioned-demo-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openThreeDots ? "true" : undefined}
                variant="outlined"
                color="neutral"
                sx={{ border: "none" }}
                onClick={(event) =>
                  handleClickThreeDots(
                    event,
                    params.id,
                    params.row.status,
                    params.row.isHold
                  )
                }
              >
                <MoreVert />
              </IconButton>
            )}
            {/* <IconButton
              id="positioned-demo-button"
              aria-controls={openThreeDots ? "positioned-demo-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openThreeDots ? "true" : undefined}
              variant="outlined"
              color="neutral"
              sx={{ border: "none" }}
              onClick={(event) =>
                handleClickThreeDots(
                  event,
                  params.id,
                  params.row.status,
                  params.row.isHold
                )
              }
            >
              <MoreVert />
            </IconButton> */}
          </>
        );
      },
    },
  ];

  const completeColumns = [
    {
      field: "sno",
      headerName: "S.No",
      align: "center",
      headerClassName: "AdminSno1-header",
      cellClassName: "pending-table",
      flex: 1,
    },
    {
      field: "DriveName",
      headerName: "Drive Name",
      headerClassName: "AdminSno-header",
      cellClassName: "pending-table",
      align: "left",
      renderCell: (params) => (
        <>
          <div
            style={{
              cursor: "pointer",
              color: "#00728E",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <span onClick={() => handleCompletedDriveClick(params.id)}>
              {params.row.DriveName}
            </span>
          </div>
        </>
      ),
      flex: 1,
    },
    {
      field: "UniversityCollage",
      headerName: "University/Institutions",
      headerClassName: "AdminSno-header",
      cellClassName: "pending-table",
      align: "left",
      flex: 1,
    },
    {
      field: "Location",
      headerName: "Location",
      headerClassName: "AdminSno-header",
      cellClassName: "pending-table",
      align: "left",
      flex: 1,
    },
    {
      field: "Date",
      headerName: "Date",
      headerClassName: "AdminSno-header",
      cellClassName: "pending-table",
      align: "left",
      flex: 1,
    },
    {
      field: "Time",
      headerName: "Time",
      headerClassName: "AdminSno-header",
      cellClassName: "pending-table",
      align: "left",
      flex: 1,
    },
    {
      field: "Status",
      headerName: "Status",
      headerClassName: "AdminSno-header",
      cellClassName: "pending-table",
      align: "left",
      flex: 1,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (value === 0) {
      let pending = "pending";
      let pageNumber = 1;
      getRecruitmentPendingDrive(pending, pageNumber);
    } else {
      let completed = "completed";
      let pagenumber = 1;
      getRecruitmentCompletedDrive(completed, pagenumber);
    }
  };

  const handleHomeClick = () => {
    navigate("/admin-home");
  };

  const handleCloseMenu = () => {
    navigate("/");
    Cookies.remove("jwtToken");
  };

  const handleCloseMenuBar = () => {
    setAnchorEl(null);
  };

  const handlependingClick = async (e, pageNumber) => {
    pageNumber = e.selected;
    getRecruitmentPendingDrive("pending", e.selected + 1);
    // getRecruitmentCompletedDrive("completed",e.selected + 1)
  };

  const handlecompeletedClick = async (e, pageNumber) => {
    pageNumber = e.selected;
    // setCompletedPage(e.selected + 1);
    getRecruitmentCompletedDrive("completed", e.selected + 1);
  };

  return (
    <Box className="main-container" >
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "space-between",
          marginTop: 0,
        }}
      >
        <div
          className="AdminDrives-about-breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <LinkM
              underline="hover"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#fff",
                "&:hover": { textDecoration: "none", color: "#fff" },
              }}
              color="inherit"
              href="/admin-home"
            >
              <WestIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </LinkM>
            <LinkM
              underline="hover"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#fff",
                "&:hover": { textDecoration: "none", color: "#fff" },
              }}
              color="inherit"
            >
              Recruitment Drives
            </LinkM>
          </Breadcrumbs>
        </div>
        <div style={{ width: "10%" }}>
          <Logout />
        </div>
      </div>
      <Box className="container">
        <Box className="Header">
          <span>Recruitment Drive</span>
        </Box>
        <Box
          style={{
            display: "flex",
            width: "99%",
            justifyContent: "space-around",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tabs"
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              label="Pending"
              style={{ textTransform: "capitalize" }}
              {...a11yProps(0)}
            />
            <Tab
              label="Completed"
              style={{ textTransform: "capitalize" }}
              {...a11yProps(1)}
            />
          </Tabs>
          <Box
            style={{
              width: "76%",
              // marginLeft: "64%",
              // paddingRight: "35px",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <ReusableButton
              style={{
                float: "right",
                backgroundColor: "#00728E",
                textTransform: "capitalize",
                "&:hover": { backgroundColor: "#00728E" },
              }}
              size="medium"
              endIcon={<ArrowRightAltIcon />}
              text="Create Drive"
              onClick={handleSubmit}
            ></ReusableButton>
          </Box>
        </Box>
        <>
          {!done ? (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#00728E"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <>
              <TabPanel value={value} index={0}>
                <Box
                  className="table"
                  style={{
                    height: "100%",
                    width: "100%",
                    marginTop: "20px",
                    marginBottom: "50px",
                  }}
                >
                  <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                    <DataTable
                      className="styleTable"
                      style={{ width: "100%" }}
                      rows={pendingtableData}
                      columns={rowColumns}
                      sx={datagridSx}
                      autoHeight
                      rowHeight={50}
                      pageSize={5}
                      getRowClassName={(params) =>
                        `dismissed${params.row.ActiveStatus}`
                      }
                      rowsPerPageOptions={[5]}
                      disableSelectionOnClick={true}
                      hideFooterSelectedRowCount={true}
                      // checkboxSelection
                      hideFooterPagination={true}
                      hideFooter={true}
                    />
                  </div>

                  {pendingtableData.length !== 0 ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        marginRight: "20px",
                      }}
                    >
                      <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlependingClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageCount={pageCount / 5}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </Box>
                  ) : null}
                </Box>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Box
                  className="table"
                  style={{
                    height: "100%",
                    width: "100%",
                    marginTop: "20px",
                    marginBottom: "50px",
                  }}
                >
                  <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                    <DataTable
                      style={{
                        width: "100%",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                      rows={completedtableData}
                      columns={completeColumns}
                      autoHeight
                      rowHeight={50}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      sx={datagridSx}
                      hideFooterSelectedRowCount={true}
                      hideFooterPagination={true}
                      hideFooter={true}
                      getRowClassName={(params) =>
                        `dismissed${params.row.ActiveStatus}`
                      }
                    />
                  </div>

                  {completedtableData.length !== 0 ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        marginRight: "20px",
                      }}
                    >
                      <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlecompeletedClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageCount={completedPage / 5}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </Box>
                  ) : null}
                </Box>
              </TabPanel>
            </>
          )}
        </>
      </Box>
      <ResuableAdminDialogBox
        open={open}
        handleClose={handleClose}
        getRecruitmentCompletedDrive={getRecruitmentCompletedDrive}
        getRecruitmentPendingDrive={getRecruitmentPendingDrive}
        comStatus={comStatus}
        penStatus={penStatus}
        pageNumber={pageNumber}
      ></ResuableAdminDialogBox>

      {dialogue && (
        <ResuableAdminEditDialogBox
          open={dialogue}
          handleClose={handleDialogueClose}
          selectedRecruiterData={selectedRecruiterData}
          recruitedAlone={recruitedAlone}
          // firstIndexData={firstIndexData}
          setRecruiterAlone={setRecruiterAlone}
          recruiterId={recruiterId}
          pageNumber={pageNumber}
          getRecruitmentCompletedDrive={getRecruitmentCompletedDrive}
          getRecruitmentPendingDrive={getRecruitmentPendingDrive}
          comStatus={comStatus}
          penStatus={penStatus}
          recruiterFinalData={recruiterFinalData}
          totalRecruitersData={totalRecruitersData}
          categoryData={categoryData}
        />
      )}
      <Menu
        id="positioned-demo-menu"
        anchorEl={anchor}
        open={openThreeDots}
        onClose={handleCloseThreeDots}
        aria-labelledby="positioned-demo-button"
        placement="bottom-end"
      >
        <MenuItem onClick={handleThreeDotsDismiss}>Dismiss</MenuItem>
        <MenuItem onClick={handleThreeDotsHold}>
          {HoldStatus === 0 ? "Hold" : "Unhold"}
        </MenuItem>
      </Menu>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}
